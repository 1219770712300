
export const ClmWindowStyle = () => { };



ClmWindowStyle.printWrapper = {
  padding: "20px",
  maxWidth: "1650px",
  margin: "auto",
  fontFamily: "Arial"
};
ClmWindowStyle.sectionWrapper = {
  marginTop: "10px",
  background: "#fff",
  boxShadow: "0 2px 10px 0 #3a35411a",
  fontFamily: "Arial"
};

ClmWindowStyle.headerTable = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontFamily: "Arial"
};

ClmWindowStyle.sectionTitle = {
  color: "#44403c",
  fontWeight: "700",
  fontSize: "14px",
  display: "flex",
  alignItems: "center",
  padding: "10px 5px 0px",
  fontFamily: "Arial"
};

ClmWindowStyle.txtTotal = {
  fontSize: "13px",
  color: "#545454",
  fontFamily: "Arial"
};
ClmWindowStyle.txtNum = {
  fontWeight: "bold",
  fontFamily: "Arial"
};
ClmWindowStyle.txtTitle = {
  fontSize: "14px",
  color: "#44403c",
  fontWeight: "bold",
  fontFamily: "Arial"
};

ClmWindowStyle.tableStyle = {
  borderCollapse: "collapse",
  border: "1px solid rgba(1, 1, 1, 1)",
  width: "100%",
  fontFamily: "Arial"
}
ClmWindowStyle.thStyle = {
  padding: "8px 10px",
  background: "#eee",
  border: "1px solid rgba(1, 1, 1, 1)",
  fontSize: "11px",
  fontWeight: "bold",
  fontSize: "12px",
  letterSpacing: "0px",
  textAlign: "center",
  color: "#000000",
  fontWeight: "bold",
  backgroundColor: "#f8f8e9",
  paddingRight: "5px",
  paddingLeft: "5px",
  fontFamily: "Arial"
}
ClmWindowStyle.tdStyle = {
  border: "1px solid rgba(1, 1, 1, 1)",
  height: "30px",
  fontSize: "11px",
  padding: "0px 5px",
  fontSize: "12px",
  letterSpacing: "0px",
  color: "#000000",
  backgroundColor: "#FFFFFF",
  paddingRight: "5px",
  paddingLeft: "5px",
  fontFamily: "Arial"
}

ClmWindowStyle.btnStyle = {
  height: "25px",
  minWidth: "80px",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  display: "inline- block",
  lineHeight: "10px",
  border: "solid 1px gray",
  borderRadius: "5px",
  padding: "6px 16px",
  backgroundColor: "white",
  fontFamily: "Arial"
}
ClmWindowStyle.h3Style = {
  display: "inline-flex",
  alignItems: "center",
  margin: "0",
  fontWeight: "bold",
  color: "#44403c",
  fontSize: "14px",
  marginRight: "8px",
  fontFamily: "Arial"
}

ClmWindowStyle.bxSearch2FixedColumn1 = {
  display: "grid",
  gap: "0px 0px",
  gridTemplateColumns: "repeat(auto-fill, minmax(100%, 100%))",
  flex: "1 1 auto",
  borderLeft: "1px solid #3a35411f",
  fontFamily: "Arial"
}
ClmWindowStyle.bxSearch2FixedColumn2 = {
  display: "grid",
  gap: "0px 0px",
  gridTemplateColumns: "repeat(auto-fill, minmax(50%, 50%))",
  flex: "1 1 auto",
  borderLeft: "1px solid #3a35411f",
  fontFamily: "Arial"
}
ClmWindowStyle.bxSearch2FixedColumn3 = {
  display: "grid",
  gap: "0px 0px",
  gridTemplateColumns: "repeat(auto-fill, minmax(33.33%, 33.33%))",
  flex: "1 1 auto",
  borderLeft: "1px solid #3a35411f",
  fontFamily: "Arial"
}
ClmWindowStyle.filterTitle = {
  display: "flex",
  justifyContent: "space-between",
  fontSize: "14px",
  minHeight: "40px",
  borderTop: "1px solid rgba(58, 53, 65, 0.12)",
  alignItems: "center",
  padding: "4px 10px 4px",
  fontFamily: "Arial"
}

ClmWindowStyle.bxItem = {
  display: "flex",
  alignItems: "center",
  minHeight: "35px",
  borderTop: "1px solid #3a35411f",
  borderBottom: "1px solid #3a35411f",
  marginTop: "-1px",
  fontFamily: "Arial"
}

ClmWindowStyle.divLabel = {
  background: "#f2f2f2",
  height: "100%",
  display: "inline-flex",
  alignItems: "center",
  borderRight: "1px solid #3a35411f",
  fontFamily: "Arial"
}

ClmWindowStyle.txtLabel = {
  display: "inline-block",
  padding: "0 10px",
  textAlign: "right",
  width: "160px",
  flex: "0 0 auto",
  color: "#44403c",
  lineHeight: "1.2",
  fontSize: "12px",
  fontWeight: "600",
  fontFamily: "Arial"
}
ClmWindowStyle.bxInput = {
  flex: "1 1 auto",
  padding: "0 6px",
  borderRight: "1px solid #3a35411f",
  display: "inline-flex",
  alignItems: "center",
  height: "100%",
  fontSize: "12px",
  gap: "10px",
  wordBreak: "break-word",
  fontFamily: "Arial"
}



ClmWindowStyle.tableOverlapStyle = {
  borderCollapse: "collapse",
  border: "1px solid rgba(58, 53, 65, 0.12)",
  width: "100%",
  fontFamily: "Arial"
}
ClmWindowStyle.thOverlapStyle = {
  padding: "8px 10px",
  background: "#eee",
  border: "1px solid rgba(58, 53, 65, 0.12)",
  fontSize: "11px",
  fontWeight: "bold",
  fontFamily: "Arial"
}
ClmWindowStyle.tdOverlapStyleCenterAlign = {
  border: "1px solid rgba(58, 53, 65, 0.12)",
  height: "30px",
  fontSize: "11px",
  padding: "0px 5px",
  textAlign: "center",
  fontFamily: "Arial"
}
ClmWindowStyle.tdOverlapStyleLeftAlign = {
  border: "1px solid rgba(58, 53, 65, 0.12)",
  height: "30px",
  fontSize: "11px",
  padding: "0px 5px",
  textAlign: "left",
  fontFamily: "Arial"
}
ClmWindowStyle.tdOverlapStyleRightAlign = {
  border: "1px solid rgba(58, 53, 65, 0.12)",
  height: "30px",
  fontSize: "11px",
  padding: "0px 5px",
  textAlign: "right",
  fontFamily: "Arial"
}

ClmWindowStyle.titleWrapper = {
  width: "100%",
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  fontFamily: "Arial"
};
ClmWindowStyle.layoutTitle = {
  width: "62%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "30px",
  fontFamily: "Arial"
};
ClmWindowStyle.titlePage = {
  display: "flex",
  flexDirection: "column",
  gap: "5px",
  alignItems: "start",
  fontFamily: "Arial"
};
ClmWindowStyle.groupColumn = {
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  padding: "5px 0px",
  fontFamily: "Arial"
};
ClmWindowStyle.coverSheetPrintWrapper = {
  padding: "20px",
  maxWidth: "1250px",
  margin: "auto",
  fontFamily: "Arial"
};

ClmWindowStyle.coverSheetDivLabel = {
  background: "#f2f2f2",
  height: "100%",
  display: "inline-flex",
  alignItems: "center",
  width: "135px",
  borderRight: "1px solid #3a35411f",
  fontFamily: "Arial"
}


ClmWindowStyle.coverSheetTxtLabel = {
  display: "inline-block",
  padding: "0 10px",
  textAlign: "right",
  width: "130px",
  color: "#44403c",
  lineHeight: "1.2",
  fontSize: "12px",
  fontWeight: "600",
  fontFamily: "Arial"
}
ClmWindowStyle.coverPrintSheetTxtLabel = {
  display: "inline-block",
  padding: "0 10px",
  textAlign: "right",
  width: "130px",
  color: "#44403c",
  lineHeight: "1.2",
  fontSize: "12px",
  fontWeight: "600",
  fontFamily: "Arial"
}

////SCM/////////////////////////////////

ClmWindowStyle.scmPrintWrapper = {
  padding: "20px",
  maxWidth: "1250px",
  margin: "auto",
  fontFamily: "Arial"
};

ClmWindowStyle.scmHeaderButtonWrapper = {
  display: "flex",
  justifyContent: "end",
  gap: "3px",
  fontFamily: "Arial"
};

ClmWindowStyle.scmFilterTitle = {
  display: "flex",
  justifyContent: "space-between",
  fontSize: "14px",
  minHeight: "40px",
  borderTop: "1px solid rgba(58, 53, 65, 0.12)",
  alignItems: "center",
  padding: "4px 10px 4px",
  fontFamily: "Arial"
};

ClmWindowStyle.scmHeaderButton = {
  height: "25px",
  minWidth: "80px",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  display: "inlineBlock",
  lineHeight: "10px",
  border: "solid 1px gray",
  borderRadius: "5px",
  padding: "6px 16px",
  fontFamily: "Arial"
};
ClmWindowStyle.scmSectionWrapper = {
  marginTop: "10px",
  background: "#fff",
  boxShadow: "0 2px 10px 0 #3a35411a",
  fontFamily: "Arial"
};
ClmWindowStyle.scmTxtTitle = {
  fontSize: "14px",
  color: "#44403c",
  fontWeight: "bold",
  fontFamily: "Arial"
};
ClmWindowStyle.scmActionHeader = {
  display: "flex",
  alignItems: "center",
  gap: "15px",
  fontFamily: "Arial"
};
ClmWindowStyle.scmFieldRequired = {
  color: "#d92d20",
  marginRight: "2px",
  fontFamily: "Arial"
};
ClmWindowStyle.scmCustomDate = {
  display: "flex",
  gap: "5px",
  alignItems: "center",
  fontFamily: "Arial"
};
ClmWindowStyle.scmBxSearch2FixedColumn1 = {
  display: "grid",
  gap: "0px 0px",
  gridTemplateColumns: "repeat(auto-fill, minmax(100%, 100%))",
  flex: "1 1 auto",
  borderLeft: "1px solid #3a35411f",
  fontFamily: "Arial"
}
ClmWindowStyle.scmBxSearch2FixedColumn2 = {
  display: "grid",
  gap: "0px 0px",
  gridTemplateColumns: "repeat(auto-fill, minmax(50%, 50%))",
  flex: "1 1 auto",
  borderLeft: "1px solid #3a35411f",
  fontFamily: "Arial"
}
ClmWindowStyle.scmBxSearch2FixedColumn3 = {
  display: "grid",
  gap: "0px 0px",
  gridTemplateColumns: "repeat(auto-fill, minmax(33.33%, 33.33%))",
  flex: "1 1 auto",
  borderLeft: "1px solid #3a35411f",
  fontFamily: "Arial"
}
ClmWindowStyle.scmBxSearch2FixedColumn4 = {
  display: "grid",
  gap: "0px 0px",
  gridTemplateColumns: "repeat(auto-fill, minmax(25%, 25%))",
  flex: "1 1 auto",
  borderLeft: "1px solid #3a35411f",
  fontFamily: "Arial"
}

ClmWindowStyle.scmBxItem = {
  display: "flex",
  alignItems: "center",
  minHeight: "35px",
  borderTop: "1px solid #3a35411f",
  borderBottom: "1px solid #3a35411f",
  marginTop: "-1px",
  fontFamily: "Arial"
}

ClmWindowStyle.scmDivLabel = {
  background: "#f2f2f2",
  height: "100%",
  display: "inline-flex",
  alignItems: "center",
  borderRight: "1px solid #3a35411f",
  fontFamily: "Arial"
}

ClmWindowStyle.scmTxtLabel = {
  display: "inline-block",
  padding: "0 10px",
  textAlign: "right",
  width: "160px",
  flex: "0 0 auto",
  color: "#44403c",
  lineHeight: "1.2",
  fontSize: "12px",
  fontWeight: "600",
  fontFamily: "Arial"
}

ClmWindowStyle.scmBxInput = {
  flex: "1 1 auto",
  padding: "0 6px",
  borderRight: "1px solid #3a35411f",
  display: "inline-flex",
  alignItems: "center",
  height: "100%",
  fontSize: "12px",
  gap: "10px",
  wordBreak: "break-word",
  fontFamily: "Arial"
}
ClmWindowStyle.scmHeaderTable = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontFamily: "Arial"
}
ClmWindowStyle.scmSectionTitle = {
  color: "#44403c",
  fontWeight: "700",
  fontSize: "14px",
  display: "flex",
  alignItems: "center",
  padding: "10px 5px 0px",
  fontFamily: "Arial"
}
ClmWindowStyle.scmH3Style = {
  display: "inline-flex",
  alignItems: "center",
  margin: "0",
  fontWeight: "bold",
  color: "#44403c",
  fontSize: "14px",
  marginRight: "8px",
  fontFamily: "Arial"
}

ClmWindowStyle.scmTableStyle = {
  borderCollapse: "collapse",
  border: "1px solid rgba(58, 53, 65, 0.12)",
  width: "100%",
  fontFamily: "Arial"
}
ClmWindowStyle.scmThStyle = {
  padding: "8px 10px",
  background: "#eee",
  border: "1px solid rgba(58, 53, 65, 0.12)",
  fontSize: "11px",
  fontWeight: "bold",
  fontFamily: "Arial"
}
ClmWindowStyle.scmTdStyle = {
  border: "1px solid rgba(58, 53, 65, 0.12)",
  height: "30px",
  fontSize: "11px",
  padding: "0px 5px",
  fontFamily: "Arial"
}
