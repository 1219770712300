import * as wijmo from "@grapecity/wijmo";
import * as wjCore from "@grapecity/wijmo";
import { CollectionView, createElement, format, toggleClass } from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid";
import * as wjGrid from "@grapecity/wijmo.grid";
import { DataMap, GroupRow } from "@grapecity/wijmo.grid";
import { FlexGridXlsxConverter } from '@grapecity/wijmo.grid.xlsx';
import { InputDate, InputNumber } from "@grapecity/wijmo.input";
import { FlexGrid } from "@grapecity/wijmo.react.grid";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import UnfoldLessRoundedIcon from "@mui/icons-material/UnfoldLessRounded";
import UnfoldMoreRoundedIcon from "@mui/icons-material/UnfoldMoreRounded";
import { Button } from "@mui/material";
import close from "assets/icons/close.svg";
import wait from "assets/images/waitimage.gif";
import "assets/styles/pages/PagesCommon.scss";
import Breadcrumb from "common/components/ComBreadcrumb";
import ComCombo from 'common/components/ComCombo';
import ComDateNotRequired from "common/components/ComDateNotRequired";
import { ComNoticfication } from "common/components/ComNoticfication";
import ComRadioBox from "common/components/ComRadioBox";
import ComTextArea from "common/components/ComTextArea";
import ComTextBox from "common/components/ComTextBox";
import { ApiManager } from 'common/lib/ApiUtil';
import { ComUtils } from "common/lib/ComUtils";
import { FileUtils } from "common/lib/FileUtils";
import { MessageContext } from "common/lib/MessageProvider";
import _ from "lodash";
import moment from 'moment/moment';
// import IrDateSoldValidationPop from "Pages/Pop/Clm/IrDateSoldValidationPop";
import SerialInvoiceUploadPop from "Pages/Pop/Clm/SerialInvoiceUploadPop";
import SerialRegPop from 'Pages/Pop/Clm/SerialRegPop';
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import cookie from "react-cookies";
import ReactDOMServer from 'react-dom/server';
import IrConfDtlList from "./IrConfDtlList";
import IrConfScmDtlList from "./IrConfScmDtlList";
import IrDateSoldValidationInfo from "./IrDateSoldValidationInfo";
import IrSpiffDateOverlapReqDtl from "./IrSpiffDateOverlapReqDtl";

function IrSpiffConfDtl({ pageInfo, closeFn, reqClaimType, reqClaimId, reqActionTarget, reqUserLevel, dtlRandomKey, popupYn }) {

  // 메세지 추가
  const { messageObject } = useContext(MessageContext);

  const successMessage = ComUtils.getMessage(messageObject, "SMSG_COM_INF_001");          //Processed Successfully
  const savedMessage = ComUtils.getMessage(messageObject, "SMSG_COM_INF_004");            //Saved Successfully
  const revertedMessage = ComUtils.getMessage(messageObject, "SMSG_COM_INF_010");         //Claim Reverted Successfully
  const revertMessage = ComUtils.getMessage(messageObject, "SMSG_COM_INF_012");           //Deleted selected item.
  const approvedMessage = ComUtils.getMessage(messageObject, "SMSG_COM_INF_008");         //Approved Successfully
  const dateSoldErrMsg = ComUtils.getMessage(messageObject, "JMSG_COMF_ERR_002");         //The start date must be later than the end date.
  const claimRejectedMessage = ComUtils.getMessage(messageObject, "SMSG_COM_INF_009");    //Claim Rejected

  //error 메시지

  const msgClmErr004 = ComUtils.getMessage(messageObject, "CLM_ERR_00004");               //Invalid date type.
  const msgClmErr006 = ComUtils.getMessage(messageObject, "CLM_ERR_00006");               //Over CM amount should be less than Approved amount.
  const msgClmErr010 = ComUtils.getMessage(messageObject, "CLM_ERR_00010");               //Cust. unit Reimburse amount should be less than LG Unit Reimburse amount.
  const msgClmErr011 = ComUtils.getMessage(messageObject, "CLM_ERR_00011");               //An error occurred during processing
  const msgClmErr027 = ComUtils.getMessage(messageObject, "CLM_ERR_00027");               //The file name is too long
  const msgClmErr029 = ComUtils.getMessage(messageObject, "CLM_ERR_00029");               //Wrong file format. Please attach PDF or image file formats.
  const msgClmErr035 = ComUtils.getMessage(messageObject, "CLM_ERR_00035");               //Please check Serial Number Validation(Duplicaton) again
  const msgClmErr043 = ComUtils.getMessage(messageObject, "CLM_ERR_00043");               //To Check serial No, input quantity first please
  const msgClmErr044 = ComUtils.getMessage(messageObject, "CLM_ERR_00044");               //To Check serial No, input model first please.
  const msgClmErr048 = ComUtils.getMessage(messageObject, "CLM_ERR_00048");               //AR confirm Quantity can not be greater than Claim Quantity.
  const msgClmErr049 = ComUtils.getMessage(messageObject, "CLM_ERR_00049");               //Bill to is inactive.Please change Bill To.
  const msgClmErr050 = ComUtils.getMessage(messageObject, "CLM_ERR_00050");               //Activity From Date shouldn't be greater than Activity To Date.
  const msgClmErr051 = ComUtils.getMessage(messageObject, "CLM_ERR_00051");               //Activity Date shouldn't be greater than today.
  const msgClmErr052 = ComUtils.getMessage(messageObject, "CLM_ERR_00052");               //Entry person can't approve/reject this claim.
  const msgClmErr054 = ComUtils.getMessage(messageObject, "CLM_ERR_00054");               //You cannot approve when reimbursement amount is zero.
  const msgClmErr055 = ComUtils.getMessage(messageObject, "CLM_ERR_00055");               //You have already attached this file to this claim.
  const msgClmErr056 = ComUtils.getMessage(messageObject, "CLM_ERR_00056");               //There are no file changes.
  const msgClmErr058 = ComUtils.getMessage(messageObject, "CLM_ERR_00058");               //Please save this claim entry first.

  const msgClmErr185 = ComUtils.getMessage(messageObject, "CLM_ERR_00185");               //Can not get commercial invoice info.


  //confirm 메시지
  const msgClmCnf002 = ComUtils.getMessage(messageObject, "CLM_CNF_00002");               //Sell Out Date Range overlaps in the previous claim.\nDo you want to continue?\n
  const msgClmCnf010 = ComUtils.getMessage(messageObject, "CLM_CNF_00010");               //Claim Qty. is Exceed Allowed Qty. Are you sure to approve this claim?
  const msgClmCnf011 = ComUtils.getMessage(messageObject, "CLM_CNF_00011");               //Status will be changed to 'HOLD-PENDING'. Do you want to Continue ?
  const msgClmCnf013 = ComUtils.getMessage(messageObject, "CLM_CNF_00013");               //This will zero ar_conf_qty and change status to 'RVRS'. Do you want to reverse ?
  const msgComCnf010 = ComUtils.getMessage(messageObject, "COM_CNF_00010", ["the serial_no"]);  //Are you sure to save {0} ?
  const msgComCnf012 = ComUtils.getMessage(messageObject, "COM_INF_00012");               //There are no changes.


  //session
  const userId = sessionStorage.getItem("userId") ?? '';
  const userType = sessionStorage.getItem("userType") ?? '';
  const comCode = sessionStorage.getItem("comCode") ?? '';
  const userbillTo = sessionStorage.getItem("billTo") ?? '';
  const userLevel = (reqUserLevel ?? '') != '' ? reqUserLevel : (pageInfo.userLevel ?? '');
  const empNo = sessionStorage.getItem("empNo") ?? '';

  //url
  const retrieveConfListDtl = "/clm/isf/retrieveConfListDtl";
  const retrieveValidateSelloutPeriod = "/clm/isf/irSpiffClaimReq/retrieveValidateSelloutPeriod";
  const retrieveSelCodeList = "/com/comCode/retrieveSelCodeList";
  const updateConfDetail = "/clm/isf/updateConfDetail";
  const updateApproveRejectListEp = "/api/clm/isf/updateApproveRejectListEp";
  const updateConfDetailReview = "/clm/isf/updateConfDetailReview";

  const updateConfFileSave = "/clm/isf/updateConfFileSave";
  const deleteFile = "/Common/File/FileDelete";
  const retrieveClaimAttchFileByAttchId = "/clm/file/retrieveClaimAttchFileByAttchId";
  const retrieveClaimsDtl = "/clm/isf/retrieveClaimsDtl";

  const updateRevertConfDetail = "/clm/isf/updateRevertConfDetail";
  const updateReverseConfDetail = "/clm/isf/updateReverseConfDetail";
  const updatePullBackConfDetail = "/clm/isf/updatePullBackConfDetail";
  const updateApproveRejectList = "/clm/isf/updateApproveRejectList";
  const updateSpiffConfListDetailSerial = "/clm/isf/updateSpiffConfListDetailSerial";
  const retrieveDefaultSalesmanList = "/clm/billTo/retrieveDefaultSalesmanList";

  //D/S Validation
  const retrieveDateSoldValidationList = "/clm/isf/retrieveDateSoldValidationList";

  const gridRef = useRef(null);
  const entityCurRow = useRef(null);
  const popElement = useRef(null);
  const claimDtlPopElement = useRef(null);
  const overlapPopElement = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  // Approval Line Grid


  const [epLoginYn, setEpLoginYn] = useState(false);
  const [claimType, setClaimType] = useState(null);
  const entityClaimType = useRef(null);
  const entityFooterHit = useRef(false);

  const [dateSoldDupFlag, setDateSoldDupFlag] = useState(null);
  const [selApprovalStatus, setSelApprovalStatus] = useState(null);
  const entitySelApprovalStatus = useRef(null);
  const [createId, setCreateId] = useState(null);

  const entityStatus = useRef(null);
  const entityNetScmFrom = useRef(null);
  const entityNeScmCheck = useRef(null);
  const entityRejectCodeMap = useRef(null);
  const entityServerURL = useRef(null);
  const entityPopupYn = useRef(null);
  const entityActionTarget = useRef(null);
  const entitySellOutCreateDate = useRef(null);
  const [initFlag, setInitFlag] = useState(false);
  const [isSelloutDateChange, setIsSelloutDateChange] = useState(false);
  const [epScmLoginFlag, setEpScmLoginFlag] = useState(false);

  const buttonStyle = popupYn === 'Y' ? {
    height: '25px',
    minWidth: '80px',
    fontSize: '1.2rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    lineHeight: '10px'
  } : {};


  const [netScmFrom, setNetScmFrom] = useState(null);
  const [netScmCheck, setNetScmCheck] = useState(null);
  const [status, setStatus] = useState(null);
  const [claimStatus, setClaimStatus] = useState(null);
  const [cont, setCont] = useState(0);
  const [postAuditCustomer, setPostAuditCustomer] = useState(null);
  const [postAuditClaim, setPostAuditClaim] = useState(null);
  const entityPostAuditClaim = useRef(null);
  const entityActivityDateFrom = useRef(null);
  const entityActivityDateTo = useRef(null);

  const entityClaimAnalystEmpNo = useRef(null);
  const entityStoreGroup = useRef(null);
  const entityBillTo = useRef(null);
  const entityOrgBillTo = useRef(null);

  //spiff
  const [email, setEmail] = useState(null);
  const [tel, setTel] = useState(null);
  const [fax, setFax] = useState(null);
  const [storeAddress, setStoreAddress] = useState(null);
  const [userName, setUserName] = useState(null);
  const [address1, setAddress1] = useState(null);
  const [address2, setAddress2] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [zip, setZip] = useState(null);
  const [approvalStatus, setApprovalStatus] = useState(null);


  const [openSerialInvoiceUploadPop, setOpenSerialInvoiceUploadPop] = useState(null);

  const entityOverlapCustomerInfo = useRef(null);
  const entityOverlapClaimList = useRef(null);


  const entityCurDt = useRef(null);
  const entityCustEntry = useRef(null);
  const entityMaxAdd = useRef(null);

  const entityApproveComment = useRef(null);

  const entityMaxAddRow = useRef(null);
  const entityAddRow = useRef(null);
  const rejectCheck = useRef(true);


  const [billTo, setBillTo] = useState(null);
  const [billToName, setBillToName] = useState(null);
  const [promotionNo, setPromotionNo] = useState(null);
  const [programId, setProgramId] = useState(null);
  const entitySalesmanCode = useRef(null);
  const entitySalesmanName = useRef(null);
  const entityParamSalesman = useRef(null);


  const [debitMemo, setDebitMemo] = useState(null);
  const [totalDebitAmt, setTotalDebitAmt] = useState(null);
  const entityComment = useRef(null);
  const entityLgComment = useRef(null);
  const entityReview = useRef(null);
  const [accumClaimQty, setAccumClaimQty] = useState(null);
  const [totalClaimAmt, setTotalClaimAmt] = useState(null);
  const [accumClaimAmt, setAccumClaimAmt] = useState(null);
  const [accumClaimOverAmt, setAccumClaimOverAmt] = useState(null);
  const [totalClaimOverQty, setTotalClaimOverQty] = useState(null);

  const entityClaimEntryType = useRef(null);

  const [viewApprovalLine, setViewApprovalLine] = useState(null);

  const gridObject = useRef();
  const filterObject = useRef();
  const [gridColGroup, setGridColGroup] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [gridDataCount, setGridDataCount] = useState(0);


  const gridObjectDtl = useRef();
  const filterObjectDtl = useRef();
  const [gridColGroupDtl, setGridColGroupDtl] = useState(null);
  const [gridDataDtl, setGridDataDtl] = useState(null);
  const [gridDataCountDtl, setGridDataCountDtl] = useState(0);

  const randomKey = useRef(null);

  const [fileList, setFileList] = useState([]);
  const fileElement = useRef(null);
  const entityFileList = useRef(null);
  const fileChkYn = useRef("N");

  //serial
  const entitySerial = useRef(null);
  const entitySerialRow = useRef(null);
  const entityResult = useRef(null);
  const entityMsg = useRef(null);
  const entityQty = useRef(null);
  const entityProgramId = useRef(null);
  const [openSerialRegPop, setOpenSerialRegPop] = useState(false);

  const [linkClasses, setLinkClasses] = useState(
    fileList?.map(() => 'file-link-blue')
  );

  //IrDateSoldValidationPop pop
  // const [openIrDateSoldValidationPop, setOpenIrDateSoldValidationPop] = useState(null);

  const defaultData = {
    period: "",
    model: "",
    spiffIrAmount: null,
    reimbursementAmount: "",
    claimQty: null,
    arConfQty: 0,
    rejectQty: 0,
    reimburAmount: null,
    rejectCode: "",
    rejectComment: "",
    status: "",
    netSellInQty: "",
    netApprovedQty: "",
    netRemainingQty: "",
    netAprvAdhocQty: "",
    dateSoldFrom: null,
    dateSoldTo: null,
    scmSellThru: "",
    scmSellThruQty: "",
    scmSellThruGDMI: "",
    scmSellThruOLAP: "",
    claimedQty: "",
    netScmrRemainingQty: "",
    dateOverlap: "",
    custUnitReimburse: "",
    overCmAmt: "",
    netScmSellThru: "",
    netScmSellThruClaim: "",
    selApprovalStatus: "",
    netScmAllowedQty: "",
    rejectComment2: "",
    claimStatus: "",
    dateRange: "",
    rejectCount: "",
    weekNo: "",
    orgArConfQty: "",
    allowedQty: "",
    claimId: reqClaimId,
    netScmConfirm: "",
    netScmCheck: "",
    uiFlag: "I",
    claimSeq: "",
    arConfirmQty: 0


  };

  const initFilter = (f) => {
    filterObject.current = f;
  };

  const initGrid = (sender) => {
    gridObject.current = sender;
    ComUtils.gridInit(sender, {
      hasShowNumbering: true,
      selectionMode: 6,
      canAreaInfo: false,
      hasCheckBox: false,
      showCheckAll: false,
      hasDisableCheck: false,

    });

    sender.beginningEdit.addHandler((s, e) => {

      // 셀수정 가능 여부 체크
      let dataItem = e.getRow()?.dataItem;
      let binding = e.getColumn()?.binding;
      //alert(binding);
      if (binding === "model"
        || binding === "claimQty"
        || binding === "reimburAmount"
      ) {
        if (dataItem.uiFlag !== "I") {
          e.cancel = true;
        }
      } else if (binding === "arConfQty") {
        if (dataItem.status === "HOLD") {
          if (entitySelApprovalStatus.current == "TO-DO" || dataItem.claimStatus == "A2") {
            e.cancel = false;
          } else {
            e.cancel = true;
          }
        } else {
          e.cancel = true;
        }
      } else if (binding === "rejectCode") {
        if (dataItem.status !== "HOLD") {
          e.cancel = true;
        }
      } else if (binding === "rejectComment") {
        if (dataItem.status !== "HOLD") {
          e.cancel = true;
        }
      } else if (binding === "dateSoldFrom") {
        if (dataItem.status !== "HOLD") {
          e.cancel = true;
        }
      } else if (binding === "dateSoldTo") {
        if (dataItem.status !== "HOLD") {
          e.cancel = true;
        }
      } else if (binding === "dateOverlapCombo") {
        if (dataItem.uiFlag !== "U") {
          e.cancel = true;
        }
      }
      //SPIFF
      if (binding === "arConfirmQty") {
        if (dataItem.status === "HOLD") {
          if (entitySelApprovalStatus.current == "TO-DO" || dataItem.claimStatus == "A2") {
            e.cancel = false;
          } else {
            e.cancel = true;
          }
        } else {
          e.cancel = true;
        }
      } else if (binding === "rejectCodeDtl") {
        if (dataItem.status !== "HOLD") {
          e.cancel = true;
        }
      } else if (binding === "rejectCommentDtl") {
        if (dataItem.status !== "HOLD") {
          e.cancel = true;
        }
      } else if (binding === "custUnitReimburse") {
        if (dataItem.status !== "HOLD") {
          e.cancel = true;
        }
      } else if (binding === "overCmAmt") {
        if (dataItem.status !== "HOLD") {
          e.cancel = true;
        }
      }


    });

    sender.cellEditEnded.addHandler((s, e) => {
      let binding = e.getColumn().binding;
      console.log("cellEditEnded==" + entityClaimType.current);
      if (entityClaimType.current === "IR") {
        if (binding === "arConfQty") {

          if (Number(s.rows[e.row].dataItem.arConfQty) > Number(s.rows[e.row].dataItem.claimQty)) {
            ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr048);
            s.rows[e.row].dataItem.arConfQty = s.rows[e.row].dataItem.orgArConfQty;
            s.rows[e.row].dataItem.reimburAmount = Number(s.rows[e.row].dataItem.arConfQty) * Number(s.rows[e.row].dataItem.reimbursementAmount);
          } else {
            s.rows[e.row].dataItem.reimburAmount = Number(s.rows[e.row].dataItem.arConfQty) * Number(s.rows[e.row].dataItem.reimbursementAmount);
          }

          s.rows[e.row].dataItem.rejectQty = Number(s.rows[e.row].dataItem.claimQty) - Number(s.rows[e.row].dataItem.arConfQty);

        } else if (binding === "overCmAmt") {

          if (s?.rows[e.row]?.dataItem?.overCmAmt) {
            let overCmAmt = Number(s?.rows[e.row]?.dataItem?.arConfQty) * ((Number(s?.rows[e.row]?.dataItem?.reimbursementAmount) - Number(s?.rows[e.row]?.dataItem?.custUnitReimburse)));
            let overCmAmtLimit = Number(s?.rows[e.row]?.dataItem?.arConfQty) * Number(s?.rows[e.row]?.dataItem?.reimbursementAmount);
            let overCmAmtInput = Number(s?.rows[e.row]?.dataItem?.overCmAmt);

            if (Number(overCmAmtInput) >= Number(overCmAmtLimit)) {
              ComNoticfication.ShowAlertUI(ComUtils._altTitleInfo, msgClmErr006);
              s.rows[e.row].dataItem.overCmAmt = overCmAmt;
            }
          } else {
            s.rows[e.row].dataItem.overCmAmt = null;
          }
        } else if (binding === "rejectCode") {
          if (s?.rows[e.row]?.dataItem?.rejectCode) {

            let findRow = entityRejectCodeMap?.current?.find(r => r.cd === s?.rows[e.row]?.dataItem?.rejectCode);
            if (findRow) {
              s.rows[e.row].dataItem.rejectComment = findRow?.locDescription;
            } else {
              s.rows[e.row].dataItem.rejectComment = '';
            }
          } else {
            s.rows[e.row].dataItem.rejectComment = '';
          }
        }


        //SPIFF
      } else {
        if (binding === "arConfirmQty") {

          if (Number(s.rows[e.row].dataItem.arConfirmQty) > Number(s.rows[e.row].dataItem.qty)) {
            ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr048);
            s.rows[e.row].dataItem.arConfirmQty = 0;
          }
          calculateCMAmt(e.row);
        } else if (binding === "overCmAmt") {

          if (s?.rows[e.row]?.dataItem?.overCmAmt) {
            let overCmAmt = Number(s?.rows[e.row]?.dataItem?.arConfirmQty) * ((Number(s?.rows[e.row]?.dataItem?.spiffIrAmount) - Number(s?.rows[e.row]?.dataItem?.custUnitReimburse)));
            let overCmAmtLimit = Number(s?.rows[e.row]?.dataItem?.arConfirmQty) * Number(s?.rows[e.row]?.dataItem?.spiffIrAmount);
            let overCmAmtInput = Number(s?.rows[e.row]?.dataItem?.overCmAmt);

            if (Number(overCmAmtInput) >= Number(overCmAmtLimit)) {
              ComNoticfication.ShowAlertUI(ComUtils._altTitleInfo, msgClmErr006);
              s.rows[e.row].dataItem.overCmAmt = overCmAmt;
            }
          } else {
            s.rows[e.row].dataItem.overCmAmt = null;
          }
        } else if (binding === "rejectCodeDtl") {
          if (s?.rows[e.row]?.dataItem?.rejectCodeDtl) {

            let findRow = entityRejectCodeMap?.current?.find(r => r.cd === s?.rows[e.row]?.dataItem?.rejectCodeDtl);
            if (findRow) {
              s.rows[e.row].dataItem.rejectCommentDtl = findRow?.locDescription;
            } else {
              s.rows[e.row].dataItem.rejectCommentDtl = '';
            }
          } else {
            s.rows[e.row].dataItem.rejectCommentDtl = '';
          }
        }
      }

      ////commom/////

      if (binding === "dateSoldFrom") {
        if (s.rows[e.row].dataItem.dateSoldFrom) {


          let chkDateSoldFrom = moment(ComUtils.DateToInteger(s.rows[e.row].dataItem.dateSoldFrom) + "", "YYYYMMDD").format("YYYY-MM-DD");
          if (!ComUtils.checkValidDate(chkDateSoldFrom)) {
            ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr004);
            s.rows[e.row].dataItem.dateSoldFrom = null;
            gridObject.current.select(e.row, e.col);
            return;
          }
          if (!handleSalesDateCheck(s.rows[e.row].dataItem)) {
            s.rows[e.row].dataItem.dateSoldFrom = null;
            gridObject.current.select(e.row, e.col);
            return;
          }


        } else {

          s.rows[e.row].dataItem.dateSoldFrom = null;
        }
      } else if (binding === "dateSoldTo") {
        if (s?.rows[e.row]?.dataItem?.dateSoldTo) {

          let chkDateSoldTo = moment(ComUtils.DateToInteger(s.rows[e.row].dataItem.dateSoldTo) + "", "YYYYMMDD").format("YYYY-MM-DD");
          if (!ComUtils.checkValidDate(chkDateSoldTo)) {
            ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr004);
            s.rows[e.row].dataItem.dateSoldTo = null;
            gridObject.current.select(e.row, e.col);
            return;
          }

          if (!handleSalesDateCheck(s.rows[e.row].dataItem)) {
            s.rows[e.row].dataItem.dateSoldTo = null;
            gridObject.current.select(e.row, e.col);
            return;
          }

        } else {
          s.rows[e.row].dataItem.dateSoldTo = null;
        }
      } else if (binding === "custUnitReimburse") {
        calculateCMAmt(e.row);
      } else if (binding === "dateOverlapCombo") {
        if (s.rows[e.row].dataItem.dateOverlapCombo) {
          onDateOverlapCombo(s.rows[e.row].dataItem.dateOverlapCombo);
        }
      }

    });

    sender.formatItem.addHandler((s, e) => {

      if (sender.rows[e.row] instanceof GroupRow) {
        wijmo.removeClass(e.cell, 'wj-wrap');
      }

      if (e.panel === s.columnHeaders) {
        if (entityClaimType.current === "IR") {
          if (e.getColumn(e.col).binding === "rejectCode" && s.rows[e.row]?.dataItem?.status === "HOLD") {
            e.cell?.firstChild?.remove();
            let element = null;
            if (rejectCheck.current) {
              element = createElement(format(
                `<span class="txt-title" style="display: inline-block;"><label>Rejection<br>Reason</label><input type="checkbox" class="wj-column-selector wj-column-selector-group wj-cell-check" tabindex="-1"></label></span>`, {}), e.cell);
            }
            else {
              element = createElement(format(
                `<span class="txt-title" style="display: inline-block;"><label>Rejection<br>Reason</label><input type="checkbox" class="wj-column-selector wj-column-selector-group wj-cell-check" tabindex="-1" checked=true></lable></spen>`, {}), e.cell);
            }

            element.onchange = function (e) {
              e.preventDefault();
              fncCopyRejectReason.apply(e, [e]);
            }

          }
        } else {
          if (e.getColumn(e.col).binding === "rejectCodeDtl" && s.rows[e.row]?.dataItem?.status === "HOLD") {
            e.cell?.firstChild?.remove();
            let element = null;
            if (rejectCheck.current) {
              element = createElement(format(
                `<span class="txt-title" style="display: inline-block;"><label>Rejection<br>Reason</label><input type="checkbox" class="wj-column-selector wj-column-selector-group wj-cell-check" tabindex="-1"></label></span>`, {}), e.cell);
            }
            else {
              element = createElement(format(
                `<span class="txt-title" style="display: inline-block;"><label>Rejection<br>Reason</label><input type="checkbox" class="wj-column-selector wj-column-selector-group wj-cell-check" tabindex="-1" checked=true></lable></spen>`, {}), e.cell);
            }

            element.onchange = function (e) {
              e.preventDefault();
              fncCopyRejectReason.apply(e, [e]);
            }

          }
        }

        if (e.getColumn(e.col).binding === "dateSoldFrom") {
          e.cell?.firstChild?.remove();
          let element = createElement(format(
            `<span class="txt-title" style="display: inline-block;"><label>Start Date </label><input type="button" value="Copy All"></label></span>`, {}), e.cell);
          element.querySelector('input[type="button"]').onclick = function (e) {
            e.preventDefault();
            onCopy("dateSoldFrom");
          }
        } else if (e.getColumn(e.col).binding === "dateSoldTo") {
          e.cell?.firstChild?.remove();
          let element = createElement(format(
            `<span class="txt-title" style="display: inline-block;"><label>End Date </label><input type="button" value="Copy All"></label></span>`, {}), e.cell);
          element.querySelector('input[type="button"]').onclick = function (e) {
            e.preventDefault();
            onCopy("dateSoldTo");
          }
        } else if (e.getColumn(e.col).binding === "serialG") {
          e.cell?.firstChild?.remove();
          let element = createElement(format(
            `<span class="txt-title" style="display: inline-block;"><label>Serial # </label><input type="button" value="Update"></label></span>`, {}), e.cell);
          element.querySelector('input[type="button"]').onclick = function (e) {
            e.preventDefault();
            onSerialSave();
          }
        }


      }
    });

    sender.addEventListener(sender.hostElement, "click", (e) => {

      // 드롭 다운 목록에서 선택된 항목 collectionView에 반영
      if (e.path?.indexOf(sender.hostElement) == -1) {
        sender.collectionView.commitEdit();
      }
    });

    sender.columnFooters.rows.push(new GroupRow());
    sender.refresh();
    // sender.mergeManager = new GridUserMergeFooter(gridObject.current);
  };

  const editingCell = (s, e) => {
    setTimeout(() => {
      let host = wjCore.closestClass(s.activeEditor, "wj-control");
      let editor = wjCore.Control.getControl(host);
      let btn = s.activeCell.querySelector(".wj-btn");
      let binding = e.getColumn().binding;

      // 드롭다운 목록 창 표시
      if (btn && (binding === "rejectCode" || binding === "rejectCodeDtl" || binding === "dateOverlapCombo")) {
        if (editor && !(editor instanceof wjcGrid.FlexGrid)) {
          editor.isDroppedDown = true;
        } else {
          btn.click();
        }
      }
    });
  }


  const onSerialSave = () => {
    let flexGrid = gridObject.current;
    let checkSerialNo = "";
    let chkCnt = 0;
    for (let inx = 0; inx < flexGrid.rows.length; inx++) {
      if (flexGrid.rows[inx].dataItem.serial != null && flexGrid.rows[inx].dataItem.serial !== "") {
        let serialNumber = flexGrid.rows[inx].dataItem.serial;
        let serialResult = flexGrid.rows[inx].dataItem.result;
        let changeSerialNo = flexGrid.rows[inx].dataItem.changeSerialNo;
        if (serialResult === "2") {
          ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, msgClmErr035, () => {
            ComUtils.setGridFocus(gridObject, gridObject.current.selection.row, "serial");
          });
          return;
        }
        if (inx == 0) {
          checkSerialNo = checkSerialNo + serialNumber;
        } else {
          checkSerialNo = checkSerialNo + "," + serialNumber;
        }
        if (changeSerialNo === "Y") {
          chkCnt++;
        }
      }

    }

    if (chkCnt === 0) {
      ComNoticfication.ShowAlertUI(ComUtils._altTitleInfo, msgComCnf012);
      return;
    }

    //alert(checkSerialNo);
    let arrText = checkSerialNo.split(",");
    for (let i = 0; i < arrText.length; i++) {
      checkSerialNo = arrText[i];
      if (checkSerialNo.trim() != "" && fncIsDuplicate(arrText, checkSerialNo) > 0) {
        // 'Serial# ' + checkSerialNo + ' is duplicated within same claim.
        ComNoticfication.ShowErrorUI(
          ComUtils._altTitleError,
          ComUtils.getMessage(messageObject, "CLM_ERR_00101", [checkSerialNo])
        );
        return;

      }
    }

    ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, msgComCnf010, (r) => {
      if (r) {
        onSerialSaveExecute();
      }
    });
  }

  const onSerialSaveExecute = async () => {
    let sendParam = {}

    let flexGrid = gridObject.current;
    let dataList = [];
    flexGrid?.rows?.forEach((el) => {
      let newDataInfo = _.cloneDeep(el.dataItem);
      if (newDataInfo.changeSerialNo === "Y") {
        dataList.push(newDataInfo);
      }
    });

    sendParam.listData = dataList;

    let resultData = await ApiManager.post(updateSpiffConfListDetailSerial, sendParam);
    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr185);
      return;
    } else {
      if (resultData?.resultCode == -100) {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, resultData.resultMsg);
        return;
      } else {
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, successMessage, () => {
          setInitFlag(!initFlag);
        });
        return;
      }
    }
  };

  const fncIsDuplicate = (arrObj, strSelVal) => {
    var iResult = 0; // no duplication :0, duplicate :1
    var checkCnt = 0;
    var strVal;
    for (var k = 0; k < arrObj.length; k++) {
      strVal = arrObj[k].toString();
      if (strVal == strSelVal) {
        checkCnt++;
      }

    }

    if (checkCnt > 1) {
      iResult = 1;
    }

    return iResult;

  }

  const onDateOverlapCombo = async (pClaimId) => {

    let sendParam = {}
    sendParam.claimType = entityClaimType.current;
    sendParam.claimId = pClaimId;
    sendParam.userLevel = '1';
    sendParam.userType = userType;

    const width = 1560;
    const height = 800;
    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height / 2) - (height / 2);
    const printWindow = window.open('', '_blank', `width=${width},height=${height},top=${top},left=${left}`);

    if (!printWindow || printWindow.closed || typeof printWindow.closed === 'undefined') {
      if (popElement.current) {
        popElement.current.postMessage("Pop-ups are blocked. Please disable your pop-up blocker and try again.");
      } else if (claimDtlPopElement.current) {
        claimDtlPopElement.current.postMessage("Pop-ups are blocked. Please disable your pop-up blocker and try again.");
      } else if (overlapPopElement.current) {
        overlapPopElement.current.postMessage("Pop-ups are blocked. Please disable your pop-up blocker and try again.");
      }
      return;
    }


    let resultData = await ApiManager.post(retrieveClaimsDtl, sendParam);
    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr185);
      return;
    } else {
      if (resultData?.list?.length > 0) {
        let cnt = 0;
        for (let inx = 0; inx < resultData?.list?.length; inx++) {
          if (Number(resultData?.list[inx]?.rejectCount ?? 0) > 0) {
            cnt++;
            break;
          }
        }

        let element = <IrSpiffDateOverlapReqDtl
          claimType={entityClaimType.current}
          claimId={pClaimId}
          customerInfo={resultData?.list[0]}
          claimList={resultData?.list}
          fileList={resultData?.fileList}
          userId={userId}
          totRejectCount={cnt}
          userLevel={userLevel}
          userType={userType}
          serverUrl={resultData.serverURL}

        />
        const htmlString = ReactDOMServer.renderToString(element);

        let script = `
              <script>


                  function mergeCell(tbl, startRow, cNum, length2, add)
                  {
                    var isAdd = false;
                    if(tbl == null) return;
                    if(startRow == null || startRow.length == 0) startRow = 1;
                    if(cNum == null || cNum.length == 0) return ;
                    if(add  == null || add.length == 0) {
                      isAdd = false;
                    }else {
                      isAdd = true;
                      add   = parseInt(add);
                    }
                    cNum   = parseInt(cNum);
                    length2 = parseInt(length2);

                    rows   = tbl.rows;
                    rowNum = rows.length;

                    tempVal  = '';
                    cnt      = 0;
                    startRow = parseInt(startRow);

                    pos=0;
                    for( i = startRow; i < rowNum; i++ ) {
                      curVal = rows[i].cells[cNum].innerHTML;
                      if( curVal == tempVal ) {
                        if(cnt == 0) {
                          cnt++;
                          startRow = i - 1;
                        }
                        cnt++;
                      }else if(cnt > 0) {
                        merge(tbl, startRow, cnt, cNum, length2);
                        startRow = endRow = 0;
                        cnt = 0;
                      }else {
                      }
                      tempVal = curVal;
                    }

                    if(cnt > 0) {
                      merge(tbl, startRow, cnt, cNum, length2);
                    }
                  }

                  function merge(tbl, startRow, cnt, cellNum, length)
                  {
                    rows = tbl.rows;
                    row  = rows[startRow];

                    for( i = startRow + 1; i < startRow + cnt; i++ ) {
                      for( j = 0; j < length; j++) {
                        rows[i].deleteCell(cellNum);
                      }
                    }
                    for( j = 0; j < length; j++) {
                      row.cells[cellNum + j].rowSpan = cnt;
                    }
                  }
                </script>
                <script>
                  mergeCell(document.getElementById('tbl0'), '1', '0', '1', '0');
                </script>
                <script>
                  const fileDownTarget = document.querySelectorAll('.fileDown');

                  fileDownTarget.forEach(el => {
                    el.addEventListener('click', function() {
                      const fileId = el.getAttribute('data-id');
                      const fileSeq = el.getAttribute('data-seq');

                      const param = {
                        fileId: fileId,
                        fileSeq: fileSeq,
                        customPath: "ir"
                      }

                      //이후 서버통신 하여 파일다운로드 진행
                      console.log(param);
                      onFileDown(fileId, fileSeq);

                    });
                  });

                  async function onFileDown(fileId, fileSeq) {
                    try {

                      const requestData = {
                        fileId: fileId,
                        fileSeq: fileSeq,
                        customPath: document.getElementById("claimType").value.toLowerCase()
                      };
                      var fullUrl = document.getElementById("serverUrl").value + "/lgsp/api/clm/file/retrieveDateOverLapClaimAttchFileByAttchId";
                      const response = await fetch(fullUrl, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(requestData),
                      });

                      if (response.ok) {
                        const sBlob = await response.blob();

                        let filename = null;
                        let disposition = response.headers.get('content-disposition');
                        if (disposition && disposition.indexOf('attachment') !== -1) {
                          let filenameMatch = disposition.split(';').find(part => part.trim().startsWith('filename='));
                          if (filenameMatch) {
                            filename = filenameMatch.split('=')[1].trim().replace(/['"]/g, '');
                          }
                        }

                        const url = window.URL.createObjectURL(sBlob);
                        const link = document.createElement('a');

                        link.setAttribute('href', url);
                        link.setAttribute('download', filename);

                        document.body.appendChild(link);

                        link.click();

                        link.parentNode.removeChild(link);
                        window.URL.revokeObjectURL(url)
                      }

                    } catch (error) {
                      console.error('Error fetching accumDtl:', error);
                    } finally {
                    }
                  };

                  document.addEventListener('DOMContentLoaded', (event) => {
                    const selectElements = document.querySelectorAll('.dataOverlap');

                    selectElements.forEach((selectElement) => {
                      selectElement.addEventListener('change', (event) => {
                        const claimId = event.target.value;
                        if (claimId != null && claimId !== "") {
                          window.opener.postMessage({ type: 'confirmIrDateOverlapSelected', claimId }, '*');
                        }
                      });
                    });

                    const spiffSelectElements = document.querySelectorAll('.spiffDataOverlap');

                    spiffSelectElements.forEach((selectElement) => {
                      selectElement.addEventListener('change', (event) => {
                        const claimId = event.target.value;
                        if (claimId != null && claimId !== "") {
                          window.opener.postMessage({ type: 'confirmSpiffDateOverlapSelected', claimId }, '*');
                        }
                      });
                    });
                  });

                  window.addEventListener('message', function handleMessage(event) {
                    if (event.origin === window.location.origin) {
                      alert(event.data);
                    }
                  });
                </script>
                `;
        let printHtml = htmlString + script;

        printWindow.document.writeln(printHtml);
        printWindow.document.close();
        printWindow.document.title = "LG Electronics :: AIC";

        printWindow.focus();
        overlapPopElement.current = null;
        overlapPopElement.current = printWindow;
      }
    }
  }

  const calculateCMAmt = (idx) => {
    let flexGrid = gridObject.current;

    if (entityClaimType.current === "IR") {

      if (flexGrid?.rows[idx]?.dataItem?.custUnitReimburse) {
        if (flexGrid?.rows[idx]?.dataItem?.arConfQty == 0 || (flexGrid?.rows[idx]?.dataItem?.arConfQty == null)) {
          flexGrid.rows[idx].dataItem.arConfQty = 0;
          flexGrid.rows[idx].dataItem.overCmAmt = null;
          flexGrid.rows[idx].dataItem.custUnitReimburse = null;
        } else {

          if (Number(flexGrid?.rows[idx]?.dataItem?.custUnitReimburse) >= Number(flexGrid?.rows[idx]?.dataItem.reimbursementAmount ?? 0)) {
            ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr010);
            flexGrid.rows[idx].dataItem.custUnitReimburse = null;
            flexGrid.rows[idx].dataItem.overCmAmt = null;
          } else {
            flexGrid.rows[idx].dataItem.overCmAmt = Number(flexGrid?.rows[idx]?.dataItem?.arConfQty) * (Number(flexGrid?.rows[idx]?.dataItem.reimbursementAmount ?? 0) - Number(flexGrid?.rows[idx]?.dataItem?.custUnitReimburse));
          }

        }
      } else {
        if (flexGrid?.rows[idx]?.dataItem?.custUnitReimburse == 0) {
          flexGrid.rows[idx].dataItem.custUnitReimburse = 0;
          flexGrid.rows[idx].dataItem.overCmAmt = Number(flexGrid?.rows[idx]?.dataItem?.arConfQty) * (Number(flexGrid?.rows[idx]?.dataItem.reimbursementAmount ?? 0) - Number(flexGrid?.rows[idx]?.dataItem?.custUnitReimburse));
        } else {
          flexGrid.rows[idx].dataItem.custUnitReimburse = null;
          flexGrid.rows[idx].dataItem.overCmAmt = null;
        }
      }
      //SPIFF
    } else {
      if (flexGrid?.rows[idx]?.dataItem?.custUnitReimburse) {
        if (flexGrid?.rows[idx]?.dataItem?.arConfirmQty == 0 || (flexGrid?.rows[idx]?.dataItem?.arConfirmQty == null)) {
          flexGrid.rows[idx].dataItem.arConfirmQty = 0;
          flexGrid.rows[idx].dataItem.overCmAmt = null;
          flexGrid.rows[idx].dataItem.custUnitReimburse = null;
        } else {

          if (Number(flexGrid?.rows[idx]?.dataItem?.custUnitReimburse) >= Number(flexGrid?.rows[idx]?.dataItem.spiffIrAmount ?? 0)) {
            ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr010);
            flexGrid.rows[idx].dataItem.custUnitReimburse = null;
            flexGrid.rows[idx].dataItem.overCmAmt = null;
          } else {
            flexGrid.rows[idx].dataItem.overCmAmt = Number(flexGrid?.rows[idx]?.dataItem?.arConfirmQty) * (Number(flexGrid?.rows[idx]?.dataItem.spiffIrAmount ?? 0) - Number(flexGrid?.rows[idx]?.dataItem?.custUnitReimburse));
          }

        }
      } else {
        if (flexGrid?.rows[idx]?.dataItem?.custUnitReimburse == 0) {
          flexGrid.rows[idx].dataItem.custUnitReimburse = 0;
          flexGrid.rows[idx].dataItem.overCmAmt = Number(flexGrid?.rows[idx]?.dataItem?.arConfirmQty) * (Number(flexGrid?.rows[idx]?.dataItem.spiffIrAmount ?? 0) - Number(flexGrid?.rows[idx]?.dataItem?.custUnitReimburse));
        } else {
          flexGrid.rows[idx].dataItem.custUnitReimburse = null;
          flexGrid.rows[idx].dataItem.overCmAmt = null;
        }
      }
    }

  }

  const handleSalesDateCheck = (dataItem) => {

    let dateSoldFrom = ComUtils.DateToInteger(dataItem.dateSoldFrom);
    let dateSoldTo = ComUtils.DateToInteger(dataItem.dateSoldTo);
    let startDate = ComUtils.DateToInteger(dataItem.startDate);
    let endDate = ComUtils.DateToInteger(dataItem.endDate);
    if (startDate > dateSoldFrom || endDate < dateSoldFrom) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "CLM_ERR_00005", [startDate, endDate]));
      return false;
    }
    if (dataItem.dateSoldFrom && dataItem.dateSoldTo) {
      if (dateSoldFrom > dateSoldTo) {
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, dateSoldErrMsg, () => {
          dataItem.dateSoldTo = null;
        });
        return false;
      }
    }
    setIsSelloutDateChange(true);
    return true;
  }

  const initGridFormat = useCallback((formatClaimType, status, netScmCheck, netScmFrom, rejectCodeMap, dateOverlapMap, sellOutCreateDate) => {
    let gridFormat = [];

    if (formatClaimType === "IR") {

      if (entityActionTarget.current === "SCM") {
        gridFormat.push({
          binding: "period",
          header: "Program Period",
          width: 200,
          allowDragging: true,
          isReadOnly: true,
          align: "center",
        });
        gridFormat.push({
          binding: "model",
          header: "Model",
          width: 257,
          allowDragging: true,
          isReadOnly: true,
          align: "left",
        });
        gridFormat.push({
          binding: "reimbursementAmount",
          header: "LG Unit \n Reimbursement",
          width: 200,
          allowDragging: true,
          isReadOnly: true,
          align: "right",
          format: "c2",
        });
        gridFormat.push({
          binding: "arConfQty",
          header: "Current Claim \r Qty.",
          width: 200,
          allowDragging: true,
          isReadOnly: true,
          aggregate: "Sum",
          align: "right",
          format: "n0",
        });
        gridFormat.push({
          binding: "netScmSellThruClaim",
          header: "Accum. Claim \r Qty.",
          width: 200,
          allowDragging: true,
          isReadOnly: true,
          aggregate: "Sum",
          align: "right",
          format: "n0",
        });
        gridFormat.push({
          binding: "netScmSellThru",
          header: "Sell Thru \r Qty.(" + sellOutCreateDate + ")",
          width: 200,
          allowDragging: true,
          isReadOnly: true,
          aggregate: "Sum",
          align: "right",
          format: "n0",

        });
        gridFormat.push({
          binding: "diffClaimQty",
          header: "Diff. b/w Claim vs\r GDMI (QTY)",
          width: 200,
          allowDragging: true,
          isReadOnly: true,
          aggregate: "Sum",
          align: "right",
          format: "n0",
        });
        gridFormat.push({
          binding: "netscmsellthru2",
          header: "Sell Thru\rQty.(OLAP)",
          width: 200,
          allowDragging: true,
          isReadOnly: true,
          aggregate: "Sum",
          format: "n0",
          align: "right",
          cellTemplate: (ctx, cell) => {

            cell?.firstChild?.remove();
            let dataItem = ctx.row.dataItem;
            let value = Number(ctx?.value ?? 0);
            let element = createElement(format(`<p>${value?.toLocaleString()}</p>`, {}), cell);
          },
        });
        gridFormat.push({
          binding: "netscmsellthru3",
          header: "Sell Thru\rQty.(GDMI)",
          width: 200,
          allowDragging: true,
          isReadOnly: true,
          aggregate: "Sum",
          format: "n0",
          align: "right",
          cellTemplate: (ctx, cell) => {
            cell?.firstChild?.remove();
            let dataItem = ctx.row.dataItem;
            let value = Number(ctx?.value ?? 0);
            let element = createElement(format(`<p>${value?.toLocaleString()}</p>`, {}), cell);
          },
        });
        ////
      } else {

        gridFormat.push({
          binding: "period",
          header: "Program Period",
          width: 166,
          allowDragging: true,
          isReadOnly: true,
          align: "center",
        });
        gridFormat.push({
          binding: "model",
          header: "Model",
          width: 140,
          allowDragging: true,
          isReadOnly: true,
          align: "left",
        });
        gridFormat.push({
          binding: "spiffIrAmount",
          header: "Rebate Amount",
          width: 106,
          allowDragging: true,
          isReadOnly: true,
          align: "right",
          format: "c2",
        });
        gridFormat.push({
          binding: "reimbursementAmount",
          header: "LG Unit \n Reimbursement",
          width: 137,
          allowDragging: true,
          isReadOnly: true,
          align: "right",
          format: "c2",
        });
        gridFormat.push({
          binding: "claimQty",
          header: "Claim QTY",
          width: 90,
          allowDragging: true,
          isReadOnly: true,
          align: "right",
          format: "n0",
          aggregate: "Sum",
          editor: new InputNumber(document.createElement("div"), {
            format: "n0",
            min: 0,
            max: 999999,
          }),
        });
        gridFormat.push({
          binding: "arConfQty",
          header: "Approve QTY",
          width: 90,
          allowDragging: true,
          aggregate: "Sum",
          align: "right",
          editor: new InputNumber(document.createElement("div"), {
            format: "n0",
            min: 0,
            max: 999999,
          }),
        });
        gridFormat.push({
          binding: "rejectQty",
          header: "Reject QTY",
          width: 90,
          allowDragging: true,
          isReadOnly: true,
          align: "right",
          format: "n0",
          aggregate: "Sum",
        });
        gridFormat.push({
          binding: "reimburAmount",
          header: "Approve AMT",
          width: 115,
          allowDragging: true,
          isReadOnly: true,
          align: "right",
          format: "c2",
          aggregate: "Sum",
          editor: new InputNumber(document.createElement("div"), {
            format: "n2",
            min: 0,
            max: 999999,
          }),
        });
        gridFormat.push({
          binding: "rejectCode",
          header: "Reject Reason",
          width: 215,
          allowDragging: true,
          dataMap: rejectCodeMap,
          dropDownCssClass: "reject-dropdown",
          dataMapEditor: "Menu"
        });
        gridFormat.push({
          binding: "rejectComment",
          header: "Reject Comment",
          width: "*",
          minWidth: 150,
          allowDragging: true,
          isReadOnly: false,
          align: "left",
          cellTemplate: (ctx, cell) => {

            cell?.firstChild?.remove();
            let dataItem = ctx.row.dataItem;
            let value = "";
            if (dataItem?.status !== "HOLD") {
              if (dataItem.rejectCount == 1) {
                value = "1st :" + dataItem.rejectComment;
              } else if (dataItem.rejectCount == 2) {
                value = "2nd :" + dataItem.rejectComment2;
              } else {
                value = dataItem.rejectComment;
              }
            } else {
              value = dataItem.rejectComment;
            }
            if (value == null) {
              value = "";
            }
            let element = createElement(format(`<p>${value}</p>`, {}), cell);
          },
        });
        gridFormat.push({
          binding: "status",
          header: "Status",
          width: 100,
          allowDragging: true,
          isReadOnly: true,
          align: "center",
        });
        gridFormat.push({
          binding: "netSellInQty",
          header: "Net Sell-In\rQTY",
          width: 100,
          allowDragging: true,
          isReadOnly: true,
          align: "right",
          format: "n0",
        });
        gridFormat.push({
          binding: "netApprovedQty",
          header: "Net Approved\rQTY",
          width: 120,
          allowDragging: true,
          isReadOnly: true,
          format: "n0",
          align: "center",
        });
        gridFormat.push({
          binding: "netRemainingQty",
          header: "Net Remaining\rQTY",
          width: 120,
          allowDragging: true,
          isReadOnly: true,
          format: "n0",
          align: "right",
          cellTemplate: (ctx, cell) => {
            cell?.firstChild?.remove();
            let dataItem = ctx.row.dataItem;
            let value = Number(ctx?.value ?? 0);
            if (Number(dataItem.claimQty ?? 0) > Number(dataItem.allowedQty ?? 0)) {
              let element = createElement(format(`<span style="color: red; font-weight: bold;">${(value)?.toLocaleString()}</span>`, {}), cell);
            } else {
              let element = createElement(format(`<span>${(value)?.toLocaleString()}</span>`, {}), cell);
            }
          },
        });
        gridFormat.push({
          binding: "netAprvAdhocQty",
          header: "Net Approved\rQTY(TC) ",
          width: 120,
          format: "n0",
          allowDragging: true,
          isReadOnly: true,
          align: "right",
        });

        gridFormat.push({
          binding: "claimSellOut",
          header: "Claim Sell Out",
          width: 200,
          allowDragging: true,
          isReadOnly: false,
          align: "center",
          columns: [
            {
              binding: "dateSoldFrom",
              header: "Start Date",
              width: 145,
              allowDragging: true,
              isReadOnly: false,
              align: "center",
              editor: new InputDate(document.createElement("div"), {
                format: "MM/dd/yyyy",
                mask: "##/##/####",
                isRequired: false,
                invalidInput: (s, e) => {
                  if (s.text === "__/__/____") {
                    s.text = "";
                    s.value = null;
                  }
                },
              }),
              cellTemplate: (ctx, cell) => {
                cell?.firstChild?.remove();
                if (ctx?.value) {
                  return wijmo.Globalize.formatDate(new Date(ctx?.value), 'MM/dd/yyyy')
                } else {
                  return '';
                }
              }
            },
            {
              binding: "dateSoldTo",
              header: "End Date",
              width: 145,
              allowDragging: true,
              isReadOnly: false,
              align: "center",
              editor: new InputDate(document.createElement("div"), {
                format: "MM/dd/yyyy",
                mask: "##/##/####",
                isRequired: false,
                invalidInput: (s, e) => {
                  if (s.text === "__/__/____") {
                    s.text = "";
                    s.value = null;
                  }
                },
              }),
              cellTemplate: (ctx, cell) => {
                cell?.firstChild?.remove();
                if (ctx?.value) {
                  return wijmo.Globalize.formatDate(new Date(ctx?.value), 'MM/dd/yyyy')
                } else {
                  return '';
                }
              }
            },
          ]
        });

        gridFormat.push({
          binding: "dateRange",
          header: "SCM Sell Thru\rStart Date - End Date",
          width: 180,
          allowDragging: true,
          isReadOnly: true,
          align: "center",
        });

        gridFormat.push({
          binding: "scmSellThru",
          header: "SCM Sell Thru\rWeek",
          width: 150,
          allowDragging: true,
          isReadOnly: true,
          align: "center",
          cellTemplate: (ctx, cell) => {
            cell?.firstChild?.remove();
            let dataItem = ctx.row.dataItem;
            let value = ctx?.value;
            let element = createElement(format(`<p>${value}</p>`, {}), cell);
          },
        });

        gridFormat.push({
          binding: "scmSellThruQty",
          header: "Sell Thru\rQTY" + ((netScmFrom === "OLAP" && status === "HOLD") ? "(OLAP)" : "(GDMI)"),
          width: 105,
          allowDragging: true,
          isReadOnly: true,
          format: "n0",
          aggregate: "Sum",
          align: "right",
          cellTemplate: (ctx, cell) => {
            cell?.firstChild?.remove();
            let dataItem = ctx.row.dataItem;
            let value = ctx?.value;
            let element = createElement(format(`<p>${(value ?? 0).toLocaleString()}</p>`, {}), cell);

          },
        });
        if (status === "HOLD" && netScmCheck !== "" && netScmFrom === "OLAP") {
          gridFormat.push({
            binding: "scmSellThruGDMI",
            header: "Sell Thru\rQTY(GDMI)",
            width: 105,
            format: "n0",
            allowDragging: true,
            isReadOnly: true,
            aggregate: "Sum",
            align: "right",
            cellTemplate: (ctx, cell) => {
              cell?.firstChild?.remove();
              let dataItem = ctx.row.dataItem;
              let value = ctx?.value;
              let element = createElement(format(`<p>${(value ?? 0).toLocaleString()}</p>`, {}), cell);

            },

          });
        }
        if (status === "HOLD" && netScmCheck !== "" && netScmFrom !== "OLAP") {

          gridFormat.push({
            binding: "scmSellThruOLAP",
            header: "Sell Thru\rQTY(OLAP)",
            width: 105,
            format: "n0",
            allowDragging: true,
            isReadOnly: true,
            aggregate: "Sum",
            align: "right",
            cellTemplate: (ctx, cell) => {
              cell?.firstChild?.remove();
              let dataItem = ctx.row.dataItem;
              let value = ctx?.value;
              let element = createElement(format(`<p>${(value ?? 0).toLocaleString()}</p>`, {}), cell);

            },
          });
        }
        gridFormat.push({
          binding: "claimedQty",
          header: "Claimed\rQTY",
          width: 105,
          format: "n0",
          allowDragging: true,
          isReadOnly: true,
          aggregate: "Sum",
          align: "right",
          cellTemplate: (ctx, cell) => {

            cell?.firstChild?.remove();
            let dataItem = ctx.row.dataItem;
            let value = ctx?.value;
            let element = createElement(format(`<p>${(value ?? 0).toLocaleString()}</p>`, {}), cell);

          },
        });
        gridFormat.push({
          binding: "netScmrRemainingQty",
          header: "Net SCM\rRemaining\rQTY",
          width: 105,
          format: "n0",
          allowDragging: true,
          isReadOnly: true,
          aggregate: "Sum",
          align: "right",
          cellTemplate: (ctx, cell) => {
            cell?.firstChild?.remove();
            let dataItem = ctx.row.dataItem;
            let value = ctx?.value;
            if (dataItem.netScmCheck === "Y" && dataItem.netScmAllowedQty != "" && Number(dataItem.netScmAllowedQty) < 0) {

              let element = createElement(format(`<span style="color: red; font-weight: bold;">${(value ?? 0).toLocaleString()}</span>`, {}), cell);
            } else {
              let element = createElement(format(`<span>${(value ?? 0).toLocaleString()}</span>`, {}), cell);
            }
          },
        });
        gridFormat.push({
          binding: "dateOverlapCombo",
          header: "Sold Date\rOverlapped",
          width: 130,
          allowDragging: true,
          isReadOnly: false,
          align: "left",
          dataMap: dateOverlapMap,
        });
        gridFormat.push({
          binding: "dateOverlap",
          header: "dateOverlap",
          visible: false   //hidden
        });
        gridFormat.push({
          binding: "custUnitReimburse",
          header: "Cust. unit Reimburse",
          width: 100,
          allowDragging: true,
          align: "right",
          format: "n2",
          aggregate: 'Sum',
          isRequired: false,
          editor: (new InputNumber(document.createElement('div'), { format: "n2", isRequired: false }))
        });
        gridFormat.push({
          binding: "overCmAmt",
          header: "Over CM Amt.",
          width: 100,
          allowDragging: true,
          align: "right",
          format: "c2",
          aggregate: 'Sum',
          editor: (new InputNumber(document.createElement('div'), { format: "n2", min: 0 }))
        });
        gridFormat.push({
          binding: "netScmSellThru",
          header: "netScmSellThru",
          visible: false,    //hidden
          cellTemplate: (ctx, cell) => {
            cell?.firstChild?.remove();
            let dataItem = ctx.row.dataItem;
            let value = "";
            if (dataItem.status === "HOLD") {
              value = dataItem.netSellOut ?? 0;
            } else {
              value = dataItem.netScmSellThru ?? 0;
            }
            value = value?.toLocaleString();
            let element = createElement(format(`<p>${value}</p>`, {}), cell);

          },
        });
      }
      gridFormat.push({
        binding: "netScmSellThruClaim",
        header: "netScmSellThruClaim",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "selApprovalStatus",
        header: "selApprovalStatus",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "netScmAllowedQty",
        header: "netScmAllowedQty",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "rejectComment2",
        header: "rejectComment2",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "claimStatus",
        header: "claimStatus",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "dateRange",
        header: "dateRange",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "rejectCount",
        header: "rejectCount",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "weekNo",
        header: "weekNo",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "orgArConfQty",
        header: "orgArConfQty",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "allowedQty",
        header: "allowedQty",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "claimId",
        header: "claimId",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "netScmConfirm",
        header: "netScmConfirm",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "diffClaimQty",
        header: "diffClaimQty",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "netScmCheck",
        header: "netScmCheck",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "netSellInStDt",
        header: "netSellInStDt",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "netSellInEnDt",
        header: "netSellInEnDt",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "uiFlag",
        header: "uiFlag",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "claimSeq",
        header: "claimSeq",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "netPendQty",
        header: "netPendQty",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "netAprvQty",
        header: "netAprvQty",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "startDate",
        header: "startDate",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "endDate",
        header: "endDate",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "netSellOutOLAP",
        header: "netSellOutOLAP",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "netSellOutGDMI",
        header: "netSellOutGDMI",
        visible: false    //hidden
      });

      //SPIFF
    } else {

      gridFormat.push({
        binding: "period",
        header: "Program Period",
        width: 166,
        allowDragging: true,
        isReadOnly: true,
        align: "center",
      });
      gridFormat.push({
        binding: "model",
        header: "Model",
        width: 140,
        allowDragging: true,
        isReadOnly: true,
        align: "left",
      });
      gridFormat.push({
        binding: "qty",
        header: "Claim QTY",
        width: 90,
        allowDragging: true,
        isReadOnly: true,
        align: "right",
        format: "n0",
        aggregate: 'Sum'
      });
      gridFormat.push({
        binding: "arConfirmQty",
        header: "Approve QTY",
        width: 90,
        allowDragging: true,
        isReadOnly: false,
        align: "right",
        format: "n0",
        aggregate: 'Sum',
        editor: new InputNumber(document.createElement("div"), {
          format: "n0",
          min: 0,
          max: 999999,
        }),
      });
      gridFormat.push({
        binding: "rejectQty",
        header: "Reject\rQTY",
        width: 70,
        allowDragging: true,
        isReadOnly: true,
        align: "right",
        format: "n0",
        aggregate: 'Sum'
      });
      gridFormat.push({
        binding: "spiffIrAmount",
        header: "Unit SPIFF\rAMT",
        width: 100,
        allowDragging: true,
        isReadOnly: true,
        align: "right",
        format: "c2"
      });
      gridFormat.push({
        binding: "claimAmount",
        header: "Claim AMT",
        width: 100,
        allowDragging: true,
        isReadOnly: true,
        align: "right",
        format: "c2",
        aggregate: 'Sum'
      });
      gridFormat.push({
        binding: "reimburAmount",
        header: "Approve\rAMT",
        width: 100,
        allowDragging: true,
        isReadOnly: true,
        align: "right",
        format: "c2",
        aggregate: 'Sum'
      });
      gridFormat.push({
        binding: "rejectCodeDtl",
        header: "Reject Reason",
        width: 215,
        allowDragging: true,
        dataMap: rejectCodeMap,
        dropDownCssClass: "reject-dropdown",
        dataMapEditor: "Menu"
      });
      gridFormat.push({
        binding: "rejectCommentDtl",
        header: "Reject Comment",
        width: 200,
        minWidth: 150,
        allowDragging: true,
        maxLength: 400,
        isReadOnly: false,
        align: "left",

      });
      gridFormat.push({
        binding: "invoiceNo",
        header: "Cust.Invoice #",
        width: 110,
        allowDragging: true,
        isReadOnly: true,
        align: "left",

      });
      gridFormat.push({
        binding: "salesDate",
        header: "Sales Date",
        width: 100,
        allowDragging: true,
        isReadOnly: true,
        align: "center",
      });
      gridFormat.push({
        binding: "claimSellOut",
        header: "Claim Sell Out",
        width: 200,
        allowDragging: true,
        isReadOnly: false,
        align: "center",
        columns: [
          {
            binding: "dateSoldFrom",
            header: "Start Date",
            width: 145,
            allowDragging: true,
            isReadOnly: false,
            align: "center",
            editor: new InputDate(document.createElement("div"), {
              format: "MM/dd/yyyy",
              mask: "##/##/####",
              isRequired: false,
              invalidInput: (s, e) => {
                if (s.text === "__/__/____") {
                  s.text = "";
                  s.value = null;
                }
              },
            }),
            cellTemplate: (ctx, cell) => {
              cell?.firstChild?.remove();
              if (ctx?.value) {
                return wijmo.Globalize.formatDate(new Date(ctx?.value), 'MM/dd/yyyy')
              } else {
                return '';
              }
            }
          },
          {
            binding: "dateSoldTo",
            header: "End Date",
            width: 145,
            allowDragging: true,
            isReadOnly: false,
            align: "center",
            editor: new InputDate(document.createElement("div"), {
              format: "MM/dd/yyyy",
              mask: "##/##/####",
              isRequired: false,
              invalidInput: (s, e) => {
                if (s.text === "__/__/____") {
                  s.text = "";
                  s.value = null;
                }
              },
            }),
            cellTemplate: (ctx, cell) => {
              cell?.firstChild?.remove();
              if (ctx?.value) {
                return wijmo.Globalize.formatDate(new Date(ctx?.value), 'MM/dd/yyyy')
              } else {
                return '';
              }
            }
          },
        ]
      });
      gridFormat.push({
        binding: "dateOverlapCombo",
        header: "Sold Date\rOverlapped",
        width: 130,
        allowDragging: true,
        isReadOnly: false,
        align: "left",
        dataMap: dateOverlapMap,
      });
      gridFormat.push({
        binding: "dateOverlap",
        header: "dateOverlap",
        visible: false   //hidden
      });
      gridFormat.push({
        binding: "serialG",
        header: "Serial #",
        width: 200,
        allowDragging: true,
        isReadOnly: false,
        align: "center",
        columns: [
          {
            binding: "serialBtn",
            header: "Serial",
            width: 100,
            allowDragging: true,
            isReadOnly: true,
            align: "center",
            cellTemplate: (ctx, cell) => {
              cell?.firstChild?.remove();
              let rowIndex = ctx.row.index;
              let value = ctx.value;
              let dataItem = ctx.row.dataItem;
              let element = createElement(format(`<div class='grid-input-wrapper'><button class='grid-input-button'>Serial</button></div>`), cell);

              let buttonElement = element.querySelector('button.grid-input-button');
              buttonElement.onclick = function (e) {
                e.preventDefault();
                onSerialClick(dataItem, rowIndex);
              };

            },
          },
          {
            binding: "serial",
            header: "Serial #",
            width: 100,
            allowDragging: true,
            isReadOnly: true,

          },
        ]
      });
      gridFormat.push({
        binding: "backUpMethodDisplay",
        header: "Backup Method",
        width: 95,
        allowDragging: true,
        isReadOnly: true,
        align: "center",
      });
      gridFormat.push({
        binding: "backUpMethod",
        header: "backUpMethod",
        visible: false
      });
      gridFormat.push({
        binding: "netSellInQty",
        header: "Net Sell-In\rQTY",
        width: 100,
        allowDragging: true,
        isReadOnly: true,
        align: "right",
        format: "n0",
      });
      gridFormat.push({
        binding: "netApprovedQtyN",
        header: "Net Approved\rQTY(N) ",
        width: 100,
        allowDragging: true,
        isReadOnly: true,
        align: "left",
        cellTemplate: (ctx, cell) => {

          cell?.firstChild?.remove();
          let dataItem = ctx.row.dataItem;
          dataItem.netApprovedQtyN = ((dataItem.netAprvQty ?? "0") == "0" ? "" : (dataItem.netAprvQty + " APRV")) + " " + ((dataItem.netPendQty ?? "0") == "0" ? "" : (dataItem.netPendQty + " HOLD"));
          let element = createElement(format(`<p>${dataItem.netApprovedQtyN}</p>`, {}), cell);
        },
      });
      gridFormat.push({
        binding: "allowedQty",
        header: "Net Remaining\rQTY(N)",
        width: 120,
        allowDragging: true,
        isReadOnly: true,
        align: "right",
        format: "n0",
        cellTemplate: (ctx, cell) => {
          cell?.firstChild?.remove();
          let dataItem = ctx.row.dataItem;

          if (Number(dataItem.qty ?? 0) > Number(dataItem.allowedQty ?? 0)) {
            let element = createElement(format(`<span style="color: red; font-weight: bold;">${(dataItem.allowedQty ?? 0).toLocaleString()}</span>`, {}), cell);
          } else {
            let element = createElement(format(`<span>${(dataItem.allowedQty ?? 0).toLocaleString()}</span>`, {}), cell);
          }
        },
      });

      gridFormat.push({
        binding: "netAprvAdhocQty",
        header: "Net Approved\rQTY(Ad-Hoc)",
        width: 100,
        allowDragging: true,
        isReadOnly: true,
        align: "right",
        format: "n0",
      });

      gridFormat.push({
        binding: "sellThru",
        header: "Sell Thru",
        width: 200,
        allowDragging: true,
        isReadOnly: false,
        align: "center",
        columns: [
          {
            binding: "dateRange",
            header: "Start Date -\rEnd Date",
            width: 120,
            allowDragging: true,
            isReadOnly: true,
            align: "center",
          },
          {
            binding: "viewWeekNo",
            header: "Week",
            width: 100,
            allowDragging: true,
            isReadOnly: true,
            align: "center",
            cellTemplate: (ctx, cell) => {
              cell?.firstChild?.remove();
              let dataItem = ctx.row.dataItem;
              let value = dataItem.weekNo ?? '';
              dataItem.viewWeekNo = ((dataItem.weekNo != '' && dataItem.weekNo != null) ? (dataItem.weekNo + "Week") : "")
              let element = createElement(format(`<p>${dataItem.viewWeekNo}</p>`, {}), cell);
            },
          },
          {
            binding: "netSellOut",
            header: "QTY",
            width: 105,
            allowDragging: true,
            isReadOnly: true,
            align: "right",
            format: "n0",
          },

        ]
      });
      gridFormat.push({
        binding: "claimedQty",
        header: "Claimed\rQTY",
        width: 105,
        allowDragging: true,
        isReadOnly: true,
        align: "right",
        format: "n0",
        cellTemplate: (ctx, cell) => {
          cell?.firstChild?.remove();
          let dataItem = ctx.row.dataItem;
          let value = 0;
          if (dataItem.status === "HOLD") {
            if (dataItem.netScmConf == null || dataItem.netScmConf == "" || dataItem.netScmConf == "0") {
              value = 0;
            } else {
              value = Number(dataItem.netScmConf ?? 0);
            }
          } else {
            value = Number(dataItem.netScmSellThruClaim ?? 0);
          }
          dataItem.claimedQty = value?.toLocaleString();
          let element = createElement(format(`<p>${dataItem.claimedQty ?? ''}</p>`, {}), cell);

        },
      });
      gridFormat.push({
        binding: "custUnitReimburse",
        header: "Cust. unit Reimburse",
        width: 100,
        allowDragging: true,
        align: "right",
        format: "n2",
        aggregate: 'Sum',
        isRequired: false,
        editor: (new InputNumber(document.createElement('div'), { format: "n2", isRequired: false }))
      });
      gridFormat.push({
        binding: "overCmAmt",
        header: "Over CM Amt.",
        width: 100,
        allowDragging: true,
        align: "right",
        format: "c2",
        aggregate: 'Sum',
        editor: (new InputNumber(document.createElement('div'), { format: "n2", min: 0 }))
      });

      gridFormat.push({
        binding: "claimId",
        header: "claimId",
        visible: false//hidden
      });
      gridFormat.push({
        binding: "claimSeq",
        header: "claimSeq",
        visible: false//hidden
      });
      gridFormat.push({
        binding: "claimQty",
        header: "claimQty",
        visible: false//hidden
      });
      gridFormat.push({
        binding: "lgReimbur",
        header: "lgReimbur",
        visible: false//hidden
      });
      gridFormat.push({
        binding: "netPendQty",
        header: "netPendQty",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "netAprvQty",
        header: "netAprvQty",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "netSellInStDt",
        header: "netSellInStDt",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "netSellInEnDt",
        header: "netSellInEnDt",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "result",
        header: "result",
        visible: false //hidden
      });
      gridFormat.push({
        binding: 'checkResult',
        header: "checkResult",
        visible: false //hidden
      });
      gridFormat.push({
        binding: 'serialDuplicate',
        header: "serialDuplicate",
        visible: false //hidden
      });
      gridFormat.push({
        binding: 'programId',
        header: "programId",
        visible: false //hidden
      });
      gridFormat.push({
        binding: 'netAprvYQty',
        header: "netAprvYQty",
        visible: false //hidden
      });
      gridFormat.push({
        binding: 'netPendYQty',
        header: "netPendYQty",
        visible: false //hidden
      });
      gridFormat.push({
        binding: "startDate",
        header: "startDate",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "endDate",
        header: "endDate",
        visible: false    //hidden
      });
      gridFormat.push({
        binding: "changeSerialNo",
        header: "changeSerialNo",
        visible: false    //hidden
      });




    } // end SPIFF

    setGridColGroup([...gridFormat]);
  }, []);

  const gridFormatItem = (s, e) => {
    let col = s?.columns[e.col];

    if (entityClaimType.current === "IR") {

      if (col?.binding === "reimbursementAmount") {
        s.columnFooters.setCellData(0, e.col, "Total", true, false);
      }

      if (e.panel !== s.columnHeaders && e.panel !== s.columnFooters) {
        if (col?.binding === "dateOverlapCombo") {
          let row = s?.rows[e.row]?.dataItem;
          if (row?.uiFlag !== 'U') {
            e.cell.innerHTML = null;
          }
        }
      }

      if (e.panel === s.columnFooters) {
        if (col?.binding === "reimburAmount") {
          let totReimburAmount = 0;

          gridObject?.current?.rows?.forEach((el) => {
            if (el.dataItem.status !== "RJCT") {
              totReimburAmount += Number(el.dataItem.arConfQty) * Number(el.dataItem.reimbursementAmount);
            }
          });
          s.columnFooters.setCellData(0, e.col, totReimburAmount, true, false);
        } else if (col?.binding === "claimQty") {
          let totClaimQty = 0;

          gridObject?.current?.rows?.forEach((el) => {
            if (el.dataItem.uiFlag !== "I") {
              totClaimQty += Number(el.dataItem.claimQty);
            }
          });
          s.columnFooters.setCellData(0, e.col, totClaimQty, true, false);
        } else if (col?.binding === "scmSellThruQty") {
          let totQty = 0;
          gridObject?.current?.rows?.forEach((el) => {
            if (el.dataItem.status === "HOLD") {
              totQty += Number(el.dataItem.netSellOut ?? 0);
            } else {
              totQty += Number(el.dataItem.netScmSellThru ?? 0);
            }
          });
          s.columnFooters.setCellData(0, e.col, totQty, true, false);
        } else if (col?.binding === "scmSellThruGDMI") {
          let totQty = 0;
          gridObject?.current?.rows?.forEach((el) => {
            if (el.dataItem.netScmFrom === "OLAP") {
              totQty += Number(el.dataItem.scmSellThruGDMI ?? 0);
            }
          });
          s.columnFooters.setCellData(0, e.col, totQty, true, false);
        } else if (col?.binding === "scmSellThruOLAP") {
          let totQty = 0;
          gridObject?.current?.rows?.forEach((el) => {
            if (el.dataItem.netScmFrom !== "OLAP") {
              totQty += Number(el.dataItem.netSellOutOLAP ?? 0);
            }
          });
          s.columnFooters.setCellData(0, e.col, totQty, true, false);
        } else if (col?.binding === "claimedQty") {
          let totQty = 0;
          gridObject?.current?.rows?.forEach((el) => {
            if (el.dataItem.status === "HOLD") {
              totQty += Number(el.dataItem.netScmConf ?? 0);
            } else {
              totQty += Number(el.dataItem.netScmSellThruClaim ?? 0);
            }
          });
          s.columnFooters.setCellData(0, e.col, totQty, true, false);
        } else if (col?.binding === "netScmrRemainingQty") {
          let totQty = 0;
          gridObject?.current?.rows?.forEach((el) => {
            totQty += Number(el.dataItem.netScmAllowedQty ?? 0);
          });
          s.columnFooters.setCellData(0, e.col, totQty, true, false);
        } else if (col?.binding === "custUnitReimburse") {
          let totClaimQty = 0;

          gridObject?.current?.rows?.forEach((el) => {
            if (el.dataItem.custUnitReimburse != null && el.dataItem.custUnitReimburse != '') {
              totClaimQty += Number(el.dataItem.arConfQty);
            }
          });
          s.columnFooters.setCellData(0, e.col, totClaimQty, true, false);
        }


        e.cell.style.justifyContent = "end";
      }

      if (e.panel === s.cells &&
        (e.getColumn(e.col).binding === "arConfQty"
          || e.getColumn(e.col).binding === "rejectCode"
          || e.getColumn(e.col).binding === "rejectComment"
          || e.getColumn(e.col).binding === "dateSoldFrom"
          || e.getColumn(e.col).binding === "dateSoldTo"
          || e.getColumn(e.col).binding === "custUnitReimburse"
          || e.getColumn(e.col).binding === "overCmAmt"
        )
      ) {
        const targetItem = e.getRow(e.row)?.dataItem;
        if (e.getColumn(e.col).binding === "arConfQty") {
          if (entitySelApprovalStatus.current == "TO-DO" || targetItem?.claimStatus == "A2") {
            toggleClass(e.cell, "cell-modify");
          }
        } else {
          if (targetItem?.status == "HOLD") {
            toggleClass(e.cell, "cell-modify");
          }
        }
      }

      //SPIFF
    } else {
      if (col?.binding === "model") {
        s.columnFooters.setCellData(0, e.col, "Total", true, false);
      }

      if (e.panel !== s.columnHeaders && e.panel !== s.columnFooters) {
        if (col?.binding === "serialBtn"
          // || col?.binding === "dateSoldFrom"
          // || col?.binding === "dateSoldTo"
          // || col?.binding === "custUnitReimburse"
          // || col?.binding === "overCmAmt"
        ) {
          let row = s?.rows[e.row]?.dataItem;
          if (row?.status !== 'HOLD') {
            e.cell.innerHTML = null;
          }
        } else if (col?.binding === "dateOverlapCombo") {
          let row = s?.rows[e.row]?.dataItem;
          if (row?.uiFlag !== 'U') {
            e.cell.innerHTML = null;
          }
        }
      }


      if (e.panel === s.columnFooters) {

        if (col?.binding === "claimAmount") {
          let totReimburAmount = 0;

          gridObject?.current?.rows?.forEach((el) => {
            if (el.dataItem.status === "HOLD" && el.dataItem.uiFlag !== "I") {
              totReimburAmount += Number(el.dataItem.claimAmount);
            }
          });
          s.columnFooters.setCellData(0, e.col, totReimburAmount, true, false);
        } else if (col?.binding === "qty") {
          let totClaimQty = 0;

          gridObject?.current?.rows?.forEach((el) => {
            if (el.dataItem.uiFlag !== "I") {
              totClaimQty += Number(el.dataItem.qty);
            }
          });
          s.columnFooters.setCellData(0, e.col, totClaimQty, true, false);
        } else if (col?.binding === "custUnitReimburse") {
          let totClaimQty = 0;

          gridObject?.current?.rows?.forEach((el) => {
            if (el.dataItem.custUnitReimburse != null && el.dataItem.custUnitReimburse != '') {
              totClaimQty += Number(el.dataItem.arConfirmQty);
            }
          });
          s.columnFooters.setCellData(0, e.col, totClaimQty, true, false);
        }

        e.cell.style.justifyContent = "end";
      }

      if (e.panel === s.cells &&
        (e.getColumn(e.col).binding === "arConfirmQty"
          || e.getColumn(e.col).binding === "rejectCodeDtl"
          || e.getColumn(e.col).binding === "rejectCommentDtl"
          || e.getColumn(e.col).binding === "dateSoldFrom"
          || e.getColumn(e.col).binding === "dateSoldTo"
          || e.getColumn(e.col).binding === "custUnitReimburse"
          || e.getColumn(e.col).binding === "overCmAmt"
        )
      ) {
        const targetItem = e.getRow(e.row)?.dataItem;
        if (targetItem?.status == "HOLD") {
          toggleClass(e.cell, "cell-modify");
        }
      }
    }

    if (e.panel === s.columnFooters) {
      if (s?.columns[e.col]?.binding === "custUnitReimburse") {
        e.cell.textContent = wijmo.Globalize.formatNumber(Number(e.cell.textContent), 'n0');
      }
    }

  };

  const initFilterDtl = (f) => {
    filterObjectDtl.current = f;
  };

  const initGridDtl = (sender) => {
    gridObjectDtl.current = sender;
    ComUtils.gridInit(sender, {
      hasShowNumbering: true,
      selectionMode: 6,
      hasCheckBox: false,
      canAreaInfo: false,
      hasReadOnlyCombo: true,
    });
  };

  const fncCopyRejectReason = (status) => {
    let tmpChecked = status.target.checked;
    let view = gridObject?.current?.collectionView;
    let rows = gridObject.current.rows ?? [];

    if (entityClaimType.current === "IR") {
      if (rows.length > 0) {
        if (tmpChecked) {
          if (rows.length > 1) {
            let rejectCode = view.items[0].rejectCode;
            let rejectComment = view.items[0].rejectComment;
            for (let i = 1; i < rows.length; i++) {
              if (view.items[i].uiFlag !== "I") {
                view.items[i].rejectCode = rejectCode;
                view.items[i].rejectComment = rejectComment;
              }
            }
          }
        } else {
          gridObject.current.collectionView.items.forEach((el) => {
            el.rejectCode = "";
            el.rejectComment = "";
          });

        }
        view.commitEdit();
        view.refresh();
      }
    } else {
      if (rows.length > 0) {
        if (tmpChecked) {
          if (rows.length > 1) {
            let rejectCodeDtl = view.items[0].rejectCodeDtl;
            let rejectCommentDtl = view.items[0].rejectCommentDtl;
            for (let i = 1; i < rows.length; i++) {
              console.log(i, rejectCodeDtl)
              view.items[i].rejectCodeDtl = rejectCodeDtl;
              view.items[i].rejectCommentDtl = rejectCommentDtl;
            }
          }
        } else {
          gridObject.current.collectionView.items.forEach((el) => {
            el.rejectCodeDtl = "";
            el.rejectCommentDtl = "";
          });

        }
        view.commitEdit();
        view.refresh();
      }
    }

    rejectCheck.current = !tmpChecked;

  }

  const onCopy = (str) => {
    if (str == 'dateSoldFrom') {
      if (gridObject.current.rows[0].dataItem.dateSoldFrom == null || gridObject.current.rows[0].dataItem.dateSoldFrom === '') {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "CLM_ERR_00001", ["date on the first line"]));
        return;
      }

      for (var i = 0; i < gridObject.current.rows.length; i++) {

        if (gridObject.current.rows[i].dataItem.uiFlag !== "I" && gridObject.current.rows[i].dataItem.status === "HOLD") {
          gridObject.current.rows[i].dataItem.dateSoldFrom = gridObject.current.rows[0].dataItem.dateSoldFrom;
          handleSalesDateCheck(gridObject.current.rows[i].dataItem);
        }
      }
    } else if (str == 'dateSoldTo') {
      if (gridObject.current.rows[0].dataItem.dateSoldTo == null || gridObject.current.rows[0].dataItem.dateSoldTo === '') {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "CLM_ERR_00001", ["date on the first line."]));
        return;
      }
      for (var i = 0; i < gridObject.current.rows.length; i++) {
        if (gridObject.current.rows[i].dataItem.uiFlag !== "I" && gridObject.current.rows[i].dataItem.status === "HOLD") {
          gridObject.current.rows[i].dataItem.dateSoldTo = gridObject.current.rows[0].dataItem.dateSoldTo;
          handleSalesDateCheck(gridObject.current.rows[i].dataItem);
        }
      }
    }
    setIsSelloutDateChange(true);
    gridObject?.current?.refresh();

  }

  const initGridFormatDtl = useCallback(() => {
    let gridFormat = [
      {
        binding: "flowSeq",
        header: "Seq.",
        width: 80,
        allowDragging: true,
        isReadOnly: true,
        align: "center"
      },
      {
        binding: "personName",
        header: "Approval",
        width: 150,
        allowDragging: true,
        isReadOnly: true,
        align: "center"
      },
      {
        binding: "email",
        header: "E-Mail",
        width: 200,
        allowDragging: true,
        isReadOnly: true,
        align: "center"
      },
      {
        binding: "status",
        header: "Status",
        width: 150,
        allowDragging: true,
        isReadOnly: true,
        align: "center",

      },
      {
        binding: "apprDate",
        header: "Approval Date",
        minWidth: 150,
        allowDragging: true,
        isReadOnly: true,
        align: "center",
        cellTemplate: (ctx) => {
          let dataItem = ctx.row.dataItem;
          if (wijmo.isUndefined(dataItem) || dataItem === null) {
            return "";
          }
          return ctx?.value ?? "";
        },
      },
      {
        binding: "comments",
        header: "Comments",
        width: "*",
        minWidth: 150,
        allowDragging: true,
        isReadOnly: true,
        align: "left"
      },
    ];
    setGridColGroupDtl([...gridFormat]);
  }, []);


  const _isMounted = useRef(true);
  useEffect(() => {
    let abortController = new AbortController();

    const getPageInfo = async () => {
      entityActionTarget.current = reqActionTarget;
      entityPopupYn.current = popupYn ?? 'N';
      if (reqClaimType && reqClaimId) {
        if (cookie.load("EP_LOGIN") === "Y") {
          setEpLoginYn(true);
          if (reqActionTarget ?? '' === "SCM") {
            setEpScmLoginFlag(true);
          }
        }

        try {

          let sendParam = {}
          sendParam.claimType = reqClaimType;
          sendParam.claimId = reqClaimId;
          sendParam.claimEntryType = '';
          sendParam.scm = '';
          sendParam.payMethod = '';
          //'appr'; //reqActionTarget : eploing SCM
          sendParam.actionTarget = reqActionTarget ?? 'appr'; //reqActionTarget : eploing SCM
          sendParam.userLevel = userLevel;
          sendParam.userType = userType;
          sendParam.userId = userId;
          sendParam.arConfType = "CONFRIM";

          setIsLoading(true);
          const [salesmanCode] = await Promise.all([
            ApiManager.post(retrieveDefaultSalesmanList, { claimId: reqClaimId }, { loadbarOpen: false }),
          ]);

          let allCombo = [{ cd: "", nm: "--Select--" }];
          entitySalesmanCode?.current?.setList({ data: allCombo.concat(salesmanCode), initValue: "" });

          const [resultData, rejectionReasonInfo, toDay] = await Promise.all([
            ApiManager.post(retrieveConfListDtl, sendParam, { loadbarOpen: false }),
            ApiManager.post(retrieveSelCodeList, { class: "046" }, { loadbarOpen: false }),
            ApiManager.post("/com/comUtil/retrieveServerDateInfo", { "dtFormat": "YYYYMMDD", "addDt": "0", "addMonth": "0" }, { loadbarOpen: false }),

          ]);

          entityCurDt.current = toDay.serverDate;
          entityRejectCodeMap.current = rejectionReasonInfo;

          if (resultData?.statusCode) {
            ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr011);
          } else {
            resultDataProc(resultData);

          }
        } catch {
          console.log("catch loading");
          setIsLoading(false);
        }

      }
    }
    getPageInfo();


    filterObject?.current?.clear();
    filterObjectDtl?.current?.clear();


    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, [dtlRandomKey, initFlag]);

  const resultDataProc = async (resultData) => {

    setGridData(null);
    setGridDataDtl(null);
    setFileList([]);
    fileChkYn.current = "N";
    entityFileList.current = null;
    entityApproveComment?.current?.setValue(null);
    entityPostAuditClaim?.current?.setValue(null);

    setClaimType(reqClaimType);
    entityClaimType.current = reqClaimType;
    console.log("resultDataProc==" + entityClaimType.current);

    if (resultData) {
      entityServerURL.current = resultData?.params?.serverURL;


      let pSalesman = "";
      setSelApprovalStatus(resultData?.params?.selApprovalStatus);
      entitySelApprovalStatus.current = resultData?.params?.selApprovalStatus;
      setDateSoldDupFlag(resultData?.dateSoldDupFlag);


      if (resultData?.claimList.length > 0) {
        entityOverlapCustomerInfo.current = resultData?.claimList[0];
        entityOverlapClaimList.current = resultData?.claimList;


        setNetScmFrom(resultData?.claimList[0]?.netScmFrom ?? '');
        setNetScmCheck(resultData?.claimList[0]?.netScmCheck ?? '');
        setStatus(resultData?.claimList[0]?.status);
        entityClaimEntryType.current = resultData?.claimList[0]?.claimEntryType;

        entityNetScmFrom.current = resultData?.claimList[0]?.netScmFrom ?? '';
        entityNeScmCheck.current = resultData?.claimList[0]?.netScmCheck ?? '';
        entityStatus.current = resultData?.claimList[0]?.status;
        entitySellOutCreateDate.current = resultData?.claimList[0]?.sellOutCreateDate ?? '';

        setCreateId(resultData?.claimList[0]?.createId);
        setPostAuditCustomer(resultData?.claimList[0]?.postAuditCustomer);
        setPostAuditClaim(resultData?.claimList[0]?.postAuditClaim);
        entityActivityDateFrom?.current?.setValue(resultData?.claimList[0]?.activityDateFrom);
        entityActivityDateTo?.current?.setValue(resultData?.claimList[0]?.activityDateTo);


        entityPostAuditClaim?.current?.setValue(resultData?.claimList[0]?.postAuditClaim);
        entityCustEntry?.current?.setValue(resultData?.claimList[0]?.custEntry ?? "N");

        setClaimStatus(resultData?.claimList[0]?.claimStatus);
        setBillTo(resultData?.claimList[0]?.billTo);
        setBillToName(resultData?.claimList[0]?.billToName);
        setPromotionNo(resultData?.claimList[0]?.promotionNo);
        setProgramId(resultData?.claimList[0]?.programId);
        entityClaimAnalystEmpNo.current = resultData?.claimList[0]?.claimAnalystEmpNo;


        entitySalesmanName?.current?.setValue(resultData?.claimList[0]?.salesmanName);

        if (reqClaimType === "IR") {
          pSalesman = resultData?.claimList[0]?.salesman;
          entityLgComment?.current?.setValue(resultData?.claimList[0]?.lgComment);
        } else {
          pSalesman = resultData?.claimList[0]?.paramSalesman;
          entityLgComment?.current?.setValue(resultData?.claimList[0]?.remarkInternal);
        }
        entityParamSalesman.current = pSalesman;

        setDebitMemo(resultData?.claimList[0]?.debitMemo);

        let iTotalDebitAmt = resultData?.claimList[0]?.debitMemoAmount ?? 0;
        setTotalDebitAmt(iTotalDebitAmt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));

        setAccumClaimQty(resultData?.claimList[0]?.accumClaimQty ?? '');

        if (resultData?.claimList[0]?.accumClaimQty ?? '' != '') {

          let iTotalClaimAmt = resultData?.claimList[0]?.totalClaimAmt ?? 0;
          setTotalClaimAmt(iTotalClaimAmt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
          let iAccumClaimAmt = resultData?.claimList[0]?.accumClaimAmt ?? 0;
          setAccumClaimAmt(iAccumClaimAmt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
          let iAccumClaimOverAmt = resultData?.claimList[0]?.accumClaimOverAmt ?? 0
          setAccumClaimOverAmt(iAccumClaimOverAmt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));

          let overQty = resultData?.claimList[0]?.accumClaimOverQty;
          if (null != overQty && overQty != "") {
            overQty = overQty?.toLocaleString();
          }

          let claimOverQtyPct = resultData?.claimList[0]?.claimOverQtyPct;
          if (null != claimOverQtyPct && claimOverQtyPct != "") {
            claimOverQtyPct = claimOverQtyPct.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          }
          setTotalClaimOverQty(overQty + " (Over " + claimOverQtyPct + " %)");
        }


        //spiff
        if (reqClaimType !== "IR") {
          setEmail(resultData?.claimList[0]?.email);
          setTel(resultData?.claimList[0]?.tel);
          setFax(resultData?.claimList[0]?.fax);
          setStoreAddress(resultData?.claimList[0]?.storeAddress);
          setUserName(resultData?.claimList[0]?.userName);
          setAddress1(resultData?.claimList[0]?.address1);
          setAddress2(resultData?.claimList[0]?.address2);
          setCity(resultData?.claimList[0]?.city);
          setState(resultData?.claimList[0]?.state);
          setZip(resultData?.claimList[0]?.zip);

          setApprovalStatus(resultData?.claimList[0]?.approvalStatus ?? '');
          entityStoreGroup.current = resultData?.claimList[0]?.storeGroup;
          entityBillTo.current = resultData?.claimList[0]?.billTo;
          entityOrgBillTo.current = resultData?.claimList[0]?.billTo;
        }

        if (entityActionTarget.current === "SCM") {
          entityComment?.current?.setValue(resultData?.claimList[0]?.comment);
        } else {
          entityComment?.current?.setValue(resultData?.claimList[0]?.remark);
        }

        entityReview?.current?.setValue(resultData?.claimList[0]?.review);

        let holdCnt = 0;

        resultData?.claimList?.forEach((el, index) => {
          el.uiFlag = "U";

          if (el.dateSoldFrom != '' && el.dateSoldFrom != null) {
            el.dateSoldFrom = moment(ComUtils.DateToInteger(el.dateSoldFrom) + "", "YYYYMMDD").format("MM/DD/YYYY")
          }
          if (el.dateSoldTo != '' && el.dateSoldTo != null) {
            el.dateSoldTo = moment(ComUtils.DateToInteger(el.dateSoldTo) + "", "YYYYMMDD").format("MM/DD/YYYY")
          }

          if (reqClaimType === "IR") {
            if (el.status === "HOLD") {
              holdCnt++;
              if (el.arConfQty === null || el.arConfQty === '') {
                el.arConfQty = Number(el.claimQty);
              }
              el.orgArConfQty = Number(el.arConfQty);
            } else if (el.status === "RJCT" || el.status === "MODI" || el.status === "RVRS") {
              el.orgArConfQty = Number(el.arConfQty);
            }

            el.rejectQty = 0;
            if (el?.claimQty != null && el?.arConfQty != null) {
              el.rejectQty = Number(el?.claimQty) - Number(el?.arConfQty);
            }
            if (el.rejectCode == null || el.rejectCode == "") {
              el.rejectCode = "";
            }

            el.dateOverlapCombo = "";
            // if (el.status !== "HOLD") {
            //     el.custUnitReimburse = 0;
            //     el.overCmAmt = 0;
            // }
            //SPIFF

            el.netApprovedQty = ((el?.netAprvQty ?? "0") == "0" ? "" : ((el?.netAprvQty ?? 0)?.toLocaleString() + " APRV")) + " " + ((el?.netPendQty ?? "0") == "0" ? "" : ((el?.netPendQty ?? 0)?.toLocaleString() + " HOLD"));
            el.netRemainingQty = el.allowedQty ?? 0;
            if (el.weekNo != null && el.weekNo != "") {
              el.scmSellThru = el.weekNo + "Week";
            } else {
              el.scmSellThru = "";
            }

            if (el.status === "HOLD") {
              el.scmSellThruQty = Number(el.netSellOut ?? 0);
            } else {
              el.scmSellThruQty = Number(el.netScmSellThru ?? 0);
            }

            if (el.netScmFrom === "OLAP") {
              if (el.netSellOutGDMI == undefined) {
                el.scmSellThruGDMI = 0;
              } else {
                el.scmSellThruGDMI = Number(el.netSellOutGDMI ?? 0);
              }
            }

            if (el.netScmFrom !== "OLAP") {
              if (el.netSellOutOLAP == undefined) {
                el.scmSellThruOLAP = 0;
              } else {
                el.scmSellThruOLAP = Number(el.netSellOutOLAP ?? 0);
              }
            }

            if (el.status === "HOLD") {
              if (el.netScmConf == null || el.netScmConf == "" || el.netScmConf == "0") {
                el.claimedQty = 0;
              } else {
                el.claimedQty = Number(el.netScmConf ?? 0);
              }
            } else {
              el.claimedQty = Number(el.netScmSellThruClaim ?? 0);
            }
            el.netScmrRemainingQty = Number(el.netScmAllowedQty ?? 0);

          } else {
            if (el.rejectCodeDtl == null || el.rejectCodeDtl == "") {
              el.rejectCodeDtl = "";
            }

            el.arConfirmQty = el.confirmQty;


            if (el.status !== "HOLD") {
              el.netAprvAdhocQty = null;
            } else {
              if (el.netAprvAdhocQty == 0) {
                el.netAprvAdhocQty = null;
              }
            }

            // let iRejectQty = 0;
            // if (el?.qty != "" && el?.qty != null && el?.arConfirmQty != "" && el?.arConfirmQty != null) {
            //   iRejectQty = Number(el?.qty) - Number(el?.arConfirmQty);
            // }
            // el.rejectQty = iRejectQty;
            el.claimQty = el.qty;
            el.lgReimbur = el.spiffIrAmount;
            if (el.salesDate != '' && el.salesDate != null) {
              el.salesDate = moment(ComUtils.DateToInteger(el.salesDate) + "", "YYYYMMDD").format("MM/DD/YYYY")
            }

            el.dateOverlapCombo = "";
          }

        });

        setCont(holdCnt);

        if (reqActionTarget === "SCM") {
          let iTotalClaimAmt = 0;
          let iAccumClaimAmt = 0;
          let iAccumClaimOverAmt = 0;
          let iAccumClaimOverQty = 0;
          let claimOverQtyPct = 0;
          let totalScmSellThruQty = 0;
          resultData?.claimList?.forEach((el, index) => {
            iTotalClaimAmt = iTotalClaimAmt + Number(el.arConfQty ?? 0) * Number(el.reimbursementAmount ?? 0);
            iAccumClaimAmt = iAccumClaimAmt + Number(el.netScmSellThruClaim ?? 0) * Number(el.reimbursementAmount ?? 0);
            let claimOverQty = Number(el.netScmSellThruClaim ?? 0) - Number(el.netScmSellThru ?? 0);
            iAccumClaimOverAmt = iAccumClaimOverAmt + claimOverQty * Number(el.reimbursementAmount ?? 0);
            iAccumClaimOverQty = iAccumClaimOverQty + claimOverQty;
            totalScmSellThruQty = totalScmSellThruQty + Number(el.netScmSellThru ?? 0);
            el.diffClaimQty = claimOverQty;
          });
          if (totalScmSellThruQty > 0) {
            claimOverQtyPct = iAccumClaimOverQty / totalScmSellThruQty * 100
            if (claimOverQtyPct > 100) {
              claimOverQtyPct = 100;
            }
          }
          setTotalClaimAmt(iTotalClaimAmt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
          setAccumClaimAmt(iAccumClaimAmt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
          setAccumClaimOverAmt(iAccumClaimOverAmt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
          setTotalClaimOverQty(iAccumClaimOverQty.toLocaleString() + " (Over " + claimOverQtyPct.toFixed(2) + " %)");
        }



        setGridData(
          new CollectionView(resultData.claimList, {
            trackChanges: true,
            refreshOnEdit: false,
          })
        );
        setGridDataCount(resultData.claimList.length);
      }

      if (resultData?.appvlList?.length > 0) {
        setViewApprovalLine("Y");
        setGridDataDtl(
          new CollectionView(resultData.appvlList, {
            trackChanges: true,
            refreshOnEdit: false,
          })
        );
        setGridDataCountDtl(resultData.appvlList.length);
      } else {
        setViewApprovalLine("N");
      }

      //salesmanCode
      // pSalesman
      entitySalesmanCode?.current?.setValue(pSalesman);

      //fileList
      if (resultData?.fileList) {
        resultData?.fileList?.forEach(el => {
          el.displayFileName = el.originalFileName;
          el.uiFlag = "U";
          el.isOld = true;
        });
        setFileList(resultData.fileList);
        entityFileList.current = resultData.fileList;
      }

    }

    if (entityAddRow.current === "Y" && (entityMaxAddRow.current !== "" && entityMaxAddRow.current !== null && Number(entityMaxAddRow.current) > 0)) {
      for (let inx = 0; inx < Number(entityMaxAddRow.current); inx++) {
        onAddExecute();
      }
      entityAddRow.current = "";
    }

    let _choice = "----- CHOICE-----";
    let allCombo = [{ cd: "", nm: _choice }];
    let rejectCodeMap = new DataMap(allCombo.concat(entityRejectCodeMap.current), "cd", "nm");

    let dateOverlapMap = new DataMap(allCombo, "cd", "nm");

    if (resultData?.claimList[0]?.dateOverlap) {
      let filterDateOverlapMap = [];
      let arrText = resultData?.claimList[0]?.dateOverlap?.split(",");
      for (let i = 0; i < arrText.length; i++) {
        filterDateOverlapMap.push({ cd: arrText[i].trim(), nm: arrText[i].trim() });
      }
      dateOverlapMap = new DataMap(allCombo.concat(filterDateOverlapMap), "cd", "nm");

    }


    initGridFormat(entityClaimType.current, entityStatus.current, entityNeScmCheck.current, entityNetScmFrom.current, rejectCodeMap, dateOverlapMap, entitySellOutCreateDate.current);
    initGridFormatDtl();
    setIsLoading(false);
    // if (entityClaimType.current === "IR") {
    //   if (gridObject?.current?.getColumn("rejectCode")?.dropDownCssClass !== undefined && gridObject?.current?.getColumn("rejectCode")?.dropDownCssClass !== null) {
    //     gridObject.current.getColumn("rejectCode").dropDownCssClass = "reject-dropdown";
    //   }
    // } else {
    //   if (gridObject?.current?.getColumn("rejectCodeDtl")?.dropDownCssClass !== undefined && gridObject?.current?.getColumn("rejectCodeDtl")?.dropDownCssClass !== null) {
    //     gridObject.current.getColumn("rejectCodeDtl").dropDownCssClass = "reject-dropdown";
    //   }
    // }
    if (gridObject?.current?.rows?.length > 0 && gridObject?.current?.columns?.length > 0) {
      ComUtils.setGridFocus(gridObject, 0, "period");
    }

    setTimeout(() => {
      //eplogin session remove
      ComUtils.removeEpLoginSessionItem();
    }, 200);

  }

  const onAddExecute = async () => {
    let newDateInfo = _.cloneDeep(defaultData);
    if (gridData?.items?.length > 0) {
      let view = gridObject.current?.editableCollectionView;
      if (view) {
        view.addNew(newDateInfo, true);
        setGridDataCount(view._pgView.length);
      }

    }
    else {

      setGridData(new CollectionView([newDateInfo], { trackChanges: true }));
      setTimeout(() => {
        let view = gridObject.current?.editableCollectionView;
        view.removeAt(0);
        view.commitEdit();
        view.addNew(newDateInfo, true);
      }, 200);
      setGridDataCount(1);
    }

    gridObject?.current?.collectionView?.refresh();
    setTimeout(() => {
      gridObject.current.scrollIntoView(gridObject.current.selection.row, 0);
    }, 300);


    ComUtils.setGridFocus(gridObject, gridObject.current.selection.row, "model");

  }

  const onMoveList = () => {
    closeFn(true);
  };

  const onNumberKeyPress = (e) => {
    if (!((e.key >= '0' && e.key <= '9') || (e.key == '.'))) {
      e.preventDefault();
    }
  };

  const onAddRow = (e) => {
    if (entityMaxAdd?.current?.getValue() == "" || entityMaxAdd?.current?.getValue() == null) {
      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "CLM_ERR_00001", ["row count you want to add"]), () => {
        entityMaxAdd.current.setFocus();
      });

      return;
    }

    entityMaxAddRow.current = entityMaxAdd?.current?.getValue();
    entityAddRow.current = "Y";
    setInitFlag(!initFlag);
  };


  const onSave = () => {
    //SPIFF
    console.log("onSave==" + entityClaimType.current);
    if (entityClaimType.current !== "IR") {
      if (billToName == null || billToName == '') {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr049);
        return;
      }
    }

    if (!checkPostAuditClaim()) {
      return;
    }
    if (isSelloutDateChange) {
      validateSelloutPeriod("SAVE");
    } else {
      let flexGrid = gridObject.current;
      for (let inx = 0; inx < flexGrid.rows.length; inx++) {
        if (flexGrid.rows[inx].dataItem.uiFlag === "I") {

          if (entityClaimType.current === "IR") {
            if (flexGrid.rows[inx].dataItem.model == null || flexGrid.rows[inx].dataItem.model == "") {
              ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "CLM_ERR_00001", ["model code for rejection"]));
              break;
            }
            if (flexGrid.rows[inx].dataItem.claimQty == null || flexGrid.rows[inx].dataItem.claimQty == "") {
              ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "CLM_ERR_00001", ["Claim Quantity for rejection"]));
              break;
            }
            if (flexGrid.rows[inx].dataItem.reimburAmount == null || flexGrid.rows[inx].dataItem.reimburAmount == "") {
              ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "CLM_ERR_00001", ["Claim Amount for rejection"]));
              break;
            }
          } else {
            if (flexGrid.rows[inx].dataItem.model == null || flexGrid.rows[inx].dataItem.model == "") {
              ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "CLM_ERR_00001", ["model code for rejection"]));
              break;
            }
            if (flexGrid.rows[inx].dataItem.qty == null || flexGrid.rows[inx].dataItem.qty == "") {
              ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "CLM_ERR_00001", ["Claim Quantity for rejection"]));
              break;
            }
            if (flexGrid.rows[inx].dataItem.claimAmount == null || flexGrid.rows[inx].dataItem.claimAmount == "") {
              ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "CLM_ERR_00001", ["Claim Amount for rejection"]));
              break;
            }

          }

        }
      }
      onSaveExecute();
    }


  }
  const onSaveExecute = async (reqClaimStatus) => {
    let sendParam = {};
    console.log("onSaveExecute==" + entityClaimType.current);
    sendParam.claimType = entityClaimType.current;
    sendParam.postAuditCustomer = postAuditCustomer;
    sendParam.postAuditClaim = entityPostAuditClaim?.current?.getValue();

    sendParam.activityDateFrom = '';
    if (entityActivityDateFrom?.current?.getValue() != null && entityActivityDateFrom?.current?.getValue() !== '') {
      sendParam.activityDateFrom = moment(entityActivityDateFrom?.current?.getDateValueInteger() + "", "YYYYMMDD").format("MMDDYYYY");
    }
    sendParam.activityDateTo = '';
    if (entityActivityDateTo?.current?.getValue() != null && entityActivityDateTo?.current?.getValue() !== '') {
      sendParam.activityDateTo = moment(entityActivityDateTo?.current?.getDateValueInteger() + "", "YYYYMMDD").format("MMDDYYYY");
    }

    sendParam.target = 'dummy';
    sendParam.review = entityReview?.current?.getValue();
    sendParam.lgComment = entityLgComment?.current?.getValue();
    sendParam.comment = entityComment?.current?.getValue();
    sendParam.claimStatus = reqClaimStatus ?? '';
    sendParam.userId = userId;
    sendParam.claimId = reqClaimId;
    sendParam.salesman = entitySalesmanCode?.current?.getValue();
    sendParam.netScmCheck = netScmCheck;

    sendParam.fileChkYn = fileChkYn.current;
    sendParam.fileList = fileList;
    sendParam.claimAnalystEmpNo = entityClaimAnalystEmpNo.current;
    sendParam.createId = createId;
    sendParam.claimEntryType = '';


    let listData = [];
    let flexGrid = gridObject.current;
    for (let inx = 0; inx < flexGrid.rows.length; inx++) {
      let newDataInfo = _.cloneDeep(flexGrid.rows[inx].dataItem);
      newDataInfo.userId = userId;

      if (newDataInfo.dateSoldFrom != null && newDataInfo.dateSoldFrom != '') {
        newDataInfo.dateSoldFrom = ComUtils.DateToInteger(newDataInfo.dateSoldFrom);
      }
      if (newDataInfo.dateSoldTo != null && newDataInfo.dateSoldTo != '') {
        newDataInfo.dateSoldTo = ComUtils.DateToInteger(newDataInfo.dateSoldTo);
      }
      if (selApprovalStatus === "TO-DO" || claimStatus === "A2") {
        newDataInfo.salesman = entitySalesmanCode?.current?.getValue();
      } else {
        newDataInfo.salesman = entityParamSalesman.current;
      }
      listData.push(newDataInfo);

    }
    sendParam.listData = listData;

    //SPIFF
    sendParam.payMethod = '1';
    sendParam.changeSerialNo = '';
    sendParam.paramSalesman = entitySalesmanCode?.current?.getValue();

    let resultData = await ApiManager.post(updateConfDetailReview, sendParam);
    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "An error occurred during processing");
    } else {
      if (resultData?.resultCode == -100) {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, resultData.resultMsg);
        return;
      } else {
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, "Processed Successfully", () => {
          setInitFlag(!initFlag);
        });
        return;
      }
    }
  };

  const checkPostAuditClaim = () => {

    if (postAuditCustomer === "Y") {
      if (entityPostAuditClaim?.current?.getValue() !== "" && entityPostAuditClaim?.current?.getValue() != null) {

        if (entityPostAuditClaim?.current?.getValue() === "Y") { //Yes

          if ((entityActivityDateFrom?.current?.getValue() ?? "") === ""
            || (entityActivityDateTo?.current?.getValue() ?? "") === ""
          ) {

            ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Please input Activity Date.");
            return false;
          }

          var activityDateFrom = entityActivityDateFrom?.current?.getDateValueInteger();
          var activityDateTo = entityActivityDateTo?.current?.getDateValueInteger();

          if (!ComUtils.checkTwoDateStr(activityDateFrom, activityDateTo)) {
            ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Activity From Date shouldn't be greater than Activity To Date.");
            return false;
          }

          if (!ComUtils.checkTwoDateStr(activityDateFrom, entityCurDt.current) || !ComUtils.checkTwoDateStr(activityDateTo, entityCurDt.current)) {
            ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Activity Date shouldn't be greater than today.");
            return false;
          }

        }
      } else {

        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Please select Post Audit claim.");
        return false;
      }
    }
    if (selApprovalStatus === "TO-DO" || claimStatus === "A2") {
      if (entitySalesmanCode?.current?.getValue() == "" || entitySalesmanCode?.current?.getValue() == null) {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Please select a LG salesperson for this customer.");
        return false;
      }
    }
    return true;
  }

  const onUploads = () => {
    randomKey.current = ComUtils.setRefRandomKey();
    setOpenSerialInvoiceUploadPop(true);
  }
  const closeSerialInvoiceUploadPop = () => {
    setOpenSerialInvoiceUploadPop(false);
    setInitFlag(!initFlag);
  }


  const onConfirm = () => {
    ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, ComUtils.getMessage(messageObject, "CLM_CNF_00009", ["confirm"]), (r) => {
      if (r) {
        if (!checkPostAuditClaim()) {
          return;
        } else {
          onSaveExecute("AR")
        }

      }
    });
  }



  const onIsExist = (str) => {
    let chkFlag = true;
    console.log("onIsExist==" + entityClaimType.current);
    //SPIFF
    if (entityClaimType.current !== "IR") {
      if (str === "APRV") {
        if (billToName == null || billToName == '') {
          chkFlag = false;
          //msgClmErr049
          ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Bill to is inactive.Please change Bill To.");
          return;
        }
      }
    }

    if (str == "APRV" || str == "RJCT" || str == "RETURN") {
      if (createId === userId) {
        chkFlag = false;
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, "Entry person can't approve/reject this claim.", () => {
        });
        return;
      }
    }


    if (str == "APRV") {
      if (!epScmLoginFlag) {
        if (!checkPostAuditClaim()) {
          chkFlag = false;
          return;
        }
      }
    } else {
      let approveComment = entityApproveComment?.current?.getValue();
      if (approveComment === "" || approveComment == null) {
        chkFlag = false;
        let msg = "Please enter the Comment.";
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, msg, () => {
          entityApproveComment.current.setFocus();
        });
        return;
      }
    }

    if (chkFlag) {

      let confirmType = str;
      if (str == "APRV") {
        confirmType = "approve";
      } else if (str == "RJCT") {
        confirmType = "reject";
      }

      let msg = "Do you want to " + confirmType.toLowerCase() + " this entry?";
      ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, msg,
        (r) => {
          if (r) {
            let rowCountOverNetAlowedQty = 0;
            let flexGrid = gridObject?.current;

            for (let i = 0; i < flexGrid?.rows?.length; i++) {
              let dataItem = flexGrid.rows[i].dataItem;
              if (dataItem.status == "HOLD" && str == "APRV") {
                //SPMS BLOCK ZERO REIMBRUSEMENT AMOUNT from interfacing
                if (entityClaimType.current === "IR") {

                  if (!epScmLoginFlag) {

                    if (dataItem.reimbursementAmount == '' || Number(dataItem.reimbursementAmount) == 0) {
                      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, "You cannot approve when reimbursement amount is zero.", () => {
                      });
                      return;
                    }

                    //C20170121_80679 Reject code is mandatory for partial rejection
                    if (Number(dataItem.claimQty) != Number(dataItem.arConfQty)) {

                      if (dataItem.rejectCode == "" || dataItem.rejectCode == null) {
                        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, "Please select Rejection Reason for partial rejection.", () => {
                        });
                        return;
                      }
                    }
                    //C20170526_92198
                    if (Number(dataItem.arConfQty) > 0) {
                      if (Number(dataItem.arConfQty) > Number(dataItem.allowedQty)) {
                        rowCountOverNetAlowedQty += 1;
                      }
                    }
                  }
                  //SPIFF
                } else {
                  if (Number(dataItem.arConfirmQty) > 0) {
                    if (Number(dataItem.arConfirmQty) > Number(dataItem.allowedQty)) {
                      rowCountOverNetAlowedQty += 1;
                    }
                  }

                  if (Number(dataItem.claimQty) != Number(dataItem.arConfirmQty)) {
                    if (dataItem.rejectCodeDtl == '' || dataItem.rejectCodeDtl == null) {

                      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, "Please select Rejection Reason for partial rejection.", () => {
                      });
                      return;
                    }
                  }
                }

              } else if (str == "RJCT") {
                if (entityClaimType.current === "IR") {
                  if (!epScmLoginFlag) {
                    if (dataItem.status == "HOLD" && (dataItem.rejectCode == "" || dataItem.rejectCode == null)) {
                      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, "Please select Rejection Reason.", () => {
                      });
                      return;
                    }
                  }
                } else {
                  if (dataItem.rejectCodeDtl == "" || dataItem.rejectCodeDtl == null) {

                    ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, "Please select Rejection Reason.", () => {
                    });
                    return;
                  }
                  if (dataItem.rejectCommentDtl == "" || dataItem.rejectCommentDtl == null) {
                    ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, "Please select Rejection Comment.", () => {
                    });
                    return;
                  }
                }
              }

            }//end for

            if (str == "APRV") {
              if (epScmLoginFlag) {
                onModify(str);
              } else {

                if (isSelloutDateChange) {
                  validateSelloutPeriod("APRV");
                } else {
                  if (rowCountOverNetAlowedQty > 0 && (reqClaimId.substring(0, 2) !== "TC")) {
                    ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, "Claim Qty. is Exceed Allowed Qty. Are you sure to approve this claim?", (r) => {
                      if (r) {
                        onModify(str);
                      }
                    });

                  } else {
                    onModify(str);
                  }
                }
              }
            } else if (str != "APRV") {
              onModify(str);
            }

          }
        });
    }
  }



  const onModify = async (str) => {
    console.log("onModify==" + entityClaimType.current);
    let sendParam = {};

    let resultData = null;
    if (epScmLoginFlag) {
      sendParam.actionTarget = str;
      sendParam.userId = userId;
      sendParam.empNo = empNo;
      sendParam.claimId = reqClaimId;
      sendParam.approveComment = entityApproveComment?.current?.getValue();;
      sendParam.claimType = '31';
      sendParam.SCM = 'Y';
      sendParam.selectedRequestNo = reqClaimId;
      sendParam.claimEntryType = entityClaimEntryType.current;
      resultData = await ApiManager.post(updateApproveRejectListEp, sendParam);

    } else {

      sendParam.claimType = entityClaimType.current;
      sendParam.claimId = reqClaimId;
      sendParam.userId = userId;
      sendParam.claimAnalystEmpNo = entityClaimAnalystEmpNo.current;
      sendParam.actionTarget = str;
      sendParam.target = str;
      sendParam.updateStatus = str;
      sendParam.approveComment = entityApproveComment?.current?.getValue();

      sendParam.postAuditCustomer = postAuditCustomer;
      sendParam.postAuditClaim = entityPostAuditClaim?.current?.getValue();

      sendParam.activityDateFrom = '';
      if (entityActivityDateFrom?.current?.getValue() != null && entityActivityDateFrom?.current?.getValue() !== '') {
        sendParam.activityDateFrom = moment(entityActivityDateFrom?.current?.getDateValueInteger() + "", "YYYYMMDD").format("MMDDYYYY");
      }
      sendParam.activityDateTo = '';
      if (entityActivityDateTo?.current?.getValue() != null && entityActivityDateTo?.current?.getValue() !== '') {
        sendParam.activityDateTo = moment(entityActivityDateTo?.current?.getDateValueInteger() + "", "YYYYMMDD").format("MMDDYYYY");
      }

      let listData = [];
      let flexGrid = gridObject.current;
      for (let inx = 0; inx < flexGrid.rows.length; inx++) {

        let newDataInfo = _.cloneDeep(flexGrid.rows[inx].dataItem);

        newDataInfo.userId = userId;
        if (newDataInfo.dateSoldFrom != null && newDataInfo.dateSoldFrom != '') {
          newDataInfo.dateSoldFrom = ComUtils.DateToInteger(newDataInfo.dateSoldFrom);
        }
        if (newDataInfo.dateSoldTo != null && newDataInfo.dateSoldTo != '') {
          newDataInfo.dateSoldTo = ComUtils.DateToInteger(newDataInfo.dateSoldTo);
        }
        if (selApprovalStatus === "TO-DO" || claimStatus === "A2") {
          newDataInfo.salesman = entitySalesmanCode?.current?.getValue();
        } else {
          newDataInfo.salesman = entityParamSalesman.current;
        }

        listData.push(newDataInfo);

      }
      sendParam.listData = listData;
      resultData = await ApiManager.post(updateConfDetail, sendParam);
    }
    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "An error occurred during processing");
    } else {
      if (resultData?.resultCode == -100) {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, resultData.resultMsg);
        return;
      } else {
        if (epLoginYn) {
          //팝업 close
          ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, "Processed Successfully", () => {
            window.close();
          });
        } else {
          if (str === "APRV") {
            closeFn(true);
          } else {
            closeFn();
          }
        }
      }

    }

  }


  const validateSelloutPeriod = async (callId) => {
    console.log("validateSelloutPeriod==" + entityClaimType.current);
    let editedData = [];
    let flexGrid = gridObject.current;
    for (let inx = 0; inx < flexGrid.rows.length; inx++) {
      let newDataInfo = _.cloneDeep(flexGrid.rows[inx].dataItem);
      if (newDataInfo.uiFlag === "U") {
        if (newDataInfo.dateSoldFrom != null && newDataInfo.dateSoldFrom != '') {
          newDataInfo.dateSoldFrom = ComUtils.DateToInteger(newDataInfo.dateSoldFrom);
        }
        if (newDataInfo.dateSoldTo != null && newDataInfo.dateSoldTo != '') {
          newDataInfo.dateSoldTo = ComUtils.DateToInteger(newDataInfo.dateSoldTo);
        }
        editedData.push(newDataInfo);
      }
    }
    if (editedData.length > 0) {
      let resultData = await ApiManager.post(retrieveValidateSelloutPeriod, { "listData": editedData, "claimType": entityClaimType.current, "billTo": billTo, "claimId": reqClaimId });
      if (resultData?.statusCode) {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "An error occurred during processing");
      } else {

        if (resultData != null && resultData?.length > 0 && resultData[0]?.dateOverlap != null) {
          let msg = "Sell Out Date Range overlaps in the previous claim.\nDo you want to continue?\n";
          for (let inx = 0; inx < resultData?.length; inx++) {
            msg += "Model : " + resultData[inx]?.model + " - " + "claimed in " + resultData[inx]?.dateOverlap + "\n";
          }
          ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, msg, (r) => {
            if (r) {
              let flexGrid = gridObject.current;

              for (let inx = 0; inx < flexGrid.rows.length; inx++) {
                for (let jnx = 0; jnx < resultData.length; jnx++) {
                  if (inx == jnx) {
                    if (entityClaimType.current === "IR") {
                      flexGrid.rows[inx].dataItem.dateOverlap = resultData[inx]?.dateOverlap;
                    } else {
                      flexGrid.rows[inx].dataItem.dateOverlap = resultData[jnx]?.dateOverlap?.indexOf(",") < 0 ? resultData[jnx]?.dateOverlap : resultData[jnx]?.dateOverlap?.substring(resultData[jnx]?.dateOverlap?.lastIndexOf(",") + 1);
                    }
                  }

                }
              }
              if (callId === "SAVE") {
                setIsSelloutDateChange(false);
                onSaveExecute();
              } else {
                if (entityClaimType.current === "IR") {
                  onModify("APRV");
                } else {
                  onSpiffModify();
                }
              }
            }
          });

        } else {
          if (callId === "SAVE") {
            setIsSelloutDateChange(false);
            onSaveExecute();
          } else {
            if (entityClaimType.current === "IR") {
              onModify("APRV");
            } else {
              onSpiffModify();
            }
          }
        }
      }
    }
  }

  const onFileUpload = () => {
    fileElement.current.click();
  }

  const handleChange = (event) => {
    if (event.target.files.length > 0) {
      let chkFlow = true;
      // file 길이 체크
      for (let i = 0; i < event.target.files.length; i++) {
        let fullFileName = event.target.files[i].name;
        let tmpFileName = event.target.files[i].name.split(".");
        let extName = tmpFileName[tmpFileName.length - 1];
        if (fullFileName.replace(extName, "").length > 92) {
          chkFlow = false;
          ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr027);
          break;
        }
      }
      // file 중복 체크
      if (fileList) {
        for (let i = 0; i < event.target.files.length; i++) {
          let isDupCheck = fileList.some((el) => el.displayFileName === event.target.files[i].name);
          if (isDupCheck) {
            chkFlow = false;
            ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr055);
            break;
          }
        }
      }
      if (chkFlow) {

        for (let i = 0; i < event.target.files.length; i++) {

          let formData = new FormData();
          let extPass = false;
          let fname = event.target.files[i].name.split(".");

          if (fname.length > 0) {
            let extName = fname[fname.length - 1];
            if (extName !== undefined && extName != null) {
              extName = extName.toLowerCase();
            }
            if (FileUtils.allowFileExt?.indexOf(extName) > -1) {
              extPass = true;
            } else {
              ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr029);
              break;
            }
          }
          if (extPass) {
            upLoadFileExcute(event.target.files[i])
          }
        }
      }

    }
  };

  const removeFile = async (fileInfo, event) => {

    const response = await ApiManager.post(deleteFile, { "filePath": fileInfo.filePath, "serverFileName": fileInfo.serverFileName, "customPath": entityClaimType.current === "IR" ? "ir" : "spiff" });

    let newFileList = fileList.filter(f => f.serverFileName !== fileInfo.serverFileName);
    entityFileList.current = newFileList;
    setFileList(newFileList);
  }

  const upLoadFileExcute = async (uploadFileInfo) => {
    console.log("upLoadFileExcute==" + entityClaimType.current);
    let formData = new FormData();
    formData.append('UploadFile', uploadFileInfo);
    formData.append("customPath", entityClaimType.current === "IR" ? "ir" : "spiff");
    formData.append("createDate", "T");
    try {
      let retrieveFileData = await ApiManager.post("/Common/File/FileUploadCustomPath", formData);
      let uploadedFileInfo = { "serverFileName": retrieveFileData[0].savedFileName, "filePath": retrieveFileData[0].savedFilePath, "fileSize": uploadFileInfo.size, "displayFileName": retrieveFileData[0].orgFileName, "userId": userId, "uiFlag": "I" };


      if (entityFileList?.current == null) {
        setFileList([uploadedFileInfo]);
        entityFileList.current = [uploadedFileInfo];
      } else {
        let allList = [];
        allList = [...entityFileList.current, uploadedFileInfo];
        entityFileList.current = allList;
        setFileList(allList);
      }
      fileChkYn.current = "Y";
    }
    catch (ex) {
      console.log(ex);
    }


    fileElement.current.value = "";

  }

  const onRevert = () => {
    let msg = "";
    if (entityClaimType.current === "IR") {
      msg = msgClmCnf011;
    } else {
      msg = ComUtils.getMessage(messageObject, "CLM_CNF_00012", ["revert"]);
    }
    ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, msg, (r) => { if (r) { onRevertExecute() } });

  }

  const onRevertExecute = async () => {
    let sendParam = {};
    sendParam.claimType = entityClaimType.current;
    sendParam.userId = userId;
    sendParam.claimId = reqClaimId;

    //SPIFF
    sendParam.submitStatus = '';
    sendParam.claimStatus = '';


    let resultData = await ApiManager.post(updateRevertConfDetail, sendParam);
    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr011);
    } else {
      if (resultData?.resultCode == -100) {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, resultData.resultMsg);
        return;
      } else {
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, revertedMessage, () => {
          closeFn();
        });
      }
    }
  }


  const onSpiffPullBack = () => {
    ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, ComUtils.getMessage(messageObject, "CLM_CNF_00012", ["Pull Back"]), (r) => { if (r) { onSpiffPullBackExecute() } });

  }

  const onSpiffPullBackExecute = async () => {
    let sendParam = {};
    sendParam.claimType = entityClaimType.current;
    sendParam.userId = userId;
    sendParam.claimId = reqClaimId;

    let resultData = await ApiManager.post(updatePullBackConfDetail, sendParam);
    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr011);
    } else {
      if (resultData == 1) {
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, revertedMessage, () => {
          closeFn();
        });
      } else {
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, revertMessage, () => {
          closeFn();
        });
      }
    }
  }
  const onReverse = () => {
    //  if (confirm(msgClmCnf011) ){
    ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, msgClmCnf013, (r) => { if (r) { onReverseExecute() } });
  }
  const onReverseExecute = async () => {
    let sendParam = {};
    sendParam.claimType = entityClaimType.current;
    sendParam.userId = userId;
    sendParam.claimId = reqClaimId;

    let resultData = await ApiManager.post(updateReverseConfDetail, sendParam);
    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr011);
    } else {
      if (resultData?.resultCode == -100) {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, resultData.resultMsg);
        return;
      } else {
        setInitFlag(!initFlag);
      }
    }
  }

  ///////////////////////////////////////////SPIFF
  const onSerialClick = (dataItem, rowIndex) => {

    let chkFlag = true;
    if ((dataItem.qty ?? 0) === 0) {
      chkFlag = false;
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr043);
    }
    if ((dataItem.model ?? '') === '') {
      chkFlag = false;
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr044);
    }

    if (chkFlag) {
      let progText = dataItem.programId

      entitySerial.current = dataItem?.serial;
      entityResult.current = dataItem?.checkResult;

      if (entitySerial.current != null && entitySerial.current != '') {
        entitySerial.current = entitySerial.current.replaceAll(",", ", ")
      }

      entityMsg.current = dataItem?.serialDuplicate;

      entityProgramId.current = progText;
      entityQty.current = dataItem.qty;
      entitySerialRow.current = rowIndex;
      randomKey.current = ComUtils.setRefRandomKey();

      setOpenSerialRegPop(true);
    }
  }

  const closeSerialRegPop = (resultData, rowIdx) => {
    let flexGrid = gridObject.current;
    if (resultData) {
      let sbSerialNo = null;
      let sbResult = null;

      for (let inx = 0; inx < resultData.serialList.length; inx++) {
        if (inx == 0) {
          sbSerialNo = resultData.serialList[inx].serialNo + ",";
          sbResult = resultData.serialList[inx].result + ",";

        } else {
          sbSerialNo = sbSerialNo + resultData.serialList[inx].serialNo + ",";
          sbResult = sbResult + resultData.serialList[inx].result + ",";
        }
      }

      let strText = sbSerialNo.substring(0, sbSerialNo.length - 1);
      let strResult = sbResult.substring(0, sbResult.length - 1);

      let arrText = strText.split(",");

      flexGrid.rows[rowIdx].dataItem.qty = Number(arrText.length);
      flexGrid.rows[rowIdx].dataItem.claimAmount = (Number(arrText.length) * Number(flexGrid.rows[rowIdx].dataItem.spiffIrAmount));
      flexGrid.rows[rowIdx].dataItem.serial = strText.toUpperCase().replace(/\s+/g, '');
      flexGrid.rows[rowIdx].dataItem.result = strResult;
      flexGrid.rows[rowIdx].dataItem.checkResult = resultData.errorResult;
      flexGrid.rows[rowIdx].dataItem.serialDuplicate = "Valid";
      flexGrid.rows[rowIdx].dataItem.changeSerialNo = "Y";
      flexGrid.collectionView.refresh();
    } else {
      if (rowIdx != null) {
        flexGrid.rows[rowIdx].dataItem.serial = null;
        flexGrid.rows[rowIdx].dataItem.result = null;
        flexGrid.rows[rowIdx].dataItem.checkResult = null;
        flexGrid.rows[rowIdx].dataItem.serialDuplicate = "";
        flexGrid.rows[rowIdx].dataItem.changeSerialNo = "Y";
        flexGrid.collectionView.refresh();
      }
    }


    setOpenSerialRegPop(false);

  }


  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === 'confirmClaimIdSelected') {
        onDateOverlapCombo(event.data.claimId);
      } else if (event.data.type === 'irConfDateOverlapSelected') {
        onDateOverlapCombo(event.data.claimId);
      } else if (event.data.type === 'confirmIrDateOverlapSelected') {
        onDateOverlapCombo(event.data.claimId);
      } else if (event.data.type === 'confirmSpiffDateOverlapSelected') {
        onDateOverlapCombo(event.data.claimId);
      }

    };
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const [isHiddenSearch, setHiddenSearch] = useState(true);

  const onDsValidaton = async () => {

    let sendParam = {}
    sendParam.claimId = reqClaimId;
    sendParam.billTo = billTo;

    const width = 1300;
    const height = 600;
    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height / 2) - (height / 2);
    popElement.current = window.open('', '_blank', `width=${width},height=${height},top=${top},left=${left}`);

    let resultData = await ApiManager.post(retrieveDateSoldValidationList, sendParam);

    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr185);
      return;
    } else {

      let element = <IrDateSoldValidationInfo
        claimList={resultData}
        claimId={reqClaimId}
        billTo={billTo}
        serverUrl={entityServerURL.current}
      />
      const htmlString = ReactDOMServer.renderToString(element);

      let script = `
              <script>
                  window.onload = function() {
                    document.getElementById("exelExport").onclick = function() {
                      onExceldown();
                    };

                    async function onExceldown() {
                    try {
                        const requestData = {
                          claimId: document.getElementById("claimId").value,
                          billTo: document.getElementById("billTo").value
                        };
                        
                        var fullUrl = document.getElementById("serverUrl").value + "/lgsp/api/clm/isf/retrieveDateSoldValidationListExcel";
                        const response = await fetch(fullUrl, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify(requestData),
                        })
                        .then(response => response.blob())
                        .then(blob => {
                          const url = window.URL.createObjectURL(new Blob([blob]));
                          const link = document.createElement('a');
                          link.href = url;
                          link.setAttribute('download', 'IRDateSold Validation Result.xlsx'); // or any other extension
                          document.body.appendChild(link);
                          link.click();
                          link.parentNode.removeChild(link);
                        })
                        .catch(error => console.error('Error downloading the Excel file:', error));

                      } catch (error) {
                        console.error('Error fetching accumDtl:', error);
                      } finally {
                      }
                    };

                  };
                 
                  const claimIdInfo = document.querySelectorAll('.claimClick');
                  claimIdInfo.forEach(el => {
                    el.addEventListener('click', function() {
                      const claimId = el.getAttribute('claim-id');
                      window.opener.postMessage({ type: 'confirmClaimIdSelected', claimId }, '*');
                    });
                  });

                  window.addEventListener('message', function handleMessage(event) {
                    if (event.origin === window.location.origin) {
                      alert(event.data);
                    }
                  });
                  
                </script>
                `;

      let printHtml = htmlString + script;

      popElement.current.document.writeln(printHtml);
      popElement.current.document.close();
      popElement.current.document.title = "LG Electronics :: AIC";


      popElement.current.focus();

    }
  }

  const onSaveFile = async () => {
    let sendParam = {};

    if (fileChkYn.current != "Y") {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr056);
      return;
    }
    let chkFlag = false;
    for (let inx = 0; inx < fileList.length; inx++) {
      if (fileList[inx].uiFlag === "I") {
        chkFlag = true;
        break;
      }
    }
    if (!chkFlag) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr056);
      return;
    }

    sendParam.claimType = entityClaimType.current;
    sendParam.userId = userId;
    sendParam.claimId = reqClaimId;
    sendParam.fileChkYn = fileChkYn.current;
    sendParam.fileList = fileList;

    let resultData = await ApiManager.post(updateConfFileSave, sendParam);
    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr011);
    } else {
      if (resultData?.resultCode == -100) {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, resultData.resultMsg);
        return;
      } else {
        fileChkYn.current = "N";
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, savedMessage, () => {
          setInitFlag(!initFlag);
        });
      }


    }
  }

  const onReturn = () => {

    let approveComment = entityApproveComment?.current?.getValue();
    if (approveComment == null || approveComment === "") {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "CLM_ERR_00053", ["the Comment"]));
      entityApproveComment.current.setFocus();
      return;
    }

    ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, ComUtils.getMessage(messageObject, "CLM_CNF_00020", ["return this entry"]), (r) => { if (r) { onReturnExecute() } });
  }
  const onReturnExecute = async () => {
    let sendParam = {};
    sendParam.claimType = "SPIFF"
    sendParam.userId = userId;
    sendParam.actionTarget = "RETURN";
    sendParam.approveComment = entityApproveComment?.current?.getValue();
    sendParam.claimEntryType = '';
    sendParam.rejectComment = '';
    sendParam.confirmType = '';
    sendParam.empNo = empNo;
    sendParam.claimId = reqClaimId;
    sendParam.selectedRequestNo = reqClaimId;


    let resultData = await ApiManager.post(updateApproveRejectList, sendParam);
    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr011);
    } else {
      if (resultData?.resultCode == -100) {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, resultData.resultMsg);
        return;
      } else {
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, savedMessage, () => {
          closeFn();
        });
      }
    }
  }

  //SPIFF
  const onApprove = () => {

    if (billToName == null || billToName == '') {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Bill to is inactive.Please change Bill To.");
      return;
    }

    if (createId == userId) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Entry person can't approve this claim.");
      return;
    }

    ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, "Do you want to approve this entry?", (r) => {
      if (r) {
        //changing claim dealer bill to code 05/20/2020 C20200428_24043
        if (entityBillTo.current == "" || entityBillTo.current == null) {

          ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Please select Bill To.");
          return;
        };

        // if (entityStoreGroup.current === "DESIGNER" && entityBillTo?.current?.getValue() == "US067120001B") {
        //   ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Please change Bill To.");
        //   return;
        // };

        if (entityBillTo.current != entityOrgBillTo.current) {
          ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Please save this claim entry first.");
          return;
        };

        if (!fncCheckQty()) {
          return;
        }
        if (isSelloutDateChange) {
          validateSelloutPeriod("APRV");
        } else {
          onSpiffModify();
        }
      }
    });

  }

  const onSpiffModify = async () => {
    let sendParam = {};
    sendParam.claimType = "SPIFF"
    sendParam.updateStatus = "APRV"
    sendParam.payMethod = "1"
    sendParam.partialApprove = "";
    sendParam.claimAnalystEmpNo = entityClaimAnalystEmpNo.current;
    sendParam.userId = userId;
    sendParam.createId = createId;
    sendParam.claimId = reqClaimId;
    sendParam.approveComment = entityApproveComment?.current?.getValue();

    let listData = [];
    let flexGrid = gridObject.current;
    for (let inx = 0; inx < flexGrid.rows.length; inx++) {
      let newDataInfo = _.cloneDeep(flexGrid.rows[inx].dataItem);

      newDataInfo.userId = userId;
      if (newDataInfo.dateSoldFrom != null && newDataInfo.dateSoldFrom != '') {
        newDataInfo.dateSoldFrom = ComUtils.DateToInteger(newDataInfo.dateSoldFrom);
      }
      if (newDataInfo.dateSoldTo != null && newDataInfo.dateSoldTo != '') {
        newDataInfo.dateSoldTo = ComUtils.DateToInteger(newDataInfo.dateSoldTo);
      }
      if (selApprovalStatus === "TO-DO" || claimStatus === "A2") {
        newDataInfo.salesman = entitySalesmanCode?.current?.getValue();
      } else {
        newDataInfo.salesman = entityParamSalesman.current;
      }
      listData.push(newDataInfo);

    }
    sendParam.listData = listData;

    let resultData = await ApiManager.post(updateConfDetail, sendParam);
    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "An error occurred during processing");
    } else {
      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, "Approved Successfully", () => {
        if (epLoginYn) {
          //팝업 close
          window.close();
        } else {
          closeFn(true);
        }

      });
    }
  }

  const fncCheckQty = () => {
    let checkLength = gridObject.current.rows.length;

    let rowCountOverNetAlowedQty = 0;

    //Credit note C20170420_63278
    if (checkLength > 1) {

      for (let i = 0; i < checkLength; i++) {
        if (Number(gridObject.current.rows[i].dataItem.arConfirmQty) > 0) {

          if (Number(gridObject.current.rows[i].dataItem.arConfirmQty) > Number(gridObject.current.rows[i].dataItem.allowedQty)) {
            rowCountOverNetAlowedQty += 1;
          }
        }

      }

      if (rowCountOverNetAlowedQty > 0) {
        ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, "Claim Qty. is Exceed Allowed Qty. Are you sure to approve this claim?", (r) => {

          if (r) {
            for (let j = 0; j < checkLength; j++) {
              if (gridObject.current.rows[j].dataItem.claimQty != gridObject.current.rows[j].dataItem.arConfirmQty) {
                if (gridObject.current.rows[j].dataItem.rejectCodeDtl == "" || gridObject.current.rows[j].dataItem.rejectCodeDtl == null) {

                  ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Please select Rejection Reason for partial rejection.");
                  return false;
                }
              }
            }
          } else {
            return false;
          }
        });

      }
    }
    return true;
  }

  const onReject = () => {

    let chkFlag = true;
    if (createId === userId) {
      chkFlag = false;

      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, "Entry person can't reject this claim.", () => {
      });
      return;
    }

    var approveComment = entityApproveComment?.current?.getValue();
    if (approveComment == null || approveComment == "") {
      chkFlag = false;

      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, "Please enter the Comment.", () => {
        entityApproveComment.current.setFocus();
      });
      return;
    }
    if (chkFlag) {

      ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, "Are you sure to reject this claim ?", (r) => {
        if (r) {

          let rejectCode = "";
          let rejectComment = "";
          //Credit note C20170420_63278
          let checkLength = gridObject?.current?.rows?.length;
          if (checkLength > 1) {
            for (let i = 0; i < checkLength; i++) {
              let dataItem = gridObject?.current?.rows[i].dataItem;
              if (dataItem.rejectCodeDtl === "" || dataItem.rejectCodeDtl == null) {

                ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Please select Rejection Reason.");
                return;
              }
              if (dataItem.rejectCommentDtl === "" || dataItem.rejectCommentDtl == null) {
                ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Please select Rejection Comment.");
                return;
              }
            }
            rejectCode = gridObject?.current?.rows[0].dataItem.rejectCodeDtl;
            rejectComment = gridObject?.current?.rows[0].dataItem.rejectCommentDtl;
          }

          onRejectExecute(rejectCode, rejectComment);
          //Spiff Confirm Screen_Rejection pop up screen removal for Credit Note. C20180927_03282
          // openPopup('/jsp/achievements/spiff/RejectCommentP.jsp', '', '620', '370', 'scrollbars=no', 'location=0');

        }
      });
    }

  }

  useEffect(() => {
    const grid = gridRef?.current?.control;

    // Add copying event handler
    grid?.copying?.addHandler((s, e) => {
      // 기본 동작 취소

      e.cancel = true;
      if (entityFooterHit.current) {
        if (s?.columnFooters?.getCellData(0, e.col)) {
          wjCore?.Clipboard?.copy(s?.columnFooters?.getCellData(0, e.col)?.toString());
        } else {
          wjCore?.Clipboard?.copy("");
        }
      }
    });

    // Add click event handler for footer cells
    grid?.hostElement?.addEventListener('click', (e) => {
      const ht = grid.hitTest(e);
      if (ht.panel === grid.columnFooters) {
        entityFooterHit.current = true;

        grid.select(ht.row, ht.col);
        grid.focus();
      } else {
        entityFooterHit.current = false;
      }
    });
  }, [gridData]);

  const onRejectExecute = async (rejectCode, rejectComment) => {
    let sendParam = {};
    sendParam.claimType = "SPIFF"
    sendParam.updateStatus = "RJCT"
    sendParam.actionTarget = "RJCT";
    sendParam.claimAnalystEmpNo = entityClaimAnalystEmpNo.current;
    sendParam.createId = createId;
    sendParam.userId = userId;
    sendParam.claimId = reqClaimId;
    sendParam.empNo = empNo;
    sendParam.claimType = '32';
    sendParam.claimEntryType = '';
    sendParam.rejectCode = rejectCode;
    sendParam.rejectComment = rejectComment;
    sendParam.serverURL = ''; //TODO
    sendParam.confirmType = '';
    sendParam.payMethod = "1"
    sendParam.approveComment = entityApproveComment?.current?.getValue();



    let listData = [];
    let flexGrid = gridObject.current;
    for (let inx = 0; inx < flexGrid.rows.length; inx++) {
      let newDataInfo = _.cloneDeep(flexGrid.rows[inx].dataItem);

      newDataInfo.userId = userId;
      if (newDataInfo.dateSoldFrom != null && newDataInfo.dateSoldFrom != '') {
        newDataInfo.dateSoldFrom = ComUtils.DateToInteger(newDataInfo.dateSoldFrom);
      }
      if (newDataInfo.dateSoldTo != null && newDataInfo.dateSoldTo != '') {
        newDataInfo.dateSoldTo = ComUtils.DateToInteger(newDataInfo.dateSoldTo);
      }
      if (selApprovalStatus === "TO-DO" || claimStatus === "A2") {
        newDataInfo.salesman = entitySalesmanCode?.current?.getValue();
      } else {
        newDataInfo.salesman = entityParamSalesman.current;
      }
      listData.push(newDataInfo);

    }
    sendParam.listData = listData;

    let resultData = await ApiManager.post(updateConfDetail, sendParam);
    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "An error occurred during processing");
    } else {
      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, "Claim Rejected", () => {
        if (epLoginYn) {
          //팝업 close
          window.close();

        } else {
          closeFn();
        }
      });
    }
  }

  const onSelectionChanged = (sender) => {
    ComUtils.startEditing(sender);

    let col = sender.selection.col;
    if ("dateOverlapCombo" == sender?.cells?.columns[col]?._binding?._key) {
      if (sender !== null && sender.selection.row !== -1) {
        if (entityCurRow.current != sender.selection.row) {
          let _item = sender.selectedItems[0];

          let allCombo = [{ cd: "", nm: "----- CHOICE-----" }];
          let columnObject = gridObject.current.getColumn('dateOverlapCombo');
          let dateOverlapMap = new DataMap(allCombo, "cd", "nm");

          if (_item?.dateOverlap) {
            let filterDateOverlapMap = [];
            let arrText = _item?.dateOverlap?.split(",");
            for (let i = 0; i < arrText.length; i++) {
              filterDateOverlapMap.push({ cd: arrText[i].trim(), nm: arrText[i].trim() });
            }
            dateOverlapMap = new DataMap(allCombo.concat(filterDateOverlapMap), "cd", "nm");
            columnObject.dataMap = dateOverlapMap;
          }
        }

      }
    }
    entityCurRow.current = sender.selection.row;
    // gridObject.current.select(sender.selection.row, sender.selection.col);

  };

  const onAccumDtl = async () => {

    let sendParam = {}
    sendParam.claimId = reqClaimId;
    sendParam.userId = userId;
    sendParam.claimType = "IR";
    sendParam.actionTarget = "SCM";
    sendParam.target = "detail";
    sendParam.popup = "Y";
    sendParam.userLevel = "1";

    const width = 1300;
    const height = 800;
    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height / 2) - (height / 2);
    const printWindow = window.open('', '_blank', `width=${width},height=${height},top=${top},left=${left}`);

    let resultData = await ApiManager.post(retrieveConfListDtl, sendParam);
    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr185);
      return;
    } else {
      if (resultData?.claimList?.length > 0) {

        let element = <IrConfScmDtlList pageInfo={pageInfo}
          claimId={reqClaimId}
          billToName={billToName}
          customerInfo={resultData?.claimList[0]}
          claimList={resultData?.claimList}
          appvlList={resultData?.appvlList}
          selApprovalStatus={selApprovalStatus}
          fileList={fileList}
          scmTotalDebitAmt={totalDebitAmt}
          scmTotalClaimAmt={totalClaimAmt}
          scmAccumClaimAmt={accumClaimAmt}
          scmAccumClaimOverAmt={accumClaimOverAmt}
          scmTotalClaimOverQty={totalClaimOverQty}
          userId={userId}
          empNo={empNo}
          serverUrl={entityServerURL.current}
        />


        const htmlString = ReactDOMServer.renderToString(element);

        let script = `
              <script>
                  window.onload = function() {
                    document.getElementById("approveButton").onclick = function() {
                      fncIsExist('APRV');
                    };
                    document.getElementById("rejectButton").onclick = function() {
                      fncIsExist('RJCT');
                    };
                    function fncIsExist(str) {
                      if (str == "APRV" || str == "RJCT") {
                        if (document.getElementById("scmUserId").value == document.getElementById("scmCreateId").value) {
                          alert(msgClmErr052);
                          return;
                        }
                      }

                      if (str == "APRV"){
                      } else {
                          var approveComment = document.getElementById("scmApproveComment").value;
                          if("" == approveComment || approveComment == null){
                            alert('Please enter the Comment.');
                            return;
                          }
                      }

                      var confirmType = str;
                      if (str == "APRV") {
                        confirmType = "approve";
                      } else if (str == "RJCT") {
                        confirmType = "reject";
                      }
                      if (!confirm("Do you want to "+ confirmType.toLowerCase() +" this entry?") ){
                        return;
                      }
                      onModify(str, confirmType);
                    }
                  };

                  async function onModify(str, confirmType) {
                    try {

                      const requestData = {
                        actionTarget: str,
                        userId: document.getElementById("scmUserId").value,
                        empNo: document.getElementById("scmEmpNo").value,
                        claimId: document.getElementById("scmClaimId").value,
                        approveComment: document.getElementById("scmApproveComment").value,
                        claimType: '31',
                        SCM: 'Y',
                        selectedRequestNo: document.getElementById("scmClaimId").value,
                        claimEntryType: document.getElementById("scmClaimEntryType").value,
                      };
                      var fullUrl = document.getElementById("serverUrl").value + "/lgsp/api/clm/isf/updateApproveRejectListEp";
                      const response = await fetch(fullUrl, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(requestData),
                      });
                      const data = await response.json();
                      const rsltCode = data.data.resultCode;
                      if (!response.ok || rsltCode === 'F') {
                        alert(data.data.statusCode);
                        throw new Error('Network response was not ok');
                      } else {
                        alert("Processed Successfully");
                        window.close();
                      }

                    } catch (error) {
                      console.error('Error fetching accumDtl:', error);
                    } finally {
                    }
                  };

                </script>
                <script>
                $(function() {
                    $("#activityDateFrom,#activityDateTo").datepicker({
                      changeMonth: true,
                      changeYear: true,
                      showAnimation:'slide',
                      showButtonPanel:false,
                      showOn:"button",
                      buttonImage:"/images/temp_calender.gif",
                      isRTL: false,
                      showMonthAfterYear: true,
                      yearSuffix: "",
                      buttonText: "Calender"
                    });
                    $("#activityDateFrom,#activityDateTo").mask("99/99/9999");
                });
            </script>`;

        let printHtml = "<link rel='stylesheet' type='text/css' href='/styles/IrConfScmDtlPrint.css'>"
        printHtml += "<link rel='stylesheet' href ='https://code.jquery.com/ui/1.12.1/themes/base/jquery-ui.css'>"
        printHtml += "<script src='https://code.jquery.com/jquery-3.6.0.min.js'></script>"
        printHtml += "<script src='https://code.jquery.com/ui/1.12.1/jquery-ui.min.js'></script>"
          + htmlString + script;

        printWindow.document.writeln(printHtml);
        printWindow.document.close();
        printWindow.document.title = "LG Electronics :: AIC";


        printWindow.focus();
      }
    }

  }

  const excelDownload = () => {
    let sExcelTitle = reqClaimType + " Claim Detail List";

    let book = FlexGridXlsxConverter.saveAsync(
      gridObject.current,
      {
        includeColumnHeaders: true,
        includeRowHeaders: true,
        sheetName: sExcelTitle,
        includeColumns: (column) => {
          return column.isVisible !== false;
        },
        formatItem: (args) => {
          const col = args.panel.columns[args.col];

          //header
          if (args.panel.cellType == wjGrid.CellType.ColumnHeader) {
            args.xlsxCell.style.hAlign = 2;
            args.xlsxCell.style.vAlign = 1;
            args.xlsxCell.style.wrapText = true;
          } else {
            //No
            if (args.col === 0) {
              if (args.panel.cellType == 4) {
                //header
                args.xlsxCell.value = "No";
                args.xlsxCell.style.hAlign = 2;
                args.xlsxCell.style.vAlign = 1;
                args.xlsxCell.rowSpan = 2;
                args.xlsxCell.style.wrapText = true;

              } else if (args.panel.cellType == 3) {
                //row
                args.xlsxCell.value = args.row + 1;
              }
            } else {
              if (col.binding === 'reimbursementAmount'
                || col.binding === 'spiffIrAmount'
                || col.binding === 'reimburAmount'
                || col.binding === 'overCmAmt'
                || col.binding === 'claimAmount'
              ) {
                args.xlsxCell.style.format = '"$"#,##0.00';
              }
            }
          }
        }
      },
      sExcelTitle + '.xlsx'
    );

  }

  const onIrClaimDetail = async () => {
    let sendParam = {}
    sendParam.claimId = reqClaimId;
    sendParam.userId = userId;
    sendParam.claimType = "IR";
    sendParam.target = "detail";
    sendParam.popup = "Y";
    sendParam.userLevel = "1";

    const width = 1600;
    const height = 800;
    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height / 2) - (height / 2);
    claimDtlPopElement.current = window.open('', '_blank', `width=${width},height=${height},top=${top},left=${left}`);

    let resultData = await ApiManager.post(retrieveConfListDtl, sendParam);
    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr185);
      return;
    } else {
      if (resultData?.claimList?.length > 0) {

        let element = <IrConfDtlList
          pageInfo={pageInfo}
          claimId={reqClaimId}
          billToName={billToName}
          customerInfo={resultData?.claimList[0]}
          claimList={resultData?.claimList}
          selApprovalStatus={selApprovalStatus}
          fileList={fileList}
          userId={userId}
          empNo={empNo}
          serverUrl={entityServerURL.current}
        />

        const htmlString = ReactDOMServer.renderToString(element);

        let script = `

            <script>
              document.addEventListener('DOMContentLoaded', (event) => {
                const selectElement = document.getElementById('dataOverlap');
                
                selectElement.addEventListener('change', (event) => {
                  const claimId = event.target.value;
                  if (claimId != null && claimId != "") {
                    window.opener.postMessage({ type: 'irConfDateOverlapSelected', claimId }, '*');
                  }
                });
              });

              window.addEventListener('message', function handleMessage(event) {
                if (event.origin === window.location.origin) {
                  alert(event.data);
                }
              });

                
            </script>

            <script>
                $(function() {
                    $("#activityDateFrom,#activityDateTo").datepicker({
                      changeMonth: true,
                      changeYear: true,
                      showAnimation:'slide',
                      showButtonPanel:false,
                      showOn:"button",
                      buttonImage:"/images/temp_calender.gif",
                      isRTL: false,
                      showMonthAfterYear: true,
                      yearSuffix: "",
                      buttonText: "Calender"
                    });
                    $("#activityDateFrom,#activityDateTo").mask("99/99/9999");
                });
            </script>`;

        let printHtml = "<link rel='stylesheet' type='text/css' href='/styles/IrConfScmDtlPrint.css'>"
        printHtml += "<link rel='stylesheet' href ='https://code.jquery.com/ui/1.12.1/themes/base/jquery-ui.css'>"
        printHtml += "<script src='https://code.jquery.com/jquery-3.6.0.min.js'></script>"
        printHtml += "<script src='https://code.jquery.com/ui/1.12.1/jquery-ui.min.js'></script>"
          + htmlString + script;

        claimDtlPopElement.current.document.writeln(printHtml);
        claimDtlPopElement.current.document.close();
        claimDtlPopElement.current.document.title = "LG Electronics :: AIC";
        claimDtlPopElement.current.focus();
      }
    }
  }

  const renderHeaderButtons = () => {
    return (
      <>
        {claimType === "IR" ?
          (
            <div className="btn-group" >
              <Button variant="outlined" style={{ ...buttonStyle, display: cookie.load("EP_LOGIN") === "Y" ? 'none' : '' }} onClick={() => { closeFn(false); }}>
                {"Back"}
              </Button>
              <Button variant="contained" style={{ ...buttonStyle, display: epScmLoginFlag ? 'none' : '' }} onClick={onSave}>
                {"Save"}
              </Button>
              <Button variant="outlined" style={{ ...buttonStyle, display: epScmLoginFlag ? 'none' : '' }} onClick={onUploads}>
                {"Uploads"}
              </Button>
              <Button variant="contained" onClick={onConfirm} style={{ ...buttonStyle, display: epScmLoginFlag ? 'none' : (claimStatus === "A2" ? '' : 'none') }}>
                {"Confirm"}
              </Button>
              <Button variant="contained" onClick={() => { onIsExist("RETURN") }} style={{ ...buttonStyle, display: cookie.load("EP_LOGIN") === "Y" ? 'none' : (selApprovalStatus === "TO-DO" ? '' : 'none') }}>
                {"Return To Sender"}
              </Button>
              <Button variant="outlined" onClick={() => { onIrClaimDetail() }} style={{ ...buttonStyle, display: epScmLoginFlag ? (selApprovalStatus === "TO-DO" ? '' : 'none') : 'none' }}>
                {"Detail"}
              </Button>
              <Button variant="contained" onClick={() => { onIsExist("APRV") }} style={{ ...buttonStyle, display: selApprovalStatus === "TO-DO" ? '' : 'none' }}>
                {"Approve"}
              </Button>
              <Button variant="contained" onClick={() => { onIsExist("RJCT") }} style={{ ...buttonStyle, display: selApprovalStatus === "TO-DO" ? '' : 'none' }}>
                {"Reject"}
              </Button>
              <Button variant="contained" onClick={() => { onRevert() }} style={{ ...buttonStyle, display: epScmLoginFlag ? 'none' : (cont === 0 ? '' : 'none') }}>
                {"Pull back"}
              </Button>
              <Button variant="contained" onClick={() => { onReverse() }} style={{ ...buttonStyle, display: epScmLoginFlag ? 'none' : (cont === 0 ? '' : 'none') }}>
                {"Reverse"}
              </Button>
              <Button variant={dateSoldDupFlag ?? '' != '' ? "contained" : "outlined"} onClick={(e) => { onDsValidaton(e) }} style={{ ...buttonStyle, display: epScmLoginFlag ? 'none' : '' }}>
                {"D/S Validation"}
              </Button>
              <Button variant="outlined" style={{ display: cookie.load("EP_LOGIN") === "Y" ? 'none' : (entityPopupYn.current === 'N' ? '' : 'none') }} onClick={onMoveList}>
                {"List"}
              </Button>
              <Button variant="outlined" className="btn-default" onClick={() => { setInitFlag(!initFlag) }} style={{ ...buttonStyle, display: cookie.load("EP_LOGIN") === "Y" ? 'none' : '', width: "90px" }}>
                {"Refresh"}
              </Button>
            </div>
          ) : null}

        {claimType === "IR" ?
          null : (
            <div className="btn-group">
              <Button variant="outlined" style={{ ...buttonStyle, display: cookie.load("EP_LOGIN") === "Y" ? 'none' : '' }} onClick={() => { closeFn(false); }}>
                {"Back"}
              </Button>
              <Button variant="contained" style={{ ...buttonStyle, display: cookie.load("EP_LOGIN") === "Y" ? 'none' : '' }} onClick={onSave} >
                {"Save"}
              </Button>
              <Button variant="outlined" onClick={onUploads} style={{ ...buttonStyle, display: cookie.load("EP_LOGIN") === "Y" ? 'none' : (status === "HOLD" ? '' : 'none') }}>
                {"Uploads"}
              </Button>
              <Button variant="contained" onClick={onConfirm} style={{ ...buttonStyle, display: cookie.load("EP_LOGIN") === "Y" ? 'none' : (status === "HOLD" ? (claimStatus === "A2" ? '' : 'none') : 'none') }}>
                {"Confirm"}
              </Button>
              <Button variant="contained" onClick={() => { onRevert() }} style={{ ...buttonStyle, display: cookie.load("EP_LOGIN") === "Y" ? 'none' : ((status === "RJCT" || status === "MODI") ? '' : 'none') }}>
                {"Revert to Pending"}
              </Button>
              <Button variant="contained" onClick={() => { onSpiffPullBack() }} style={{ ...buttonStyle, display: cookie.load("EP_LOGIN") === "Y" ? 'none' : (status === "APRV" ? '' : 'none') }}>
                {"Pull Back"}
              </Button>
              <Button variant="contained" onClick={() => { onReverse() }} style={{ ...buttonStyle, display: cookie.load("EP_LOGIN") === "Y" ? 'none' : (status === "APRV" ? '' : 'none') }}>
                {"Reverse"}
              </Button>
              <Button variant="contained" onClick={() => { onReturn() }} style={{ ...buttonStyle, display: cookie.load("EP_LOGIN") === "Y" ? 'none' : (selApprovalStatus === "TO-DO" ? '' : 'none') }}>
                {"Return To Sender"}
              </Button>
              <Button variant="contained" onClick={() => { onApprove() }} style={{ ...buttonStyle, display: selApprovalStatus === "TO-DO" ? '' : 'none' }}>
                {"Approve"}
              </Button>
              <Button variant="contained" onClick={() => { onReject() }} style={{ ...buttonStyle, display: selApprovalStatus === "TO-DO" ? '' : 'none' }}>
                {"Reject"}
              </Button>
              <Button variant="outlined" style={{ display: cookie.load("EP_LOGIN") === "Y" ? 'none' : (entityPopupYn.current === 'N' ? '' : 'none') }} onClick={onMoveList}>
                {"List"}
              </Button>
              <Button variant="outlined" className="btn-default" onClick={() => { setInitFlag(!initFlag) }} style={{ ...buttonStyle, width: "90px", display: cookie.load("EP_LOGIN") === "Y" ? 'none' : '' }}>
                {"Refresh"}
              </Button>
            </div>
          )}
      </>
    );
  }

  const renderSectionHeader = () => {
    return (
      <>

        <div className="d-flex" style={{ display: (postAuditCustomer === "Y" || postAuditClaim === "Y") ? '' : 'none' }}>
          <div className="d-flex">
            <span className="fz-12">
              <span className="field-required">*</span>This is Post Audit Claim
            </span>
            <ComRadioBox
              radioList={[
                {
                  Value: "Y",
                  Description: "Yes",
                },
                {
                  Value: "N",
                  Description: "No",
                },
              ]}
              ref={entityPostAuditClaim}
              elementId={"entityPostAuditClaim"}
              radioGroupName={"entityPostAuditClaim"}
              direction={1}
              defaultCheckValue="N"
            />
          </div>
          <div className="ml-5 d-flex custom-date">
            <span className="fz-12">
              <span className="field-required">*</span>Activity Date
            </span>
          </div>
          <div className="ml-10 d-flex" style={{ width: '130px' }}>
            <ComDateNotRequired
              ref={entityActivityDateFrom}
              isNullable={true}
              controlDateFormat={"MM/dd/yyyy"}
              controlDateMask={"##/##/####"}
              controlDateValidFormat={"__/__/____"}
            />
          </div>
          {` ~ `}
          <div className="ml-10 d-flex" style={{ width: '130px' }}>
            <ComDateNotRequired
              ref={entityActivityDateTo}
              isNullable={true}
              controlDateFormat={"MM/dd/yyyy"}
              controlDateMask={"##/##/####"}
              controlDateValidFormat={"__/__/____"}
            />
          </div>
        </div>
        <div className="d-flex" style={{ display: (postAuditCustomer === "Y" || postAuditClaim === "Y") ? 'none' : '' }}>
          <div className="d-flex">
            <span className="fz-12">
              <label style={{
                backgroundColor: "#f2f2f2"
                , fontFamily: "Inter"
                , fontWeight: "400"
                , fontSize: "12px"
                , boxSizing: "border-box"
                , textTransform: "none"
              }}>
                Cust. Entry
              </label>
            </span>
            <span className="disabled-color-change" style={{ display: "contents" }}>
              <ComRadioBox
                radioList={[
                  {
                    Value: "Y",
                    Description: "Yes",
                  },
                  {
                    Value: "N",
                    Description: "No",
                  },
                ]}
                ref={entityCustEntry}
                elementId={"entityCustEntry"}
                radioGroupName={"entityCustEntry"}
                defaultDisabled={true}
                direction={1}
              />
            </span>
          </div>
        </div>
      </>
    );
  };
  return (
    <div className="page-wrap">
      {!epLoginYn ?
        <div className="breadcrumb-header">
          <Breadcrumb items={pageInfo} />
          <div className=" btn-group-end" >
            {renderHeaderButtons()}
          </div>
        </div>
        :
        <div className=" btn-group-end mb-10" >
          {renderHeaderButtons()}
        </div>
      }

      <div
        className="loading-container"
        style={{ display: isLoading ? "flex" : "none" }}
      >
        <img src={wait} />
      </div>
      <div className="page-in-wrap">
        <div className="bx-card-group">
          <div className="filter-title">
            <div
              className={
                isHiddenSearch ? "bx-inp-control open" : "bx-inp-control"
              }
              onClick={() => setHiddenSearch(!isHiddenSearch)}
            >
              {isHiddenSearch ? (
                <UnfoldMoreRoundedIcon />
              ) : (
                <UnfoldLessRoundedIcon />
              )}
              <span className="txt-title">
                Customer Information (<span className="field-required" style={{ fontWeight: "bold" }}> {claimType === "IR" ? 'IR' : 'SPIFF'}</span>
                )
              </span>
            </div>
            <div>{renderSectionHeader()}</div>
          </div>

          <div
            className="search-wrapper"
            style={isHiddenSearch ? {} : { display: "none" }}
          >
            <div className="bx-search2">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Claim Number</label>
                </div>
                <div className="bx-inp">{reqClaimId}</div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Bill To Code</label>
                </div>
                <div className="bx-inp">{billTo}</div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Bill To Name</label>
                </div>
                <div className="bx-inp" style={{ whiteSpace: "break-spaces" }}>{billToName}</div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Promotion No</label>
                </div>
                <div className="bx-inp" style={{ whiteSpace: "break-spaces" }}>{promotionNo}</div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Program ID</label>
                </div>
                <div className="bx-inp" style={{ whiteSpace: "break-spaces" }}>{programId}</div>
              </div>
              <div className="bx-item" style={{ display: epScmLoginFlag ? 'none' : '' }}>
                <div className="div-label">
                  <label className="txt-label">LG Salesperson</label>
                </div>
                <div className="bx-inp" style={{ display: ((selApprovalStatus === "TO-DO" || claimStatus === "A2") ? '' : 'none') }}>
                  <ComCombo
                    ref={entitySalesmanCode}
                    elementId={"entitySalesmanCode"}
                  />
                </div>
                <div className="bx-inp" style={{ display: ((selApprovalStatus === "TO-DO" || claimStatus === "A2") ? 'none' : '') }}>
                  <ComTextBox
                    ref={entitySalesmanName}
                    elementId={"entitySalesmanName"}
                    defaultReadOnly={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Debit Memo</label>
                </div>
                <div className="bx-inp" style={{ whiteSpace: "break-spaces" }}>{debitMemo}</div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Total Debit Amt.</label>
                </div>
                <div className="bx-inp">$ {totalDebitAmt}</div>
              </div>
              <div className="bx-item" style={{ display: claimType === "IR" ? 'none' : '' }}>
                <div className="div-label">
                  <label className="txt-label">Store Address</label>
                </div>
                <div className="bx-inp"> {storeAddress}</div>
              </div>
              <div className="bx-item column-3column" style={{ display: claimType === "IR" ? 'none' : '' }}>
                <div className="div-label">
                  <label className="txt-label">Mailing Address</label>
                </div>

                <div className="bx-inp"> {address1}  {address2}  {city} {state} {zip}   </div>
              </div>
              <div className="bx-item" style={{ display: claimType === "IR" ? 'none' : '' }}>
                <div className="div-label">
                  <label className="txt-label">Tel / Fax</label>
                </div>
                <div className="bx-inp" style={{ whiteSpace: "break-spaces" }}> {tel} / {fax}</div>
              </div>
              <div className="bx-item column-3column" style={{ display: claimType === "IR" ? 'none' : '' }}>
                <div className="div-label">
                  <label className="txt-label">E-mail</label>
                </div>
                <div className="bx-inp"> {email}</div>
              </div>
              <div className="bx-item column-4column">
                <div className="div-label">
                  <label className="txt-label">Remark</label>
                </div>
                <div
                  className="bx-inp"
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  <ComTextArea
                    defaultStyle={{
                      resize: "vertical",
                      margin: 0,
                      padding: "4px 6px",
                      border: "none",
                    }}
                    ref={entityComment}
                    elementId={"entityComment"}
                    defaultClassName={"wj_textArea confirmDetail-textarea"}
                    placeholder={"Please input Remark ..."}
                  ></ComTextArea>
                </div>
              </div>
              <div className="bx-item column-4column">
                <div className="div-label">
                  <label className="txt-label">LG Claim Remark</label>
                </div>
                <div
                  className="bx-inp"
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  <ComTextArea
                    defaultStyle={{
                      resize: "vertical",
                      margin: 0,
                      padding: "4px 6px",
                      border: "none",
                    }}
                    ref={entityLgComment}
                    elementId={"entityLgComment"}
                    defaultClassName={"wj_textArea confirmDetail-textarea"}
                    placeholder={"Please input LG Claim Remark ..."}
                  ></ComTextArea>
                </div>
              </div>
              <div className="bx-item column-4column">
                <div className="div-label">
                  <label className="txt-label">Approver Review</label>
                </div>
                <div
                  className="bx-inp"
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  <ComTextArea
                    defaultStyle={{
                      resize: "vertical",
                      margin: 0,
                      padding: "4px 6px",
                      border: "none",
                    }}
                    ref={entityReview}
                    elementId={"entityReview"}
                    defaultClassName={"wj_textArea confirmDetail-textarea"}
                    placeholder={"Please input Approver Review ..."}
                  ></ComTextArea>
                </div>
              </div>
              <div className="bx-item" style={{ display: claimType === "IR" ? ((accumClaimQty != "" || reqActionTarget === "SCM") ? '' : 'none') : 'none' }}>
                <div className="div-label">
                  <label className="txt-label">Current Claim Amount</label>
                </div>
                <div className="bx-inp">$ {totalClaimAmt}</div>
              </div>
              <div className="bx-item" style={{ display: claimType === "IR" ? ((accumClaimQty != "" || reqActionTarget === "SCM") ? '' : 'none') : 'none' }}>
                <div className="div-label">
                  <label className="txt-label">Accum. Claim Amount</label>
                </div>
                <div className="bx-inp">$ {accumClaimAmt}</div>
              </div>
              <div className="bx-item" style={{ display: claimType === "IR" ? ((accumClaimQty != "" || reqActionTarget === "SCM") ? '' : 'none') : 'none' }}>
                <div className="div-label">
                  <label className="txt-label">Claim<br />Over Amount</label>
                </div>
                <div className="bx-inp" style={{ color: 'red', fontWeight: 'bold' }}>$ {accumClaimOverAmt}</div>
              </div>
              <div className="bx-item" style={{ display: claimType === "IR" ? ((accumClaimQty != "" || reqActionTarget === "SCM") ? '' : 'none') : 'none' }}>
                <div className="div-label">
                  <label className="txt-label">Accum. Claim<br />Over Qty.</label> <br />
                  <Button variant="outlined" className="btn-default" style={{ width: "90px", display: reqActionTarget === "SCM" ? 'none' : '' }} onClick={onAccumDtl}>
                    {'Detail'}
                  </Button>
                </div>
                <div className="bx-inp" style={{ color: 'red', fontWeight: 'bold' }}>{totalClaimOverQty}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-area-wrapper">
          <div className="tb-content">
            <div className="bx-top">
              <div>
                <div className="txt-title">
                  <h3>{"IR/SPIFF Claim Detail List"}</h3>
                  <p className="txt-total">
                    {"Total"}
                    <strong className="txt-num">
                      {ComUtils.NumberWithComma(gridDataCount)}
                    </strong>
                  </p>
                </div>
              </div>
              <div className="bx-btn-group">
                <div style={{ display: "none" }}>
                  <ComTextBox
                    ref={entityMaxAdd}
                    elementId={"entityMaxAdd"}
                    keyPressFuction={onNumberKeyPress}
                    maxLength={2}
                    defaultValue={1}
                  />
                </div>
                {/* <Button variant="outlined" className="btn-default" onClick={onAddRow} style={{ width: "90px" }}>
                  {"Add Row"}
                </Button> */}
                <div className="bx-btn-group">
                  <Button variant="outlined" style={{ ...buttonStyle }} onClick={excelDownload}>
                    {"Excel Export"}
                  </Button>
                </div>
              </div>
            </div>
            <div className={reqActionTarget === "SCM" ? "bx-table" : "bx-table numbering-merge"}>
              <FlexGrid
                ref={gridRef}
                // className="no-scrollbars"
                columnGroups={gridColGroup} // 그리드 포멧
                itemsSource={gridData} // 바인딩할 데이터
                initialized={initGrid} // 그리드 초기 이벤트 및 등록 처리
                formatItem={gridFormatItem}
                // allowSorting={false}
                headersVisibility="Column" // Show only column headers
                style={{ height: "550px" }}
                selectionChanged={onSelectionChanged}   // 행선택 변경
                beginningEdit={editingCell}
              >
                <FlexGridFilter initialized={initFilter} />
              </FlexGrid>
            </div>
          </div>
        </div>
        <div className="bx-card-group column-4column">
          <div className="filter-title">
            <span className="txt-title">Attachment</span>
          </div>

          <div className="search-wrapper">
            <div className="d-flex" style={{ alignItems: "flex-start" }}>
              <div className="layout-file w-50">
                <Button variant="contained" style={{ ...buttonStyle, margin: "0px 5px", width: "110px", display: epScmLoginFlag ? 'none' : '' }} onClick={onSaveFile}>
                  {"Save file"}
                </Button>
                <Button variant="contained" style={{ ...buttonStyle, margin: "0px 5px", width: "110px", display: epScmLoginFlag ? 'none' : '' }} onClick={onFileUpload}>
                  {"Upload file"}
                </Button>
                <div className="bx-inp  inp-mix" style={{ overflowY: "auto", height: "200px", width: "100%" }}>
                  <div className="layout-file">
                    <input type="file" onChange={handleChange} ref={fileElement} style={{ display: 'none' }} multiple />
                    <div className="file-group">
                      <>
                        {fileList.map((f, index) => (
                          f.isOld ?
                            <div className="ls-file-upload" key={index}>
                              {/* <div className="file-stt">{index}</div> */}
                              <div className="file-name">
                                <p className={FileUtils.fileExtMatch(f.displayFileName)} style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    f.clickChk = true;
                                    const newLinkClasses = fileList.map((f, i) =>
                                      f.clickChk ? 'file-link-purple' : 'file-link-blue'
                                    );
                                    setLinkClasses(newLinkClasses);

                                    FileUtils.callFileDownload(f, retrieveClaimAttchFileByAttchId, (entityClaimType.current === "IR" ? "ir" : "spiff"), e)
                                  }}>
                                  <span
                                    key={index}
                                    className={linkClasses[index] || 'file-link-blue'}
                                  >
                                    {f.displayFileName}
                                  </span>
                                </p>
                                {f.uiFlag !== "U" ? <img src={close} width="12px" onClick={(e) => { removeFile(f, e) }} /> : null}
                              </div>
                            </div>
                            :
                            <div className="ls-file-upload" key={index}>
                              <div className="file-name">
                                <p className={FileUtils.fileExtMatch(f.displayFileName)}>
                                  <span
                                    key={index}
                                    style={{ textDecoration: "none" }}
                                  >
                                    {f.displayFileName}
                                  </span>
                                </p>
                                {f.uiFlag !== "U" ? <img src={close} width="12px" onClick={(e) => { removeFile(f, e) }} /> : null}
                              </div>
                            </div>
                        ))}
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="table-area-wrapper mt-10" style={{ display: viewApprovalLine === "Y" ? '' : 'none' }}>
          <div className="tb-content">
            <div className="bx-top">
              <div>
                <div className="txt-title">
                  <h3>{"Approval Line"}</h3>
                  <p className="txt-total">
                    {"Total"}
                    <strong className="txt-num">
                      {ComUtils.NumberWithComma(gridDataCountDtl)}
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="bx-table">
              <FlexGrid
                className="no-scrollbars"
                columnGroups={gridColGroupDtl} // 그리드 포멧
                itemsSource={gridDataDtl} // 바인딩할 데이터
                initialized={initGridDtl} // 그리드 초기 이벤트 및 등록 처리
              >
                <FlexGridFilter initialized={initFilterDtl} />
              </FlexGrid>
            </div>
            <div className="search-wrapper" style={{ display: selApprovalStatus === "TO-DO" ? '' : 'none' }}>
              <div className="bx-search2">
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      Approval / Rejection Comment
                    </label>
                  </div>
                  <div
                    className="bx-inp"
                    style={{ paddingLeft: "0px", paddingRight: "0px" }}
                  >
                    <ComTextArea
                      defaultStyle={{
                        border: "unset",
                        resize: "vertical",
                        minHeight: "40px",
                        margin: 0,
                        padding: "4px 6px",
                      }}
                      ref={entityApproveComment}
                      elementId={"entityApproveComment"}
                      placeholder={"Please input Approval/Rejection Comment ..."}
                      maxlength={100}
                    ></ComTextArea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {openIrDateSoldValidationPop ? <IrDateSoldValidationPop open={openIrDateSoldValidationPop} closeFn={closeIrDateSoldValidationPop} randomKey={randomKey.current} reqClaimId={reqClaimId} reqBillTo={billTo} /> : null} */}
      {openSerialRegPop ? <SerialRegPop open={openSerialRegPop} closeFn={closeSerialRegPop} pageInfo={pageInfo} randomKey={randomKey.current} reqQty={entityQty?.current} reqProgramId={entityProgramId?.current} reqSerialNo={entitySerial?.current} reqResult={entityResult?.current} reqMsg={entityMsg?.current} reQstrScreen={"SpiffConfRM"} reqRow={entitySerialRow.current} reqClaimId={reqClaimId} /> : null}
      {openSerialInvoiceUploadPop ? <SerialInvoiceUploadPop open={openSerialInvoiceUploadPop} closeFn={closeSerialInvoiceUploadPop} pageInfo={pageInfo} reqRandomKey={randomKey.current} reqClaimType={reqClaimType} reqBillToCd={billTo} reqBillToCdNm={billToName} reqClaimId={reqClaimId} reqProgramId={programId} /> : null}


    </div>
  );
}

export default IrSpiffConfDtl;
