import _ from 'lodash';
import { ComUtils } from "common/lib/ComUtils";
import { ComNoticfication } from "common/components/ComNoticfication";
import { ApiManager } from "common/lib/ApiUtil";
import FileSaver from 'file-saver';
import { FileUtils } from "common/lib/FileUtils";
export const GsrUtils = () => { };



// 시간 콤보 데이터
GsrUtils.hourDataList = () => {

  let hourDataList = [];
  for (var z = 0; z < 2; z++) {
    for (var i = 0; i < 13; i++) {
      for (var j = 0; j < 2; j++) {
        var timeStr = i.toString().padStart(2, "0") + ":" + (j === 0 ? "00" : "30");
        var meridiem = z === 0 ? "AM" : "PM";
        hourDataList.push({ cd: timeStr + meridiem, nm: timeStr + " " + meridiem });
      }
    }
  }

  return hourDataList;
}


GsrUtils.checkEmailAddress = (pEmail) => {
  var email = pEmail ?? "";
  if (email.trim() === "") return true;

  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var arrText = email.split(",");
  for (var i = 0; i < arrText.length; i++) {
    var strEmail = arrText[i];
    var arrText2 = strEmail.split(";");

    for (var j = 0; j < arrText2.length; j++) {

      if (arrText2[j].trim() === "") continue;

      if (re.test(arrText2[j].trim()) === false) {
        return false;
      }
    }

  }

  return true;
}


// 파일업로드시 체크
GsrUtils.checkFile = (event, fileList, excuteUpload, setFileList, fileElement, userId) => {
  //const allowFileExt = ["gif", "jpeg", "bmp", "jpg", "png", "doc", "hwp", "xls", "xlsx", "xlsb", "ppt", "pptx", "csv", "txt", "doc", "pdf", "docx"];
  // docx,doc,pdf,msg,eml,xls,jpeg,txt,xlsx,zip,jpg,png,gif,mht,rar,  ppt,pptx,tif,jfif,html,ics,tiff,mov,mp4,csv

  if (event.target.files.length > 0) {

    // file 길이 체크
    for (let i = 0; i < event.target.files.length; i++) {
      let fullFileName = event.target.files[i].name;
      let tmpFileName = event.target.files[i].name.split(".");
      let extName = tmpFileName[tmpFileName.length - 1];
      // console.log("filelength:" + fullFileName.replace(extName, "").length);
      if (fullFileName.replace(extName, "").length > 300) {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "The file name is too long");
        return;
      }
    }
    // file 중복 체크
    if (fileList) {
      for (let i = 0; i < event.target.files.length; i++) {
        let isDupCheck = fileList.some((el) => el.displayFileName === event.target.files[i].name);
        if (isDupCheck) {
          ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Duplicated record. Please check it");
          return;
        }
      }
    }

    for (let i = 0; i < event.target.files.length; i++) {

      let formData = new FormData();
      let extPass = false;
      let fname = event.target.files[i].name.split(".");

      if (fname.length > 0) {
        let extName = fname[fname.length - 1];
        if (extName !== undefined && extName != null) {
          extName = extName.toLowerCase();
        }
        if (FileUtils.allowFileExt.indexOf(extName) > -1) {
          extPass = true;
        }
      }
      if (extPass) {
        if (excuteUpload) {
          excuteUpload(event.target.files[i], fileList, setFileList, fileElement, userId)
        }
      }
    }
  }
};


// 파일다운로드 (GR)
GsrUtils.callFileDownloadGR = async (fileinfoObj, url, param) => {

  if (fileinfoObj?.attachNo) {

    let fileinfo = await ApiManager.downloadFileById(url, param);
    if (fileinfo != -1) {
      let filename = null;
      let disposition = fileinfo.headers['content-disposition'];
      if (disposition && disposition.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }

      if (filename) {
        let extPass = false;
        filename = filename.replace(/\.\./g, "");
        let extensionRegex = /\.([^.]*)$/;
        let match = filename.match(extensionRegex);

        if (FileUtils.allowFileExt?.indexOf(match[1].toLowerCase()) > -1) {
          extPass = true;
        } else {
          ComNoticfication.ShowErrorUI("Error", "Wrong file format. Please attach PDF or image file formats.");
          return;
        }
        if (extPass) {
          try {
            FileSaver.saveAs(new Blob([fileinfo.data], { type: fileinfo.headers['content-type'] }), decodeURI(filename));
          } catch (ex) {
            console.log(ex);
            ComNoticfication.ShowAlertUI("Info", "There are no files to download");

          }
        }
      }


    }
  }
  else {
    ComNoticfication.ShowAlertUI("Info", "You can download it after you save it");

  }
}



GsrUtils.cfIsNull = (value) => {
  if (value == null ||
    (typeof (value) == "string" && value.trim() == "")
  ) {
    return true;
  }

  return false;
}




