import { useState, useEffect, useContext, useRef, useCallback } from "react";
import { Button, Divider } from "@mui/material";
import "assets/styles/pages/PagesCommon.scss";

import moment from 'moment/moment';
import { ComUtils } from "common/lib/ComUtils";
import { SampleRequestUtils } from "Pages/Spl/Common/SampleRequestUtils";
import { ComNoticfication } from "common/components/ComNoticfication";
import { SampleUtils } from "Pages/Spl/Common/SampleUtils";

import * as wijmo from "@grapecity/wijmo";
import { CollectionView } from "@grapecity/wijmo";

//import * as wjGrid from '@grapecity/wijmo.grid';
import _ from "lodash";
import { FlexGrid } from "@grapecity/wijmo.react.grid";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import { createElement, format } from "@grapecity/wijmo";

import { MessageContext } from "common/lib/MessageProvider";

import Breadcrumb from "common/components/ComBreadcrumb";
import ComTextBox from "common/components/ComTextBox";
import ComCombo from "common/components/ComCombo";
import ComDate from "common/components/ComDate";
import ComCheckBox from "common/components/ComCheckBox";

import CreditCardInfoPop from "Pages/Pop/Spl/CreditCardInfoPop";

import { ApiManager } from 'common/lib/ApiUtil';
import SampleOrderTopNav from "./Common/SampleOrderTopNav";
import SampleOrderDetail from "./SampleOrderDetail";
import ApprovalLine from "./Common/ApprovalLine";


function SampleTrackingAddressChange({ pageInfo, props, closeFn }) {

  // 메세지 추가
  const { messageObject } = useContext(MessageContext);

  //info 메시지
  const savedMessage = ComUtils.getMessage(messageObject, "SMSG_COM_INF_004");        //Saved Successfully

  //error 메시지
  const msgSplErr001 = ComUtils.getMessage(messageObject, "SPL_ERR_00001");           //An error occurred during save

  const userId = sessionStorage.getItem("userId").toString();
  const emailId = sessionStorage.getItem("emailId").toString();
  const comCode = sessionStorage.getItem("comCode").toString();
  const userGroup = sessionStorage.getItem("userGroup").toString();

  //url
  const retrieveOrderDetailForAppr = "/order/sampleOrderTracking/retrieveOrderDetailForAppr";
  const retrieveShipToByBillTo = "/spl/common/retrieveShipToByBillTo";
  const saveSplFileList = "/spl/sampleRequest/saveSplFileList";

  // popup
  const [randomKey, setRandomKey] = useState(null);

  const initState = useRef(false);
  const query = useRef(null);
  const dtlRef = useRef(null);

  const [orderInfo, setOrderInfo] = useState({});
  const [config, setConfig] = useState({});

  const [headerId, setHeaderId] = useState('');
  const [requestDate, setRequestDate] = useState('');
  const [salesOrderNo, setSalesOrderNo] = useState('');
  const [apprProps, setApprProps] = useState(null);
  const [lineData, setLineData] = useState({});

  const gridObject = useRef();
  const [gridColGroup, setGridColGroup] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [gridDataCount, setGridDataCount] = useState(0);


  const onClickToList = () => {
    if (closeFn) {
      closeFn()
    }
  }

  const onSave = async () => {
    let fileList = dtlRef.current?.getFileList();
    let sendParam = {
      headerId: headerId,
      fileList: fileList,
      userId: userId
    };

    let resultData = await ApiManager.post(saveSplFileList, sendParam);

    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgSplErr001);
    } else {
      ComNoticfication.ShowAlertUICallback(
        ComUtils._altTitleInfo, 
        savedMessage,
        () => {
          getPageInfo();
        }
      );
    }
  }

  const initGrid = (sender) => {
    gridObject.current = sender;
    ComUtils.gridInit(sender, {
      selectionMode: 6,
      hasCheckBox: false,
      showCheckAll: false,
      hasDisableCheck: true,
      canAreaInfo: false,
    });
    sender.allowSorting = false;

    sender.addEventListener(sender.hostElement, "click", (e) => {     
      let ht = sender.hitTest(e);
      const column = sender.columns[ht.col];

      if (ht._row !== -1 && ht._p.cellType === 1 && (sender.selectedItems) && Array.isArray(sender.selectedItems) && sender.selectedItems.length == 1) {
        setLineData(lineData => ({
          ...lineData,
          data: sender.selectedItems[0]
        }));
      }
    });
  };


  const initGridFormat = useCallback(
    (
      findModel,
      findWareHouse,
      subInventoryMap,
    ) => {
      let gridFormat = [
        {
          binding: "lineNo",
          header: "Line No",
          width: 70,
          isReadOnly: true,
          allowDragging: true,
          align: "center",
        },
        {
          binding: "modelSuffix",
          header: "Model",
          width: "*",
          minWidth: 220,
          allowDragging: true,
          isReadOnly: true,
        },
        {
          binding: "shippingDate",
          header: "Shipped Date	",
          width: 160,
          allowDragging: true,
          isReadOnly: true,
          align: "center",
        },
        {
          binding: "warehouseCode",
          header: "Originating Warehouse",
          width: 160,
          allowDragging: true,
          isReadOnly: true,
          align: "center",
        },
        {
          binding: "remainingQty",
          header: "Original Order QTY	",
          width: 160,
          allowDragging: true,
          isReadOnly: true,
          align: "right",
          format: "n0",
        },
        {
          binding: "transactQty",
          header: "Transact Qty",
          width: 160,
          allowDragging: true,
          isReadOnly: true,
          isRequired: true,
          align: "right",
          format: "n0",
        },
        {
          binding: "unitSellingPrice",
          header: "Unit Selling Price",
          width: 160,
          allowDragging: true,
          isReadOnly: true,
          align: "right",
          format: "n2",
        },
        {
          binding: "totalAmt",
          header: "Total Amount",
          width: 160,
          allowDragging: true,
          isReadOnly: true,
          isRequired: true,
          align: "right",
          format: "n2",
          cellTemplate: (ctx, cell) => "0"
        },
      ];
      setGridColGroup([...gridFormat]);
    }, []);

    const getPageInfo = async () => {
      initState.current = false;

      if (props?.epLogin) {
        ComUtils.removeEpLoginSessionItem();
      }

      const [resultData] = await Promise.all([
        ApiManager.post(retrieveOrderDetailForAppr, props),
      ]);

      if (resultData?.statusCode) {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "SPL_ERR_00002", ["sample request"]));
        return;
      }

      query.current = resultData?.query;

      let shipToList = await ApiManager.post(retrieveShipToByBillTo, {billToCode: resultData?.query?.billTo}, {loadbarOpen: false});

      const fileList = resultData?.fileList?.map(f => SampleRequestUtils.convertFile(f));
      setOrderInfo({...resultData?.query, fileList});
      setConfig({
        bizType: {list: resultData?.bizType},
        transferPurpose: {show: true},
        contactNm: {required: true},
        fileList: {show: true, required: true},
      });

      setRequestDate(resultData?.query?.createDate);
      setHeaderId(props.headerId);      
      setSalesOrderNo(resultData?.query?.salesOrderNo ?? '');

      // Product List
      setGridData(
        new CollectionView(resultData?.resultList, {
            trackChanges: true,
            refreshOnEdit: false,
        })
      );
      setGridDataCount(resultData?.resultList?.length);

      // Line No
      if (resultData?.resultList?.length > 0) {
        setLineData({
          data: resultData?.resultList[0],
          billTo: resultData?.billList?.find(b => b.code === resultData?.query?.billTo)?.value,
          shipTo: shipToList.length > 0 ? shipToList[0] : {},
          collectionDate: resultData?.query?.collectionDate
        });
      }

      // Approval Line
      setApprProps({
        actionType: resultData?.query.actionType ?? ''
        , dealType: resultData?.query.dealType
        , targetEmpNo: resultData?.query?.targetEmpNo ?? ''
        , headerId: resultData?.query?.headerId  ?? ''
        , arApprovalYn : resultData?.query?.arApprovalYn  ?? ''
        , ariEmpNo: resultData?.query?.ariEmpNo ?? ''
        , isOutstanding: resultData?.isOutstanding
      });

      initState.current = true;
    }

  const _isMounted = useRef(true);
  useEffect(() => {
    let abortController = new AbortController();

    initGridFormat();

    getPageInfo();

    // ComponentWillUnmount in Class Component
    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, []);


  const [isHiddenSearch, setHiddenSearch] = useState(true);
  return (
    <div className="page-wrap">
      <div className="breadcrumb-header"  style={{ display: props?.epLogin ? 'none' : '' }}>
        <Breadcrumb items={pageInfo} />
      </div>
      <Divider component="li" />
      <SampleOrderTopNav dealType={props?.dealType} readType={props?.readType}/>
      <div className="page-in-wrap">
        <div className="bx-card-group">
          <div className="filter-title">
            <div>
              <div className="txt-title">
                <h2>GERP SO#{salesOrderNo} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </h2>
                <div className="bx-contact-group">Request No: {headerId} | Request Date : {requestDate}</div>
              </div>              
            </div>

            <div className="link-action d-flex">
              { closeFn &&
                <Button className="btn-default" variant="outlined" onClick={() => onClickToList()}>
                  {"List"}
                </Button>
              }
              {(query?.current?.orderStatus === "WAITINGAPPROVE" || query?.current?.orderStatus === "APPROVING") && (
              <Button variant="contained" onClick={() => onSave()}>
                {"Save"}
              </Button>
            )}              
            </div>
          </div>

          <SampleOrderDetail ref={dtlRef} pageInfo={pageInfo} values={orderInfo} config={config}/>
        </div>
        
        <div className="table-area-wrapper">
          <div className="tb-content">
            <div className="bx-top">
              <div>
                <div className="txt-title">
                  <h3>{"Detail & Division Information"}</h3>
                  <p className="txt-total">
                    {"Total"}
                    <strong className="txt-num">{gridDataCount}</strong>
                  </p>
                </div>
              </div>
              <div className="bx-btn-group">
              </div>
            </div>
            <div className="bx-table">
              <FlexGrid
                columnGroups={gridColGroup} // 그리드 포멧
                itemsSource={gridData} // 바인딩할 데이터
                initialized={initGrid} // 그리드 초기 이벤트 및 등록 처리
                style={{ minheight: "200px" }}
              >
              </FlexGrid>
            </div>
          </div>
        </div>

        <div className="bx-card-group mt-10">
          <div className="filter-title">
            <div className="txt-title">Line No: {lineData?.data?.lineNo}</div>
          </div>
          <div className="search-wrapper">
            <div className="bx-search2 fixed-column3">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Operation Type</label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    defaultValue={"ADDRESS CHANGE"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">To be Returned Date</label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    defaultValue={lineData?.collectionDate}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Virtual Address</label>
                </div>
                <div className="bx-inp combo">
                  <ComCheckBox
                    defaultValue={lineData?.data?.virtualAddressChange === 'Y'}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Bill To</label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    defaultValue={lineData?.billTo}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Ship To</label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    defaultValue={lineData?.shipTo?.value}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Pick Up Date
                  </label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    defaultValue={lineData?.data?.pickupDate}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Delivery Address 1
                  </label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    defaultValue={lineData?.data?.address1}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Delivery Address 2</label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    defaultValue={lineData?.data?.address2}
                    defaultDisabled={true}
                  />
                </div>
              </div>                
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                  </label>
                </div>
                <div className="bx-inp combo">
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>City
                  </label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    defaultValue={lineData?.data?.city}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>State
                  </label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    defaultValue={lineData?.data?.state}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Zip
                  </label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    defaultValue={lineData?.data?.zip}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Contact Name
                  </label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    defaultValue={lineData?.data?.receiverName}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Contact Phone1
                  </label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    defaultValue={lineData?.data?.receiverPhoneNo}
                    defaultDisabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <ApprovalLine props={apprProps} pageInfo={pageInfo} isEpLogin={props?.epLogin}/>
      </div>
    </div>
  );
}

export default SampleTrackingAddressChange;
