import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";

//sharepoint_cns 추가
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { Button, Divider } from "@mui/material";

import imgExternal from "assets/icons/external-user-icon.svg";
import imgSpiff from "assets/icons/external-people-icon.svg";
import "assets/styles/pages/SignUpPage.scss";

import Breadcrumb from "common/components/ComBreadcrumb";
import ComCheckBox from "common/components/ComCheckBox";

function SignUpExternalUserPage({ closeSignUpExternal }) {
  const isCheckedAgree = useRef(null);
  const [isDisabled, setIsDisabled] = useState(true);

  const navigate = useNavigate();

  const onCheckedAgreeClick = (e) => {
    if (e.target.checked) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const breadCrumbs = [
    {
      text: "Login",
      link: "#",
    },
    {
      text: "Sign up",
      link: "#",
    },
  ];
  return (
    <div className="container-signup">
      <div className="user-type-card">
        <Button className="card default" onClick={closeSignUpExternal}>
          <img src={imgSpiff} />
          <p>RSA User</p>
        </Button>
        <Button className="card active">
          <img src={imgExternal} />
          <p>External User</p>
        </Button>
      </div>
      <div className="body-text">
        <div className="boder-text" 
        // style={{height: "76.2vh"}}
        >
          <div className="ct-tx-spiffuser">
            <span className="title-spiff">External User Agreement</span>
            <div>
              <span className="sub-title-spiff">Terms of Use</span>
              <p>
                The use of this LG Electronics USA, Inc. ("LGEUS") website
                ("Site") is subject to the following terms and conditions
                ("Terms"). By accessing, using or downloading materials from
                this Site, you agree to follow and be bound by these Terms, as
                well as our Privacy Policy and, in the event you purchase any
                products on this Site, our Terms of Sale.
              </p>
            </div>
            <div>
              <span className="sub-title-spiff">General Use Restrictions</span>
              <p>
                All information, documents, products and services, trademarks,
                logos, graphics, and images ("Materials") provided on this Site
                are copyrighted or trademarked and are the property of LG Group,
                LG Electronics, Inc. and/or it's subsidiaries, including LGEUS.
                Any unauthorized use of any material contained on the Site may
                violate copyright laws, trademark laws, the laws of privacy and
                communications statutes LGEUS grants you the limited right to
                display the Materials only on your personal computer for your
                personal use. You agree not to use the materials for any other
                purpose without the prior written consent of LGEUS. Without
                limitation, you agree not to reproduce,
              </p>
            </div>
            <div>
              <span className="sub-title-spiff">General Use Restrictions</span>
              <p>
                All information, documents, products and services, trademarks,
                logos, graphics, and images ("Materials") provided on this Site
                are copyrighted or trademarked and are the property of LG Group,
                LG Electronics, Inc. and/or it's subsidiaries, including LGEUS.
                Any unauthorized use of any material contained on the Site may
                violate copyright laws, trademark laws, the laws of privacy and
                communications statutes LGEUS grants you the limited right to
                display the Materials only on your personal computer for your
                personal use. You agree not to use the materials for any other
                purpose without the prior written consent of LGEUS. Without
                limitation, you agree not to reproduce.
              </p>
            </div>
            <div className="ct-checkbox-spiffuser">
              <div className="check-condition">
                <input
                  name="comCheckBox"
                  type="checkbox"
                  ref={isCheckedAgree}
                  onClick={onCheckedAgreeClick}
                  class
                  id="conditionCheckbox"
                ></input>{" "}
                <label for="conditionCheckbox">
                  I have read and agree to the LG "External User Agreement"
                </label>
              </div>
            </div>
          </div>
          <div className="btn-footer">
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={() => navigate("/login", {})}
            >
              {"Cancel"}
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate("/sign-up-external", {})}
              disabled={isDisabled}
            >
              {"Next"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SignUpExternalUserPage;
