import { useState, useEffect, useContext, useRef, useCallback } from "react";
import "assets/styles/pages/PagesCommon.scss";

import { Button, Divider } from "@mui/material";
import {
  CollectionView,
  toggleClass,
  createElement,
  format,
} from "@grapecity/wijmo";
import { ApiManager } from 'common/lib/ApiUtil';
import { ComUtils } from "common/lib/ComUtils";
import { SampleUtils } from "Pages/Spl/Common/SampleUtils";
import { FileUtils } from 'common/lib/FileUtils';
import Breadcrumb from "common/components/ComBreadcrumb";
import * as wijmo from "@grapecity/wijmo";
import { FlexGrid } from "@grapecity/wijmo.react.grid";
import { MessageContext } from "common/lib/MessageProvider";
import _ from "lodash";
import UnfoldLessRoundedIcon from "@mui/icons-material/UnfoldLessRounded";
import UnfoldMoreRoundedIcon from "@mui/icons-material/UnfoldMoreRounded";
import FileSaver from 'file-saver';
import IcPdf from "assets/images/files/file_icon_pdf.png";

import ComTextArea from "common/components/ComTextArea";
import { GroupRow, DataMap } from "@grapecity/wijmo.grid";
import { ComNoticfication } from "common/components/ComNoticfication";
import ComRadioBox from "common/components/ComRadioBox";
import ComTextBox from "common/components/ComTextBox";
import ComMaskInput from "common/components/ComMaskInput";
import ComNumberInput from "common/components/ComNumberInput";
import ComComboMultiColumn from "common/components/ComComboMultiColumn";
import ComTextBoxFind from "common/components/ComTextBoxFind";
import ComDate from "common/components/ComDate";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ClaimADV from "./classes/ClaimADV";
import ClaimSellIn from "./classes/ClaimSellIn";
import ClaimSellOut from "./classes/ClaimSellOut";
import ClaimMarkdown from "./classes/ClaimMarkdown";
import ClaimPriceProtection from "./classes/ClaimPriceProtection";
import ClaimPriceDifference from "./classes/ClaimPriceDifference";
import ClaimFreight from "./classes/ClaimFreight";
import ClaimMeetcomp from "./classes/ClaimMeetcomp";
import ClaimRecycling from "./classes/ClaimRecycling";
import ClaimDamageAllow from "./classes/ClaimDamageAllow";
import ClaimTax from "./classes/ClaimTax";
import ClaimVLPriceDiff from "./classes/ClaimVLPriceDiff";
import ClaimIrSpiff from "./classes/ClaimIrSpiff";

import DiscountChart from "Pages/Clm/Oth/Other/components/DiscountChart";
import DAInvoicePop from "Pages/Pop/DAInvoicePop"
import ESNExcelUploadPop from "Pages/Pop/Clm/ESNExcelUploadPop";
import OtherClaimViewPop from "./OtherClaimViewPop";


function OtherClaimEntryDtl({ pageInfo, initData, closeFn, backFn }) {

  const { messageObject } = useContext(MessageContext);

  //info 메시지
  const msgOthInf007 = ComUtils.getMessage(messageObject, "OTH_INF_00007");           //Claim status has been changed
  const msgOthInf008 = ComUtils.getMessage(messageObject, "OTH_INF_00008");           //There are no files to download
  const msgOthInf009 = ComUtils.getMessage(messageObject, "OTH_INF_00009");           //You can download it after you save it

  //warning 메시지
  const msgOthWar001 = ComUtils.getMessage(messageObject, "OTH_WAR_00001");           //To use this item, please enter [Bill To Code]

  //error 메시지
  const msgOthErr001 = ComUtils.getMessage(messageObject, "OTH_ERR_00001");           //An error occurred during processing
  const msgOthErr022 = ComUtils.getMessage(messageObject, "OTH_ERR_00022");           //Enter the Approval / Rejection Comment .
  const msgOthErr024 = ComUtils.getMessage(messageObject, "OTH_ERR_00024");           //Wrong file format. Please attach PDF or image file formats.

  const userType = sessionStorage.getItem("userType")?.toString();
  const userGroup = sessionStorage.getItem("userGroup")?.toString();
  const comCode = sessionStorage.getItem("comCode")?.toString();
  const userName = sessionStorage.getItem("userName")?.toString();
  const userEmail = sessionStorage.getItem("emailId")?.toString();
  const userId = sessionStorage.getItem("userId").toString();
  const empNo = sessionStorage.getItem("empNo").toString();
  let userLevel = pageInfo.userLevel;


  const [popup, setPopup] = useState(initData?.input?.popup ?? "");
  const [viewType, setViewType] = useState(initData?.input?.viewType ?? "");
  const [selApprovalStatus, setSelApprovalStatus] = useState(initData?.input?.selApprovalStatus ?? "");
  const [showBtnGoAgreement, setShowBtnGoAgreement] = useState(false);
  const [showBtnSave, setShowBtnSave] = useState(false);
  const [showBtnReEntry, setShowBtnReEntry] = useState(false);
  const [showBtnRevert, setShowBtnRevert] = useState(false);
  const [isDisablePaymentMethod, setDisablePaymentMethod] = useState(false);
  const [isDisableVenderName, setDisableVenderName] = useState(true);
  const [approvalLineView, setApprovalLineView] = useState(false);
  const [isHiddenApporval, setIsHiddenApporval] = useState(false);
  const [approveCommentView, setApproveCommentView] = useState(initData?.input?.selApprovalStatus === "TO-DO");
  const [systemEntryNoView, setSystemEntryNoView] = useState(initData?.claimMstInfo?.systemEntryNo ?? "");
  const [requestDateView, setRequestDateView] = useState(initData?.claimMstInfo?.requestDate ?? "");
  const [updateDateView, setUpdateDateView] = useState(initData?.claimMstInfo?.updateDate ?? "");
  const [claimStatusView, setClaimStatusView] = useState(initData?.claimMstInfo?.claimStatus ?? "");
  const [fileListMod, setFileListMod] = useState(false);
  const [fileListNoView, setFileListNoView] = useState(false);

  const entityCustEntry = useRef(null);
  const entityReverse = useRef(null);
  const entityBillTo = useRef(null);
  const entityBillToName = useRef(null);
  const entityShipTo = useRef(null);
  const entityShipToName = useRef(null);
  const entityDivision = useRef(null);
  const entityBillToAddress = useRef(null);
  const entityBillToCity = useRef(null);
  const entityBillToContactName = useRef(null);
  const entitySalesrepName = useRef(null);
  const entityBillToState = useRef(null);
  const entityBillToContactTel = useRef(null);
  const entitySalesrepTel = useRef(null);
  const entityBillToZipCode = useRef(null);
  const entityBillToContactEmail = useRef(null);
  const entitySalesrepEmail = useRef(null);
  const entityReasonCode = useRef(null);
  const entityClaimAnalyst = useRef(null);
  const entityDebitMemoNo = useRef(null);
  const entityDebitMemoAmt = useRef(null);
  const entityPaymentMethod = useRef(null);
  const entityLgChargebackNo = useRef(null);
  const entityVendorName = useRef(null);
  const entityVendorCode = useRef(null);
  const entityOutstandingInvoiceAmt = useRef(null);
  const entityPayToAddress = useRef(null);
  const entityPendingCode = useRef(null);
  const entityQuotationNo = useRef(null);
  const entityMultiQuote = useRef(null);
  const entityGroupCodeFlag = useRef(null);
  const entityTradeClaim = useRef(null);
  const entityMoreClaims = useRef(null);
  const entityFollowUpDate = useRef(null);
  const entityFollowFlag = useRef(null);
  const entityCustomerClaimDesc = useRef(null);
  const entityLgReviewComment = useRef(null);
  const entityClaimType = useRef(null);
  const entityPostAuditClaim = useRef(null);
  const entityActivityDateFrom = useRef(null);
  const entityActivityDateTo = useRef(null);
  const entityApproveComment = useRef(null);

  const input = useRef();
  const claimMstInfo = useRef();
  const toDay = useRef();
  const systemEntryNo = useRef("");
  const claimAnalystEmpNo = useRef("");
  const claimStatus = useRef("AA");
  const confirmType = useRef("SAVE");
  const systemEntryNoUpload = useRef("");
  const [postAuditCustomer, setPostAuditCustomer] = useState(initData?.claimMstInfo?.postAuditCustomer ?? "");
  const [postAuditClaim, setPostAuditClaim] = useState(initData?.claimMstInfo?.postAuditClaim ?? "");
  const [claimAnalystYn, setClaimAnalystYn] = useState(initData?.input?.claimAnalystYn ?? "");
  const gSingleMulti = useRef("N");
  const orgRequestHidden = useRef(true);
  const [orgDebitMemoAmount, setOrgDebitMemoAmount] = useState(initData?.claimMstInfo?.orgDebitMemoAmount);
  const [rvsClaimNo, setRvsClaimNo] = useState(initData?.claimMstInfo?.rvsClaimNo ?? "");
  const [apprvAsstView, setApprvAsstView] = useState('');
  const [apprvMgrView, setApprvMgrView] = useState('');
  const [apprvSrMgrView, setApprvSrMgrView] = useState('');
  const [apprvVPView, setApprvVPView] = useState('');

  const reasonCodeListRef = useRef([]);
  const pendingCodeListRef = useRef([]);
  const noClaimBillToListRef = useRef([]);

  const fileElement = useRef(null);
  const [fileList, setFileList] = useState([]);
  const [linkClasses, setLinkClasses] = useState(
    fileList?.map(() => 'file-link-blue')
  );

  const [discountChart, setDiscountChart] = useState(false);
  const randomKey = useRef(null);

  // url
  const updateOtherClaimApprovalConfirmUrl = "/clm/oth/otherRequest/updateOtherClaimApprovalConfirm";
  const retrieveReEntryClaimList = "/clm/oth/otherRequest/retrieveReEntryClaimList";
  const retrieveOtherClaimConfirmInfo = "/clm/oth/otherConfirm/retrieveOtherClaimConfirmInfo";
  const retrieveOtherClaimInfo = "/clm/oth/otherConfirm/retrieveOtherClaimInfo";
  const updateOtherClaimViewUrl = "/clm/oth/otherRequest/updateOtherClaimView";
  const updateOtherClaimStatus = "/clm/oth/otherRequest/updateOtherClaimStatus";
  const retrieveAttachFileListByAttachNoUrl = "/gsr/grCrrClaim/retrieveAttachFileListByAttachNo";
  const retrieveGRPdfCreditMemoUrl = "/gsr/grRequest/retrieveGRPdfCreditMemo";
  const retrieveComInvoPdfUrl = "/gsr/grRequest/retrieveComInvoPdf";

  const allCombo = [{ cd: "", nm: "ALL" }];
  const allCombo2 = [{ cd: "ALL", nm: "" }];
  const selCombo = [{ cd: "", nm: "Select" }];
  const selCombo2 = [{ cd: "Select", nm: "" }];
  const defaultCode = "ALL";

  // popup
  const [openModalDAInvoice, setOpenModalDAInvoice] = useState(false);
  const [openGSRESNUploadPop, setOpenGSRESNUploadPop] = useState(false);
  const [openModalClaimRequestPop, setOpenModalClaimRequestPop] = useState(false);
  const invoiceUsedInitParam = useRef(null);

  // grid
  const ClaimClassPool = useRef({});
  const [claimType, setClaimType] = useState(initData?.claimMstInfo?.claimType);
  const claimRef = useRef(null);

  const rejectCheck = useRef(false);

  const gridObject = useRef();
  const gridObjApprv = useRef();
  const [gridColGroup, setGridColGroup] = useState(null);
  const [gridData, setGidData] = useState(null);
  const [gridDataApprv, setGridDataApprv] = useState(null);
  const [gridDataCount, setGridDataCount] = useState(0);
  const [gridColGroupApprv, setGridColGroupApprv] = useState(null);


  // button click event\
  const fncReturn = () => {
    if (!entityApproveComment.current?.getValue()) {
      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, "Enter the Approval / Rejection Comment .", () => entityApproveComment.current?.setFocus());
      return;
    }

    ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, "Do you want to return to sender?", (isConfirmed) => {
      if (isConfirmed) {
        updateOtherClaimApprovalConfirm(claimStatus.current, "RETURN");
      }
    });

  };

  const fncAttachSave = async () => {
    let dtlList = gridObject.current.collectionView.items.map(item => {
      const rowData = {};

      for (const [key, value] of Object.entries(item)) {
        if (value != null) {
          rowData[key] = value instanceof Date ? wijmo.Globalize.format(value, 'yyyyMMdd') : value;
        } else {
          rowData[key] = '';
        }
      }
      return rowData;
    });

    // frmClaim
    let sendParam = {
      userType: userType,
      systemEntryNo: systemEntryNo.current,
      claimAnalystYn: initData?.input?.claimAnalystYn ?? "",
      prevJob: initData.input?.prevJob ?? "",
      selApprovalStatus: initData.input?.selApprovalStatus ?? "",
      claimStatus: claimStatus.current ?? "",
      confirmType: confirmType.current ?? "",
      systemEntryNoUpload: claimMstInfo.current?.systemEntryNoUpload ?? "",
      viewType: initData?.input?.viewType ?? "",
      lgReviewComment: entityLgReviewComment?.current?.getValue() ?? "",
      approveComment: entityApproveComment?.current?.getValue() ?? "",
      followUpFlag: entityFollowFlag?.current?.getValue() ?? "",
      docType: initData?.claimMstInfo?.docType,
      list: dtlList,
      fileList: fileList,
      fileNoList: fileList?.map(item => item?.fileNo ?? "") ?? []
    };

    let resultData = await ApiManager.post(updateOtherClaimViewUrl, sendParam);
    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00028"));
      return;
    }

    ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, ComUtils.getMessage(messageObject, "OTH_INF_00004", ["Claim View."]), async (r, _resultData) => {
      let sendParam = {
        userId, comCode, userType, empNo,
        systemEntryNo: _resultData?.params?.systemEntryNo ?? "",
        prevJob: _resultData?.params?.prevJob,
        selApprovalStatus: _resultData?.params?.selApprovalStatus,
      }

      let resultData = await ApiManager.post(retrieveOtherClaimConfirmInfo, sendParam);
      if (resultData?.statusCode) {
        ComNoticfication.ShowAlertUI(ComUtils._altTitleError, msgOthErr001);
        return;
      }

      // UM, RM에따라 다시 열림
      closeFn?.(resultData);
    }, resultData);
  };

  const fncRevert = () => {
    ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, ComUtils.getMessage(messageObject, "OTH_CNF_00002", ["pull back this entry"]), async (isConfirmed) => {
      if (isConfirmed) {
        let sendParam = getParam();
        sendParam.entryType = "";
        sendParam.claimStatus = "A5";

        let resultData = await ApiManager.post(updateOtherClaimStatus, sendParam);
        if (resultData?.statusCode) {
          ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00028"));
          return;
        }

        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, msgOthInf007, async () => {
          let resultData = null;

          if ("confirm" === sendParam?.prevJob) {
            let sendParam2 = {
              userId, comCode, userType, empNo,
              systemEntryNo: sendParam?.systemEntryNo,
              confirm: "Y",
              approverYn: "N",
              prevJob: sendParam?.prevJob,
              selApprovalStatus: sendParam?.selApprovalStatus,
            }

            resultData = await ApiManager.post(retrieveOtherClaimConfirmInfo, sendParam2);
          } else {
            let sendParam2 = {
              userId, comCode, userType, empNo,
              systemEntryNo: sendParam?.systemEntryNo,
              prevJob: sendParam?.prevJob,
              selApprovalStatus: sendParam?.selApprovalStatus
            }

            resultData = await ApiManager.post(retrieveOtherClaimInfo, sendParam2);
          }

          if (resultData?.statusCode) {
            ComNoticfication.ShowAlertUI(ComUtils._altTitleError, msgOthErr001);
            return;
          }

          // UM, RM에따라 다시 열림
          closeFn?.(resultData);
        });
      }
    });
  };

  const fncApprove = () => {
    if ("Y" === input.current?.endFlag) {
      //"This is final phase.\nDo you really want to approve ?
      ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, "Do you want to approve?"
        , (isConfirmed) => {
          if (isConfirmed) {
            updateOtherClaimApprovalConfirm("A3", "APPROVE");
          }
        });
    } else {
      //"Do you want to approve ?
      ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, "Do you want to approve?"
        , (isConfirmed) => {
          if (isConfirmed) {
            updateOtherClaimApprovalConfirm("AA", "APPROVE");
          }
        });
    }
  };

  const fncReject = () => {
    if (!entityApproveComment.current?.getValue()) {
      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, "Enter the Approval / Rejection Comment .", () => entityApproveComment.current?.setFocus());
      return;
    }

    ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, "Do you want to reject?", (isConfirmed) => {
      if (isConfirmed) {
        let rows = gridObject.current.collectionView?.items;
        for (let i = 0; i < rows.length; i++) {
          if (rows[i].rejectCode == "") { // {0} row : {1} is required.
            ComNoticfication.ShowAlertUI(ComUtils._altTitleError, (i + 1) + " row :" + "Rejection Reason is required.");
            return;
          }
          if (rows[i].rejectDesc == "") {
            ComNoticfication.ShowAlertUI(ComUtils._altTitleError, (i + 1) + " row :" + "Rejection Comment is required.");
            return;
          }
        }

        updateOtherClaimApprovalConfirm("A5", "REJECT");
      }
    });

  };

  const fncGoAgreement = () => {
    let farwindow = window.open(claimMstInfo.current?.attribute7, '', 'height=600, width=1005, scrollbars=yes, resizable=true');
    if (farwindow) farwindow.focus();
  };

  const fncESNUploadExcel = () => {
    setOpenGSRESNUploadPop(true);
  };

  const fncReEntry = async () => {
    let resultData = await ApiManager.post(retrieveReEntryClaimList, { systemEntryNo: systemEntryNo.current });
    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00028"));
      return;
    }

    if (resultData.length > 0) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00023", [resultData[0].systemEntryNo]));
    } else {
      // frmEntry
      let sendParam = {
        comCode: comCode,
        userId: userId,
        userType: userType,
        empNo: empNo,
        systemEntryNo: systemEntryNo.current ?? "",
        entryType: "Reentry",
        confirmType: "",
        claimType: claimMstInfo.current?.claimType,
        claimStatus: "",
        approveComment: "",
        viewType: "",
        prevJob: initData.input?.prevJob ?? "",
        selApprovalStatus: initData.input?.selApprovalStatus ?? "",
        list: []
      };

      // ReRequest
      let pageData = await ApiManager.post(retrieveOtherClaimInfo, sendParam);
      pageData.viewMode = "RE";

      closeFn?.(pageData);
    }
  };

  const onClickInvoice = () => {
    setOpenModalDAInvoice(true);
  }

  // 파일 업로드 관련
  const onClickUploadFile = () => {
    fileElement.current.click();
  };

  const getParam = () => {
    let dtlList = gridObject.current.collectionView.items.map(item => {
      const rowData = {};
      for (const [key, value] of Object.entries(item)) {
        if (value) {
          rowData[key] = value instanceof Date ? wijmo.Globalize.format(value, 'yyyyMMdd') : value;
        } else {
          rowData[key] = '';
        }
      }
      return rowData;
    });

    let sendParam = {
      comCode: comCode,
      userId: userId,
      userType: userType,
      empNo: empNo,
      systemEntryNo: systemEntryNo.current ?? "",
      entryType: "Reentry",
      confirmType: confirmType.current ?? "",
      claimType: claimMstInfo.current?.claimType,
      claimStatus: claimStatus.current ?? "",
      approveComment: entityApproveComment.current?.getValue() ?? "",
      viewType: initData?.input?.viewType ?? "",
      prevJob: initData.input?.prevJob ?? "",
      selApprovalStatus: initData.input?.selApprovalStatus ?? "",
      list: dtlList
    };

    return sendParam;
  }

  const updateOtherClaimApprovalConfirm = async (status, type) => {
    setSelApprovalStatus("");
    claimStatus.current = status;
    confirmType.current = type;

    let sendParam = getParam();

    let resultData = await ApiManager.post(updateOtherClaimApprovalConfirmUrl, sendParam);
    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Submit Error. Please Contact System Admin.");
      return;
    }


    const executeCallback = () => {
      if (initData?.epLogin) {
        window.close();
      } else {
        backFn?.(true);
      }
    }

    if (confirmType.current === "APPROVE") {
      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, "Approved Successfully.", () => executeCallback());
    } else if (confirmType.current === "REJECT") {
      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, "Rejected Successfully.", () => executeCallback());
    } else if (confirmType.current === "SAVE") {
      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, "Saved Successfully.", () => executeCallback());
    } else if (confirmType.current === "RETURN") {
      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, "Returned Successfully.", () => executeCallback());
    } else {
      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, "Submit Successfully.", () => executeCallback());
    }
  }

  const uploadFileExcute = async (uploadFileInfo) => {
    let formData = new FormData();
    formData.append('UploadFile', uploadFileInfo);
    formData.append("customPath", "claim");
    formData.append("createDate", "T");
    try {
      let retrieveFileData = await ApiManager.post("/Common/File/FileUploadCustomPath", formData);
      let uploadedFileInfo = { "serverFileName": retrieveFileData[0].savedFileName, "filePath": retrieveFileData[0].savedFilePath, "fileSize": uploadFileInfo.size, "displayFileName": retrieveFileData[0].orgFileName, "userId": userId }
      setFileList(fileList => [...fileList, uploadedFileInfo]);
    }
    catch (ex) {
      console.log(ex);
    }
    fileElement.current.value = "";
  };

  const callFileDownload = async (fileInfo, event) => {

    if (fileInfo?.attachNo) {

      let fileinfo = await ApiManager.downloadFileById(retrieveAttachFileListByAttachNoUrl, { "systemEntryNo": systemEntryNo.current, "attachNo": fileInfo.attachNo })
      if (fileinfo != -1) {
        let filename = null;
        let disposition = fileinfo.headers['content-disposition'];
        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }
        if (filename) {
          let extPass = false;
          filename = filename.replace(/\.\./g, "");
          let extensionRegex = /\.([^.]*)$/;
          let match = filename.match(extensionRegex);

          if (FileUtils.allowFileExt?.indexOf(match[1].toLowerCase()) > -1) {
            extPass = true;
          } else {
            ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgOthErr024);
            return;
          }
          if (extPass) {
            try {
              FileSaver.saveAs(new Blob([fileinfo.data], { type: fileinfo.headers['content-type'] }), decodeURI(filename));
            } catch (ex) {
              console.log(ex);
              ComNoticfication.ShowAlertUI(ComUtils._altTitleInfo, msgOthInf008);

            }
          }
        }
      }
    }
    else {
      ComNoticfication.ShowAlertUI(ComUtils._altTitleInfo, msgOthInf009);

    }
  }

  const removeFile = async (fileInfo, event) => {
    if (fileInfo?.isOld) {
      // 기존파일
      setFileList(fileList => fileList.map((file) => file.attachNo == fileInfo.attachNo ? { ...file, "isDeleted": true } : file));
    } else {
      // 신규파일
      const response = await ApiManager.post("/Common/File/FileDelete", { "filePath": fileInfo.filePath, "serverFileName": fileInfo.serverFileName, "customPath": "claim" });
      setFileList(fileList => fileList.filter(f => f.serverFileName !== fileInfo.serverFileName));
    }
  };

  const handleFileNoChange = (selectedFile, value) => {
    setFileList(prevFileList =>
      prevFileList.map((file) =>
        file === selectedFile ? { ...file, fileNo: value } : file
      )
    );
  }

  const fncCopyRejectReason = (status) => {
    let tmpChecked = status.target.checked;
    let view = gridObject.current?.collectionView;
    let rows = gridObject.current.rows ?? [];
    if (rows.length > 0) {
      if (tmpChecked) {
        if (rows.length > 1) {
          let rejectCode = view.items[0].rejectCode;
          let rejectDesc = view.items[0].rejectDesc;

          for (let i = 1; i < rows.length; i++) {
            view.items[i].rejectCode = rejectCode;
            view.items[i].rejectDesc = rejectDesc;
          }
        }

      } else {
        gridObject.current.collectionView.items.forEach((el) => {
          el.rejectCode = "";
          el.rejectDesc = "";
        });
      }
      view.commitEdit();
      view.refresh();
    }

    rejectCheck.current = tmpChecked;
  };

  const closeESNExcelUploadPopup = (_systemEntryNoUpload) => {
    if (_systemEntryNoUpload) {
      systemEntryNoUpload.current = _systemEntryNoUpload;
    }
    setOpenGSRESNUploadPop(false);
  };

  const handleClaimView = async (selectedSystemEntryNo) => {
    if (selectedSystemEntryNo) {
      let resultData = await ApiManager.post(retrieveOtherClaimInfo, { systemEntryNo: selectedSystemEntryNo, popup: "Y" });
      if (resultData?.statusCode) {
        ComNoticfication.ShowAlertUI(ComUtils._altTitleError, msgOthErr001);
        return;
      }

      invoiceUsedInitParam.current = resultData;
      setOpenModalClaimRequestPop(true);
    }
  };

  const changeClaimType = async (type) => {
    rejectCheck.current = false;

    claimRef.current = ClaimClassPool.current[type];
    setClaimType(type);
    initGridFormat(type);

    let claimDtlList = initData?.claimDtlList ?? [];
    claimDtlList = claimDtlList.map(item => ({
      ...item,
      approvedAmt: Number(item.approvedAmt) || 0,
      netAllowedQty: Number(item.netAllowedQty) || 0
    }));

    setGidData(
      new CollectionView(claimDtlList, {
        trackChanges: true,
        refreshOnEdit: false,
      })
    );
  }

  const checkBill = () => {
    if (!entityBillTo.current.getValue()) {
      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleWarn, msgOthWar001, () => entityBillTo.current.setFocusCode());
      return true;
    } else {
      return false;
    }
  }

  const checkEndEditing = () => {
    let isValid = true;
    const grid = gridObject.current;

    for (let col of grid.columns) {
      if (col.isRequired) {
        for (let item of grid.itemsSource.items) {
          if (!item[col.binding] || item[col.binding].toString().trim() === '') {
            return false;
          }
        }
      }
    }

    let requestAmt = grid.collectionView?.currentItem?.requestAmt || 0;
    let lgChargebackAmt = grid.collectionView?.currentItem?.lgChargebackAmt || 0;
    if (parseFloat(lgChargebackAmt) > parseFloat(requestAmt)) {
      // "Chargeback amount should not be greater than Request Amount."
      ComNoticfication.ShowAlertUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00113"));
      return false;
    }

    return true;
  };

  const checkClaimType = () => {
    let claimType = entityClaimType?.current?.getValue();
    if (!claimType) {
      //choose the claim type.
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00117"));
      return false;
    } else {
      return true;
    }
  }

  const fncFileCheck = () => {
    // AS-IS에서 빈값을 체크하는게 아닌듯함
    // if (fileList.length === 0) {
    //   // Please attach a file.
    //   ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00054"), () => { });
    //   return false;
    // }

    return true;
  };


  //DiscountChart[S]
  const closeDiscountChart = (item) => {
    setDiscountChart(() => false);
  };

  const showDiscountChart = (e) => {
    randomKey.current = ComUtils.setRefRandomKey();
    setDiscountChart(true);
  };
  //DiscountChart[E]

  const downloadPdf = async (row, e) => {
    let invoiceNo = row?.dataItem?.invoiceNo;

    if (invoiceNo && invoiceNo.length == 10) {
      let url = invoiceNo.substring(0, 2) == "CN" ? retrieveGRPdfCreditMemoUrl : retrieveComInvoPdfUrl;

      let pdfParam = { lgCreditMemoNo: invoiceNo };
      let resultData = await ApiManager.callExcelDowonload(url, pdfParam);
      if (!resultData?.data)
        return;

      let disposition = resultData.headers['content-disposition'];
      let filename = 'sample-pdf.pdf';
      if (disposition && disposition.indexOf('attachment') !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }
      if (filename) {
        try {
          FileSaver.saveAs(new Blob([resultData.data], { type: resultData.headers['content-type'] }), decodeURI(filename));
        } catch (ex) {
          console.log(ex);
        }
      }

    }
  }

  const convertUpperCase = (el, value) => {
    if (value && el.current) {
      el.current.setValue(value.toUpperCase());
    }
  };

  const getPageInfo = async () => {
    // epLogin으로 들어온경우 조회
    if (initData?.epLogin) {
      ComUtils.removeEpLoginSessionItem();

      setViewType(initData?.viewType ?? "");

      let param = {
        userId: userId,
        comCode: comCode,
        userType: userType,
        empNo: empNo,
        systemEntryNo: initData?.systemEntryNo ?? "",
        selApprovalStatus: initData?.viewType === "approval" ? "TO-DO" : "DONE",
        confirm: "Y",
        viewType: initData?.viewType ?? "",
      }

      let resultData = await ApiManager.post(retrieveOtherClaimConfirmInfo, param);
      if (resultData?.statusCode) {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgOthErr001);
        return;
      }

      initData = resultData;
      setClaimType(initData?.claimMstInfo?.claimType);
    }


    // 초기화
    setPopup(initData?.input?.popup ?? "");
    setViewType(initData?.input?.viewType ?? "");
    setSelApprovalStatus(initData?.input?.selApprovalStatus ?? "");
    setSystemEntryNoView(initData?.claimMstInfo?.systemEntryNo ?? "");
    setRequestDateView(initData?.claimMstInfo?.requestDate ?? "");
    setUpdateDateView(initData?.claimMstInfo?.updateDate ?? "");
    setClaimStatusView(initData?.claimMstInfo?.claimStatus ?? "");
    setPostAuditCustomer(initData?.claimMstInfo?.postAuditCustomer ?? "");
    setPostAuditClaim(initData?.claimMstInfo?.postAuditClaim ?? "");
    setClaimAnalystYn(initData?.input?.claimAnalystYn ?? "");
    setOrgDebitMemoAmount(initData?.claimMstInfo?.orgDebitMemoAmount);
    setRvsClaimNo(initData?.claimMstInfo?.rvsClaimNo ?? "")


    initData.input.rejectYn = initData.input?.selApprovalStatus === "TO-DO" ? "Y" : "N";
    toDay.current = initData?.toDay;
    input.current = initData?.input;
    claimStatus.current = "AA";
    confirmType.current = "SAVE";
    claimMstInfo.current = initData?.claimMstInfo;
    systemEntryNo.current = initData?.claimMstInfo?.systemEntryNo;
    orgRequestHidden.current = initData?.input.rejectYn === 'Y' ? false : true;
    reasonCodeListRef.current = initData?.reasonCodeList ?? [];
    pendingCodeListRef.current = initData?.pendingCodeList ?? [];
    noClaimBillToListRef.current = initData?.noClaimBillToList ?? [];

    let props = {
      userType,
      pageType: "Dtl",
      claimAnalystYn: initData?.input?.claimAnalystYn,
      rejectYn: initData?.input.rejectYn,
      orgRequestHidden: initData.input?.selApprovalStatus === "TO-DO" ? false : true,
      rejectionReasonList: selCombo.concat(initData?.rejectionReasonList),
      // pdf
      downloadPdf,
      // state
      isDisabledorgPgmNo: true,
    }

    ClaimClassPool.current = {
      1: new ClaimADV(props),
      2: new ClaimSellIn(props),
      14: new ClaimSellOut(props),
      13: new ClaimMarkdown(props),
      4: new ClaimPriceProtection(props),
      5: new ClaimPriceDifference(props),
      6: new ClaimFreight(props),
      3: new ClaimMeetcomp(props),
      8: new ClaimRecycling(props),
      9: new ClaimDamageAllow(props),
      7: new ClaimTax(props),
      11: new ClaimVLPriceDiff(props),
      12: new ClaimIrSpiff(props),
    };

    entitySalesrepName.current?.setColumnConfig([
      { binding: "cd", header: "Code", width: 120, textAlign: "center" },
      { binding: "nm", header: "Name", width: 200 },
      { binding: "emailId", header: "E-mail", width: 200 },
    ]);

    entityReasonCode.current?.setColumnConfig([
      { binding: "nm", header: "Reason Code", width: 140, textAlign: "center" },
      { binding: "locDescription", header: "Reason Code Description", width: "*" },
    ]);


    // set entities
    entityPostAuditClaim.current?.setValue(initData?.claimMstInfo?.postAuditClaim);
    entityCustEntry.current?.setValue(initData?.claimMstInfo?.custEntry === "Y" ? "Y" : "N");
    entityReverse.current?.setValue(initData?.claimMstInfo?.reverseFlag);
    entityDivision.current?.setValue(initData?.claimMstInfo?.divisionCode);
    entityBillTo.current?.setValue(initData?.claimMstInfo?.billToCode);
    entityBillToName.current?.setValue(initData?.claimMstInfo?.billToName);
    entityBillToAddress.current?.setValue(initData?.claimMstInfo?.billToAddress);
    entityBillToCity.current?.setValue(initData?.claimMstInfo?.billToCity);
    entityBillToState.current?.setValue(initData?.claimMstInfo?.billToState);
    entityBillToZipCode.current?.setValue(initData?.claimMstInfo?.billToZipCode);
    entityBillToContactName.current?.setValue(initData?.claimMstInfo?.billToContactName);
    entityBillToContactTel.current?.setValue(initData?.claimMstInfo?.billToContactTel?.replace(/\D/g, '')?.slice(-10));
    entityBillToContactEmail.current?.setValue(initData?.claimMstInfo?.billToContactEmail);
    entityShipTo.current?.setValue(initData?.claimMstInfo?.shipToCode);
    entityShipToName.current?.setValue(initData?.claimMstInfo?.shipToName);
    entityActivityDateFrom.current?.setValue(initData?.claimMstInfo?.activityDateFrom);
    entityActivityDateTo.current?.setValue(initData?.claimMstInfo?.activityDateTo);
    entityFollowUpDate.current?.setValue(initData?.claimMstInfo?.followUpDate);
    entitySalesrepName.current?.setList({ data: [{ cd: initData?.claimMstInfo?.salesrepId, nm: initData?.claimMstInfo?.salesrepName }] });
    entitySalesrepName.current?.setValue(initData?.claimMstInfo?.salesrepId);
    entityReasonCode.current?.setList({ data: selCombo.concat(initData?.reasonCodeList), code: "nm", name: "locDescription", initValue: initData?.claimMstInfo?.reasonCode });
    entityPendingCode.current?.setValue(initData?.claimMstInfo?.pendingType);
    entityClaimAnalyst.current?.setValue(initData?.claimMstInfo?.claimAnalyst);
    claimAnalystEmpNo.current = initData?.claimMstInfo?.claimAnalystEmpNo;
    entityDebitMemoNo.current?.setValue(initData?.claimMstInfo?.debitMemoNo);
    entityDebitMemoAmt.current?.setValue(initData?.claimMstInfo?.debitMemoAmount ? Number(initData?.claimMstInfo?.debitMemoAmount?.replaceAll(',', '')) : 0);
    entityPaymentMethod.current?.setValue(initData?.claimMstInfo?.paymentMethod);
    entityOutstandingInvoiceAmt.current?.setValue(initData?.claimMstInfo?.outstandingInvoiceAmt);
    entityLgChargebackNo.current?.setValue(initData?.claimMstInfo?.lgChargebackNo);
    entityQuotationNo.current?.setValue(initData?.claimMstInfo?.quotationNo);
    entityMultiQuote.current?.setValue(initData?.claimMstInfo?.multiQuote);
    entityGroupCodeFlag.current?.setValue(initData?.claimMstInfo?.groupCodeFlag === 'Y' ? 'Y' : 'N');
    entityVendorName.current?.setValue(initData?.claimMstInfo?.vendorName);
    entityVendorCode.current?.setValue(initData?.claimMstInfo?.vendorCode);
    entityPayToAddress.current?.setValue(initData?.claimMstInfo?.payToAddress);
    entityTradeClaim.current?.setValue(initData?.claimMstInfo?.tradeClaim);
    entityCustomerClaimDesc.current?.setValue(initData?.claimMstInfo?.customerClaimDesc);
    entityLgReviewComment.current?.setValue(initData?.claimMstInfo?.lgReviewComment);
    entityMoreClaims.current?.setValue(initData?.claimMstInfo?.moreClaims);
    entityFollowFlag.current?.setValue(initData?.claimMstInfo?.followUpFlag);
    systemEntryNoUpload.current = initData?.claimMstInfo?.systemEntryNoUpload;

    // 추후 comment 보여주는 조건 정해지면 보여주도록함
    // if (initData?.input?.selApprovalStatus === "TO-DO") {
    //   entityApproveComment?.current?.setValue(initData?.claimMstInfo?.approveComment ?? "");
    // }

    // entitySalesrepName 이벤트로 리셋 방지
    setTimeout(() => {
      entitySalesrepTel.current?.setValue(initData?.claimMstInfo?.salesrepTel?.replaceAll('-', ''));
      entitySalesrepEmail.current?.setValue(initData?.claimMstInfo?.salesrepEmail);
    }, 200);

    // set claim Type, grid data
    changeClaimType(initData?.claimMstInfo?.claimType);
    entityClaimType.current.setValue(initData?.claimMstInfo?.claimType);

    // attach file
    let attachList = initData?.attachList ?? [];
    const newFileList = attachList.map(f => {
      return {
        fileNo: f?.fileNo ?? "",
        attachNo: f?.attachNo ?? "",
        isDeleted: false,
        isOld: true,
        serverFileName: f?.saveFileName ?? "",
        filePath: f?.filePath ?? "",
        fileSize: "",
        displayFileName: f?.fileName ?? "",
        userId: f?.createId ?? ""
      };
    });

    setFileList(newFileList);

    // set approval line
    initGridFormatApprv();
    if (userType === "E" && initData?.approvalList.length > 0) {
      setIsHiddenApporval(true);
      setApprovalLineView(true);
      setGridDataApprv(new CollectionView(initData?.approvalList, { trackChanges: true, refreshOnEdit: false }));
    } else {
      setIsHiddenApporval(false);
      setApprovalLineView(false);
    }

    // 버튼, Approval show/hide
    setShowBtnGoAgreement(initData?.claimMstInfo?.attribute7);
    setShowBtnSave(!initData?.input?.viewType && popup === "" && userType === "E" && initData?.claimMstInfo?.docType === "ATTACHMENT");
    setShowBtnReEntry(!initData?.input?.viewType && popup === "" && initData?.claimMstInfo?.claimStatusCode === "A5");
    setShowBtnRevert(!initData?.input?.viewType && popup === "" && initData?.claimMstInfo?.claimStatusCode === "A6" && initData?.input?.claimAnalystYn === "Y");
    setFileListMod(!initData?.input?.viewType && popup === "" && userType === "E" && initData?.claimMstInfo?.docType === "ATTACHMENT");
    setFileListNoView(userType === "E" && initData?.claimMstInfo?.docType === "ATTACHMENT" && (initData?.input?.claimAnalystYn === "Y" || initData?.input?.selApprovalStatus === "TO-DO"));
    setApproveCommentView(initData?.input?.selApprovalStatus === "TO-DO");

    // 결재 승인 데이터 셋팅
    let apprRoleType = "";
    let personName = "";
    let sMgr = "";
    let sSrMgr = "";
    let sVp = "";
    let approvaldataList = initData?.approvalList ?? [];
    for (let i = 0; i < approvaldataList.length; i++) {
      apprRoleType = approvaldataList[i]?.apprRoleType ?? "";
      personName = approvaldataList[i]?.personName ?? "";
      if ((approvaldataList[i]?.status ?? "") === "approved") {
        if (apprRoleType === "Mgr.") {
          sMgr = personName;
        } else if (apprRoleType === "Sr. Mgr.") {
          sSrMgr = personName;
        } else if (apprRoleType === "VP") {
          sVp = personName;
        }
      } else {
        if (apprRoleType === "Mgr.") {
          sMgr = "";
        } else if (apprRoleType === "Sr. Mgr.") {
          sSrMgr = "";
        } else if (apprRoleType === "VP") {
          sVp = "";
        }
      }
    }

    setApprvAsstView(initData?.claimMstInfo?.claimAnalyst ?? "");
    setApprvMgrView(sMgr);
    setApprvSrMgrView(sSrMgr);
    setApprvVPView(sVp);
  }

  const _isMounted = useRef(true);
  useEffect(() => {
    let abortController = new AbortController();

    if (initData?.claimMstInfo?.systemEntryNo || initData?.epLogin) {
      getPageInfo();
    }

    // ComponentWillUnmount in Class Component
    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, [initData]);

  const [listClaimType, setListClaimType] = useState([
    { Value: "1", Description: "ADV. (MDF, Co-op, Display, VR)", },
    { Value: "2", Description: "Sell-In(TC)" },
    { Value: "14", Description: "Sell-Out(Bundle)" },
    { Value: "13", Description: "Markdown" },
    { Value: "4", Description: "Price Protection", },
    { Value: "5", Description: "Price Difference", },
    { Value: "6", Description: "Freight" },
    { Value: "3", Description: "Meetcomp" },
    { Value: "8", Description: "Recycling" },
    { Value: "9", Description: "Damage Allow." },
    { Value: "7", Description: "Tax" },
    { Value: "11", Description: "VL. Price Diff." },
    { Value: "12", Description: "IR/SPIFF" },
  ]);

  const showLayoutByClaimType = () => {
    switch (claimType) {
      case "2":
        return (
          <>
            <div className="bx-item column-2column">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Required Attachment
                </label>
              </div>
              <div className="bx-inp combo">
                <div>
                  Debit memo copy & Proof of Perfomance
                  <span className="field-required">
                    (Claim reject if no evidence)
                  </span>
                </div>
              </div>
            </div>
            <div className="bx-item">
              <div className="div-label">
                <label className="txt-label">
                  Trade Claim
                </label>
              </div>
              <div className="bx-inp combo disabled-color-change">
                <ComRadioBox
                  ref={entityTradeClaim}
                  radioList={[
                    { Value: "Y", Description: "Yes" },
                    { Value: "N", Description: "No" },
                  ]}
                  direction={1}
                  radioGroupName={"TradeClaim"}
                  defaultDisabled={true}
                />
              </div>
            </div>
          </>
        );
      case "14":
        return (
          <>
            <div className="bx-item column-3column">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Required Attachment
                </label>
              </div>
              <div className="bx-inp combo">
                <div>
                  Debit memo copy & Proof of Perfomance
                  <span className="field-required">
                    (Claim reject if no evidence)
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      case "13":
        return (
          <>
            <div className="bx-item column-2column">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Required Attachment
                </label>
              </div>
              <div className="bx-inp combo">
                <div>
                  Debit memo copy & Customer On-hand inventory report
                  <span className="field-required">
                    (Claim reject if no evidence)
                  </span>
                </div>
              </div>
            </div>
            <div className="bx-item">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Trade Claim
                </label>
              </div>
              <div className="bx-inp combo disabled-color-change">
                <ComRadioBox
                  ref={entityTradeClaim}
                  radioList={[
                    { Value: "Y", Description: "Yes" },
                    { Value: "N", Description: "No" },
                  ]}
                  direction={1}
                  radioGroupName={"TradeClaim"}
                  defaultDisabled={true}
                />
              </div>
            </div>
          </>
        );
      case "4":
        return (
          <>
            <div className="bx-item column-3column">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Required Attachment
                </label>
              </div>
              <div className="bx-inp combo">
                <div>
                  Debit memo copy & Proof of Customer On-hand inventory report
                  as of price drop date
                  <span className="field-required">
                    (Claim reject if no evidence)
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      case "5":
        return (
          <>
            <div className="bx-item column-3column">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Required Attachment
                </label>
              </div>
              <div className="bx-inp combo">
                <div>
                  Debit memo or LG Invoice copy & Customer purchase order copy
                  as of price drop date
                  <span className="field-required">
                    (Claim reject if no evidence)
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      case "6":
        return (
          <>
            <div className="bx-item column-3column">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Required Attachment
                </label>
              </div>
              <div className="bx-inp combo">
                <div>
                  Debit memo or LG Invoice copy & Customer purchase order copy
                  as of price drop date
                  <span className="field-required">
                    (Claim reject if no evidence)
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      case "3":
        return (
          <>
            <div className="bx-item column-3column">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Required Attachment
                </label>
              </div>
              <div className="bx-inp combo">
                <div>
                  Debit copy & Customer On-hand inventory report
                  <span className="field-required">
                    (Claim reject if no evidence)
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      case "8":
        return (
          <>
            <div className="bx-item column-3column">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Required Attachment
                </label>
              </div>
              <div className="bx-inp combo">
                <div>
                  Debit Memo or LG invoice copy & Exemption certificate required
                  <span className="field-required">
                    (Claim reject if no evidence)
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      case "9":
        return (
          <>
            <div className="bx-item column-3column">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Required Attachment
                </label>
              </div>
              <div className="bx-inp combo">
                <div>
                  Debit Memo with LG approved document - LG Claim from
                  <span className="field-required">
                    (Claim reject if no evidence)
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      case "7":
        return (
          <>
            <div className="bx-item column-3column">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Required Attachment
                </label>
              </div>
              <div className="bx-inp combo">
                <div>
                  Debit Memo with LG approved document - LG Claim from
                  <span className="field-required">
                    (Claim reject if no evidence)
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      case "11":
        return (
          <>
            <div className="bx-item column-3column">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Required Attachment
                </label>
              </div>
              <div className="bx-inp combo">
                <div>
                  Debit Memo with LG approved document - LG Claim from
                  <span className="field-required">
                    (Claim reject if no evidence)
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      case "12":
        return (
          <>
            <div className="bx-item column-3column">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Required Attachment
                </label>
              </div>
              <div className="bx-inp combo">
                <div>
                  Debit Memo with LG approved document - LG Claim from
                  <span className="field-required">
                    (Claim reject if no evidence)
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return (
          <>
            <div className="bx-item column-2column">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Required Attachment
                </label>
              </div>
              <div className="bx-inp combo">
                <div>
                  Debit memo copy & Proof of Perfomance
                  <span className="field-required">
                    (Claim reject if no evidence)
                  </span>
                  <p>
                    {
                      "1) MDF/COOP __ Advertisement (Newspaper, Flyers, Online Etc,..) 2) VR __ Sales data for period"
                    }
                  </p>
                </div>
              </div>
            </div>
            <div className="bx-item">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Trade Claim
                </label>
              </div>
              <div className="bx-inp combo disabled-color-change">
                <ComRadioBox
                  ref={entityTradeClaim}
                  radioList={[
                    { Value: "Y", Description: "Yes" },
                    { Value: "N", Description: "No" },
                  ]}
                  direction={1}
                  radioGroupName={"TradeClaim"}
                  defaultDisabled={true}
                />
              </div>
            </div>
          </>
        );
    }
  };

  const initGrid = (sender) => {
    gridObject.current = sender;
    ComUtils.gridInit(sender, {
      selectionMode: 6,
      hasShowNumbering: true,
      hasCheckBox: false,
      showCheckAll: false,
      hasDisableCheck: false,
      canAreaInfo: false,
    });
    sender.allowSorting = false;


    sender.cellEditEnded.addHandler((s, e) => {
      let binding = e.getColumn().binding;
      let dataItem = s.rows[e.row].dataItem;

      if (binding === "rejectCode") {
        // initData?.rejectionReasonList 에서 code를 찾아 nm으로 채워준다.
        let rejectReason = initData?.rejectionReasonList?.find(item => item.cd === dataItem.rejectCode);
        dataItem.rejectDesc = rejectReason?.nm;
        s.refresh();
      }
    });


    sender.formatItem.addHandler((s, e) => {
      if (e.panel === s.columnHeaders && e.getColumn(e.col).binding === "rejectCode") {
        if (input.current?.rejectYn === "Y") {
          e.cell?.firstChild?.remove();
          const col = s.columns[e.col];

          let element = null;
          if (rejectCheck.current) {
            element = createElement(format(
              `<span class="txt-title" style="display: inline-block;"><label>Rejection</label><br><input type="checkbox" class="wj-column-selector wj-column-selector-group wj-cell-check" tabindex="-1" checked=true>Reason</label></span>`, {}), e.cell);
          }
          else {
            element = createElement(format(
              `<span class="txt-title" style="display: inline-block;"><label>Rejection</label><br><input type="checkbox" class="wj-column-selector wj-column-selector-group wj-cell-check" tabindex="-1">Reason</lable></spen>`, {}), e.cell);
          }

          element.onchange = function (e) {
            e.preventDefault();
            fncCopyRejectReason.apply(e, [e]);
          }
        }
      }
    });


    // add total footer
    sender.columnFooters.rows.push(new GroupRow());
  };

  const initGridFormat = useCallback(
    (
      type,
      popups
    ) => {
      let gridFormat = claimRef.current?.getGirdFormat();

      // rejectCode, rejectDesc이면서  rejectYn === 'Y' 이면 수정 가능
      gridFormat = gridFormat.map(item => (
        {
          ...item,
          isReadOnly: !((item.binding === 'rejectCode' || item.binding === 'rejectDesc') && initData?.input?.rejectYn === 'Y'),
          columns: item?.columns?.map(col => (
            {
              ...col,
              isReadOnly: !((col.binding === 'rejectCode' || col.binding === 'rejectDesc') && initData?.input?.rejectYn === 'Y')
            }
          ))
        }
      ));

      setGridColGroup([...gridFormat]);
    }, []
  );

  const initGridFormatApprv = useCallback(() => {
    let gridFormat = [
      { binding: "flowSeq", header: "Seq.", width: 80, allowDragging: true, isReadOnly: true, align: "center", },
      { binding: "personName", header: "Approver", width: 150, allowDragging: true, isReadOnly: true, align: "center", },
      { binding: "email", header: "E-Mail", width: 200, allowDragging: true, isReadOnly: true, align: "center", },
      { binding: "status", header: "Status", width: 120, allowDragging: true, isReadOnly: true, align: "center", },
      { binding: "apprDate", header: "Approval Date", width: 150, allowDragging: true, isReadOnly: true, align: "center", },
      { binding: "comments", header: "Comments", width: "*", allowDragging: true, isReadOnly: true, align: "left", },
    ];
    setGridColGroupApprv([...gridFormat]);
  }, []);

  const initGridBindData = useCallback((type) => {
    let resultData = claimRef.current?.getData();

    setGidData(
      new CollectionView(resultData, {
        trackChanges: true,
        refreshOnEdit: false,
      })
    );
  });

  const gridFormatItemClaimDetail = (s, e) => {
    claimRef?.current?.setFormatItem(s, e);
  };

  // Approval Line Grid
  const initGridApprv = (sender) => {
    gridObjApprv.current = sender;
    ComUtils.gridInit(sender, {
      // hasShowNumbering: true,
      canAreaInfo: false,
      selectionMode: 6,
      hasCheckBox: false,
      showCheckAll: false,
      hasDisableCheck: false,
    });
    sender.allowSorting = false;
  };

  const showTable = () => {
    return <div className={claimRef.current?.isMergeNumbering() ? "numbering-merge" : undefined}>
      <FlexGrid key={claimType}
        columnGroups={gridColGroup} // 그리드 포멧
        itemsSource={gridData} // 바인딩할 데이터
        initialized={initGrid} // 그리드 초기 이벤트 및 등록 처리
        style={{ minheight: "200px" }}
        formatItem={gridFormatItemClaimDetail}>
      </FlexGrid>
    </div>;
  };

  const renderHeaderButtons = () => {
    return (
      <>
        {(popup === "" && selApprovalStatus === "TO-DO") && <Button variant="outlined" onClick={(e) => fncReturn()}>{"Return To Sender"}</Button>}
        {(popup === "" && viewType === "" && backFn) && <Button variant="outlined" onClick={() => backFn()}>{"Back"}</Button>}
        {(popup === "" && viewType === "" && backFn) && <Button variant="outlined" onClick={() => backFn(true)}>{"List"}</Button>}
        {showBtnReEntry && <Button variant="outlined" onClick={(e) => fncReEntry()}>{"Re Entry"}</Button>}
        {showBtnSave && <Button variant="contained" onClick={(e) => { fncAttachSave() }}>{"Save"}</Button>}
        {showBtnRevert && <Button variant="contained" onClick={(e) => { fncRevert() }}>{"Pull Back"}</Button>}
        {(popup === "" && selApprovalStatus === "TO-DO") && <Button variant="contained" onClick={(e) => { fncApprove() }}>{"Approve"}</Button>}
        {(popup === "" && selApprovalStatus === "TO-DO") && <Button variant="contained" onClick={(e) => { fncReject() }}>{"Reject"}</Button>}
      </>
    );
  }

  return (
    <div className="page-wrap">
      {viewType === "" ?
        <div className="breadcrumb-header" style={{ display: popup === "" ? '' : 'none' }}>
          <Breadcrumb items={pageInfo} />
          <div className=" btn-group-end" >
            {renderHeaderButtons()}
          </div>
        </div>
        :
        <div className=" btn-group-end mb-10" >
          {renderHeaderButtons()}
        </div>
      }

      <div className="page-in-wrap">
        <div className="bx-card-group">
          <div className="filter-title">
            <div className="txt-title">
              Other Claim View
              <div className="ml-5">
              </div>
            </div>
          </div>

          <div className="search-wrapper">
            <div className="bx-search2 fixed-column3">
              <div className="bx-item">
                <span className="field-required fz-12 ml-5 fwb">
                  * is required field
                </span>
              </div>

              <div className="bx-item">
                {(postAuditCustomer === "Y" || postAuditClaim === "Y") ?
                  <>
                    <span className="fz-12 fwb">This is Post Audit claim</span>
                    <div className="ml-10 d-flex disabled-color-change">
                      <ComRadioBox
                        radioList={[
                          { Value: "Y", Description: "Yes" },
                          { Value: "N", Description: "No" },
                        ]}
                        ref={entityPostAuditClaim}
                        elementId={"entityPostAuditClaim"}
                        radioGroupName={"PostAuditClaim"}
                        direction={1}
                        defaultDisabled={true}
                      />
                    </div>
                    <div className="ml-10 d-flex" style={{ width: '130px' }}>
                      <ComDate
                        ref={entityActivityDateFrom}
                        elementId={"entityActivityDateFrom"}
                        controlDateFormat={"MM/dd/yyyy"}
                        controlDateMask={"##/##/####"}
                        controlDateValidFormat={"__/__/____"}
                        isNullable={true}
                        defaultDisabled={true}
                      />
                    </div>
                    <div className="ml-10 d-flex" style={{ width: '130px' }}>
                      <ComDate
                        ref={entityActivityDateTo}
                        elementId={"entityActivityDateTo"}
                        controlDateFormat={"MM/dd/yyyy"}
                        controlDateMask={"##/##/####"}
                        controlDateValidFormat={"__/__/____"}
                        isNullable={true}
                        defaultDisabled={true}
                      />
                    </div>
                  </>
                  :
                  <>
                    <span className="fz-12 fwb">
                      Cust. Entry
                    </span>
                    <div className="ml-10 d-flex disabled-color-change">
                      <ComRadioBox
                        radioList={[
                          { Value: "Y", Description: "Yes" },
                          { Value: "N", Description: "No" },
                        ]}
                        ref={entityCustEntry}
                        elementId={"entityCustEntry"}
                        radioGroupName={"CustEntry"}
                        direction={1}
                        defaultDisabled={true}
                      />
                    </div>
                  </>
                }
              </div>

              <div className="bx-item d-flex-end">
                <span className="fz-12 fwb">
                  Reverse(Debit):
                </span>
                <div className="mr-10 d-flex disabled-color-change">
                  <ComRadioBox
                    ref={entityReverse}
                    radioList={
                      [
                        { Value: "Y", Description: "Yes" },
                        { Value: "N", Description: "No" },
                      ]
                    }
                    direction={1}
                    radioGroupName={"ReverseDebitGroup"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
            </div>

            <div className="bx-search2 fixed-column4">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Portal Entry#</label>
                </div>
                <div className="bx-inp combo">{systemEntryNoView}</div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Request Date</label>
                </div>
                <div className="bx-inp combo">{requestDateView}</div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Last Update</label>
                </div>
                <div className="bx-inp combo">{updateDateView}</div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">SP Status</label>
                </div>
                <div className="bx-inp combo">
                  {claimStatusView}
                </div>
              </div>
            </div>
            <div className="bx-search2 fixed-column3">
              <div className="bx-item column-2column d-flex-column">
                <div className="ct-fl-sample-request w-100">
                  <div className="bx-item " style={{ width: "100%" }}>
                    <div className="div-label">
                      <label className="txt-label">
                        <span className="requiresimbol"> *</span> Bill To Code
                      </label>
                    </div>
                    <div className="bx-inp combo inp-mix">
                      <ComTextBox
                        ref={entityBillTo}
                        elementId={"entityBillTo"}
                        placeholderCode={"Enter a Bill To Code"}
                        placeholderText={"Bill To Name"}
                        defaultRaiseClick={false}
                        defaultMaxLength={20}
                        defaultDisabled={true}
                      />
                      <ComTextBox
                        ref={entityBillToName}
                        elementId={"entityBillToName"}
                        placeholder={"Bill To Name"}
                        defaultDisabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="ct-fl-sample-request w-100">
                  <div className="bx-item" style={{ width: "100%" }}>
                    <div className="div-label">
                      <label className="txt-label">
                        <span className="requiresimbol"> *</span> Ship To Code
                      </label>
                    </div>
                    <div className="bx-inp combo inp-mix">
                      <ComTextBox
                        ref={entityShipTo}
                        elementId={"entityShipTo"}
                        placeholderCode={"Enter a Ship To Code"}
                        placeholderText={"Ship To Name"}
                        defaultRaiseClick={false}
                        defaultMaxLength={20}
                        defaultDisabled={true}
                      />
                      <ComTextBox
                        ref={entityShipToName}
                        elementId={"entityShipToName"}
                        placeholder={"Ship To Name"}
                        defaultDisabled={true}
                      />
                      {showBtnGoAgreement && <Button variant="outlined" onClick={e => fncGoAgreement()}>{"Agreement Dtl"}</Button>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  {userType !== "B" &&
                    <>
                      <label className="txt-label"> {claimAnalystYn === "Y" ? <span className="requiresimbol"> *</span> : undefined} Division</label>
                    </>
                  }
                </div>
                <div
                  className="bx-inp combo inp-mix disabled-color-change"
                  style={{
                    wordBreak: "unset",
                    flexWrap: "wrap",
                    columnGap: "20px",
                  }}
                >
                  {userType !== "B" &&
                    <ComRadioBox
                      ref={entityDivision}
                      radioList={[
                        { Value: "HA", Description: "HA" },
                        { Value: "AE", Description: "AE" },
                        { Value: "HE", Description: "HE" },
                        { Value: "B2B", Description: "B2B" },
                        //{ Value: "MC", Description: "MC" },
                        { Value: "BUILDER", Description: "Builder" },
                        { Value: "SKS", Description: "SKS" },
                        { Value: "PROJECT", Description: "Project" },
                        { Value: "OEM", Description: "OEM" },
                      ]}
                      direction={1}
                      radioGroupName={"DivisionGroup"}
                      defaultDisabled={true}
                    />
                  }
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> Address</label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    defaultDisabled
                    ref={entityBillToAddress}
                  />
                </div>
              </div>

              <div className="bx-item item-only-bg txt-center d-flex-center">
                Dealer Contact Information
              </div>
              <div className="bx-item item-only-bg txt-center d-flex-center">
                LG Sales Person Contact Information
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> City</label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    defaultDisabled
                    ref={entityBillToCity}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Name
                  </label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    ref={entityBillToContactName}
                    defaultMaxLength={50}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Name
                  </label>
                </div>
                <div className="bx-inp combo">
                  <ComComboMultiColumn
                    ref={entitySalesrepName}
                    callRelationFuntion={(id, value, index, item) => {
                      entitySalesrepEmail.current?.setValue(item?.emailId);
                      entitySalesrepTel.current?.setValue(item?.tel);
                    }}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> State</label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    defaultDisabled
                    ref={entityBillToState}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Tel
                  </label>
                </div>
                <div className="bx-inp combo">
                  <ComMaskInput
                    ref={entityBillToContactTel}
                    mask="000-000-0000"
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> Tel</label>
                </div>
                <div className="bx-inp combo">
                  <ComMaskInput
                    ref={entitySalesrepTel}
                    mask="000-000-0000"
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> Zip Code</label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    ref={entityBillToZipCode}
                    defaultDisabled
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>E-Mail
                  </label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    ref={entityBillToContactEmail}
                    defaultMaxLength={50}
                    placeholder="Enter a email address"
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>E-Mail
                  </label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    ref={entitySalesrepEmail}
                    defaultMaxLength={50}
                    placeholder="Enter a email address"
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item column-2column">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Claim Type <br />(
                    <span className="field-required">Select 1 Only</span>)
                  </label>
                </div>
                <div
                  className="bx-inp combo disabled-color-change"
                  style={{
                    wordBreak: "unset",
                    flexWrap: "wrap",
                    columnGap: "50px",
                  }}
                >
                  <ComRadioBox
                    ref={entityClaimType}
                    radioList={listClaimType}
                    direction={1}
                    radioGroupName={"ClaimTypeGroup"}
                    defaultValue={claimType}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item d-flex-column">
                <div className="ct-fl-sample-request w-100">
                  <div className="bx-item " style={{ width: "100%" }}>
                    <div className="div-label">
                      <label className="txt-label">
                        {userType !== "B" &&
                          <>
                            {claimAnalystYn === "Y" &&
                              <span className="requiresimbol"> *</span>
                            }
                            Reason Code
                          </>
                        }
                      </label>
                    </div>
                    <div className="bx-inp combo inp-mix">
                      {userType !== "B" &&
                        <ComComboMultiColumn
                          ref={entityReasonCode}
                          elementId="entityReasonCode"
                          defaultDisabled={true}
                        />
                      }
                    </div>
                  </div>
                </div>
                <div className="ct-fl-sample-request w-100">
                  <div className="bx-item" style={{ width: "100%" }}>
                    <div className="div-label">
                      <label className="txt-label">Claim Analyst</label>
                    </div>
                    <div className="bx-inp combo inp-mix">
                      <ComTextBox
                        ref={entityClaimAnalyst}
                        defaultRaiseClick={false}
                        defaultReadOnly={false}
                        defaultDisabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Debit Memo
                  </label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    ref={entityDebitMemoNo}
                    elementId={entityDebitMemoNo}
                    defaultMaxLength={50}
                    defaultDisabled={true}
                    callRelationFuntion={(id, value) => convertUpperCase(id, value)}
                  />
                  <img
                    src={IcPdf}
                    style={{ cursor: "pointer" }}
                    onClick={e => {
                      downloadPdf({ dataItem: { invoiceNo: entityDebitMemoNo.current?.getValue() } });
                    }}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Request Amount
                  </label>
                </div>
                <div className="bx-inp combo">
                  <ComNumberInput
                    ref={entityDebitMemoAmt}
                    formatValue="n2"
                    minValue={0}
                    defaultStyle={{ color: "red" }}
                    defaultDisabled={true}
                  />
                  <span style={{ width: "60%" }}>
                    Org.: {orgDebitMemoAmount}
                  </span>
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    {!rvsClaimNo &&
                      <><span className="requiresimbol"> *</span>Payment Method</>
                    }
                  </label>
                </div>
                <div className="bx-inp combo disabled-color-change">
                  {!rvsClaimNo &&
                    <ComRadioBox
                      ref={entityPaymentMethod}
                      defaultDisabled={true}
                      radioList={[
                        { Value: "CREDIT", Description: "Credit" },
                        { Value: "CHECK", Description: "Check" },
                      ]}
                      direction={1}
                      radioGroupName={"PaymentMethod"}
                    />
                  }
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">LG Chargeback#</label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBoxFind
                    ref={entityLgChargebackNo}
                    elementId={entityLgChargebackNo}
                    defaultRaiseClick={false}
                    defaultReadOnly={false}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Vendor Name</label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    ref={entityVendorName}
                    defaultMaxLength={70}
                    defaultRaiseClick={false}
                    defaultReadOnly={false}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Vendor No.</label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    ref={entityVendorCode}
                    defaultDisabled
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Outstanding Invoice Amt</label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    ref={entityOutstandingInvoiceAmt}
                    placeholder={"**********"}
                    defaultStyle={{ color: "red", textAlign: "right" }}
                    defaultClassName={"input-placeholder-red"}
                    defaultDisabled
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    Pay to address
                  </label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    ref={entityPayToAddress}
                    defaultMaxLength={500}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    {userType === "E" &&
                      `Pending Type`
                    }
                  </label>
                </div>
                <div className="bx-inp combo">
                  {userType === "E" &&
                    <ComTextBox
                      ref={entityPendingCode}
                      elementId={"entityPendingCode"}
                      defaultDisabled={true}
                    />
                  }
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Quotation No.</label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    ref={entityQuotationNo}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Multiple Quote</label>
                </div>
                <div className="bx-inp combo disabled-color-change">
                  <ComRadioBox
                    ref={entityMultiQuote}
                    radioList={[
                      { Value: "Y", Description: "Yes" },
                      { Value: "N", Description: "No" },
                      { Value: "NQ", Description: "No Quote" },
                    ]}
                    direction={1}
                    radioGroupName={"MultibleQuote"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  {claimAnalystYn === "Y" &&
                    <label className="txt-label">Group Code</label>
                  }
                </div>
                <div className="bx-inp combo disabled-color-change">
                  {claimAnalystYn === "Y" &&
                    <ComRadioBox
                      ref={entityGroupCodeFlag}
                      radioList={[
                        { Value: "Y", Description: "Yes" },
                        { Value: "N", Description: "No" },
                      ]}
                      direction={1}
                      radioGroupName={"GroupCode"}
                      defaultDisabled={true}
                    />
                  }
                </div>
              </div>
              {showLayoutByClaimType()}
            </div>
          </div>
        </div>
        <div className="table-area-wrapper">
          <div className="tb-content">
            <div className="bx-top">
              <div>
                <div className="txt-title">
                  <h3>
                    Claim Type:
                    <span className="field-required ml-5 fwb">
                      {claimType !== "" &&
                        listClaimType.find((x) => x.Value == claimType)?.Description || ""
                      }
                    </span>
                  </h3>
                </div>
              </div>
              <div className="bx-btn-group">
                {(userType === "E" && claimType == "9") &&
                  <Button variant="outlined" className="btn-default" onClick={e => onClickInvoice()}>
                    {"Invoice"}
                  </Button>
                }
                {(claimAnalystYn === "Y" || selApprovalStatus == "TO-DO") &&
                  <Button variant="outlined" className="btn-default" onClick={e => fncESNUploadExcel()}>
                    {"Validation"}
                  </Button>
                }
              </div>
            </div>
            {claimType !== "" &&
              <div className="bx-table">
                {showTable()}
              </div>
            }
          </div>
        </div>
        <div className="search-wrapper mt-10">
          <div className="bx-search2 fixed-column2">
            <div className="bx-item">
              {
                ('1' == claimType || '2' == claimType || '14' == claimType || '3' == claimType || '13' == claimType || '4' == claimType) &&
                <>
                  * This claim is Final for this program.
                  <div className="ml-5 d-flex disabled-color-change">
                    <ComRadioBox
                      ref={entityMoreClaims}
                      radioList={[
                        { Value: "N", Description: "Yes" },
                        { Value: "Y", Description: "No" },
                      ]}
                      direction={1}
                      radioGroupName={"ThisClaim"}
                      defaultDisabled={true}
                    />
                  </div>
                </>
              }
            </div>

            <div className="bx-item">
              {"To be Follow Up (Pre Payment )"}
              <div className="ml-5">
                <ComDate
                  ref={entityFollowUpDate}
                  elementId={"entityReturnDateFrom"}
                  controlDateFormat={"MM/dd/yyyy"}
                  controlDateMask={"##/##/####"}
                  controlDateValidFormat={"__/__/____"}
                  isNullable={true}
                  defaultDisabled={true}
                />
              </div>

              <div className="ml-5 d-flex disabled-color-change">
                <ComRadioBox
                  ref={entityFollowFlag}
                  radioList={[
                    { Value: "Y", Description: "Done" },
                    { Value: "N", Description: "Wait" },
                  ]}
                  direction={1}
                  radioGroupName={"ThisClaim"}
                  defaultDisabled={claimAnalystYn !== "Y"}
                />
              </div>
            </div>
          </div>
          <div className="bx-search2 fixed-column1">
            <div className="bx-item">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Customer Claim <br />Description
                </label>
              </div>
              <div
                className="bx-inp combo"
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <ComTextArea
                  ref={entityCustomerClaimDesc}
                  defaultStyle={{
                    border: "unset",
                    resize: "vertical",
                    minHeight: "40px",
                    margin: 0,
                    padding: "4px 6px",
                  }}
                  defaultMaxLength={2000}
                  defaultDisabled={true}
                ></ComTextArea>
              </div>
            </div>
            <div className="bx-item">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>LG Review Comment
                </label>
              </div>
              <div
                className="bx-inp combo"
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <ComTextArea
                  ref={entityLgReviewComment}
                  defaultStyle={{
                    border: "unset",
                    resize: "vertical",
                    minHeight: "40px",
                    margin: 0,
                    padding: "4px 6px",
                  }}
                  defaultDisabled={claimAnalystYn != "Y"}
                ></ComTextArea>
              </div>
            </div>
          </div>
        </div>

        {/* Approval */
          approvalLineView &&
          <div className="bx-card-group mt-10">
            <div className="filter-title">
              <div
                className={
                  isHiddenApporval ? "bx-inp-control open" : "bx-inp-control"
                }
                onClick={() => setIsHiddenApporval(!isHiddenApporval)}
              >
                {isHiddenApporval ? (
                  <UnfoldMoreRoundedIcon />
                ) : (
                  <UnfoldLessRoundedIcon />
                )}
                <span className="txt-title">Approval Line</span>
              </div>
            </div>

            <div style={isHiddenApporval ? {} : { display: approvalLineView ? '' : 'none' }}>

              <div className="table-area-wrapper">
                <div className="tb-content">
                  <div className="bx-table">
                    <FlexGrid
                      columnGroups={gridColGroupApprv} // 그리드 포멧
                      itemsSource={gridDataApprv} // 바인딩할 데이터
                      initialized={initGridApprv} // 그리드 초기 이벤트 및 등록 처리
                      style={{ minheight: "200px" }}
                    >
                    </FlexGrid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        {approveCommentView &&
          <div className="bx-card-group mt-10">
            <div className="search-wrapper">
              <div className="bx-search2">

                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      Approval / Save / Rejection Comment
                    </label>
                  </div>
                  <div
                    className="bx-inp combo"
                    style={{ paddingLeft: "0px", paddingRight: "0px" }}
                  >
                    <ComTextArea
                      defaultStyle={{
                        border: "unset",
                        resize: "vertical",
                        minHeight: "40px",
                      }}
                      elementId={"entityApproveComment"}
                      ref={entityApproveComment}
                    ></ComTextArea>
                  </div>
                </div>

              </div>
            </div>
          </div>
        }

        <div className="search-wrapper mt-10">
          <div className="bx-search2">

            <div className="bx-item column-3column">
              <div className="div-label">
                <label className="txt-label ml-5">
                  Backup Document <br />Submission By
                </label>
              </div>
              <div className="bx-inp combo inp-mix inp-column">
                {(claimAnalystYn === "Y" || selApprovalStatus === "TO-DO") &&
                  <div style={{ fontSize: "11px" }}>
                    1. Debit Memo &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2. POP/Proof &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3. Analysis &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4. Email/PGM
                  </div>
                }


                <div className="layout-file w-50">
                  <div>
                    {fileListMod &&
                      <Button variant="outlined" style={{ margin: "0px 10px" }} onClick={onClickUploadFile}>
                        {"Find File"}
                      </Button>
                    }
                    <input type="file" onChange={(e) => SampleUtils.checkFile(e, fileList, uploadFileExcute, null, null, null, 300, true)} ref={fileElement} style={{ display: 'none' }} multiple />

                  </div>
                  <div className="file-group">
                    {fileList &&
                      fileList?.filter((file) => (file?.isDeleted ?? false) == false)
                        ?.map((file, index) => (
                          <>
                            <div className="file-list" key={file.serverFileName}>
                              {fileListNoView &&
                                <input className="file-stt" style={{ width: "40px" }} defaultValue={file?.fileNo ?? ''} onChange={(e) => handleFileNoChange(file, e.target.value)} />
                              }
                              <div className="file-name">
                                {!file?.isOld && <p className="file-name">{file.displayFileName}</p>}
                                {file?.isOld && <p className="file-name" style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    file.clickChk = true;
                                    const newLinkClasses = fileList.map((file, i) =>
                                      file.clickChk ? 'file-link-purple' : 'file-link-blue'
                                    );
                                    setLinkClasses(newLinkClasses);

                                    callFileDownload(file, e)
                                  }} >

                                  <span
                                    key={index}
                                    className={linkClasses[index] || 'file-link-blue'}
                                  >
                                    {file.displayFileName}
                                  </span>
                                </p>}

                                {(viewType === "" && userType === "E" && initData?.claimMstInfo?.docType === "ATTACHMENT") && <CloseOutlinedIcon onClick={(e) => removeFile(file, e)} />}
                              </div>
                            </div>
                          </>
                        ))
                    }
                  </div>
                </div>
              </div>
            </div>


            <div className="bx-item ml-10 mb-5" style={{ "maxHeight": "70px" }}>
              <div className="guide-table w-100 h-100">
                <table className="approval-sign">
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: '25%' }}>Asst.</th>
                      <th scope="col" style={{ width: '25%' }}>Mgr.</th>
                      <th scope="col" style={{ width: '25%' }}>Sr.Mgr.</th>
                      <th scope="col" style={{ width: '25%' }}>VP</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="txt-center">{apprvAsstView}</td>
                      <td className="txt-center">{apprvMgrView}</td>
                      <td className="txt-center">{apprvSrMgrView}</td>
                      <td className="txt-center">{apprvVPView}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>


          </div>
        </div>
      </div>


      {discountChart && <DiscountChart
        callbackFuntion={closeDiscountChart}
        isOpen={discountChart}
        randomKey={randomKey.current}
      />
      }

      {openModalDAInvoice && <DAInvoicePop open={openModalDAInvoice} closeFn={() => setOpenModalDAInvoice(false)} billToCode={entityBillTo?.current?.getValue()} rows={gridObject.current?.collectionView?.items} randomKey={randomKey.current} pageInfo={pageInfo} />}
      {openGSRESNUploadPop && <ESNExcelUploadPop open={openGSRESNUploadPop} closeFn={closeESNExcelUploadPopup} claimType={entityClaimType?.current?.getValue()} billToCode={entityBillTo?.current?.getValue()} divisionCode={entityDivision.current?.getValue()} systemEntryNo={systemEntryNoView} openClaimView={handleClaimView} randomKey={randomKey.current} pageInfo={pageInfo} />}
      {openModalClaimRequestPop && <OtherClaimViewPop open={openModalClaimRequestPop} closeFn={() => setOpenModalClaimRequestPop(false)} initData={invoiceUsedInitParam.current} randomKey={randomKey.current} pageInfo={pageInfo} />}
    </div>
  );
}

export default OtherClaimEntryDtl;
