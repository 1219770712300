import { useContext, useEffect, useRef, useState } from 'react';


import { ApiManager } from 'common/lib/ApiUtil';
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import "assets/styles/pages/ModalCommon.scss";

import { ComUtils } from "common/lib/ComUtils";
import { ComNoticfication } from 'common/components/ComNoticfication';
import { MessageContext } from "common/lib/MessageProvider";
import Divider from "@mui/material/Divider";
import ComTextArea from "common/components/ComTextArea";

import * as wjInput from "@grapecity/wijmo.react.input";


function ExcelUploadResultPop({ open, closeFn, resMessage }) {


  const entitySerialText = useRef(null);

  const positionTopValue = useRef();
  const popComponent = useRef();
  const [popuView, setPopuView] = useState(true);
  const [resultMsg, setResultMsg] = useState(null);

  const { messageObject } = useContext(MessageContext);

  //Memory
  const _isMounted = useRef(true);


  // 데이터 로딩 초기화
  useEffect(() => {
    let abortController = new AbortController();

    const getPageInfo = async () => {

      setResultMsg(resMessage);
      
    }
    getPageInfo();

    return () => { _isMounted.current = false; abortController.abort(); }

  }, [open]);



  const onCancleClick = () => {
    popComponent.current.hide();
    setPopuView(false);
    closeFn(null, null);
  };

  const initForm = (s) => {
    popComponent.current = s;
    popComponent.current.show(true, (sender) => {
      closeFn(null, null);
    });
  }


  let top = 0;
  const shown = (p) => {
    top = p.hostElement.style.top;
  }
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  }

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 17)), window.scrollX);
    bnd.top = Math.max(Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height), window.scrollY);
  }

  const positionChanged = (s, e) => {
    //console.log(e);
    top = s.hostElement.style.top
  }

  const [customWidths, setCustomWidths] = useState([{ size: 200, minSize: 100, resize: "dynamic" }, { minSize: 100, resize: "dynamic" }]);
  const handlePanelUpdate = (widths) => { setCustomWidths(widths) };

  return (

    <wjInput.Popup
      className="pop-resizable_small"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      positionChanging={popupPositionChanging}
      positionChanged={positionChanged}
      shown={shown}
      sizeChanged={sizeChanged}
      style={{ maxWidth: "1200px", maxHeight: "800px" }}
    >
      <div className="wj-dialog-header">
        <h2 className="pop-title">{"Error"}</h2>
        <Button color="secondary" className="btn-close" onClick={onCancleClick}>
          <CloseIcon />
        </Button>
      </div>
      <div className="content-modal" style={{ minHeight: "95px", overflowY: "auto" }}>
        <Divider component="li" />
        <div className="bx-table mt-10">
          <div className="bx-inp" style={{ whiteSpace: "pre-line" }}>
            {resultMsg}
          </div>
        </div>
      </div>
      <div className="wj-dialog-footer">
        <Button variant="outlined" onClick={onCancleClick} style={{ border: "1px solid black", color: "black" }}>
          {"Close"}
        </Button>
      </div>
    </wjInput.Popup >
  );
}

export default ExcelUploadResultPop;
