import React, { useEffect, useRef } from 'react';
import { ClmWindowStyle } from "../Rsf/ClmWindowStyle";


function IrDateSoldValidationInfo({ claimList, claimId, billTo, serverUrl }) {
  /*******Grid Format *********************************************************************************/
  //Memory
  const _isMounted = useRef(true);
  /**********************************  Page Load 설정 **********************************/

  // 데이터 로딩 초기화
  useEffect(() => {
    let abortController = new AbortController();

    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, []);


  const getColor = (verificationResult) => {
    let currColor = { background: '' };
    if ((verificationResult ?? '') === "Overlapped Claim") {
      currColor = { background: '#F6F6F6' };
    }
    currColor = { ...ClmWindowStyle.tdStyle, ...currColor };

    return { currColor };
  };

  return (
    <div style={ClmWindowStyle.printWrapper}>
      <div style={ClmWindowStyle.sectionWrapper}>
        <div style={ClmWindowStyle.headerTable}>
          <span style={ClmWindowStyle.sectionTitle}>
            <h3 style={ClmWindowStyle.h3Style}>{"IR Date Sold Validation Result Total : "} <strong style={ClmWindowStyle.txtNum}>{claimList?.length?.toLocaleString()}</strong> </h3>
          </span>
          <div style={{ padding: "10px 5px 0px" }}>
            <button style={ClmWindowStyle.btnStyle} id="exelExport">Excel Export</button>
          </div>
        </div>
        <input type="hidden" name="claimId" id="claimId" value={claimId} />
        <input type="hidden" name="billTo" id="billTo" value={billTo} />
        <input type="hidden" name="serverUrl" id="serverUrl" value={serverUrl} />

        <div style={{ marginTop: "5px" }}>
          <div style={{ marginTop: "10px" }}>
            <table style={{ ...ClmWindowStyle.tableStyle, userSelect: "text" }}>
              <thead>
                <tr>
                  <th style={{ ...ClmWindowStyle.thStyle, width: "100px" }} scope="col" >&nbsp;</th>
                  <th style={{ ...ClmWindowStyle.thStyle, width: "80px" }} scope="col" >Claim ID</th>
                  <th style={{ ...ClmWindowStyle.thStyle, width: "80px" }} scope="col" >Claim Date</th>
                  <th style={{ ...ClmWindowStyle.thStyle }} scope="col" >Program Id</th>
                  <th style={{ ...ClmWindowStyle.thStyle, width: "150px" }} scope="col" >Program Period</th>
                  <th style={{ ...ClmWindowStyle.thStyle }} scope="col" >Model</th>
                  <th style={{ ...ClmWindowStyle.thStyle, width: "90px" }} scope="col" >Date Sold From</th>
                  <th style={{ ...ClmWindowStyle.thStyle, width: "90px" }} scope="col" >Date Sold To</th>
                  <th style={{ ...ClmWindowStyle.thStyle, width: "170px" }} scope="col" >Debit Memo</th>
                </tr>
              </thead>
              <tbody>
                {claimList.map((item, i) => {
                  const { currColor } = getColor(item.verificationResult);
                  return (
                    <React.Fragment key={i}>
                      {i > 0 && (item.verificationResult === "" || item.verificationResult == null) && (
                        <tr>
                          <td height="22" colSpan="23"></td>
                        </tr>
                      )}
                      <tr>
                        <td height="25" style={currColor}>{item.verificationResult}</td>
                        {
                          item.verificationResult === "Current Claim" && (
                            <td height="22" style={{ ...currColor, textAlign: "center" }}>{item.claimId}</td>
                          )
                        }
                        {
                          item.verificationResult !== "Current Claim" && (
                            <td height="22" className="claimClick"
                              claim-id={`${item.claimId}`}
                              style={{ ...currColor, cursor: "pointer", width: "fit-content", textAlign: "center", textDecoration: "underline" }}
                            >
                              {item.claimId}
                            </td>
                          )
                        }
                        <td height="25" style={{ ...currColor, textAlign: "center" }}>{item.createDate}</td>
                        <td height="22" style={{ ...currColor, textAlign: "center" }}>{item.programId}</td>
                        <td height="25" style={{ ...currColor, textAlign: "center" }}>{item.periodDate}</td>
                        <td height="22" style={{ ...currColor, textAlign: "center" }}>{item.modelCode}</td>
                        <td height="22" style={{ ...currColor, textAlign: "center" }}>{item.dateSoldFrom}</td>
                        <td height="22" style={{ ...currColor, textAlign: "center" }}>{item.dateSoldTo}</td>
                        <td height="22" style={{ ...currColor, textAlign: "left" }}>{item.debitMemo}</td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IrDateSoldValidationInfo;
