import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';

//redux
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { createSelector } from "reselect";
import { registerUser, registerUserProceeding, registerUserSuccessful, registerUserFailed } from 'store/auth/register/reducer';

import "assets/styles/pages/SignUpPage.scss";

import { Button, Divider } from '@mui/material';
import Grid from '@mui/material/Grid';

import { MessageContext } from "common/lib/MessageProvider";
import { ComNoticfication } from "common/components/ComNoticfication";

import ComTextBox from "common/components/ComTextBoxWithForm";
import ComPassword from "common/components/ComPassword";

import { ApiManager } from 'common/lib/ApiUtil';
import { ComUtils } from "common/lib/ComUtils";

const LoginInforSpiffPage = React.forwardRef(
    ({ }
        , ref
    ) => {

        // 메세지 추가
        // const { messageObject } = useContext(MessageContext);
        // const savedMessage = ComUtils.getMessage(messageObject, "SMSG_COM_INF_004");
        // const errorMessage = ComUtils.getMessage(messageObject, "JMSG_COM_ERR_001");

        const retrieveCheckUserIdUrl = "/init/b2buser/retrieveCheckUserId";
        const idRegex = /^[A-Za-z0-9_@.-]{6,30}$/;
        const pwRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,20}$/; //최소 8자, 최소 하나의 문자, 하나의 숫자 및 하나의 특수 문자 

        const dispatch = useDispatch();

        const condUserId = useRef(null);
        const entityPassword = useRef(null);
        const entityRePassword = useRef(null);

        const AccountProperties = createSelector(
            (state) => state.account,
            (account) => ({
                user: account.user
            })
        );
        const { user } = useSelector(AccountProperties, shallowEqual);

        React.useImperativeHandle(ref, () => ({
            isEmptyRequired: () => {
                if (!user.userId) {
                    ComNoticfication.ShowAlertUICallback(
                        ComUtils._altTitleWarn, "User ID is required."
                        , () => { condUserId.current.setFocus(); });
                }
                else if (!user.userIdCheck) {
                    ComNoticfication.ShowAlertUICallback(
                        ComUtils._altTitleWarn, "Please check User ID by clicking 'ID Check' button."
                        , () => { condUserId.current.setFocus(); });
                }
                else if (!user.password || user.password === "lgsp2_us") {
                    ComNoticfication.ShowAlertUICallback(
                        ComUtils._altTitleWarn, "Password is required."
                        , () => { entityPassword.current.setFocus(); });
                }
                else if (!user.repassword) {
                    ComNoticfication.ShowAlertUICallback(
                        ComUtils._altTitleWarn, "Re-enter Password is required."
                        , () => { entityRePassword.current.setFocus(); });
                }
                else { }
            }
        }));

        // ID 중복체크
        const onCheckId = async () => {

            let tmpUserId = condUserId.current.getValue();
            if (tmpUserId) {
                if (tmpUserId.match(idRegex)) {
                    // if (idRegex.test(value)) {
                    const [checkUserIdCnt] = await Promise.all([
                        ApiManager.post(retrieveCheckUserIdUrl, { "userId": tmpUserId }),
                    ]);

                    if (checkUserIdCnt && checkUserIdCnt.length > 0) {
                        if (checkUserIdCnt[0].userCnt === 0) {
                            user.userId = tmpUserId;
                            user.userIdCheck = true;
                            dispatch(registerUserProceeding(user));
                            ComNoticfication.ShowAlertUI(ComUtils._altTitleInfo, `You can use ${tmpUserId} as your ID.`);
                        } else {
                            user.userId = "";
                            user.userIdCheck = false;
                            dispatch(registerUserProceeding(user));
                            ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, `${tmpUserId} is already registered in the system.`);
                        }
                    }
                } else {
                    ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, `Please check User ID.\n\nAllowed Types : \nCapital letters (26), Small letters (26), Numbers (10), \nSpecial Characters (_@.-  Underscore, Ampersat, Period, Hiphen)`);
                }
            } else {
                ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, `Please check User ID.\n\nAllowed Types : \nCapital letters (26), Small letters (26), Numbers (10), \nSpecial Characters (_@.-  Underscore, Ampersat, Period, Hiphen)`);
            }

        }

        const onKeyPress = (e) => {
            // Enter를 눌렀을 때 이벤트
            if (e.key == "Enter") {
                onCheckId();
            }
        };

        const onBlurId = (id, value) => {
            if (value) {
                if (!value.match(idRegex)) {
                    ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, `Please check User ID.\n\nAllowed Types : \nCapital letters (26), Small letters (26), Numbers (10), \nSpecial Characters (_@.-  Underscore, Ampersat, Period, Hiphen)`);
                } else if (user.userIdCheck && user.userId !== value) {
                    user.userId = value;
                    user.userIdCheck = false;
                    dispatch(registerUserProceeding(user));
                    ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, `Please check User ID by clicking 'ID Check' button.`);
                } else {
                    user.userId = value;
                    dispatch(registerUserProceeding(user));
                }
            }
        };

        const onBlurPassword = (id, value) => {
            // if (value.match(pwRegex)) {
            if (pwRegex.test(value)) {
                user.password = value;
                dispatch(registerUserProceeding(user));
            } else {
                if (value) {
                    let msg = "Your password must be between 8 and 20 characters long, must contain\r"
                    msg += "special characters, and must include at least two of the three options below.\r[Uppercase letters, lowercase letters, numbers]";
                    ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, msg);
                    entityPassword.current.setValue("");
                }
            }
        };
        const onBlurRePassword = (id, value) => {
            if (pwRegex.test(value)) {
                if (user.password === value) {
                    user.repassword = value;
                    dispatch(registerUserProceeding(user))
                } else {
                    ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, "Please check that your password match and try again.");
                    entityRePassword.current.setValue("");
                }
            } else {
                if (value) {
                    ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, "Please check that your password match and try again.")
                    entityRePassword.current.setValue("");
                }
            }
        };

        const _isMounted = useRef(true);
        useEffect(() => {
            let abortController = new AbortController();

            const getPageInfo = async () => {
                condUserId.current.setValue(user.userId);
                //보안 이슈 처리
                if (user.password && user.password !== "lgsp2_us") {
                    entityPassword.current.setValue(user.password);
                    entityRePassword.current.setValue(user.repassword);
                }
            }

            getPageInfo();

            // ComponentWillUnmount in Class Component
            return () => {
                _isMounted.current = false;
                abortController.abort();
            };
        }, [user]);

        return (
            <div className="content-signup">
                <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    className="mb-custombtn-flex"
                >
                    <Grid>
                        <p>
                            User ID <span className="required">*</span>
                        </p>
                        <div className="d-flex">
                            <ComTextBox ref={condUserId} callRelationBlur={onBlurId} keyPressFuction={onKeyPress} defaultMaxLength={16} />
                        </div>
                        <span className="hint-text">Please enter 6 to 16 characters.</span>
                    </Grid>
                    <Grid className="custom-mb-btn-check">
                        <div className="btn-verify">
                            <Button variant="contained" onClick={onCheckId}>{"ID Check"}</Button>
                        </div>
                    </Grid>
                </Grid>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid className="ip-number">
                        <div className='item-input3'>
                            <p>
                                Password <span className="required">*</span>
                            </p>
                            <ComPassword ref={entityPassword} elementId={"entityPassword"} callRelationBlur={onBlurPassword} defaultMaxLength={100} />
                            <span className="hint-text">
                                Your password must be between 8 and 20 characters long, must contain
                                special characters, and must include at least two of the three options below. [Uppercase letters, lowercase letters, numbers]
                            </span>
                        </div>
                    </Grid>
                    <Grid className="ip-number mb-inp-custom-pw">
                        <p>
                            Re-enter Password <span className="required">*</span>
                        </p>
                        <ComPassword ref={entityRePassword} elementId={"entityRePassword"} callRelationBlur={onBlurRePassword} defaultMaxLength={100} />
                    </Grid>
                </Grid>
                <br />
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid>
                        <p>SPIFF Payment Method</p>
                        <ComTextBox placeholder='Debit card' defaultDisabled={true} />
                    </Grid>
                    <Grid></Grid>
                </Grid>
            </div>

        )
    }
);
export default LoginInforSpiffPage;