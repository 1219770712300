import { ClmWindowStyle } from "../Rsf/ClmWindowStyle";
import { ComUtils } from "common/lib/ComUtils";
import moment from 'moment/moment';
function IrConfDtlList({ pageInfo,
  claimId,
  billToName,
  customerInfo,
  claimList,
  selApprovalStatus,
  fileList,
  userId,
  empNo,
  serverUrl
}) {


  return (
    <div style={ClmWindowStyle.printWrapper}>
      <div style={ClmWindowStyle.scmSectionWrapper}>
        <div style={ClmWindowStyle.scmFilterTitle}>

          <div>
            <span style={ClmWindowStyle.scmTxtTitle}>
              Customer Information
            </span>
          </div>

          <div style={{
            ...ClmWindowStyle.scmActionHeader,
            display: customerInfo.claimEntryType != "WEEKLY" ?
              ((customerInfo.postAuditCustomer === "Y" || customerInfo.postAuditClaim === "Y") ? 'flex' : 'none') : 'none'
          }}>
            <div>
              <span style={ClmWindowStyle.scmFieldRequired}>*</span>This is Post Audit Claim
            </div>
            <label>
              <input
                type="radio"
                name="postAuditClaim"
                value="Y"
                checked={customerInfo.postAuditClaim === 'Y'}
              />
              Yes
            </label>

            <label>
              <input
                type="radio"
                name="postAuditClaim"
                value="N"
                checked={customerInfo.postAuditClaim === 'N'}
              />
              No
            </label>

            <div style={ClmWindowStyle.scmCustomDate}>
              <span style={ClmWindowStyle.scmFieldRequired}>*</span>Activity Date
              <div>
                <input type='text' name='activityDateFrom' id="activityDateFrom" value="" />
                ~ &nbsp;
                <input type='text' name='activityDateTo' id="activityDateTo" value="" />
              </div>

            </div>
          </div>
        </div>
        <div style={{ marginTop: "5px" }}>
          <div style={ClmWindowStyle.scmBxSearch2FixedColumn4}>
            <div style={ClmWindowStyle.scmBxItem}>
              <div style={ClmWindowStyle.scmDivLabel}>
                <label style={ClmWindowStyle.scmTxtLabel}> Claim Number </label>
              </div>
              <div style={ClmWindowStyle.scmBxInput}>
                {claimId}
              </div>
            </div>
            <div style={ClmWindowStyle.scmBxItem}>
              <div style={ClmWindowStyle.scmDivLabel}>
                <label style={ClmWindowStyle.scmTxtLabel}> Bill To Code </label>
              </div>
              <div style={ClmWindowStyle.scmBxInput}>
                {customerInfo.billTo}
              </div>
            </div>
            <div style={ClmWindowStyle.scmBxItem}>
              <div style={ClmWindowStyle.scmDivLabel}>
                <label style={ClmWindowStyle.scmTxtLabel}>Bill To Name</label>
              </div>
              <div style={ClmWindowStyle.scmBxInput}>
                {billToName}
              </div>
            </div>
            <div style={ClmWindowStyle.scmBxItem}>
              <div style={ClmWindowStyle.scmDivLabel}>
                <label style={ClmWindowStyle.scmTxtLabel}>Promotion No</label>
              </div>
              <div style={ClmWindowStyle.scmBxInput}>
                {customerInfo.promotionNo}
              </div>
            </div>
            <div style={ClmWindowStyle.scmBxItem}>
              <div style={ClmWindowStyle.scmDivLabel}>
                <label style={ClmWindowStyle.scmTxtLabel}>Promotion ID</label>
              </div>
              <div style={ClmWindowStyle.scmBxInput}>
                {customerInfo.programId}
              </div>
            </div>
            <div style={ClmWindowStyle.scmBxItem}>
              <div style={ClmWindowStyle.scmDivLabel}>
                <label style={ClmWindowStyle.scmTxtLabel}>LG Salesperson</label>
              </div>
              <div style={ClmWindowStyle.scmBxInput}>
                {customerInfo.salesmanName}
              </div>
            </div>
            <div style={ClmWindowStyle.scmBxItem}>
              <div style={ClmWindowStyle.scmDivLabel}>
                <label style={ClmWindowStyle.scmTxtLabel}>Debit Memo</label>
              </div>
              <div style={ClmWindowStyle.scmBxInput}>
                {customerInfo.debitMemo}
              </div>
            </div>
            <div style={ClmWindowStyle.scmBxItem}>
              <div style={ClmWindowStyle.scmDivLabel}>
                <label style={ClmWindowStyle.scmTxtLabel}>Total Debit Amt.</label>
              </div>
              <div style={ClmWindowStyle.scmBxInput}>
                $ {(customerInfo?.debitMemoAmount ?? 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </div>
            </div>
          </div>
          <div style={ClmWindowStyle.scmBxSearch2FixedColumn1}>
            <div style={ClmWindowStyle.scmBxItem}>
              <div style={ClmWindowStyle.scmDivLabel}>
                <label style={ClmWindowStyle.scmTxtLabel}> Remark </label>
              </div>
              <div style={{ ...ClmWindowStyle.scmBxInput, paddingRight: "0px" }}>
                <textarea
                  style={{
                    border: "unset",
                    resize: "vertical",
                    minHeight: "40px",
                    width: "100%",
                    fontFamily: "Arial"
                  }}
                  readOnly={true}
                  value={customerInfo.remark}
                  id="comment"

                />
              </div>
            </div>
            <div style={ClmWindowStyle.scmBxItem}>
              <div style={ClmWindowStyle.scmDivLabel}>
                <label style={ClmWindowStyle.scmTxtLabel}> LG Claim Remark </label>
              </div>
              <div style={{ ...ClmWindowStyle.scmBxInput, paddingRight: "0px" }}>
                <textarea
                  style={{
                    border: "unset",
                    resize: "vertical",
                    minHeight: "40px",
                    width: "100%",
                    fontFamily: "Arial"
                  }}
                  readOnly={true}
                  value={customerInfo.lgComment}
                  id="lgComment"
                />
              </div>
            </div>
          </div>

          <div style={ClmWindowStyle.scmBxSearch2FixedColumn1}>
            <div style={ClmWindowStyle.scmBxItem}>
              <div style={ClmWindowStyle.scmDivLabel}>
                <label style={ClmWindowStyle.scmTxtLabel}> Approver Review </label>
              </div>
              <div style={{ ...ClmWindowStyle.scmBxInput, paddingRight: "0px" }}>
                <textarea
                  style={{
                    border: "unset",
                    resize: "vertical",
                    minHeight: "40px",
                    width: "100%",
                    fontFamily: "Arial"
                  }}
                  readOnly={true}
                  value={customerInfo.review}
                  id="approveComment"
                />
                <input type="hidden" name="serverUrl" id="serverUrl" value={serverUrl} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={ClmWindowStyle.scmSectionWrapper}>
        <div style={ClmWindowStyle.scmHeaderTable}>
          <span style={ClmWindowStyle.scmSectionTitle}>
            <h3 style={ClmWindowStyle.scmH3Style}>{"IR Claim Detail List"}</h3>
          </span>
        </div>

        <div style={{ marginTop: "5px" }}>
          <div style={{ marginTop: "10px", overflowX: "auto" }}>
            <table style={{ width: "100px", borderCollapse: "collapse", tableLayout: "fixed" }}>
              <thead>
                <tr>
                  <th style={{ ...ClmWindowStyle.scmThStyle, width: "130px" }} scope="col" rowspan="2">Program Period</th>
                  <th style={{ ...ClmWindowStyle.scmThStyle, width: "140px" }} scope="col" rowspan="2">Model</th>
                  <th style={{ ...ClmWindowStyle.scmThStyle, width: "65px" }} scope="col" rowspan="2">Rebate Amount</th>
                  <th style={{ ...ClmWindowStyle.scmThStyle, width: "90px" }} scope="col" rowspan="2">LG Unit Reimbursement</th>
                  <th style={{ ...ClmWindowStyle.scmThStyle, width: "60px" }} scope="col" rowspan="2">Claim QTY</th>
                  <th style={{ ...ClmWindowStyle.scmThStyle, width: "65px" }} scope="col" rowspan="2">Approve QTY</th>
                  <th style={{ ...ClmWindowStyle.scmThStyle, width: "55px" }} scope="col" rowspan="2">Reject QTY</th>
                  <th style={{ ...ClmWindowStyle.scmThStyle, width: "65px" }} scope="col" rowspan="2">Approve AMT</th>
                  <th style={{ ...ClmWindowStyle.scmThStyle, width: "80px" }} scope="col" rowspan="2">Reject Reason</th>
                  <th style={{ ...ClmWindowStyle.scmThStyle, width: "100px" }} scope="col" rowspan="2">Reject Comment</th>
                  <th style={{ ...ClmWindowStyle.scmThStyle, width: "80px" }} scope="col" rowspan="2">Status</th>
                  <th style={{ ...ClmWindowStyle.scmThStyle, width: "90px" }} scope="col" rowspan="2">Net Sell-In<br />QTY</th>
                  <th style={{ ...ClmWindowStyle.scmThStyle, width: "90px" }} scope="col" rowspan="2">Net Approved<br />QTY</th>
                  <th style={{ ...ClmWindowStyle.scmThStyle, width: "90px" }} scope="col" rowspan="2">Net Remaining<br />QTY</th>
                  <th style={{ ...ClmWindowStyle.scmThStyle, width: "90px" }} scope="col" rowspan="2">Net Approved<br />QTY(TC)</th>
                  <th style={{ ...ClmWindowStyle.scmThStyle, width: "280px" }} scope="col" colspan="2">Claim Sell Out</th>
                  <th style={{ ...ClmWindowStyle.scmThStyle, width: "120px" }} scope="col" rowspan="2">SCM Sell Thru<br />Start Date - End Date</th>
                  <th style={{ ...ClmWindowStyle.scmThStyle, width: "90px" }} scope="col" rowspan="2">SCM Sell Thru<br />Week</th>
                  <th style={{ ...ClmWindowStyle.scmThStyle, width: "90px" }} scope="col" rowspan="2">
                    Sell Thru<br />QTY  {customerInfo.netScmFrom === "OLAP" && customerInfo.status === "HOLD" ? "(OLAP)" : "(GDMI)"}
                  </th>
                  {(customerInfo.status === "HOLD" && (customerInfo.netScmCheck ?? "") !== "" && (customerInfo.netScmFrom ?? "") === "OLAP") && <th style={{ ...ClmWindowStyle.scmThStyle, width: "90px" }} scope="col" rowspan="2">Sell Thru<br />QTY(GDMI)</th>}
                  {(customerInfo.status === "HOLD" && (customerInfo.netScmCheck ?? "") !== "" && (customerInfo.netScmFrom ?? "") !== "OLAP") && <th style={{ ...ClmWindowStyle.scmThStyle, width: "90px" }} scope="col" rowspan="2">Sell Thru<br />QTY(OLAP)</th>}
                  <th style={{ ...ClmWindowStyle.scmThStyle, width: "90px" }} scope="col" rowspan="2">Claimed<br />QTY</th>
                  <th style={{ ...ClmWindowStyle.scmThStyle, width: "100px" }} scope="col" rowspan="2">Net SCM<br />Remaining<br />QTY</th>
                  <th style={{ ...ClmWindowStyle.scmThStyle, width: "120px" }} scope="col" rowspan="2">Sold Date<br />Overlapped</th>
                  <th style={{ ...ClmWindowStyle.scmThStyle, width: "100px" }} scope="col" rowspan="2">Cust. unit Reimburse</th>
                  <th style={{ ...ClmWindowStyle.scmThStyle, width: "100px" }} scope="col" rowspan="2">Over CM Amt.</th>
                </tr>
                <tr>
                  <th style={{ ...ClmWindowStyle.scmThStyle, width: "140px" }} scope="col">Start Date</th>
                  <th style={{ ...ClmWindowStyle.scmThStyle, width: "140px" }} scope="col">End Date</th>
                </tr>
              </thead>
              <tbody>
                {
                  claimList?.map((fn, index) => (
                    <tr key={index}>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "center", width: "130px" }} >{fn.period}</td>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "left", width: "140px" }} >{fn.model}</td>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }} >$ {(fn.spiffIrAmount ?? 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>  {/* Rebate Amount */}
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }} >$ {(fn.reimbursementAmount ?? 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td> {/* LG Unit Reimbursement */}
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }} >{(fn.claimQty ?? 0).toLocaleString()}</td>  {/* Claim QTY */}
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>{(fn.arConfQty ?? 0).toLocaleString()}</td> {/* Approve QTY */}
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}> {(fn.claimQty - fn.arConfQty ?? 0).toLocaleString()}</td> {/* Reject QTY */}
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }} >$ {(fn.reimburAmount ?? 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td> {/* Approve AMT */}
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "center" }} >{fn.rejectCode}</td> {/* Reject Reason */}
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "left" }} >
                        {fn.status !== "HOLD" ? (fn.rejectCount == 1 ? ("1st :" + fn.rejectComment) : (fn.rejectCount == 2 ? ("2nd :" + fn.rejectComment) : fn.rejectComment)) : fn.rejectComment} {/* Reject Comment */}
                      </td>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "center" }} >{fn.status}</td> {/* Status */}
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>{(fn.netSellInQty ?? 0).toLocaleString()}</td> {/* Net Sell-In<br />QTY */}
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "center" }} >
                        {(fn.netAprvQty ?? 0) == 0 ? "" : (fn.netAprvQty ?? 0).toLocaleString() + " APRV"}<br />{(fn.netPendQty ?? 0) == 0 ? "" : (fn.netPendQty ?? 0).toLocaleString() + " HOLD"}  {/* Net Approved<br />QTY */}
                      </td>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right", color: Number(fn.claimQty ?? 0) > Number(fn.allowedQty ?? 0) ? 'red' : '' }}>
                        {(fn.allowedQty ?? 0).toLocaleString()}  {/* Net Remaining<br />QTY */}
                      </td>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>
                        {fn.status !== "HOLD" ? 0 : ((fn.netAprvAdhocQty ?? 0).toLocaleString())} {/* Net Approved<br />QTY(TC) */}

                      </td>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "center" }} >{fn.dateSoldFrom && moment(ComUtils.DateToInteger(fn.dateSoldFrom) + "", "YYYYMMDD").format("MM/DD/YYYY")}</td> {/* Start Date */}
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "center" }} >{fn.dateSoldTo && moment(ComUtils.DateToInteger(fn.dateSoldTo) + "", "YYYYMMDD").format("MM/DD/YYYY")}</td> {/* End Date */}
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "center" }} >{fn.dateRange}</td> {/* SCM Sell Thru Start Date - End Date */}
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "center" }} >{fn.weekNo && (fn.weekNo + "Week")}</td> {/* SCM Sell Thru Week */}
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>
                        {fn.status === "HOLD" ? Number(fn.netSellOut ?? 0).toLocaleString() : Number(fn.netScmSellThru ?? 0).toLocaleString()} {/* Sell Thru QTY (GDMI) */}
                      </td>

                      {(customerInfo.status === "HOLD" && (customerInfo.netScmCheck ?? "") !== "" && (customerInfo.netScmFrom ?? "") === "OLAP") && <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>{(fn.netSellOutGDMI == undefined ? 0 : fn.netSellOutGDMI ?? 0).toLocaleString()}</td>}
                      {(customerInfo.status === "HOLD" && (customerInfo.netScmCheck ?? "") !== "" && (customerInfo.netScmFrom ?? "") !== "OLAP") && <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>{(fn.netSellOutGDMI == undefined ? 0 : fn.netSellOutOLAP ?? 0).toLocaleString()}</td>}
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>
                        {/* Claimed QTY */}
                        {fn.status === "HOLD" ? ((fn.netScmConf == null || fn.netScmConf == "" || fn.netScmConf == "0") ? 0 : Number(fn.netScmConf ?? 0).toLocaleString()) : Number(fn.netScmSellThruClaim ?? 0).toLocaleString()}
                      </td>
                      <td style={{
                        ...ClmWindowStyle.scmTdStyle, textAlign: "right", color: (fn.netScmCheck === "Y" && fn.netScmAllowedQty != "" && Number(fn.netScmAllowedQty ?? 0) < 0) ? 'red' : ''
                      }}>{(fn.netScmAllowedQty ?? 0).toLocaleString()}</td> {/* Net SCM\rRemaining QTY */}

                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "center" }} >
                        {fn.dateOverlap && <select name="dataOverlap" id="dataOverlap" >
                          <option key={-1} value={""}>
                            {"----- CHOICE-----"}
                          </option>
                          {
                            fn.dateOverlap.split(',').map((option, index) => (
                              <option key={index} value={option}>
                                {option.trim()}
                              </option>
                            ))}
                        </select>}
                      </td>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>{Number(fn.custUnitReimburse ?? 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>$ {Number(fn.overCmAmt ?? 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                    </tr>
                  ))
                }
                <tr>
                  <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }} colSpan="4">Total</td>
                  <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>{claimList.reduce((sum, claim) => sum + Number(claim.claimQty ?? 0), 0).toLocaleString()}</td>
                  <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>{claimList.reduce((sum, claim) => sum + Number(claim.arConfQty ?? 0), 0).toLocaleString()}</td>
                  <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>{claimList.reduce((sum, claim) => sum + (Number(claim.claimQty ?? 0) - Number(claim.arConfQty ?? 0)), 0).toLocaleString()}</td>
                  <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>$ {claimList.reduce((sum, claim) => sum + (Number(claim.reimburAmount ?? 0)), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                  <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }} colSpan="11"></td>
                  <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>{claimList.reduce((sum, claim) => sum + (claim.status === "HOLD" ? Number(claim.netSellOut ?? 0) : Number(claim.netScmSellThru ?? 0)), 0).toLocaleString()}</td>
                  {(customerInfo.status === "HOLD" && (customerInfo.netScmCheck ?? "") !== "" && (customerInfo.netScmFrom ?? "") === "OLAP") && <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>{claimList.reduce((sum, claim) => sum + Number(claim.netSellOutGDMI == undefined ? 0 : claim.netSellOutGDMI ?? 0), 0).toLocaleString()}</td>}
                  {(customerInfo.status === "HOLD" && (customerInfo.netScmCheck ?? "") !== "" && (customerInfo.netScmFrom ?? "") !== "OLAP") && <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>{claimList.reduce((sum, claim) => sum + Number(claim.netSellOutOLAP == undefined ? 0 : claim.netSellOutOLAP ?? 0), 0).toLocaleString()}</td>}
                  <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>{claimList.reduce((sum, claim) => sum + (claim.status === "HOLD" ? ((claim.netScmConf == null || claim.netScmConf == "" || claim.netScmConf == "0") ? 0 : Number(claim.netScmConf ?? 0)) : Number(claim.netScmSellThruClaim ?? 0)), 0).toLocaleString()}</td>
                  <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>{claimList.reduce((sum, claim) => sum + Number(claim.netScmAllowedQty ?? 0), 0).toLocaleString()}</td>
                  <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }} colSpan="1"></td>
                  <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>{claimList.reduce((sum, claim) => sum + ((claim.custUnitReimburse != null && claim.custUnitReimburse != "") && Number(claim.arConfQty ?? 0)), 0).toLocaleString()}</td>
                  <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>$ {claimList.reduce((sum, claim) => sum + (Number(claim.overCmAmt ?? 0)), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div style={ClmWindowStyle.scmSectionWrapper}>
        <div style={ClmWindowStyle.scmHeaderTable}>
          <span style={ClmWindowStyle.scmSectionTitle}>
            <h3 style={ClmWindowStyle.scmH3Style}>{"Attachment"}</h3>
          </span>
        </div>

        <div style={{ marginTop: "5px" }}>
          <div style={{ marginTop: "10px" }}>
            <table style={ClmWindowStyle.scmTableStyle}>
              <thead>
                <tr>

                  <th scope="col" style={{ ...ClmWindowStyle.scmThStyle, width: "100px" }}>No.</th>
                  <th style={ClmWindowStyle.scmThStyle} scope="col">fileName</th>
                </tr>
              </thead>
              <tbody>
                {
                  fileList?.map((fn, index) => (
                    <tr key={index}>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "center" }}>{index + 1}</td>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "left" }}>
                        <p
                          className="fileDown"
                          data-id={`${fn.fileId}`}
                          data-seq={`${fn.fileSeq}`}
                          style={{ cursor: "pointer", width: "fit-content" }}
                        >
                          {fn.originalFileName}
                        </p>
                      </td>

                    </tr>
                  ))
                }

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div >
  );
}

export default IrConfDtlList;
