import { useState, useEffect, useContext, useRef, useCallback } from "react";
import { Button } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import "assets/styles/pages/PagesCommon.scss";

import moment from "moment/moment";
import { ComUtils } from "common/lib/ComUtils";
import { FileUtils } from "common/lib/FileUtils";
import { SampleRequestUtils } from "Pages/Spl/Common/SampleRequestUtils";
import { ComNoticfication } from "common/components/ComNoticfication";
import { SampleUtils } from "Pages/Spl/Common/SampleUtils";
import close from "assets/icons/close.svg";

import ComCombo from "common/components/ComCombo";
import ComComboTable from "common/components/ComComboTable";
import ComTextArea from "common/components/ComTextArea";
import ComTextBoxFind from "common/components/ComTextBoxFind";

import ComTextBox from "common/components/ComTextBox";
import ComDate from "common/components/ComDate";
import ComDateNotRequired from "common/components/ComDateNotRequired";
import ComRadioBox from "common/components/ComRadioBox";

import * as wijmo from "@grapecity/wijmo";
import { CollectionView } from "@grapecity/wijmo";

//import * as wjGrid from '@grapecity/wijmo.grid';
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { openMenu, viewHome, closeMenu } from "reducer/OpenMenus";
import { DataMap, GroupRow, CellType } from "@grapecity/wijmo.grid";
import { FlexGrid } from "@grapecity/wijmo.react.grid";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import GridUserMergeFooter from "common/lib/GridUserMergeFooter";
import { createElement, format } from "@grapecity/wijmo";
// 컬럼
import search from "assets/icons/search.svg";

import { MessageContext } from "common/lib/MessageProvider";

import Breadcrumb from "common/components/ComBreadcrumb";

import { ApiManager } from "common/lib/ApiUtil";
import FollowUpRemark from "./Common/FollowUpRemark";
import ApprovalLine from "./Common/ApprovalLine";

import ARCommentPop from "Pages/Pop/Spl/ARCommentPop";
import AddressInfoPop from "Pages/Pop/Spl/AddressInfoPop";
import OutstandingListPop from "Pages/Pop/Spl/OutstandingListPop";

function SampleRequestApprover({ pageInfo, props, closeFn }) {
  const dispatch = useDispatch();

  const { messageObject } = useContext(MessageContext);

  //info 메시지
  const savedMessage = ComUtils.getMessage(messageObject, "SMSG_COM_INF_004");        //Saved Successfully

  //error 메시지
  const msgSplErr001 = ComUtils.getMessage(messageObject, "SPL_ERR_00001");           //An error occurred during save
  const msgSplErr021 = ComUtils.getMessage(messageObject, "SPL_ERR_00021");           //An error occurred during processing
  const msgSplErr023 = ComUtils.getMessage(messageObject, "SPL_ERR_00023");           //Requestors with outstanding samples from to be returned date will be blocked from new sample request.\n\nPlease contact A/R Dept.


  const userId = sessionStorage.getItem("userId").toString();
  const emailId = sessionStorage.getItem("emailId").toString();
  const comCode = sessionStorage.getItem("comCode").toString();
  const userGroup = sessionStorage.getItem("userGroup").toString();

  //url
  const retrieveSampleRequestSingle =
    "/spl/sampleRequest/retrieveSampleRequestSingle";
  const retrieveShipMethodsCode = "/spl/common/retrieveShipMethodsCode";
  const saveSplFileList = "/spl/sampleRequest/saveSplFileList";

  // popup
  const [randomKey, setRandomKey] = useState(null);
  const [openModalArComment, setOpenModalArComment] = useState(false);
  const [openModalAddressInfo, setOpenModalAddressInfo] = useState(false);
  const [openModalOutstandingList, setOpenModalOutstandingList] =
    useState(false);
  const addressInfoPopupLineId = useRef(null);

  const modelDefaultData = SampleRequestUtils.getDefaultModel();

  const initState = useRef(false);
  const originalId = useRef("");
  const query = useRef(null);

  const [headerId, setHeaderId] = useState("");
  const [city, setCity] = useState("");
  const [outOfCity, setOutOfCity] = useState("");
  const [state, setState] = useState("");
  const [outOfState, setOutOfState] = useState("");
  const [fileList, setFileList] = useState([]);
  const [airFileList, setAirFileList] = useState([]);
  const [apprProps, setApprProps] = useState(null);
  const [followUpProps, setFollowUpProps] = useState(null);

  const [salesOrderNo, setSalesOrderNo] = useState("");
  const [shipMethod, setShipMethod] = useState("");
  const [requestDate, setRequestDate] = useState("");
  const [entryBy, setEntryBy] = useState("");
  const [showInstallation, setShowInstallation] = useState(true);
  const [showFas, setShowFas] = useState(false);
  const [showRerequest, setShowRerequest] = useState(false);
  const [outOfCountry, setOutOfCountry] = useState(false);

  // entities
  const entityDivisionSample = useRef(null);
  const entityDivisionShowroom = useRef(null);
  const entityDivisionAcademy = useRef(null);
  const entityOrderType = useRef(null);
  const entityReason = useRef(null);
  const entityReasonDetail = useRef(null);
  const entityBillToCd = useRef(null);
  const entityBillToNm = useRef(null);
  const entityExpenseDepartment = useRef(null);
  const entityRequestorName = useRef(null);
  const entityShipToCode = useRef(null);
  const entityExpenseAccount = useRef(null);
  const entityRequestorEmail = useRef(null);
  const entityAddress1 = useRef(null);
  const entityExpenseProjectCode = useRef(null);
  const entityB2bProjectCode = useRef(null);
  const entityContactName = useRef(null);
  const entityExpenseAu = useRef(null);
  const entityLiftgate = useRef(null);
  const entityReceiverPhoneNo = useRef(null);
  const entityExpenseActivity = useRef(null);
  const entityWhiteGlove = useRef(null);
  const entityReceiverEmail = useRef(null);
  const entityTobeReturnDate = useRef(null);
  const entityCity = useRef(null);
  const entityState = useRef(null);
  const entityZip = useRef(null);
  const entityOutOfCity = useRef(null);
  const entityOutOfState = useRef(null);
  const entityOutOfZip = useRef(null);
  const entityCountry = useRef(null);
  const entityCustPoNo = useRef(null);
  const entityReceiverName = useRef(null);
  const entityReqArrivalDate = useRef(null);
  const entityDeliveryTime1 = useRef(null);
  const entityDeliveryTime2 = useRef(null);
  const entityPickingRemark = useRef(null);
  const entityPurposeType = useRef(null);
  const entityShipMethodCode = useRef(null);
  const entityDeliveryRemark = useRef(null);
  const entityAirShipmentReason = useRef(null);
  const entityShippingRemark = useRef(null);
  const entityInstallation = useRef(null);
  const entityFaProjectCode = useRef(null);
  const entityPlannedDate = useRef(null);
  const entityLocation = useRef(null);
  const entityFollowUpRemark = useRef(null);
  const fileElement = useRef(null);

  const gridObject = useRef();
  const [gridColGroup, setGridColGroup] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [gridDataCount, setGridDataCount] = useState(0);

  const closeArCommentPopup = (selectedItems) => {
    setOpenModalArComment(false);
  };

  const openAddressInfoPopup = async (row, e) => {
    ComUtils.setComRandomKey(setRandomKey);
    addressInfoPopupLineId.current = row.value; // line id
    setOpenModalAddressInfo(true);
  };

  const closeAddressInfoPopup = async (selectedItems) => {
    setOpenModalAddressInfo(false);
    addressInfoPopupLineId.current = null;
  };

  const openOutstandingListPopup = async () => {
    ComUtils.setComRandomKey(setRandomKey);
    setOpenModalOutstandingList(true);
  };

  const closeOutstandingListPopup = async (selectedItems) => {
    setOpenModalOutstandingList(false);
  };

  const onClickRerequest = async () => {
    // getPageInfo(props.headerId, "copy", props.orderStatusD);

    // TODO: SampleRequest로 탭 이동
    // pageInfo에 props 삽입
    // SampleRequest에서는 pageInfo에도 props가 있는지 확인 후 처리

    // 메뉴 닫고 열기
    let closeMenuInfo = _.cloneDeep({});
    closeMenuInfo["tabUniqueKey"] = pageInfo.menuId + "_Re";
    dispatch(closeMenu(closeMenuInfo));
    document.getElementById("areaInfoObject").style.display = "none";

    setTimeout(() => {

      let newMenuInfo = _.cloneDeep(pageInfo);
      let now = new Date();
      let timeKey =
        now
          .toISOString()
          .replace("T", " ")
          .substring(0, 19)
          .replace(/[^0-9]/g, "") + now.getMilliseconds().toString();
      newMenuInfo["menuName"] = "Sample Request";
      newMenuInfo["tabUniqueKey"] = pageInfo.menuId + "_Re";
      newMenuInfo["timeKey"] = timeKey;
      newMenuInfo["Active"] = true;
      newMenuInfo["copyFlag"] = true;
      newMenuInfo["editFlag"] = true;
      newMenuInfo["fromMenuCode"] = pageInfo.menuId;
      newMenuInfo["menuUrl"] = "Spl/SampleRequest";
      newMenuInfo["props"] = {
        headerId: props.headerId,
        optype: "copy",
        orderStatusD: props.orderStatusD,
      };
      dispatch(openMenu(newMenuInfo));
    });
  };

  const onClickUploadFile = () => {
    fileElement.current.click();
  };

  const removeFile = async (e, fileInfo) => {
    if (fileInfo.isOld) {
      // 기존파일
      setFileList(fileList => fileList.map((file) => file.fileId == fileInfo.fileId ? { ...file, "isDeleted": true } : file));
    } else {
      // 신규 파일
      let response = await ApiManager.post("/Common/File/FileDelete", {
        "filePath": fileInfo.filePath,
        "serverFileName": fileInfo.serverFileName,
        "customPath": "sampleportal"
      });
    
      setFileList(fileList => fileList.filter(f => f.serverFileName !== fileInfo.serverFileName));
    }
  }

  const onSave = async () => {
    let sendParam = {
      headerId: headerId,
      fileList: fileList,
      userId: userId
    };

    let resultData = await ApiManager.post(saveSplFileList, sendParam);

    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgSplErr001);
    } else {
      ComNoticfication.ShowAlertUICallback(
        ComUtils._altTitleInfo, 
        savedMessage,
        () => {
          getPageInfo();
        }
      );
    }
  }

  const initGrid = (sender) => {
    gridObject.current = sender;
    ComUtils.gridInit(sender, {
      selectionMode: 6,
      hasCheckBox: false,
      showCheckAll: false,
      hasDisableCheck: false,
      canAreaInfo: false,
    });
    sender.allowSorting = false;

    // total amount
    sender.columnFooters.rows.push(new GroupRow());
    sender.mergeManager = new GridUserMergeFooter(gridObject.current);
  };

  const gridFormatItem = (s, e) => {
    //let col = s.columns[e.col];
    if (s.columns[e.col].binding === "price") {
      s.columnFooters.setCellData(0, e.col, "Request Total Amt", true, false);
    }
    if (e.panel === s.columnFooters) {
      e.cell.style.justifyContent = "end";
    }
  };

  const initGridFormat = useCallback((subInventoryMap, findAddress) => {
    let gridFormat = [
      {
        binding: "lineNo",
        header: "Line No",
        width: 70,
        isReadOnly: true,
        allowDragging: true,
        align: "center",
      },
      {
        binding: "modelSuffix",
        header: "Model",
        minWidth: 200,
        allowDragging: true,
        isReadOnly: true,
        cellTemplate: (ctx, cell) => {
          cell?.firstChild?.remove();
          let dataItem = ctx.row.dataItem;
          let value = ctx.value ? ctx.value.toUpperCase() : ""; //this._formatChange(ctx.value);

          let rowIndex = ctx.row.index;
          let element = createElement(
            format(
              `<div class="wj-cell-button"><span>${value}</span> <img class="grid_btn_find" src='${search}' /></div>`,
              {}
            ),
            cell
          );
          let buttonElement = element.querySelector("img.grid_btn_find");
          //let cloneCtx = CellMaker._cloneContext(ctx);
          buttonElement.onclick = function (e) {
            e.preventDefault();
            //findModel.apply(e, [{ rowIndex, dataItem, ctx }, e]);
          }; //.bind({ctx});
        },
      },
      {
        binding: "qty",
        header: "Qty",
        width: 80,
        isReadOnly: true,
        allowDragging: true,
        align: "right",
        format: "n0",
      },
      {
        binding: "warehouseCd",
        header: "Warehouse",
        width: 200,
        isReadOnly: true,
        allowDragging: true,
        // isReadOnly: true,
        cellTemplate: (ctx, cell) => {
          cell?.firstChild?.remove();
          let dataItem = ctx.row.dataItem;
          let value = ctx.value; //this._formatChange(ctx.value);

          let rowIndex = ctx.row.index;
          let element = createElement(
            format(
              `<div class="wj-cell-button"><span>${value ?? ""
              }</span> <img class="grid_btn_find" src='${search}' /></div>`,
              {}
            ),
            cell
          );
          let buttonElement = element.querySelector("img.grid_btn_find");
          //let cloneCtx = CellMaker._cloneContext(ctx);
          buttonElement.onclick = function (e) {
            e.preventDefault();
          }; //.bind({ctx});
        },
      },
      {
        binding: "subInventoryCd",
        header: "SubInventory",
        width: 200,
        isReadOnly: true,
        allowDragging: true,
        align: "center",
        dataMap: subInventoryMap,
      },
      {
        binding: "avqty",
        header: "Availability",
        width: "*",
        minWidth: 80,
        allowDragging: true,
        isReadOnly: true,
        align: "right",
        format: "n0",
      },
      {
        binding: "price",
        header: "Unit Price",
        width: "*",
        minWidth: 80,
        allowDragging: true,
        isReadOnly: true,
        align: "right",
        format: "n2",
      },
      {
        binding: "totalAmt",
        header: "Total Amount",
        width: "*",
        minWidth: 80,
        allowDragging: true,
        isReadOnly: true,
        align: "right",
        format: "n2",
        aggregate: "Sum",
      },
      {
        binding: "lineId",
        header: "Address",
        width: 80,
        allowDragging: true,
        isReadOnly: true,
        align: "center",
        cellTemplate: (ctx, cell) => {
          cell?.firstChild?.remove();
          let value = ctx.value;
          let dataItem = ctx.row.dataItem;
          let element = createElement(
            format(
              `<div class="wj-cell-button" style="justify-content: center;"><img class="grid_btn_find" src='${search}' /></div>`,
              {}
            ),
            cell
          );
          let buttonElement = element.querySelector("img.grid_btn_find");
          buttonElement.onclick = function (e) {
            e.preventDefault();
            findAddress.apply(e, [{ value }, e]);
          }; //.bind({ctx});
        },
      },
      {
        binding: "apmsNo",
        header: "APMS Request No.",
        width: "*",
        minWidth: 150,
        isReadOnly: true,
        allowDragging: true,
        align: "right",
        format: "n2",
      },
    ];
    setGridColGroup([...gridFormat]);
  }, []);

  const initGridBindData = useCallback(() => {
    let resultData = [modelDefaultData];
    setGridData(
      new CollectionView(resultData, {
        trackChanges: true,
        refreshOnEdit: false,
      })
    );
    setGridDataCount(resultData.length);
  });

  const getPageInfo = async () => {
    initState.current = false;

    if (props?.epLogin) {
      ComUtils.removeEpLoginSessionItem();
    }

    let sendParam = {
      ...props,
      optype: props?.optype ?? "",
      orderStatusD: props?.orderStatusD ?? "",
      opp: props?.opp ?? "",
    };

    // 1. get info
    const [resultData, resultShipMethodCode] = await Promise.all([
      ApiManager.post(retrieveSampleRequestSingle, sendParam),
      ApiManager.post(retrieveShipMethodsCode, { chkDept: null }),
    ]);

    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(
        ComUtils._altTitleError,
        msgSplErr021
      );
      return;
    }

    query.current = resultData?.query;
    originalId.current = resultData.query.originalId;
    setShowRerequest(resultData.query?.orderCurrentStatus == "rejected");

    // 2. set order info
    SampleRequestUtils.setOrderInfo(
      {
        entityDivisionSample,
        entityDivisionShowroom,
        entityDivisionAcademy,
        entityOrderType,
        entityReason,
        entityReasonDetail,
        entityBillToCd,
        entityBillToNm,
        entityExpenseDepartment,
        entityRequestorName,
        entityShipToCode,
        entityExpenseAccount,
        entityRequestorEmail,
        entityAddress1,
        entityExpenseProjectCode,
        entityB2bProjectCode,
        entityContactName,
        entityExpenseAu,
        entityLiftgate,
        entityReceiverPhoneNo,
        entityExpenseActivity,
        entityWhiteGlove,
        entityReceiverEmail,
        entityTobeReturnDate,
        entityCity,
        entityState,
        entityZip,
        entityCountry,
        entityCustPoNo,
        entityReceiverName,
        entityReqArrivalDate,
        entityDeliveryTime1,
        entityDeliveryTime2,
        entityPickingRemark,
        entityPurposeType,
        entityShipMethodCode,
        entityDeliveryRemark,
        entityAirShipmentReason,
        entityShippingRemark,
        entityInstallation,
        entityFaProjectCode,
        entityPlannedDate,
        entityLocation,
        setHeaderId,
        setRequestDate,
        setEntryBy,
        setFileList,
        setAirFileList,
        setShowFas,
      },
      resultData,
      resultShipMethodCode
    );

    setSalesOrderNo(resultData?.query?.salesOrderNo ?? "");
    setShipMethod(
      resultData?.query?.shipMethodCode === "000001_US-OTHR_A_DOM_SITE"
        ? "Ship_Method_Code:Air"
        : ""
    );

    // 3. set model list
    let mappedModel = [];
    for (let data of resultData.mData) {
      let newModelInfo = _.cloneDeep(modelDefaultData);
      newModelInfo.lineId = data.lineId;
      newModelInfo.lineNo = data.lineNo;
      newModelInfo.modelSuffix = data.modelSuffix;
      newModelInfo.model = data.model;
      newModelInfo.qty = data.reqQty;
      newModelInfo.warehouseCd = data.warehouseCode;
      newModelInfo.avqty = data.remainingQty;
      newModelInfo.price = data.unitSellingPrice;
      newModelInfo.totalAmt = data.totalAmt;
      newModelInfo.apmsNo = data.apmsNo;
      newModelInfo.subInventoryCd = data.subinventoryCode;
      newModelInfo.pmEmpNo = data.pmEmpNo;
      newModelInfo.itemCategory = data.itemCategory;
      mappedModel.push(newModelInfo);
    }
    setGridData(
      new CollectionView(mappedModel, {
        trackChanges: true,
        refreshOnEdit: false,
      })
    );

    // 4. set followUp remark
    setFollowUpProps({
      headerId: resultData.query.headerId,
      remark: resultData.query.followUpRemark,
    });

    // 5. set apprval
    setApprProps({
      actionType: resultData.query.actionType ?? "",
      dealType: "REQUEST",
      targetEmpNo: resultData.query?.targetEmpNo ?? "",
      headerId: resultData.query?.headerId ?? "",
      arApprovalYn: resultData.query?.arApprovalYn ?? "",
      ariEmpNo: resultData.query?.ariEmpNo ?? "",
      isOutstanding: resultData.isOutstanding,
    });

    setFileList(fileList =>
      fileList?.filter((file) => (file?.isDeleted ?? false) == false)
              ?.map((item) => ({
        ...item,
        isOld: true,
      }))
    );

    // open outstanding popup
    if (resultData?.isOutstanding === "Y") {
      ComNoticfication.ShowAlertUICallback(
        ComUtils._altTitleInfo,
        msgSplErr023,
        () => openOutstandingListPopup()
      );
    }

    initState.current = true;
  };

  const _isMounted = useRef(true);
  useEffect(() => {
    let abortController = new AbortController();

    initGridFormat(
      SampleRequestUtils.getModelSubInventory(),
      openAddressInfoPopup
    );
    initGridBindData();

    getPageInfo();

    // ComponentWillUnmount in Class Component
    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, []);

  const [isHiddenSearch, setHiddenSearch] = useState(true);
  return (
    <div className="page-wrap">
      <div className="breadcrumb-header" style={{ display: props?.epLogin ? 'none' : '' }}>
        <Breadcrumb items={pageInfo} />
      </div>
      <div className="page-in-wrap">
        <div className="bx-card-group">
          <div className="filter-title">
            <div>
              <div className="txt-title">
                <h2>
                  GERP SO#{salesOrderNo} {shipMethod}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </h2>
                <div className="bx-contact-group">
                  Request No: {headerId} | Request Date : {requestDate} | Entry
                  by : {entryBy}
                </div>
              </div>
            </div>
            <div className="link-action d-flex">
              {closeFn && (
                <Button variant="outlined" onClick={() => closeFn()}>
                  {"List"}
                </Button>
              )}
              {showRerequest && (
                <Button variant="outlined" onClick={() => onClickRerequest()}>
                  {"Re-request"}
                </Button>
              )}
              {(query?.current?.orderStatus === "WAITINGAPPROVE" || query?.current?.orderStatus === "APPROVING") && (
                <Button variant="contained" onClick={() => onSave()}>
                  {"Save"}
                </Button>
              )}
              <Button
                variant="contained"
                onClick={() => setOpenModalArComment(true)}
              >
                {"AR Comment"}
              </Button>
            </div>
          </div>
          <div
            className="search-wrapper"
            style={isHiddenSearch ? {} : { display: "none" }}
          >
            <div className="bx-search2 fixed-column3">
              <div className="bx-item column-3column">
                <div className="div-label">
                  <label className="txt-label">Business Division</label>
                </div>
                <div className="bx-inp comRadiBox-inp">
                  <div className="inp-radio-sample">
                    <p className="fz-11">Sample: </p>
                    <div className="flex-wrap">
                      <ComRadioBox
                        ref={entityDivisionSample}
                        elementId={"entityDivisionSample"}
                        radioGroupName={"entityDivision"}
                        // callRelationFuntion={onChangeBusinessDivision}
                        direction={1}
                        defaultDisabled={true}
                      />
                    </div>
                  </div>
                  <div className="inp-radio-sample">
                    <p className="fz-11">Showroom: </p>
                    <div className="flex-wrap">
                      <ComRadioBox
                        ref={entityDivisionShowroom}
                        elementId={"entityDivisionShowroom"}
                        radioGroupName={"entityDivision"}
                        // callRelationFuntion={onChangeBusinessDivision}
                        direction={1}
                        defaultDisabled={true}
                      />
                    </div>
                  </div>
                  <div className="inp-radio-sample">
                    <p className="fz-11">Academy: </p>
                    <div className="flex-wrap">
                      <ComRadioBox
                        ref={entityDivisionAcademy}
                        elementId={"entityDivisionAcademy"}
                        radioGroupName={"entityDivision"}
                        // callRelationFuntion={onChangeBusinessDivision}
                        direction={1}
                        defaultDisabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Order Type</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComRadioBox
                    ref={entityOrderType}
                    radioGroupName={"entityOrderType"}
                    // callRelationFuntion={onChangeOrderType}
                    direction={1}
                    defaultDisabled={true}
                    radioList={[
                      {
                        Value: "OTHERS_OUT_NO_RETURN",
                        Description: "No Return",
                      },
                      { Value: "OTHERS_OUT_FOR_RETURN", Description: "Return" },
                    ]}
                  // defaultValue={"OTHERS_OUT_NO_RETURN"}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Reason</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComCombo
                    ref={entityReason}
                    elementId={"entityReason"}
                    // callRelationFuntion={onChangeReason}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">To be Returned Date</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComDate
                    ref={entityTobeReturnDate}
                    elementId={"entityTobeReturnDate"}
                    controlDateFormat={"MM/dd/yyyy"}
                    controlDateMask={"##/##/####"}
                    controlDateValidFormat={"__/__/____"}
                    isNullable={true}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> Bill To Code</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    ref={entityBillToCd}
                    elementId={"entityBillToCd"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> Reason Detail</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComCombo
                    ref={entityReasonDetail}
                    elementId={"entityReasonDetail"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> Requestor</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    ref={entityRequestorName}
                    elementId={"entityRequestorName"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Bill To Name</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComCombo
                    ref={entityBillToNm}
                    elementId={"entityBillToNm"}
                    // callRelationFuntion={onChangeBillToName}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Expense Dept Code</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComCombo
                    ref={entityExpenseDepartment}
                    elementId={"entityExpenseDepartment"}
                    defaultDisabled={true}
                  // callRelationFuntion={onChangeDeptCombo}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> Requestor Email</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    ref={entityRequestorEmail}
                    elementId={"entityRequestorEmail"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> Ship To Code</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComCombo
                    ref={entityShipToCode}
                    elementId={"entityShipToCode"}
                    // callRelationFuntion={onChangeShipToCode}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Expense Account</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComCombo
                    ref={entityExpenseAccount}
                    elementId={"entityExpenseAccount"}
                    // callRelationFuntion={onChangeExpenseAccount}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Liftgate(VIP Only)</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComRadioBox
                    radioList={[
                      { Value: "Y", Description: "Yes" },
                      { Value: "N", Description: "No" },
                    ]}
                    defaultValue={"N"}
                    ref={entityLiftgate}
                    radioGroupName={"entityLiftgate"}
                    direction={1}
                    defaultDisabled={true}
                  // callRelationFuntion={setRemarkText}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Delivery Address</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    ref={entityAddress1}
                    elementId={"entityAddress1"}
                    defaultMaxLength={1000}
                    defaultStyle={{ textTransform: "uppercase" }}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Expense PJT Code</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComCombo
                    ref={entityExpenseProjectCode}
                    elementId={"entityExpenseProjectCode"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> B2B-Project Code</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    ref={entityB2bProjectCode}
                    elementId={"entityB2bProjectCode"}
                    defaultStyle={{ textTransform: "uppercase" }}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Contact Name</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    ref={entityContactName}
                    elementId={"entityContactName"}
                    defaultStyle={{ textTransform: "uppercase" }}
                    defaultMaxLength={200}
                    // callRelationFuntion={setRemarkText}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Expense AU</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComCombo
                    ref={entityExpenseAu}
                    elementId={"entityExpenseAu"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> White Glove</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComRadioBox
                    radioList={[
                      { Value: "Y", Description: "Yes" },
                      { Value: "N", Description: "No" },
                    ]}
                    defaultValue={"N"}
                    ref={entityWhiteGlove}
                    radioGroupName={"entityWhiteGlove"}
                    direction={1}
                    // callRelationFuntion={setRemarkText}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Contact Phone</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    ref={entityReceiverPhoneNo}
                    elementId={"entityReceiverPhoneNo"}
                    defaultStyle={{ textTransform: "uppercase" }}
                    defaultMaxLength={30}
                    // callRelationFuntion={setRemarkText}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Expense Activity</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComCombo
                    ref={entityExpenseActivity}
                    elementId={"entityExpenseActivity"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    {showInstallation && "Installation"}
                  </label>
                </div>
                <div className="bx-inp combo inp-mix">
                  {showInstallation && (
                    <ComRadioBox
                      ref={entityInstallation}
                      radioGroupName={"entityInstallation"}
                      direction={1}
                      radioList={[
                        { Value: "Y", Description: "Yes" },
                        { Value: "N", Description: "No" },
                      ]}
                      defaultValue={"N"}
                      // callRelationFuntion={setRemarkText}
                      defaultDisabled={true}
                    />
                  )}
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Customer Email</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    ref={entityReceiverEmail}
                    elementId={"entityReceiverEmail"}
                    defaultStyle={{ textTransform: "uppercase" }}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"></label>
                </div>
                <div className="bx-inp combo inp-mix"></div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> Reference(PO.etc.)</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    ref={entityCustPoNo}
                    elementId={"entityCustPoNo"}
                    defaultMaxLength={50}
                    defaultStyle={{ textTransform: "uppercase" }}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              {showFas && (
                <div className="bx-item">
                  <div className="div-label">
                    <label className="txt-label">FA Project Code</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityFaProjectCode}
                      elementId={"entityFaProjectCode"}
                      defaultStyle={{ textTransform: "uppercase" }}
                      defaultDisabled={true}
                    />
                  </div>
                </div>
              )}
              {showFas && (
                <div className="bx-item">
                  <div className="div-label">
                    <label className="txt-label">
                      Planned installation Date
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComDate
                      ref={entityPlannedDate}
                      elementId={"entityPlannedDate"}
                      controlDateFormat={"MM/dd/yyyy"}
                      controlDateMask={"##/##/####"}
                      controlDateValidFormat={"__/__/____"}
                      isNullable={true}
                      defaultDisabled={true}
                    />
                  </div>
                </div>
              )}
              {showFas && (
                <div className="bx-item">
                  <div className="div-label">
                    <label className="txt-label">Location</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityLocation}
                      elementId={"entityLocation"}
                      defaultStyle={{ textTransform: "uppercase" }}
                      defaultDisabled={true}
                    />
                  </div>
                </div>
              )}
              <div className="bx-item column-2column">
                <div className="div-label-sample">
                  <div className="content-item-sample w-10">
                    <label className="txt-label">City</label>
                    {outOfCountry ? (
                      <ComTextBox
                        ref={entityOutOfCity}
                        elementId={"entityOutOfCity"}
                        defaultValue={outOfCity}
                        // callRelationFuntion={onChangeOutOfCity}
                        defaultDisabled={true}
                      />
                    ) : (
                      <ComTextBox
                        ref={entityCity}
                        defaultValue={city}
                        elementId={"entityCity"}
                        defaultDisabled={true}
                      // callRelationFuntion={onChangeCity}
                      />
                    )}
                  </div>
                  <div className="content-item-sample w-10">
                    <label className="txt-label">State</label>
                    {outOfCountry ? (
                      <ComTextBox
                        ref={entityOutOfState}
                        elementId={"entityOutOfState"}
                        defaultValue={outOfState}
                        // callRelationFuntion={onChangeOutOfState}
                        defaultDisabled={true}
                      />
                    ) : (
                      <ComTextBox
                        ref={entityState}
                        elementId={"entityState"}
                        defaultDisabled={true}
                        defaultValue={state}
                      // callRelationFuntion={onChangeState}
                      />
                    )}
                  </div>
                  <div className="content-item-sample">
                    ZIP
                    {outOfCountry ? (
                      <ComTextBox
                        ref={entityOutOfZip}
                        elementId={"entityOutOfZip"}
                        defaultDisabled={true}
                      />
                    ) : (
                      <ComTextBox
                        ref={entityZip}
                        elementId={"entityZip"}
                        defaultDisabled={true}
                      />
                    )}
                  </div>
                  <div className="content-item-sample w-40">
                    Country
                    <ComCombo
                      ref={entityCountry}
                      elementId={"entityCountry"}
                      defaultDisabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Pick Remark</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextArea
                    ref={entityPickingRemark}
                    elementId={"entityPickingRemark"}
                    defaultDisabled={true}
                  />
                </div>
              </div>

              <div className="column-2column">
                <div className="ct-fl-sample-request">
                  <div className="bx-item">
                    <div className="div-label">
                      <label className="txt-label">Delivery Customer</label>
                    </div>
                    <div className="bx-inp combo inp-mix">
                      <ComTextBox
                        ref={entityReceiverName}
                        elementId={"entityReceiverName"}
                        defaultMaxLength={30}
                        defaultStyle={{ textTransform: "uppercase" }}
                        defaultDisabled={true}
                      />
                    </div>
                  </div>
                  <div className="bx-item">
                    <div className="div-label">
                      <label className="txt-label">Delivery Arrival Date</label>
                    </div>
                    <div className="bx-inp timeToMin combo">
                      <div>
                        <ComDate
                          ref={entityReqArrivalDate}
                          elementId={"entityReqArrivalDate"}
                          controlDateFormat={"MM/dd/yyyy"}
                          controlDateMask={"##/##/####"}
                          controlDateValidFormat={"__/__/____"}
                          isNullable={true}
                          // callRelationFuntion={setRemarkText}
                          defaultDisabled={true}
                        />
                      </div>
                      <div>
                        <ComCombo
                          ref={entityDeliveryTime1}
                          elementId={"entityDeliveryTime1"}
                          defaultDisabled={true}
                        />
                      </div>
                      ~{" "}
                      <div>
                        <ComCombo
                          ref={entityDeliveryTime2}
                          elementId={"entityDeliveryTime2"}
                          defaultDisabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ct-fl-sample-request">
                  {" "}
                  <div className="bx-item">
                    <div className="div-label">
                      <label className="txt-label">Purpose Type</label>
                    </div>
                    <div className="bx-inp combo inp-mix">
                      <ComCombo
                        ref={entityPurposeType}
                        elementId={"entityPurposeType"}
                        defaultDisabled={true}
                      />
                    </div>
                  </div>
                  <div className="bx-item">
                    <div className="div-label">
                      <label className="txt-label">Ship Method Code</label>
                    </div>
                    <div className="bx-inp combo inp-mix">
                      <ComCombo
                        ref={entityShipMethodCode}
                        elementId={"entityShipMethodCode"}
                        defaultDisabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Delivery Remark</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextArea
                    ref={entityDeliveryRemark}
                    elementId={"entityDeliveryRemark"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item column-3column">
                <div className="div-label">
                  <label className="txt-label">Reason For Air Shipment</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextArea
                    ref={entityAirShipmentReason}
                    elementId={"entityAirShipmentReason"}
                    defaultDisabled={true}
                    defaultStyle={{
                      resize: "vertical",
                      minHeight: "40px",
                    }}
                  />
                </div>
              </div>
              <div className="bx-item column-3column">
                <div className="div-label">
                  <label className="txt-label">
                    Attach File for <br />Air Shipment
                  </label>
                </div>
                <div className="bx-inp combo inp-mix inp-column">
                  <div className="layout-file">
                    <div className="file-group">
                      {airFileList.map((file, index) => (
                        <div className="file-list">
                          <div className="file-stt">{index + 1}</div>
                          <div className="file-name">
                            <span
                              onClick={(e) =>
                                SampleRequestUtils.downloadFile(e, file)
                              }
                            >
                              {file.displayFileName}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bx-item column-3column">
                <div className="div-label">
                  <label className="txt-label">Sample Purpose</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextArea
                    ref={entityShippingRemark}
                    elementId={"entityShippingRemark"}
                    defaultDisabled={true}
                    defaultStyle={{
                      resize: "vertical",
                      minHeight: "40px",
                    }}
                  />
                </div>
              </div>
              <div className="bx-item column-3column">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span> Attach File
                  </label>
                </div>
                <div className="bx-inp">
                  <div className="layout-file">
                    {(query.current?.orderStatus === "WAITINGAPPROVE" || query.current?.orderStatus === "APPROVING") && (
                      <div className="file-search">
                        <Button
                          variant="outlined"
                          style={{ margin: "0px 10px" }}
                          onClick={onClickUploadFile}
                        >
                          {"Find File"}
                        </Button>
                        <input
                          type="file"
                          onChange={(e) =>
                            SampleUtils.checkFile(
                              e,
                              fileList,
                              SampleRequestUtils.uploadFileExcute,
                              setFileList,
                              fileElement,
                              userId
                            )
                          }
                          ref={fileElement}
                          style={{ display: "none" }}
                          multiple
                        />
                      </div>
                    )}
                    <div className="file-group">
                    {query?.current?.orderStatus === "WAITINGAPPROVE" || query?.current?.orderStatus === "APPROVING" ?
                      fileList &&
                      fileList?.filter((file) => (file?.isDeleted ?? false) == false)
                      ?.map((file, index) => (
                        <div
                          className="file-list"
                          key={file.serverFileName}
                        >
                          <div className="file-stt">{index + 1}</div>
                          <div className="file-name">
                            {!file?.isOld && <span style={{textDecoration: "none", cursor: "default"}}>{file.displayFileName}</span>}
                            {file?.isOld && <span onClick={(e) => { SampleRequestUtils.downloadFile(e, file) }} >
                              {file.displayFileName}
                            </span>}
                            <img
                              src={close}
                              width="12px"
                              onClick={(e) =>
                                removeFile(e, file)
                              }
                            />
                          </div>
                        </div>
                      ))
                    :
                      fileList?.map((file, index) => (
                        <div className="file-list">
                          <div className="file-stt">{index + 1}</div>
                          <div className="file-name">
                            <span
                              onClick={(e) =>
                                SampleRequestUtils.downloadFile(e, file)
                              }
                            >
                              {file.displayFileName}
                            </span>
                          </div>
                        </div>
                    ))
                    }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="table-area-wrapper">
          <div className="tb-content">
            <div className="bx-top">
              <div>
                <div className="txt-title">
                  <h3>{"Model List"}</h3>
                  <p className="txt-total">
                    {"Total"}
                    <strong className="txt-num">{gridDataCount}</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="bx-table">
              <FlexGrid
                columnGroups={gridColGroup} // 그리드 포멧
                itemsSource={gridData} // 바인딩할 데이터
                initialized={initGrid} // 그리드 초기 이벤트 및 등록 처리
                formatItem={gridFormatItem}
                style={{ minheight: "200px" }}
              >
              </FlexGrid>
            </div>
          </div>
        </div>

        <FollowUpRemark props={followUpProps} />
        <ApprovalLine props={apprProps} pageInfo={pageInfo} isEpLogin={props?.epLogin} />
      </div>

      {openModalArComment && (
        <ARCommentPop
          open={openModalArComment}
          closeFn={closeArCommentPopup}
          randomKey={randomKey}
          headerId={query?.current?.headerId}
          recipient={query?.current?.requesterEmail}
          recipientName={query?.current?.originalNm}
          pageInfo={pageInfo}
        />
      )}
      {openModalAddressInfo && (
        <AddressInfoPop
          open={openModalAddressInfo}
          closeFn={closeAddressInfoPopup}
          randomKey={randomKey}
          headerId={query?.current?.headerId}
          lineId={addressInfoPopupLineId?.current}
          pageInfo={pageInfo}
        />
      )}
      {openModalOutstandingList && (
        <OutstandingListPop
          open={openModalOutstandingList}
          closeFn={closeOutstandingListPopup}
          randomKey={randomKey}
          userId={query?.current?.requesterEmail}
          pageInfo={pageInfo}
        />
      )}
    </div>
  );
}

export default SampleRequestApprover;
