import { useState, useEffect, useContext, useRef, useCallback } from "react";
import { Button, Divider } from "@mui/material";
import "assets/styles/pages/PagesCommon.scss";

import moment from 'moment/moment';
import { ComUtils } from "common/lib/ComUtils";
import { SampleRequestUtils } from "Pages/Spl/Common/SampleRequestUtils";
import { ComNoticfication } from "common/components/ComNoticfication";
import { SampleUtils } from "Pages/Spl/Common/SampleUtils";

import * as wijmo from "@grapecity/wijmo";
import { CollectionView } from "@grapecity/wijmo";

//import * as wjGrid from '@grapecity/wijmo.grid';
import _ from "lodash";
import { FlexGrid } from "@grapecity/wijmo.react.grid";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import { createElement, format } from "@grapecity/wijmo";

import { MessageContext } from "common/lib/MessageProvider";

import Breadcrumb from "common/components/ComBreadcrumb";

import CreditCardInfoPop from "Pages/Pop/Spl/CreditCardInfoPop";

import { ApiManager } from 'common/lib/ApiUtil';
import SampleOrderTopNav from "./Common/SampleOrderTopNav";
import SampleOrderDetail from "./SampleOrderDetail";
import ApprovalLine from "./Common/ApprovalLine";

function SampleTrackingGift({ pageInfo, props, closeFn }) {

  // 메세지 추가
  const { messageObject } = useContext(MessageContext);

  //info 메시지
  const savedMessage = ComUtils.getMessage(messageObject, "SMSG_COM_INF_004");        //Saved Successfully

  //error 메시지
  const msgSplErr001 = ComUtils.getMessage(messageObject, "SPL_ERR_00001");           //An error occurred during save

  const userId = sessionStorage.getItem("userId").toString();
  const emailId = sessionStorage.getItem("emailId").toString();
  const comCode = sessionStorage.getItem("comCode").toString();
  const userGroup = sessionStorage.getItem("userGroup").toString();

  //url
  const retrieveOrderDetailForAppr = "/order/sampleOrderTracking/retrieveOrderDetailForAppr";
  const saveSplFileList = "/spl/sampleRequest/saveSplFileList";

  // popup
  const [randomKey, setRandomKey] = useState(null);

  const initState = useRef(false);
  const query = useRef(null);
  const dtlRef = useRef(null);

  const [orderInfo, setOrderInfo] = useState({});
  const [config, setConfig] = useState({});

  const [headerId, setHeaderId] = useState('');
  const [requestDate, setRequestDate] = useState('');
  const [salesOrderNo, setSalesOrderNo] = useState('');
  const [apprProps, setApprProps] = useState(null);
  const [cardInfoProps, setCardInfoProps] = useState({});

  const gridObject = useRef();
  const [gridColGroup, setGridColGroup] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [gridDataCount, setGridDataCount] = useState(0);


  const onClickToList = () => {
    if (closeFn) {
      closeFn()
    }
  }

  const onSave = async () => {
    let fileList = dtlRef.current?.getFileList();
    let sendParam = {
      headerId: headerId,
      fileList: fileList,
      userId: userId
    };

    let resultData = await ApiManager.post(saveSplFileList, sendParam);

    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgSplErr001);
    } else {
      ComNoticfication.ShowAlertUICallback(
        ComUtils._altTitleInfo, 
        savedMessage,
        () => {
          getPageInfo();
        }
      );
    }
  }

  const initGrid = (sender) => {
    gridObject.current = sender;
    ComUtils.gridInit(sender, {
      selectionMode: 6,
      hasCheckBox: false,
      showCheckAll: false,
      hasDisableCheck: true,
      canAreaInfo: false,
    });
    sender.allowSorting = false;
  };


  const initGridFormat = useCallback(
    (
      findModel,
      findWareHouse,
      subInventoryMap,
    ) => {
      let gridFormat = [
        {
          binding: "lineNo",
          header: "Line No",
          width: 70,
          isReadOnly: true,
          allowDragging: true,
          align: "center",
        },
        {
          binding: "modelSuffix",
          header: "Model",
          width: "*",
          minWidth: 220,
          allowDragging: true,
          isReadOnly: true,
        },
        {
          binding: "orginalShippedQty",
          header: "Original Shipped Qty",
          width: 160,
          allowDragging: true,
          isReadOnly: true,
          align: "right",
          format: "n0",
        },
        {
          binding: "clearedQty",
          header: "Cleared Qty",
          width: 160,
          allowDragging: true,
          isReadOnly: true,
          align: "right",
          format: "n0",
        },
        {
          binding: "remainingQty",
          header: "Remaining Qty",
          width: 160,
          allowDragging: true,
          isReadOnly: true,
          align: "right",
          format: "n0",
        },
        {
          binding: "unitSellingPrice",
          header: "Unit Selling Price",
          width: 160,
          allowDragging: true,
          isReadOnly: true,
          align: "right",
          format: "n2",
        },
        {
          binding: "totalAmt",
          header: "AMT",
          width: 120,
          allowDragging: true,
          isReadOnly: true,
          align: "right",
          format: "n2",
        },
        {
          binding: "reqQty",
          header: "As a Gift QTY",
          width: 160,
          allowDragging: true,
          isReadOnly: true,
          isRequired: true,
          align: "right",
          format: "n0",
        },
        {
          binding: "reqAmt",
          header: "As a Gift AMT",
          width: 160,
          align: "right",
          format: "n2",
          allowDragging: true,
          isReadOnly: true,
          isRequired: true,
        },
      ];
      setGridColGroup([...gridFormat]);
  }, []);

  const getPageInfo = async () => {
    initState.current = false;

    if (props?.epLogin) {
      ComUtils.removeEpLoginSessionItem();
    }

    const [resultData] = await Promise.all([
      ApiManager.post(retrieveOrderDetailForAppr, props),
    ]);

    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "SPL_ERR_00002", ["sample request"]));
      return;
    }

    query.current = resultData?.query;

    resultData.query.othersOutOrderType = "OTHERS_OUT_NO_RETURN";

    const fileList = resultData?.fileList?.map(f => SampleRequestUtils.convertFile(f));
    setOrderInfo({
      ...resultData?.query,
      expenseProjectCode: resultData?.query?.expenseProjectCd,
      expenseAu: resultData?.query?.expenseAuCd,
      expenseActivity: resultData?.activityCodeList?.find(i => i.code === resultData?.query?.expenseActivityCd)?.value,
      fileList});
    setConfig({
      bizType: {list: resultData?.bizType},
      remark: {show: true},
      expenseDepartment: {required: true},
      fileList: {show: true, required: true},
    });

    setRequestDate(resultData?.query?.createDate);
    setHeaderId(props.headerId);      
    setSalesOrderNo(resultData?.query?.salesOrderNo ?? '');

    // Product List
    setGridData(
      new CollectionView(resultData?.resultList, {
          trackChanges: true,
          refreshOnEdit: false,
      })
    );
    setGridDataCount(resultData?.resultList?.length);

    // Approval Line
    setApprProps({
      actionType: resultData?.query.actionType ?? ''
      , dealType: resultData?.query.dealType
      , targetEmpNo: resultData?.query?.targetEmpNo ?? ''
      , headerId: resultData?.query?.headerId  ?? ''
      , arApprovalYn : resultData?.query?.arApprovalYn  ?? ''
      , ariEmpNo: resultData?.query?.ariEmpNo ?? ''
      , isOutstanding: resultData?.isOutstanding
    });

    initState.current = true;
  }

  const _isMounted = useRef(true);
  useEffect(() => {
    let abortController = new AbortController();

    initGridFormat();

    getPageInfo();

    // ComponentWillUnmount in Class Component
    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, []);


  const [isHiddenSearch, setHiddenSearch] = useState(true);
  return (
    <div className="page-wrap">
      <div className="breadcrumb-header"  style={{ display: props?.epLogin ? 'none' : '' }}>
        <Breadcrumb items={pageInfo} />
      </div>
      <Divider component="li" />
      <SampleOrderTopNav dealType={props?.dealType} readType={props?.readType}/>
      <div className="page-in-wrap">
        <div className="bx-card-group">
          <div className="filter-title">
            <div>
              <div className="txt-title">
                <h2>GERP SO#{salesOrderNo} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </h2>
                <div className="bx-contact-group">Request No: {headerId} | Request Date : {requestDate}</div>
              </div>              
            </div>

            <div className="link-action d-flex">
            { closeFn &&
              <Button className="btn-default" variant="outlined" onClick={() => onClickToList()}>
                {"List"}
              </Button>
            }
            {(query?.current?.orderStatus === "WAITINGAPPROVE" || query?.current?.orderStatus === "APPROVING") && (
              <Button variant="contained" onClick={() => onSave()}>
                {"Save"}
              </Button>
            )}
            </div>
          </div>

          <SampleOrderDetail ref={dtlRef} pageInfo={pageInfo} values={orderInfo} config={config}/>
        </div>
        
        <div className="table-area-wrapper">
          <div className="tb-content">
            <div className="bx-top">
              <div>
                <div className="txt-title">
                  <h3>{"Detail & Division Information"}</h3>
                  <p className="txt-total">
                    {"Total"}
                    <strong className="txt-num">{gridDataCount}</strong>
                  </p>
                </div>
              </div>
              <div className="bx-btn-group">
              </div>
            </div>
            <div className="bx-table">
              <FlexGrid
                columnGroups={gridColGroup} // 그리드 포멧
                itemsSource={gridData} // 바인딩할 데이터
                initialized={initGrid} // 그리드 초기 이벤트 및 등록 처리
                style={{ minheight: "200px" }}
              >
              </FlexGrid>
            </div>
          </div>
        </div>

        <ApprovalLine props={apprProps} pageInfo={pageInfo} isEpLogin={props?.epLogin}/>
      </div>
    </div>
  );
}

export default SampleTrackingGift;
