import { useState, useEffect, useContext, useRef, useCallback } from "react";
import ReactDOMServer from 'react-dom/server';
import { Button, Divider } from "@mui/material";
import "assets/styles/pages/PagesCommon.scss";
import logo from "assets/images/common/lge-logo.gif";

import { ComUtils } from "common/lib/ComUtils";
import { SampleRequestUtils } from "Pages/Spl/Common/SampleRequestUtils";
import { ComNoticfication } from "common/components/ComNoticfication";
import { CollectionView } from "@grapecity/wijmo";

import _ from "lodash";
import { FlexGrid } from "@grapecity/wijmo.react.grid";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import { createElement, format } from "@grapecity/wijmo";

import { MessageContext } from "common/lib/MessageProvider";
import Breadcrumb from "common/components/ComBreadcrumb";

import CreditCardInfoPop from "Pages/Pop/Spl/CreditCardInfoPop";
import CommercialInvoicePop from "Pages/Pop/Spl/CommercialInvoicePop";

import { ApiManager } from 'common/lib/ApiUtil';
import SampleOrderTopNav from "./Common/SampleOrderTopNav";
import SampleOrderDetail from "./SampleOrderDetail";
import FollowUpRemark from "./Common/FollowUpRemark";
import ApprovalLine from "./Common/ApprovalLine";

function SampleTrackingInvoice({ pageInfo, props, closeFn }) {

  const userId = sessionStorage.getItem("userId").toString();

  // 메세지 추가
  const { messageObject } = useContext(MessageContext);

  //info 메시지
  const savedMessage = ComUtils.getMessage(messageObject, "SMSG_COM_INF_004");        //Saved Successfully

  //error 메시지
  const msgSplErr001 = ComUtils.getMessage(messageObject, "SPL_ERR_00001");           //An error occurred during save
  const msgClmErr185 = ComUtils.getMessage(messageObject, "CLM_ERR_00185");           //Can not get commercial invoice info.

  //url
  const retrieveOrderDetailForAppr = "/order/sampleOrderTracking/retrieveOrderDetailForAppr";
  const retrieveCommercialDetail = "/com/splComPop/retrieveCommercialDetail";
  const saveSplFileList = "/spl/sampleRequest/saveSplFileList";

  // popup
  const [randomKey, setRandomKey] = useState(null);
  const [openModalCardInfo, setOpenModalCardInfo] = useState(false);
  const [openModalInvoice, setOpenModalInvoice] = useState(false);

  const initState = useRef(false);
  const query = useRef(null);
  const dtlRef = useRef(null);

  const [orderInfo, setOrderInfo] = useState({});
  const [config, setConfig] = useState({});

  const [headerId, setHeaderId] = useState('');
  const [requestDate, setRequestDate] = useState('');
  const [salesOrderNo, setSalesOrderNo] = useState('');
  const [followUpProps, setFollowUpProps] = useState(null);
  const [apprProps, setApprProps] = useState(null);
  const [cardInfoProps, setCardInfoProps] = useState({});

  const gridObject = useRef();
  const [gridColGroup, setGridColGroup] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [gridDataCount, setGridDataCount] = useState(0);

  const onSave = async () => {
    let fileList = dtlRef.current?.getFileList();
    let sendParam = {
      headerId: headerId,
      fileList: fileList,
      userId: userId
    };

    let resultData = await ApiManager.post(saveSplFileList, sendParam);

    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgSplErr001);
    } else {
      ComNoticfication.ShowAlertUICallback(
        ComUtils._altTitleInfo, 
        savedMessage,
        () => {
          getPageInfo();
        }
      );
    }
  }

  const getEmptyTr = (resultList) => {
    const divElements = [];

    for (let i = resultList.length; i < 12; i++) {
      divElements.push(
        <tr id={"tr_" + i} align="center">
          <td class="table_text"></td>
          <td class="table_text"></td>
          <td class="table_text"></td>
          <td class="table_text">&nbsp;</td>
          <td class="table_text"></td>
          <td class="table_text"></td>
          <td class="table_text"></td>
        </tr>);
    }
    return divElements;
  }

  const onClickInvoice = async () => {

    const width = 1000;
    const height = 800;
    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height / 2) - (height / 2);
    const printWindow = window.open('', '_blank', `width=${width},height=${height},top=${top},left=${left}`);

    let resultData = await ApiManager.post(retrieveCommercialDetail, { headerId });
    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr185);
      return;
    }

    let element = getCommercialInvoice(resultData.query, resultData.resultList);
    const htmlString = ReactDOMServer.renderToString(element);

    const cssStyles = `<style>
      .table_title {font-family: "Arial"; font-size: 12px; letter-spacing: 0px;  color: #000000; font-weight: bold; background-color: #f8f8e9;; padding-right: 5px; padding-left: 5px}
      .table_bg2 {border: 1px  solid #777777;}
      .table_text {
        font-family: "Arial"; 
        font-size: 12px;
        letter-spacing: 0px; 
        color: #000000; 
        background-color: #FFFFFF; 
        padding-right: 5px; 
        padding-left: 5px;
      }

      .page_title {
        line-height:36px;
        font-size: 36px;
        font-family:Arial, sans-serif;
      }
      .page_text12 {font-size: 12px;line-height:14px;}
      .page_text12b {font-size: 12px;font-weight: bold;line-height:14px;}
      .page_text14 {font-size: 14px;line-height:16px;}
      .page_text14b {font-size: 14px;font-weight: bold;line-height:16px;}
      .page_text16 {font-size: 16px;line-height:16px;}
      .page_text16b {font-size: 16px;font-weight: bold;line-height:18px;}

      .tr_hover{background-color: #E6E6E6;}
      .tr_hover td{
        font-family: "Arial"; 
        font-size: 12px;
        letter-spacing: 0px; 
        color: 000000; 
        padding-right: 5px; 
        padding-left: 5px;
      }
      .tr_hover input{background-color: #E6E6E6;}
      .tr_click{background-color: #FFFBD9;}
      .tr_click td{
        font-family: "Arial"; 
        font-size: 12px;
        letter-spacing: 0px; 
        color: #000000;
        padding-right: 5px; 
        padding-left: 5px;
      }
      .tr_click input{background-color: #FFFBD9;}
      .tr_click input{background-color: #FFFBD9;}
    
      .pl-20 {padding-left: 20px;}
  </style>`;

    let script = `<script>
                  window.onload = function() {
                    document.getElementById("printButton").onclick = function() {
                      debugger;
                      var checkFlag = true;
                      
                      if(document.getElementById("realBillTo1").value==""){
                        alert("Real Bill To Name Should Be Input!");
                        document.getElementById("realBillTo1").focus();
                        return;
                      }
                      if(document.getElementById("realBillTo2").value==""){
                        alert("Real Bill To Name Should Be Input!");
                        document.getElementById("realBillTo2").focus();
                        return;
                      }
                      if(document.getElementById("realBillTo3").value==""){
                        alert("Real Bill To Name Should Be Input!");
                        document.getElementById("realBillTo3").focus();
                        return;
                      }

                      document.getElementById("printButton").style.display = "none";
                      window.print();
                      document.getElementById("printButton").style.display = "block";
                    };
                  };
                </script>`;


    let printHtml = htmlString + script + cssStyles;
    printWindow.document.writeln(printHtml);
    printWindow.document.close();
    printWindow.document.title = "LG Electronics :: AIC";
    printWindow.focus();
  }

  const onClickToList = () => {
    if (closeFn) {
      closeFn()
    }
  }

  const closeModalCardInfo = async (saveItem) => {
    setOpenModalCardInfo(false);
  }

  const closeModalInvoice = () => {
    setOpenModalInvoice(false);
  }

  const initGrid = (sender) => {
    gridObject.current = sender;
    ComUtils.gridInit(sender, {
      selectionMode: 6,
      hasCheckBox: false,
      showCheckAll: false,
      hasDisableCheck: true,
      canAreaInfo: false,
    });
    sender.allowSorting = false;
  };


  const initGridFormat = useCallback(
    () => {
      let gridFormat = [
        {
          binding: "lineNo",
          header: "Line No",
          width: 70,
          isReadOnly: true,
          allowDragging: true,
          align: "center",
        },
        {
          binding: "modelSuffix",
          header: "Model",
          width: "*",
          minWidth: 180,
          allowDragging: true,
          isReadOnly: true,
        },
        {
          binding: "orginalShippedQty",
          header: "Original Shipped Qty",
          width: 160,
          allowDragging: true,
          isReadOnly: true,
          align: "right",
          format: "n0",
        },
        {
          binding: "clearedQty",
          header: "Cleared Qty",
          width: 160,
          allowDragging: true,
          isReadOnly: true,
          align: "right",
          format: "n0",
        },
        {
          binding: "remainingQty",
          header: "Remaining Qty",
          width: 160,
          allowDragging: true,
          isReadOnly: true,
          align: "right",
          format: "n0",
        },
        {
          binding: "unitListPrice",
          header: "Price",
          width: 120,
          allowDragging: true,
          isReadOnly: true,
          align: "right",
          format: "n2",
          isRequired: true,
        },
        {
          binding: "reqQty",
          header: "Qty",
          width: 100,
          allowDragging: true,
          isReadOnly: true,
          align: "right",
          format: "n0",
          isRequired: true,
        },
        {
          binding: "shipTo",
          header: "Ship To",
          width: 160,
          align: "center",
          allowDragging: true,
          isReadOnly: true,
          isRequired: true,
        },
        {
          binding: "paymentMethodNm",
          header: "Payment Method",
          width: 120,
          allowDragging: true,
          isReadOnly: true,
          isRequired: true,
          cellTemplate: (ctx, cell) => {
            cell?.firstChild?.remove();
            let dataItem = ctx.row.dataItem;
            let value = ctx.value;

            let element = createElement(
              format(
                `
                  <div style="display: flex; justify-content: space-between">
                    <span>${value}</span>
                    <div style="display: ${dataItem.paymentMethod == 'N0010FSN1708' ? 'block' : 'none'}; padding-left: 10px;">
                      <div style="border: solid 1px gray; padding: 1px 8px; border-radius: 2px; cursor: pointer">CCInfo</div>
                    </div>
                  </div>
              `, {}
              ), cell
            );

            let buttonElement = element.querySelector("div div");
            buttonElement.onclick = function (e) {
              e.stopPropagation();

              // open credit card info popup
              ComUtils.setComRandomKey(setRandomKey);
              setCardInfoProps(dataItem);
              setOpenModalCardInfo(true);
            };
          },
        },
        {
          binding: "checkNo",
          header: "Check No.",
          width: 120,
          allowDragging: true,
          isReadOnly: true,
        },
        {
          binding: "checkSendDate",
          header: "Send Date to NJ",
          width: 120,
          allowDragging: true,
          isReadOnly: true,
          align: "center",
        },
      ];
      setGridColGroup([...gridFormat]);
    }, []);

  const getCommercialInvoice = (query, resultList) => {
    return (
      <>
        <table width="100%" border={0} cellSpacing={0} cellPadding={0} height="100%">
          <tbody>
            <tr>
              <td style={{ padding: "5 20 0 20" }} valign="top" height={25}>
                <table width="862px" border={0} cellPadding={0} cellSpacing={1}>
                  <tbody>
                    <tr>
                      <td align="center" className="page_title">
                        COMMERCIAL INVOICE
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table width="862px" border={0} cellPadding={0} cellSpacing={1}>
                  <tbody>
                    <tr>
                      <td height={20} />
                    </tr>
                  </tbody>
                </table>
                <table width="862px" border={0} cellPadding={0} cellSpacing={1}>
                  <tbody>
                    <tr>
                      <td width="360px;">
                        <table border={0} cellPadding={0}>
                          <tbody>
                            <tr>
                              <td>
                                <img src={logo} height={24} />
                              </td>
                            </tr>
                            <tr>
                              <td className="page_text14 pl-20">111 Sylvan Ave.</td>
                            </tr>
                            <tr>
                              <td className="page_text14 pl-20">North Building</td>
                            </tr>
                            <tr>
                              <td className="page_text14 pl-20">
                                Englewood Cliffs, NJ 07632
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
                <table width="862px" border={0} cellPadding={0} cellSpacing={1}>
                  <tbody>
                    <tr>
                      <td height={20} />
                    </tr>
                  </tbody>
                </table>
                <table width="862px" border={0} cellPadding={0} cellSpacing={1}>
                  <tbody>
                    <tr>
                      <td>
                        <strong>BILL TO</strong>
                      </td>
                      <td />
                      <td />
                    </tr>
                    <tr>
                      <td width={350}>
                        <table
                          width="100%"
                          align="center"
                          height="100px;"
                          className="table_bg2"
                        >
                          <tbody>
                            <tr>
                              <td align="center">{query.receiverName}</td>
                            </tr>
                            <tr>
                              <td align="center">
                                Real Bill To Name:
                                <input
                                  type="text"
                                  name="realBillTo1"
                                  id="realBillTo1"
                                  defaultValue=""
                                />
                              </td>
                            </tr>
                            <tr>
                              <td align="center">{query.address1},{query.address2}</td>
                            </tr>
                            <tr>
                              <td align="center">{query.city}, {query.state}, {query.zip}</td>
                            </tr>
                            <tr>
                              <td align="center">{query.receiverPhoneNo}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td className="table_text">&nbsp;</td>
                      <td width={390} className="table_text">
                        <table width="100%" border="0px;">
                          <tbody>
                            <tr>
                              <td />
                              <td width={189} align="right">
                                INVOICE NO
                              </td>
                              <td width={148} align="left">
                                <input
                                  name="textfield"
                                  type="text"
                                  size={20}
                                  maxLength={50}
                                  defaultValue={query.headerId}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td />
                              <td align="right">Date</td>
                              <td>
                                <input
                                  name="textfield2"
                                  type="text"
                                  size={20}
                                  maxLength={50}
                                  defaultValue={query.currDate}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table width="862px" border={0} cellPadding={0} cellSpacing={1}>
                  <tbody>
                    <tr>
                      <td height={20} />
                    </tr>
                  </tbody>
                </table>
                <table width="862px" border={0} cellPadding={0} cellSpacing={1}>
                  <tbody>
                    <tr>
                      <td className="table_text">
                        <strong>INVOICE MAIL TO</strong>
                      </td>
                      <td className="table_text">&nbsp;</td>
                      <td className="table_text">
                        <strong>SOLD LOCATION</strong>
                      </td>
                    </tr>
                    <tr>
                      <td width={350}>
                        <table
                          width="100%"
                          align="center"
                          height="100px;"
                          className="table_bg2"
                        >
                          <tbody>
                            <tr>
                              <td align="center">{query.receiverName}</td>
                            </tr>
                            <tr>
                              <td align="center">
                                Real Bill To Name:
                                <input
                                  type="text"
                                  name="realBillTo2"
                                  id="realBillTo2"
                                  defaultValue=""
                                />
                              </td>
                            </tr>
                            <tr>
                              <td align="center">{query.address1},{query.address2}</td>
                            </tr>
                            <tr>
                              <td align="center">{query.city}, {query.state}, {query.zip}</td>
                            </tr>
                            <tr>
                              <td align="center">{query.receiverPhoneNo}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td>&nbsp;</td>
                      <td width={390} valign="top">
                        <table
                          width="100%"
                          align="center"
                          height="100px;"
                          className="table_bg2"
                        >
                          <tbody>
                            <tr>
                              <td align="center">{query.receiverName}</td>
                            </tr>
                            <tr>
                              <td align="center">
                                Real Bill To Name:
                                <input
                                  type="text"
                                  name="realBillTo3"
                                  id="realBillTo3"
                                  defaultValue=""
                                />
                              </td>
                            </tr>
                            <tr>
                              <td align="center">{query.address1},{query.address2}</td>
                            </tr>
                            <tr>
                              <td align="center">{query.city}, {query.state}, {query.zip}</td>
                            </tr>
                            <tr>
                              <td align="center">{query.receiverPhoneNo}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table width="862px" border={0} cellPadding={0} cellSpacing={1}>
                  <tbody>
                    <tr>
                      <td height={20} />
                    </tr>
                  </tbody>
                </table>
                <table
                  width="862px"
                  border={1}
                  cellPadding={0}
                  cellSpacing={0}
                  className="table_bg2"
                >
                  <tbody>
                    <tr align="center">
                      <td className="table_title" width={200}>
                        <strong>CUSTOMER NO.</strong>
                      </td>
                      <td className="table_title" width={210}>
                        <strong>REFERENCE / P.O. NO.</strong>
                      </td>
                      <td className="table_title">
                        <strong>TERMS</strong>
                      </td>
                      <td className="table_title" width={200}>
                        <strong>DUE DATE</strong>
                      </td>
                    </tr>
                    <tr align="center">
                      <td className="table_text" width={200}>{query.billTo}</td>
                      <td className="table_text" width={210}>{query.custPoNo}</td>
                      <td className="table_text">DUE UPON RECEIPT</td>
                      <td className="table_text" width={200}>DUE UPON RECEIPT</td>
                    </tr>
                  </tbody>
                </table>
                <table width="862px" border={0} cellPadding={0} cellSpacing={1}>
                  <tbody>
                    <tr>
                      <td height={5} />
                    </tr>
                  </tbody>
                </table>
                <table
                  width="862px"
                  border={1}
                  cellPadding={0}
                  cellSpacing={0}
                  className="table_bg2"
                >
                  <tbody>
                    <tr align="center">
                      <td className="table_title" width={200}>
                        <strong>CREDIT APPROVAL NO.</strong>
                      </td>
                      <td className="table_title" width={210}>
                        <strong>CARRIER PRO NO.</strong>
                      </td>
                      <td className="table_title">
                        <strong>DATE SHIPPED</strong>
                      </td>
                      <td className="table_title">
                        <strong>SHIPPED VIA</strong>
                      </td>
                      <td className="table_title" width={80}>
                        <strong>W/H</strong>
                      </td>
                    </tr>
                    <tr align="center">
                      <td className="table_text">{query.creditApprNo}</td>
                      <td className="table_text">{query.carrierProNo}</td>
                      <td className="table_text">{query.dateShipped}</td>
                      <td className="table_text">FED EX</td>
                      <td className="table_text">{query.warehouseCode}</td>
                    </tr>
                  </tbody>
                </table>
                <table width="862px" border={0} cellPadding={0} cellSpacing={1}>
                  <tbody>
                    <tr>
                      <td height={5} />
                    </tr>
                  </tbody>
                </table>
                <table
                  width="862px"
                  border={1}
                  cellPadding={0}
                  cellSpacing={0}
                  className="table_bg2"
                >
                  <tbody>
                    <tr align="center">
                      <td
                        className="table_title"
                        width={200}
                        rowSpan={2}
                        align="center"
                      >
                        <strong>MODEL / SUFFIX</strong>
                      </td>
                      <td
                        className="table_title"
                        width={170}
                        rowSpan={2}
                        align="center"
                      >
                        <strong>DESCRIPTION</strong>
                      </td>
                      <td className="table_title" colSpan={3} align="center">
                        <strong>QUANTITY</strong>
                      </td>
                      <td className="table_title" width={93} rowSpan={2} align="center">
                        <strong>UNIT PRICE</strong>
                      </td>
                      <td className="table_title" width={93} rowSpan={2} align="center">
                        <strong>AMOUNT</strong>
                      </td>
                    </tr>
                    <tr align="center">
                      <td className="table_title" align="center">
                        <strong>ORDERED</strong>
                      </td>
                      <td className="table_title" align="center">
                        <strong>B/ORDERED</strong>
                      </td>
                      <td className="table_title" align="center">
                        <strong>SHIPPED</strong>
                      </td>
                    </tr>

                    {
                      resultList.map((item, index) => (
                        <tr id={"tr_" + index} align="center">
                          <td className="table_text">
                            {item.modelSuffix}
                            <input type="hidden" name="lineId" defaultValue={item.lineId} />
                          </td>
                          <td className="table_text">{item.prodDesc}</td>
                          <td className="table_text">{item.reqQty}</td>
                          <td className="table_text">&nbsp;</td>
                          <td className="table_text">{item.reqQty}</td>
                          <td className="table_text">{item.unitListPrice}</td>
                          <td className="table_text">{item.reqAmt}</td>
                        </tr>
                      ))
                    }
                    {resultList.length < 10 &&
                      getEmptyTr(resultList).map((item) => item)
                    }


                    <tr align="center">
                      <td colSpan={4} rowSpan={3} className="table_text">
                        <table width="100%" border={0}>
                          <tbody>
                            <tr>
                              <td className="table_text">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span style={{ backgroundColor: "#EAF1DD" }}>
                                  PLEASE SEND REMITTANCE TO:
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td align="center">LG ELECTRONICS</td>
                            </tr>
                            <tr>
                              <td align="center">AR DEPARTMENT</td>
                            </tr>
                            <tr>
                              <td align="center">111 SYLVAN AVENUE</td>
                            </tr>
                            <tr>
                              <td align="center">NORTH BUILDING</td>
                            </tr>
                            <tr>
                              <td align="center">ENGLEWOOD CLIFFS, NJ 07632</td>
                            </tr>
                            <tr>
                              <td align="center">ATTN: MARIBEL PUJOLS</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td className="table_text" rowSpan={2}>
                        &nbsp;
                      </td>
                      <td className="table_text">NET AMOUNT</td>
                      <td className="table_text">
                        <input
                          name="textfield3"
                          style={{ textAlign: "right" }}
                          type="text"
                          size={15}
                          maxLength={50}
                          readOnly=""
                          id="netAmount"
                          defaultValue={query.totAmt}
                        />
                      </td>
                    </tr>
                    <tr align="center">
                      <td className="table_text">TAX AMOUNT</td>
                      <td className="table_text">
                        <input
                          name="textfield3"
                          type="text"
                          style={{ textAlign: "right" }}
                          size={15}
                          maxLength={50}
                          id="taxAmount"
                        />
                      </td>
                    </tr>
                    <tr align="center">
                      <td className="table_text" colSpan={2}>
                        <table width="100%" border={0}>
                          <tbody>
                            <tr>
                              <td className="table_text" width="58%">
                                PLEASE PAY
                                <br />
                                THIS AMOUNT
                              </td>
                              <td
                                className="table_text page_text14b"
                                width="22%"
                                align="center"
                              >↓
                              </td>
                              <td
                                className="table_text page_text14b"
                                width="20%"
                                align="center"
                              >→
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td className="table_text">
                        <input
                          name="textfield32"
                          type="text"
                          style={{ textAlign: "right" }}
                          size={15}
                          maxLength={50}
                          id="totAmount"
                          defaultValue={query.totAmt}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  width="862px"
                  border={0}
                  cellPadding={0}
                  cellSpacing={0}
                  className="table_bg2"
                >
                  <tbody>
                    <tr>
                      <td className="table_text">
                        <table width="100%">
                          <tbody>
                            <tr>
                              <td align="center">
                                ITEMS ORDERED BUT NOT SHIPPED HAVE BEEN BACKORDERED AND
                                WILL BE SHIPPED AS SOON AS POSSIBLE.
                              </td>
                            </tr>
                            <tr>
                              <td align="center">
                                CONSIGNEE : NOTE CAREFULLY CONDITION OF PACKAGES BEFORE
                                RECEIPTING IN GOOD ORDER.
                              </td>
                            </tr>
                            <tr>
                              <td align="center">
                                The merchandise hereon was delivered to the carrier in
                                good condition. If any shortage or damage is discovered,
                              </td>
                            </tr>
                            <tr>
                              <td align="center">
                                either at time of delivery or later, it is incumbent
                                upon the consignee to file claim for redress against the
                                carrier.
                              </td>
                            </tr>
                            <tr>
                              <td align="center">
                                No returns accepted unless specifically authorized.
                              </td>
                            </tr>
                            <tr>
                              <td align="center">
                                Seller's other standard terms and conditions apply and
                                supercede any additional or conflicting provisions in
                                puchaser's order form.
                              </td>
                            </tr>
                            <tr>
                              <td align="center" className="page_text12b">
                                NOTE : PAST DUE BALANCES ARE SUBJECT TO LATE PAYMENT
                                CHARGES OF 1 1/2% PER MONTH.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table width="862px" border={0} cellpadding={0} cellspacing={1} className="noprint">
                  <tr>
                    <td align="right">
                      <button id="printButton">Print This Page</button>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </tbody>
        </table>

      </>
    );
  }

  const getPageInfo = async () => {
    initState.current = false;

    if (props?.epLogin) {
      ComUtils.removeEpLoginSessionItem();
    }

    const [resultData] = await Promise.all([
      ApiManager.post(retrieveOrderDetailForAppr, props),
    ]);

    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "SPL_ERR_00002", ["sample request"]));
      return;
    }

    query.current = resultData?.query;

    const fileList = resultData?.fileList?.map(f => SampleRequestUtils.convertFile(f));
    setOrderInfo({ ...resultData?.query, fileList });
    setConfig({
      bizType: { list: resultData?.bizType },
      remark: { show: true },
      invoiceRemark: { show: true },
      contactNm: { required: true },
      billTo: { required: true },
      address1: { required: true },
      fileList: { show: true, required: true },
    });

    setRequestDate(resultData?.query?.createDate);
    setHeaderId(props.headerId);
    setSalesOrderNo(resultData?.query?.salesOrderNo ?? '');

    // Product List
    setGridData(
      new CollectionView(resultData?.resultList, {
        trackChanges: true,
        refreshOnEdit: false,
      })
    );
    setGridDataCount(resultData?.resultList?.length);

    // FollowUp Activity
    setFollowUpProps({ headerId: resultData?.query?.headerId, remark: resultData?.query?.followUpRemark });

    // Approval Line
    setApprProps({
      actionType: resultData?.query.actionType ?? ''
      , dealType: resultData?.query.dealType
      , targetEmpNo: resultData?.query?.targetEmpNo ?? ''
      , headerId: resultData?.query?.headerId ?? ''
      , arApprovalYn: resultData?.query?.arApprovalYn ?? ''
      , ariEmpNo: resultData?.query?.ariEmpNo ?? ''
      , isOutstanding: resultData?.isOutstanding
    });

    initState.current = true;
  }

  const _isMounted = useRef(true);
  useEffect(() => {
    let abortController = new AbortController();

    initGridFormat();

    getPageInfo();

    // ComponentWillUnmount in Class Component
    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, []);


  const [isHiddenSearch, setHiddenSearch] = useState(true);
  return (
    <div className="page-wrap">
      <div className="breadcrumb-header" style={{ display: props?.epLogin ? 'none' : '' }}>
        <Breadcrumb items={pageInfo} />
      </div>
      <Divider component="li" />
      <SampleOrderTopNav dealType={props?.dealType} readType={props?.readType} />
      <div className="page-in-wrap">
        <div className="bx-card-group">
          <div className="filter-title">
            <div>
              <div className="txt-title">
                <h2>GERP SO#{salesOrderNo} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </h2>
                <div className="bx-contact-group">Request No: {headerId} | Request Date : {requestDate}</div>
              </div>
            </div>

            <div className="link-action d-flex">
              <Button className="btn-default" variant="contained" onClick={() => onClickInvoice()}>
                {"Invoice"}
              </Button>
              {closeFn &&
                <Button className="btn-default" variant="outlined" onClick={() => onClickToList()}>
                  {"List"}
                </Button>
              }
              {(query?.current?.orderStatus === "WAITINGAPPROVE" || query?.current?.orderStatus === "APPROVING") && (
                <Button variant="contained" onClick={() => onSave()}>
                  {"Save"}
                </Button>
              )}
            </div>
          </div>

          <SampleOrderDetail ref={dtlRef} pageInfo={pageInfo} values={orderInfo} config={config} />
        </div>

        <div className="table-area-wrapper">
          <div className="tb-content">
            <div className="bx-top">
              <div>
                <div className="txt-title">
                  <h3>{"Product List"}</h3>
                  <p className="txt-total">
                    {"Total"}
                    <strong className="txt-num">{gridDataCount}</strong>
                  </p>
                </div>
              </div>
              <div className="bx-btn-group">
              </div>
            </div>
            <div className="bx-table">
              <FlexGrid
                columnGroups={gridColGroup} // 그리드 포멧
                itemsSource={gridData} // 바인딩할 데이터
                initialized={initGrid} // 그리드 초기 이벤트 및 등록 처리
                style={{ minheight: "200px" }}
              >
              </FlexGrid>
            </div>
          </div>
        </div>

        <FollowUpRemark props={followUpProps} />
        <ApprovalLine props={apprProps} pageInfo={pageInfo} isEpLogin={props?.epLogin} />
      </div>

      {openModalCardInfo && <CreditCardInfoPop open={openModalCardInfo} closeFn={closeModalCardInfo} randomKey={randomKey} pageInfo={pageInfo} readType={"ready"} values={cardInfoProps} />}
      {openModalInvoice && <CommercialInvoicePop open={openModalInvoice} closeFn={closeModalInvoice} randomKey={randomKey} pageInfo={pageInfo} />}
    </div>
  );
}

export default SampleTrackingInvoice;
