import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import "assets/styles/pages/PagesCommon.scss";

import { SampleRequestUtils } from "Pages/Spl/Common/SampleRequestUtils";
import close from "assets/icons/close.svg";

import ComTextArea from "common/components/ComTextArea";
import ComTextBox from "common/components/ComTextBox";
import ComRadioBox from "common/components/ComRadioBox";

import _ from "lodash";
import { Button } from "@mui/material";
import { ApiManager } from "common/lib/ApiUtil";
import { SampleUtils } from "./Common/SampleUtils";

const SampleOrderDetail = React.forwardRef(({ values, config }, ref) => {

  const userId = sessionStorage.getItem("userId").toString();

  // entities
  const entityDivisionSample = useRef(null);
  const entityDivisionShowroom = useRef(null);
  const entityDivisionAcademy = useRef(null);

  const [showFas, setShowFas] = useState(false);
  const [fileList, setFileList] = useState([]);
  const fileElement = useRef(true);
  
  const onClickUploadFile = () => {
    fileElement.current.click();
  };

  const removeFile = async (e, fileInfo) => {
    if (fileInfo.isOld) {
      // 기존파일
      setFileList(fileList => fileList.map((file) => file.fileId == fileInfo.fileId ? { ...file, "isDeleted": true } : file));
    } else {
      // 신규 파일
      let response = await ApiManager.post("/Common/File/FileDelete", {
        "filePath": fileInfo.filePath,
        "serverFileName": fileInfo.serverFileName,
        "customPath": "sampleportal"
      });
    
      setFileList(fileList => fileList.filter(f => f.serverFileName !== fileInfo.serverFileName));
    }
  }

  const _isMounted = useRef(true);

  React.useImperativeHandle(ref, () => ({
    getValue: () => {},
    getFileList: () => (fileList),
  }));

  useEffect(() => {
    let abortController = new AbortController();

    // Business Division
    SampleRequestUtils.initBusinessDivisionList(
      entityDivisionSample,
      entityDivisionShowroom,
      entityDivisionAcademy,
      config?.bizType?.list, // config?.businessDivision.list
      values?.bizType,
      true
    );

    setShowFas(
      values?.reasonCode === "NR09" && values?.expenseAccountCd === "18300323"
    );

    let files = values?.fileList?.filter((file) => (file?.isDeleted ?? false) == false)?.map((item) => ({...item, isOld: true, }));
    setFileList(files);

    // ComponentWillUnmount in Class Component
    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, [values, config]);

  const [isHiddenSearch, setHiddenSearch] = useState(true);
  return (
    <div
      className="search-wrapper"
      style={isHiddenSearch ? {} : { display: "none" }}
    >
      <div className="bx-search2 fixed-column3">
        <div className="bx-item column-3column">
          <div className="div-label">
            <label className="txt-label">Business Division</label>
          </div>
          <div className="bx-inp comRadiBox-inp">
            <div className="inp-radio-sample">
              <p className="fz-11">Sample: </p>
              <div className="flex-wrap">
                <ComRadioBox
                  ref={entityDivisionSample}
                  elementId={"entityDivisionSample"}
                  radioGroupName={"entityDivision"}
                  direction={1}
                  defaultDisabled={true}
                />
              </div>
            </div>
            <div className="inp-radio-sample">
              <p className="fz-11">Showroom: </p>
              <div className="flex-wrap">
                <ComRadioBox
                  ref={entityDivisionShowroom}
                  elementId={"entityDivisionShowroom"}
                  radioGroupName={"entityDivision"}
                  direction={1}
                  defaultDisabled={true}
                />
              </div>
            </div>
            <div className="inp-radio-sample">
              <p className="fz-11">Academy: </p>
              <div className="flex-wrap">
                <ComRadioBox
                  ref={entityDivisionAcademy}
                  elementId={"entityDivisionAcademy"}
                  radioGroupName={"entityDivision"}
                  direction={1}
                  defaultDisabled={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bx-item">
          <div className="div-label">
            <label className="txt-label">Order Type</label>
          </div>
          <div className="bx-inp combo inp-mix">
            <ComRadioBox
              radioGroupName={"entityOrderType"}
              radioList={[
                { Value: "OTHERS_OUT_NO_RETURN", Description: "No Return" },
                { Value: "OTHERS_OUT_FOR_RETURN", Description: "Return" },
              ]}
              direction={1}
              defaultValue={values?.othersOutOrderType}
              defaultDisabled={true}
            />
          </div>
        </div>
        <div className="bx-item">
          <div className="div-label">
            <label className="txt-label">Reason</label>
          </div>
          <div className="bx-inp combo inp-mix">
            <ComTextBox
              elementId={"entityReason"}
              defaultValue={values?.reasonNm}
              defaultDisabled={true}
            />
          </div>
        </div>
        <div className="bx-item">
          <div className="div-label">
            <label className="txt-label">
              {config?.collectionDate?.required && (
                <span className="requiresimbol"> *</span>
              )}{" "}
              To be Returned Date
            </label>
          </div>
          <div className="bx-inp combo inp-mix">
            <ComTextBox
              elementId={"entityTobeReturnDate"}
              defaultValue={values?.collectionDate}
              defaultDisabled={true}
            />
          </div>
        </div>
        <div className="bx-item">
          <div className="div-label">
            <label className="txt-label">
              {config?.billTo?.required && (
                <span className="requiresimbol"> *</span>
              )}{" "}
              Bill To Code
            </label>
          </div>
          <div className="bx-inp combo inp-mix">
            <ComTextBox
              defaultValue={values?.billTo}
              elementId={"entityBillToCd"}
              defaultDisabled={true}
            />
          </div>
        </div>
        <div className="bx-item">
          <div className="div-label">
            <label className="txt-label"> Reason Detail</label>
          </div>
          <div className="bx-inp combo inp-mix">
            <ComTextBox
              defaultValue={values?.reasonDetailNm}
              elementId={"entityReasonDetail"}
              defaultDisabled={true}
            />
          </div>
        </div>
        <div className="bx-item">
          <div className="div-label">
            <label className="txt-label"> Requestor</label>
          </div>
          <div className="bx-inp combo inp-mix">
            <ComTextBox
              defaultValue={values?.originalNm}
              elementId={"entityRequestorName"}
              defaultDisabled={true}
            />
          </div>
        </div>
        <div className="bx-item">
          <div className="div-label">
            <label className="txt-label">Bill To Name</label>
          </div>
          <div className="bx-inp combo inp-mix">
            <ComTextBox
              defaultValue={values?.billToNm}
              elementId={"entityBillToNm"}
              defaultDisabled={true}
            />
          </div>
        </div>
        <div className="bx-item">
          <div className="div-label">
            <label className="txt-label">
              {config?.expenseDepartment?.required && (
                <span className="requiresimbol"> *</span>
              )}{" "}
              Expense Dept Code
            </label>
          </div>
          <div className="bx-inp combo inp-mix">
            <ComTextBox
              defaultValue={values?.expenseDepartment}
              elementId={"entityExpenseDepartment"}
              defaultDisabled={true}
            />
          </div>
        </div>
        <div className="bx-item">
          <div className="div-label">
            <label className="txt-label"> Requestor Email</label>
          </div>
          <div className="bx-inp combo inp-mix">
            <ComTextBox
              defaultValue={values?.requesterEmail}
              elementId={"entityRequestorEmail"}
              defaultDisabled={true}
            />
          </div>
        </div>
        <div className="bx-item">
          <div className="div-label">
            <label className="txt-label"> Ship To Code</label>
          </div>
          <div className="bx-inp combo inp-mix">
            <ComTextBox
              defaultValue={values?.shipTo}
              elementId={"entityShipToCode"}
              defaultDisabled={true}
            />
          </div>
        </div>
        <div className="bx-item">
          <div className="div-label">
            <label className="txt-label">Expense Account</label>
          </div>
          <div className="bx-inp combo inp-mix">
            <ComTextBox
              defaultValue={values?.expenseAccount}
              elementId={"entityExpenseAccount"}
              defaultDisabled={true}
            />
          </div>
        </div>
        <div className="bx-item">
          <div className="div-label">
            <label className="txt-label">Liftgate(VIP Only)</label>
          </div>
          <div className="bx-inp combo inp-mix">
            <ComRadioBox
              radioList={[
                { Value: "Y", Description: "Yes" },
                { Value: "N", Description: "No" },
              ]}
              defaultValue={values?.liftGateFlag}
              radioGroupName={"entityLiftgate"}
              direction={1}
              defaultDisabled={true}
            />
          </div>
        </div>
        <div className="bx-item">
          <div className="div-label">
            <label className="txt-label">
              {config?.address1?.required && (
                <span className="requiresimbol"> *</span>
              )}
              Delivery Address
            </label>
          </div>
          <div className="bx-inp combo inp-mix">
            <ComTextBox
              defaultValue={values?.address1}
              elementId={"entityAddress1"}
              defaultDisabled={true}
              defaultMaxLength={1000}
              defaultStyle={{ textTransform: "uppercase" }}
            />
          </div>
        </div>
        <div className="bx-item">
          <div className="div-label">
            <label className="txt-label">Expense Project Code</label>
          </div>
          <div className="bx-inp combo inp-mix">
            <ComTextBox
              defaultValue={values?.expenseProjectCode}
              elementId={"entityExpenseProjectCode"}
              defaultDisabled={true}
            />
          </div>
        </div>
        <div className="bx-item">
          <div className="div-label">
            <label className="txt-label"> B2B-Project Code</label>
          </div>
          <div className="bx-inp combo inp-mix">
            <ComTextBox
              defaultValue={values?.b2bProjectCode}
              elementId={"entityB2bProjectCode"}
              defaultDisabled={true}
            />
          </div>
        </div>
        <div className="bx-item">
          <div className="div-label">
            <label className="txt-label">
              {config?.contactNm?.required && (
                <span className="requiresimbol"> *</span>
              )}{" "}
              Contact Name
            </label>
          </div>
          <div className="bx-inp combo inp-mix">
            <ComTextBox
              defaultValue={values?.contactNm}
              elementId={"entityContactName"}
              defaultDisabled={config?.contactNm?.disabled ?? true}
              defaultMaxLength={200}
              defaultStyle={{ textTransform: "uppercase" }}
            />
          </div>
        </div>
        <div className="bx-item">
          <div className="div-label">
            <label className="txt-label">Expense AU</label>
          </div>
          <div className="bx-inp combo inp-mix">
            <ComTextBox
              defaultValue={values?.expenseAu}
              elementId={"entityExpenseAu"}
              defaultDisabled={true}
            />
          </div>
        </div>
        <div className="bx-item">
          <div className="div-label">
            <label className="txt-label"> White Glove</label>
          </div>
          <div className="bx-inp combo inp-mix">
            <ComRadioBox
              radioList={[
                { Value: "Y", Description: "Yes" },
                { Value: "N", Description: "No" },
              ]}
              defaultValue={values?.whiteGloveFlag}
              radioGroupName={"entityWhiteGlove"}
              direction={1}
              defaultDisabled={true}
            />
          </div>
        </div>
        <div className="bx-item">
          <div className="div-label-sample">
            <div className="content-item-sample">
              <label className="txt-label">City</label>
              <ComTextBox
                defaultValue={values?.city}
                elementId={"entityCity"}
                defaultDisabled={true}
              />
            </div>
            <div className="content-item-sample">
              <label className="txt-label">State</label>
              <ComTextBox
                defaultValue={values?.state}
                elementId={"entityState"}
                defaultDisabled={true}
              />
            </div>
            <div className="content-item-sample">
              ZIP
              <ComTextBox
                defaultValue={values?.zip}
                elementId={"entityZip"}
                defaultDisabled={true}
              />
            </div>
          </div>
        </div>
        <div className="bx-item">
          <div className="div-label">
            <label className="txt-label">Expense Activity</label>
          </div>
          <div className="bx-inp combo inp-mix">
            <ComTextBox
              defaultValue={values?.expenseActivity}
              elementId={"entityExpenseActivity"}
              defaultDisabled={true}
            />
          </div>
        </div>
        <div className="bx-item">
          <div className="div-label">
            <label className="txt-label">Delivery Arrival Date</label>
          </div>
          <div className="bx-inp combo inp-mix">
            <ComTextBox
              defaultValue={values?.reqArrivalDate}
              elementId={"entityReqArrivalDate"}
              defaultDisabled={true}
            />
          </div>
        </div>
        {showFas && (
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">FA Project Code</label>
            </div>
            <div className="bx-inp combo inp-mix">
              <ComTextBox
                defaultValue={values?.faProjectCode}
                elementId={"entityFaProjectCode"}
                defaultDisabled={true}
              />
            </div>
          </div>
        )}
        {showFas && (
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">Planned installation Date</label>
            </div>
            <div className="bx-inp combo inp-mix">
              <ComTextBox
                defaultValue={values?.faInstallDate}
                elementId={"entityPlannedDate"}
                defaultDisabled={true}
              />
            </div>
          </div>
        )}
        {showFas && (
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                <span className="requiresimbol"> *</span> Location
              </label>
            </div>
            <div className="bx-inp combo inp-mix">
              <ComTextBox
                defaultValue={values?.faLocation}
                elementId={"entityLocation"}
                defaultDisabled={true}
              />
            </div>
          </div>
        )}
        <div className="column-2column">
          <div className="ct-fl-sample-request">
            <div className="bx-item">
              <div className="div-label">
                <label className="txt-label">Delivery Customer</label>
              </div>
              <div className="bx-inp combo inp-mix">
                <ComTextBox
                  defaultValue={values?.receiverName}
                  elementId={"entityReceiverName"}
                  defaultDisabled={config?.receiverName?.disabled ?? true}
                  defaultMaxLength={30}
                  defaultStyle={{ textTransform: "uppercase" }}
                />
              </div>
            </div>
            <div className="bx-item">
              <div className="div-label">
                <label className="txt-label"> Reference(PO.etc.)</label>
              </div>
              <div className="bx-inp combo inp-mix">
                <ComTextBox
                  defaultValue={values?.custPoNo}
                  elementId={"entityCustPoNo"}
                  defaultDisabled={true}
                  defaultMaxLength={50}
                />
              </div>
            </div>
          </div>
          <div className="ct-fl-sample-request">
            <div className="bx-item">
              <div className="div-label">
                <label className="txt-label">Contact Phone</label>
              </div>
              <div className="bx-inp combo inp-mix">
                <ComTextBox
                  defaultValue={values?.receiverPhoneNo}
                  elementId={"entityReceiverPhoneNo"}
                  defaultDisabled={config?.receiverPhoneNo?.disabled ?? true}
                  defaultMaxLength={30}
                />
              </div>
            </div>
            <div className="bx-item">
              <div className="div-label">
                <label className="txt-label">Ship Method Code</label>
              </div>
              <div className="bx-inp combo inp-mix">
                <ComTextBox
                  defaultValue={values?.shipMethodCode}
                  elementId={"entityShipMethodCode"}
                  defaultDisabled={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bx-item">
          <div className="div-label">
            <label className="txt-label">Pick Remark</label>
          </div>
          <div className="bx-inp combo inp-mix">
            <ComTextArea
              defaultValue={values?.pickingRemark}
              elementId={"entityPickingRemark"}
              defaultDisabled={true}
            />
          </div>
        </div>
        <div className="bx-item column-3column">
          <div className="div-label">
            <label className="txt-label">Sample Purpose</label>
          </div>
          <div className="bx-inp combo inp-mix">
            <ComTextArea
              defaultValue={values?.shippingRemark}
              elementId={"entityShippingRemark"}
              defaultDisabled={true}
              defaultStyle={{
                resize: "vertical",
                minHeight: "40px",
              }}
            />
          </div>
        </div>
        {config?.remark?.show && (
          <div className="bx-item column-3column">
            <div className="div-label">
              <label className="txt-label">Request Remark</label>
            </div>
            <div className="bx-inp combo inp-mix">
              <ComTextArea
                defaultValue={values?.remark}
                elementId={"entityRequestRemark"}
                defaultDisabled={true}
                defaultStyle={{
                  resize: "vertical",
                  minHeight: "40px",
                }}
              />
            </div>
          </div>
        )}
        {config?.invoiceRemark?.show && (
          <div className="bx-item column-3column">
            <div className="div-label">
              <label className="txt-label">Invoice Remark</label>
            </div>
            <div className="bx-inp combo inp-mix">
              <ComTextArea
                defaultValue={values?.invoiceRemark}
                elementId={"entityInvoiceRemark"}
                defaultDisabled={true}
                defaultStyle={{
                  resize: "vertical",
                  minHeight: "40px",
                }}
              />
            </div>
          </div>
        )}
        {config?.transferPurpose?.show && (
          <div className="bx-item column-3column">
            <div className="div-label">
              <label className="txt-label">Transfer Purpose</label>
            </div>
            <div className="bx-inp combo inp-mix">
              <ComTextArea
                defaultValue={values?.remark}
                elementId={"entityTransferPurpose"}
                defaultDisabled={true}
                defaultStyle={{
                  resize: "vertical",
                  minHeight: "40px",
                }}
              />
            </div>
          </div>
        )}
        {config?.currPackaging?.show && (
          <div className="bx-item column-3column">
            <div className="div-label">
              <label className="txt-label">Special Instruction</label>
            </div>
            <div className="bx-inp combo inp-mix">
              <ComTextArea
                defaultValue={values?.currPackaging}
                elementId={"entitySpecialInstruction"}
                defaultDisabled={config?.currPackaging?.disabled ?? true}
                defaultStyle={{
                  resize: "vertical",
                  minHeight: "40px",
                }}
              />
            </div>
          </div>
        )}
        {config?.fileList?.show && (
          <div className="bx-item column-3column">
            <div className="div-label">
              <label className="txt-label">
                {config?.fileList?.required && (
                  <span className="requiresimbol"> *</span>
                )}{" "}
                Attach File
              </label>
            </div>
            <div className="bx-inp">
              <div className="layout-file">
                {(values?.orderStatus === "WAITINGAPPROVE" || values?.orderStatus === "APPROVING") && (
                  <div className="file-search">
                    <Button
                      variant="outlined"
                      style={{ margin: "0px 10px" }}
                      onClick={onClickUploadFile}
                    >
                      {"Find File"}
                    </Button>
                    <input
                      type="file"
                      onChange={(e) =>
                        SampleUtils.checkFile(
                          e,
                          fileList,
                          SampleRequestUtils.uploadFileExcute,
                          setFileList,
                          fileElement,
                          userId
                        )
                      }
                      ref={fileElement}
                      style={{ display: "none" }}
                      multiple
                    />
                  </div>
                )}
                <div className="file-group">
                {values?.orderStatus === "WAITINGAPPROVE" || values?.orderStatus === "APPROVING" ?
                  fileList &&
                  fileList?.filter((file) => (file?.isDeleted ?? false) == false)
                  ?.map((file, index) => (
                    <div
                      className="file-list"
                      key={file.serverFileName}
                    >
                      <div className="file-stt">{index + 1}</div>
                      <div className="file-name">
                        {!file?.isOld && <span style={{textDecoration: "none", cursor: "default"}}>{file.displayFileName}</span>}
                        {file?.isOld && <span onClick={(e) => { SampleRequestUtils.downloadFile(e, file) }} >
                          {file.displayFileName}
                        </span>}
                        <img
                          src={close}
                          width="12px"
                          onClick={(e) =>
                            removeFile(e, file)
                          }
                        />
                      </div>
                    </div>
                  ))
                :
                  fileList?.map((file, index) => (
                    <div className="file-list">
                      <div className="file-stt">{index + 1}</div>
                      <div className="file-name">
                        <span
                          onClick={(e) =>
                            SampleRequestUtils.downloadFile(e, file)
                          }
                        >
                          {file.displayFileName}
                        </span>
                      </div>
                    </div>
                ))
                }
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default React.memo(SampleOrderDetail);
